import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResortLabel } from '../shared/models/resort/resort-label';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ResortLabelService extends RepositoryService {
  private URL_LABELS = 'api/webapp/resort-label';

  constructor(http: HttpClient) {
    super(http);
  }

  getLabels(): Observable<ResortLabel[]> {
    return this.get(this.URL_LABELS);
  }
}
