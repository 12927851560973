import { Pipe, PipeTransform } from '@angular/core';
import { Establishment } from '../models/accommodation/establishment';

@Pipe({
  standalone: true,
  name: 'toEstablishmentLinks',
  pure: true
})
export class ToEstablishmentLinksPipe implements PipeTransform {
  transform(establishment: Establishment, resortName?: string) {
    return establishment.mapLinkCard(resortName);
  }
}
