import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AtomicLibModule, ScreenPipe } from 'atomic-lib';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../../environments/environment';
import { CartModule } from '../cart/cart.module';
import { FooterModule } from '../footer/footer.module';
import { SharedModule } from '../shared/shared.module';
import { NgxTranslateModule } from '../store/translate.module';
import { PaymentRoutingModule } from './payment-routing.module';
import { PaymentComponent } from './payment.component';
import { StepConnexionComponent } from './step-connexion/step-connexion.component';
import { FormParticipantPaymentComponent } from './step-participants-information/form-participant-payment/form-participant-payment.component';
import { StepParticipantsInformationComponent } from './step-participants-information/step-participants-information.component';
import { StepPaymentComponent } from './step-payment/step-payment.component';

@NgModule({
  declarations: [
    PaymentComponent,
    StepConnexionComponent,
    FormParticipantPaymentComponent,
    StepPaymentComponent,
    StepParticipantsInformationComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxTranslateModule,
    SharedModule,
    PaymentRoutingModule,
    NgxStripeModule.forRoot(environment.stripeKey),
    FormsModule,
    ReactiveFormsModule,
    AtomicLibModule,
    CartModule,
    ScreenPipe,
    FooterModule
  ],
  exports: [PaymentComponent]
})
export class PaymentModule {}
