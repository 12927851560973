import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Carousel } from '../shared/models/carousel';
import { TextBlock } from '../shared/models/text-block';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class CmsService extends RepositoryService {
  private URL_ACTIVITY = 'api/webapp/cms';

  constructor(http: HttpClient) {
    super(http);
  }

  getAllTextBlocks(): Observable<TextBlock[]> {
    return this.get(this.URL_ACTIVITY + '/text-blocks');
  }

  getCarouselsByZone(zone: number): Observable<Carousel[]> {
    return this.get(this.URL_ACTIVITY + '/carousels?zoneId=' + zone);
  }
}
