import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

/**
 * TODO : Move to atomic-lib
 */
@Component({
  selector: 'vsk-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent {
  searchControl: FormControl<string | null> = new FormControl<string | null>(
    ''
  );

  @Input() set search(value: string) {
    if (value) {
      this.searchControl.setValue(value);
    }
  }
  @Input() placeholder = 'Rechercher ...';
  @Input() values: any[];
  @Input() property: string;
  @Output() filteredValues: EventEmitter<any[]> = new EventEmitter<any[]>();

  constructor() {
    this.searchControl.valueChanges.subscribe(this.filter.bind(this));
  }

  filter(search: string | null): void {
    const searchLower = search?.toLowerCase();
    this.filteredValues.emit(
      this.values
        .filter(
          (val) =>
            val[this.property].toLowerCase().indexOf(search?.toLowerCase()) !==
            -1
        )
        .sort((a, b) => {
          const indexA = a[this.property].toLowerCase().indexOf(searchLower);
          const indexB = b[this.property].toLowerCase().indexOf(searchLower);
          return indexA - indexB;
        })
    );
  }
}
