import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KeyCardCheck } from '../shared/models/skipass/key-card-check';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class CartElibSkiPassService extends RepositoryService {
  private URL_DOMAIN = 'api/webapp/eliberty';

  constructor(http: HttpClient) {
    super(http);
  }

  validateKeyCard(
    keyCardNumber: string,
    cardType: string
  ): Observable<KeyCardCheck> {
    return this.get(
      this.URL_DOMAIN +
        `/keycard-validation?keyCardNumber=${keyCardNumber}&cardType=${cardType}`
    );
  }
}
