<vsk-header-filters *ngIf="windowResource.width() | async | screen: 'small-desktop-above'"
                    [filtersTripInfo]="filtersMap$ | async"
                    [filters]="filtersStation$ | async"
                    [resortLabels]="resortLabels$ | async"
                    [tags]="tags$ | async">
</vsk-header-filters>

<div class="flex flex-row max-h-full max-w-full overflow-y-hidden min-h-full">
  <vsk-side-map (outMarker)="outMarker($event)"
                (overMarker)="overMarker($event)"
                [activities]="activities$ | async"
                [allStations]="allStations"
                [currentPage]="pageNumber"
                [filtersMap]="filtersMap$ | async"
                [filters]="filtersStation$ | async"
                [loading]="loadingStation"
                [nbNights]="nbNights"
                [ngClass]="{'hide': showMap, 'z-[2]': windowResource.width() | async | mobile }"
                [remainingResortsMap]="remainingStations"
                [resortLabels]="resortLabels$ | async"
                [resortsMap]="resortsMap"
                [selectedActivities]="selectedActivities$ | async"
                [tags]="tags$ | async"
                [totalStationsFiltered]="totalStationsFiltered">
  </vsk-side-map>
  <google-map
    (mapClick)="hideMarker()"
    (mapDragend)="boundsChanged()"
    [center]="center"
    [class]="{'show-map': showMap}"
    [options]="options"
    [zoom]="zoom"
    height="100%"
    width="100%">
    <div class="refresh-map-on-zoom">
      <atc-input-checkbox [control]="refreshOnMoveMap">
        <p>Rafraîchir les stations en déplaçant la carte</p>
      </atc-input-checkbox>
    </div>
    <map-marker #marker="mapMarker"
                (mapClick)="openInfoWindow(pinpoint, marker)"
                (mapMouseout)="outMarker(pinpoint)"
                (mapMouseover)="overMarker(pinpoint)"
                *ngFor="let pinpoint of markers"
                [icon]="pinpoint.icon"
                [label]="pinpoint.label"
                [options]="pinpoint.options"
                [position]="pinpoint.position"
                [title]="pinpoint.title">
    </map-marker>
    <map-info-window *ngFor="let pinpoint of markers">
      <vsk-list-station-card *ngIf="stationPinpoint"
                             [activities]="activities$ | async"
                             [filters]="filters"
                             [isInMap]="true"
                             [nbNights]="nbNights"
                             [selectedActivities]="selectedActivities$ | async"
                             [station]="stationPinpoint">
      </vsk-list-station-card>
    </map-info-window>
  </google-map>

  <atc-button (click)="changeView()"
              *ngIf="windowResource.width() | async | mobile"
              [float]="true"
              [icon]="showMap ? 'list' : 'map'"
              [label]="showMap ? 'Voir la liste' : 'Voir la carte'"
              [ngClass]="{'z-[2]': (popup$ | async) === false}"
              class="left-8 bottom-[5rem] fixed"
              color="ternary">
  </atc-button>
</div>
