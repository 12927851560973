import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UpdateUserPass } from '../../app.action';
import { AppState } from '../../app.state';
import { UserService } from '../../service/user.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { UserSession } from '../../shared/models/user-session';

@Component({
  selector: 'vsk-account-pass',
  templateUrl: './account-pass.component.html',
  styleUrls: ['./account-pass.component.scss']
})
export class AccountPassComponent extends RxjsComponent {
  @Select(AppState.user) user$: Observable<UserSession>;

  loadingInformation = true;
  error: string;
  success: string;

  skiPassForm: FormGroup<SkiPassForm>;
  skidataForm = new FormControl<string>(
    '',
    Validators.pattern(new RegExp('^[0-9]{23}$'))
  );
  axessForm = new FormControl<string>(
    '',
    Validators.pattern(new RegExp('^[A-Z0-9]{14}$'))
  );

  constructor(
    private userService: UserService,
    private store: Store
  ) {
    super();
    this.loadingInformation = true;

    this.skiPassForm = new FormGroup<SkiPassForm>({
      axess: this.axessForm,
      skidata: this.skidataForm
    });

    this.register(
      this.user$
        .pipe(switchMap((user) => this.userService.getUser(user?.email)))
        .subscribe((user) => {
          this.axessForm.setValue(user.cardAxess);
          this.skidataForm.setValue(user.cardSkidata);
          this.loadingInformation = false;
        })
    );
  }

  validation(): void {
    if (this.skiPassForm.valid) {
      this.register(
        this.store
          .dispatch(
            new UpdateUserPass({
              ...this.skiPassForm.getRawValue()
            })
          )
          .subscribe(
            () => {
              this.success = 'Vos informations sont à jour';
              this.error = '';
              this.resetMessage();
            },
            () => {
              this.error =
                'Une erreur est survenue lors de la mise à jour de vos informations :(';
              this.success = '';
              this.resetMessage();
            }
          )
      );
    } else {
      this.success = '';
      this.error = 'Des champs ne sont pas remplis ou de façon incorrect';
      this.resetMessage();
    }
  }

  resetMessage() {
    setTimeout(() => {
      this.success = '';
      this.error = '';
    }, 3000);
  }
}

export interface SkiPassForm {
  skidata: FormControl<string | null>;
  axess: FormControl<string | null>;
}
