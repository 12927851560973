export class DownloadUtils {
  public static downloadPDF(pdf: any, filename: string): void {
    const newBlob = new Blob([pdf], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(newBlob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
