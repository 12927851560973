<div class="p-8 py-4 bg-white rounded-[20px] flex flex-col">
  <div class="flex flex-row justify-between items-center">
    <h4>Voyageur {{ index }}</h4>
    <atc-button (click)="autoFillInformation()"
                color="ternary"
                icon="person"
                label="C'est moi"></atc-button>
  </div>
  <div class="flex flex-col gap-4 mt-4">
    <div class="flex flex-row gap-2">
      <!-- Nom -->
      <atc-input [control]="lastNameForm"
                 [id]="'id__participant-form-lastname-' + index"
                 errorMessage="Champs obligatoire"
                 label="Nom*"
                 placeholder="Nom">
      </atc-input>

      <!-- Prénom -->
      <atc-input [control]="firstNameForm"
                 [id]="'id__participant-form-firstname-' + index"
                 errorMessage="Champs obligatoire"
                 label="Prénom*"
                 placeholder="Prénom">
      </atc-input>
    </div>

    <!-- Birthdate -->
    <atc-input-date [control]="birthdateForm"
                    [id]="'id__participant-form-birthdate-' + index"
                    label="Date de naissance*">
    </atc-input-date>

    <div *ngIf="skiCard as skiPass" class="flex flex-col gap-y-4">
      <atc-toggle-switch [control]="buyCardForm"
                         [label]="getLabelSki(skiPass.price)"
                         size="small"

      >
      </atc-toggle-switch>

      <!-- Skidata -->
      <atc-input *ngIf="skiPass.type === 'SKIDATA' && !buyCardForm.value"
                 [control]="skidataForm"
                 [dropSpecialCharacters]="false"
                 [id]="'id__participant-form-skidata-' + index"
                 [specialCharacters]="['-']"
                 label="Numéro de ma carte de ski"
                 mask="00-00000000000000000000-0"
                 placeholder="01-16140000000000000000-0"
                 type="text"></atc-input>

      <!-- Team Axess -->
      <atc-input *ngIf="skiPass.type === 'TEAMAXESS' && !buyCardForm.value"
                 [control]="axessForm"
                 [dropSpecialCharacters]="false"
                 [id]="'id__participant-form-team-axess-' + index"
                 [specialCharacters]="['-']"
                 label="Numéro de ma carte de ski"
                 mask="AAAAAAAA-AAA-AAA"
                 placeholder="A0000BCD-EFG-IJ0"
                 type="text"></atc-input>
    </div>

    <p *ngIf="skidataForm.invalid && (skidataForm.dirty || skidataForm.touched)" class="vsk__error-text">
      <span *ngIf="skidataForm.errors?.required">Ce champ est requis.</span>
      <span *ngIf="skidataForm.errors?.pattern">Numéro de carte invalide.</span>
    </p>
    <p *ngIf="skidataForm.hasError('invalidKeycard')" class="vsk__error-text">
      {{ skidataForm.getError('invalidKeycard') }}
    </p>
    <p *ngIf="skidataForm.hasError('loading')">
      Vérification de votre carte en cours
    </p>

    <div class="grid grid-cols-2 gap-x-2 gap-y-4">
      <!-- Custom fields -->
      <atc-input *ngFor="let field of customFieldsForm"
                 [control]="field.control"
                 [label]="field.label"
                 [placeholder]="field.placeholder"
                 errorMessage="Champs obligatoire"
                 type="text"></atc-input>
    </div>
  </div>
  <p *ngIf="showError && (!this.participant?.isValidPayment() || !birthdateForm.valid)"
     class="error">{{ errorLabel }}</p>
</div>
