import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { WindowResource } from 'atomic-lib';
import hash from 'object-hash';
import { Observable, combineLatest, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  tap
} from 'rxjs/operators';
import { CartState } from '../../cart/cart.state';
import {
  Bounds,
  GeoBoundsActiveChange,
  SetFiltersExperiences
} from '../../filters.action';
import { FiltersState } from '../../filters.state';
import { FetchExperiencesGroup } from '../../resort/resort.action';
import { ResortState } from '../../resort/resort.state';
import { UrlManagerResource } from '../../resource/url-manager.resource';
import { FiltersService } from '../../service/filters.service';
import { ArrayUtils } from '../../utils/array-utils';
import { Activity } from '../models/activity/activity';
import { ExperienceGroup } from '../models/activity/experience-group';
import { FiltersExperienceSearch } from '../models/activity/filters-experience-search';
import { Cart } from '../models/cart/cart';
import { ExperienceCategoryEnum } from '../models/enum/experience-category.enum';
import { FiltersInfoExperience } from '../models/filters/filters-info-experience';
import { MarkerWrapper } from '../models/marker-wrapper';
import { Participant } from '../models/participant/participant';
import { Period } from '../models/period';
import { Resort } from '../models/resort/resort';
import { ToHourPipe } from '../pipe/to-hour.pipe';
import { MapComponent } from './map.component';

@Component({ standalone: true, template: '' })
export class ExperienceTemplateComponent
  extends MapComponent<ExperienceGroup>
  implements OnInit, OnDestroy
{
  @Select(CartState.cart) cart$: Observable<Cart>;
  @Select(CartState.markers) markersCart$: Observable<MarkerWrapper<any>[]>;
  @Select(ResortState.experiencesGroup) experiencesGroup$: Observable<
    ExperienceGroup[]
  >;
  @Select(FiltersState.participants) participants$: Observable<Participant[]>;
  @Select(FiltersState.activities) activities$: Observable<Activity[]>;
  @Select(FiltersState.filtersExperiences)
  filtersExperiences$: Observable<FiltersExperienceSearch>;
  @Select(FiltersState.sessionId) sessionId$: Observable<string>;
  @Select(FiltersState.selectedActivities) selectedActivities$: Observable<
    Activity[]
  >;
  @Select(FiltersState.period) period$: Observable<Period>;
  @Select(FiltersState.resort) resort$: Observable<Resort>;

  resortName: string;
  pictures: string[] = [];
  experienceGroups: ExperienceGroup[] = [];
  filtersInfo: FiltersInfoExperience;
  filters: FiltersExperienceSearch;
  page = 0;
  loaderExperiences = true;
  pageResort = false;
  resortId: number;
  type = ExperienceCategoryEnum.GENERAL;
  excludeActivityFilter = false;
  allFiltersInfo: FiltersInfoExperience;
  previousFiltersInfo: FiltersInfoExperience;
  previousExperienceGroups: ExperienceGroup[];
  isFirstLoad = true;
  isPopupOpen = false;

  constructor(
    public windowResource: WindowResource,
    protected store: Store,
    protected hourPipe: ToHourPipe,
    protected translate: TranslateService,
    protected changeDetectionRef: ChangeDetectorRef,
    protected filtersService: FiltersService,
    protected activatedRoute: ActivatedRoute,
    protected urlManager: UrlManagerResource,
    public router: Router
  ) {
    super(windowResource, router);
    this.zoom = 5;
  }

  ngOnInit(): void {
    const resortPage$ = this.pageResort
      ? this.resort$.pipe(filter((resort) => !!resort))
      : of(null);

    this.register(
      combineLatest([
        this.sessionId$.pipe(filter((sessionId) => !!sessionId)),
        this.period$.pipe(
          filter((period) => period.isValid),
          distinctUntilChanged(
            (prev, curr) =>
              prev.startDate.isSame(curr.startDate, 'day') &&
              prev.endDate.isSame(curr.endDate, 'day')
          )
        ),
        this.participants$.pipe(
          filter((participants) => participants.length > 0),
          distinctUntilChanged(
            (prev, curr) =>
              hash(prev.map((participant) => participant.uuid)) ===
              hash(curr.map((participant) => participant.uuid))
          )
        ),
        resortPage$,
        this.selectedActivities$.pipe(
          distinctUntilChanged((prev, curr) => ArrayUtils.areEquals(prev, curr))
        )
      ]).subscribe(([sessionId, period, participants, resort, activities]) =>
        this.initFilters(period, sessionId, participants, resort, activities)
      )
    );

    this.register(
      combineLatest([
        this.experiencesGroup$,
        this.participants$,
        this.markersCart$,
        this.period$
      ])
        .pipe(debounceTime(100))
        .subscribe(([experiencesGroup, participants, markersCart, period]) => {
          if (!experiencesGroup?.length) {
            this.experienceGroups = [];
            return;
          }
          this.experienceGroups = experiencesGroup;
          if (experiencesGroup.length > 0) {
            this.previousExperienceGroups = experiencesGroup;
          }
          this.experienceGroups.forEach((experienceGroup) =>
            experienceGroup.mapLinkCard(
              participants,
              this.hourPipe,
              this.translate,
              this.resortName,
              period
            )
          );

          const markersExperience = this.buildMarker();
          this.markers = markersExperience.length
            ? [...markersExperience, ...markersCart]
            : [];
          this.placeMarkersOnMap();
          this.fitBounds();
          this.changeDetectionRef.markForCheck();
        })
    );

    this.register(
      this.filtersExperiences$
        .pipe(
          filter((value) => !!value),
          tap((filters) => {
            if (this.pageResort && this.resortId) {
              filters.resorts = [this.resortId];
            }
          }),
          filter((experienceFilters) => experienceFilters.isValid),
          distinctUntilChanged((prev, curr) =>
            this.compareWithHash(prev, curr)
          ),
          tap(() => (this.loaderExperiences = true)),
          tap((filters) => {
            if (this.isFirstLoad) {
              this.getDefaultExperiences(filters).subscribe(
                (defaultFilters) => {
                  this.allFiltersInfo = defaultFilters;
                }
              );
              this.isFirstLoad = false;
            }
          }),
          switchMap((filters) =>
            combineLatest([
              this.filtersService.getFiltersExperienceInfo(filters),
              this.dispatchFetchGroup(filters)
            ])
          )
        )
        .subscribe(([info]) => {
          if (info.total > 0) {
            this.previousFiltersInfo = info;
          }
          this.filtersInfo = info;
          this.loaderExperiences = false;
          if (!this.isManualScroll) {
            this.refreshPov();
          }
          this.changeDetectionRef.markForCheck();
        })
    );
  }

  getDefaultExperiences(filters: any) {
    const noFilters = new FiltersExperienceSearch({
      ...filters,
      resorts: [],
      activities: [],
      difficulties: [],
      heart: false,
      promo: false,
      priceRange: {
        min: 0,
        max: 1000
      },
      durationRange: {
        min: 0,
        max: 1000
      },
      geoBoundsActive: false
    });

    if (this.pageResort) {
      noFilters.resorts = [this.resortId];
    }

    return this.filtersService.getFiltersExperienceInfo(noFilters);
  }

  loadMoreExperiences() {
    this.page++;
    this.store.dispatch(
      new FetchExperiencesGroup(this.filters, this.page, this.type)
    );
  }

  compareWithHash(prev: any, curr: any): boolean {
    const prevHash = hash(prev);
    const currHash = hash(curr);

    if (prevHash !== currHash) {
      const differences: { [key: string]: { prev: any; curr: any } } = {};

      for (const key in prev) {
        if (prev[key] !== curr[key]) {
          differences[key] = { prev: prev[key], curr: curr[key] };
        }
      }

      if (
        Object.prototype.hasOwnProperty.call(differences, 'mapPolygon') &&
        JSON.stringify(differences.mapPolygon['prev']) !==
          JSON.stringify(differences.mapPolygon['curr'])
      ) {
        curr.geoBoundsActive = true;
        this.isManualScroll = true;
      } else {
        curr.geoBoundsActive = false;
        this.isManualScroll = false;
      }
      return false;
    }
    return true;
  }

  dispatchFetchGroup(filters: FiltersExperienceSearch) {
    this.filters = filters;
    this.page = 0;
    return this.store.dispatch(
      new FetchExperiencesGroup(filters, 0, this.type, true)
    );
  }

  buildMarker() {
    return this.experienceGroups.map((group) => {
      const minPrice = group.getMinPrice();
      const text = minPrice ? Math.round(minPrice) + ' €' : group.activityName;
      const marker = new MarkerWrapper<ExperienceGroup>(group, {
        clickable: true,
        position: {
          lat: group.location.lat,
          lng: group.location.lon
        },
        title: group.activityName + ' - ' + group.partnerName,
        label: {
          color: '#1f1f1f',
          text: text,
          fontWeight: '500',
          fontSize: '11px',
          className: 'pinpoint',
          fontFamily: 'General'
        },
        icon: {
          url: '../../../../assets/svg/pinpoint.svg',
          scaledSize: new google.maps.Size(
            text.length * 5 + 40,
            36,
            'px',
            'px'
          ),
          origin: new google.maps.Point(-13, -5)
        },
        anchorPoint: new google.maps.Point(0, 0),
        optimized: false,
        zIndex: 100
      });
      group.marker = marker;
      return marker;
    });
  }

  initFilters(
    period: Period,
    sessionId: string,
    participants: Participant[],
    resort: Resort | null,
    activities: Activity[]
  ) {
    if (resort) {
      this.resortId = resort.id;
    }

    const init = new FiltersExperienceSearch({
      startDate: period.startDate,
      endDate: period.endDate,
      sessionId,
      participants,
      activities: this.excludeActivityFilter
        ? []
        : activities.map((activity) => activity.id),
      type: this.type,
      priceRange: {
        min: 0,
        max: 1000
      },
      durationRange: {
        min: 0,
        max: 1000
      }
    });

    this.store.dispatch(
      new SetFiltersExperiences(
        new FiltersExperienceSearch({
          ...init,
          ...this.urlManager.fetchParams(
            Object.keys(init),
            this.excludeActivityFilter
          ),
          startDate: period.startDate,
          endDate: period.endDate,
          geoBoundsActive: false
        })
      )
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.store.dispatch(
      new Bounds({
        east: 15.279743671417222,
        north: 49.618420074289716,
        south: 41.862059405400565,
        west: -5.814006328582777
      })
    );
  }

  protected boundsChangedAction(
    bounds: google.maps.LatLngBoundsLiteral,
    refresh: boolean
  ): void {
    this.store.dispatch(new GeoBoundsActiveChange(refresh));
    this.store.dispatch(new Bounds(bounds));
  }
}
