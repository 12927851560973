import { Pipe, PipeTransform } from '@angular/core';
import { Participant } from '../models/participant/participant';

@Pipe({
  name: 'sortByAgeDesc'
})
export class SortByAgeDescPipe implements PipeTransform {
  transform(value: Participant[]): any[] {
    return value.sort((val1, val2) => (val2.age <= val1.age ? -1 : 1));
  }
}
