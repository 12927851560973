<form [formGroup]="participantForm">
  <atc-input (focusOut)="validate()"
             (focusin)="removeDefaultName()"
             [control]="nameControl"
             [placeholder]="'Voyageur ' + index">
    <img *ngIf="windowResource.isDesktop()"
         alt="Editer"
         atcLazyLoad
         height="20"
         ngSrc="assets/svg/edit.svg"
         width="20"
    />
  </atc-input>

  <atc-select [control]="ageRangeControl"
              [optionElements]="ageRangeItems"></atc-select>

  <atc-select *ngIf="isChild()"
              [control]="ageControl"
              [optionElements]="ageItems"></atc-select>
</form>
