<atc-popup (closeChanged)="closeChanged.emit()"
           [withImg]="false"
           title="Suppression de compte"
           width="600px">
  <div class="flex flex-col gap-4">
    <p>Êtes-vous sûr de vouloir supprimer votre compte ? <br> Toutes vos données seront supprimées</p>
    <p class="vsk__confirmation-text-input">{{confirmationValue}}</p>
    <div class="vsk__column">
      <!-- Input code -->
      <atc-input [control]="deleteForm"
                 [placeholder]="confirmationValue"
                 class="vsk__margin-bottom-1"
                 label="Tapez le code ci-dessus"
                 type="text"></atc-input>

      <atc-button (click)="deleteAccount()"
                  [disabled]="!isFormValid()"
                  class="vsk__spacer"
                  color="danger"
                  label="Confirmer la suppression"></atc-button>
    </div>
  </div>
</atc-popup>
