<!-- Desktop -->
<ng-container *ngIf="!responsive">

  <div (click)="showPicker = !showPicker"
       [ngClass]="{'vsk__is-in-navbar': isInNavbar,
       'vsk__is-not-in-navbar': !isInNavbar,
       'vsk__is-active': showPicker && currentPage !== 'Home',
       'vsk__is-active-in-home-page activity': showPicker && currentPage === 'Home',
       'hover-element': currentPage !== 'Home',
       'home-element': currentPage === 'Home'
       }">

      <svg class="lg:max-h-6 lg:min-h-6 lg:max-w-[2rem] stroke-secondary">
        <use [attr.xlink:href]="'assets/icons/icons.svg#mountain'"></use>
      </svg>
      <div class="vsk__column lg:my-auto">
      <p class="subtitle">Destinations</p>
      </div>

  </div>


  <atc-dropdown (closeChanged)="showPicker = !showPicker"
                [isOpen]="showPicker"
                [left]="LEFT"
                [position]="'bottom-left'"
                [width]="WIDTH">
    <div class="vsk__container-activity">
      <atc-input [control]="searchForm"
                 icon="search"
                 placeholder="Rechercher...">
      </atc-input>
      <div class="container-resorts">
        <atc-input-checkbox *ngFor="let element of resortsElement"
                            [control]="getControl(element.control)"
                            [ngClass]="element.classCss">
          <div class="flex flex-row ml-4 items-center cursor-pointer">
            <img [alt]="element.id?.name" [src]="element.id?.urlLogo" class="max-h-6 logo-resort"/>
            <p>{{ element.label }}</p>
          </div>
        </atc-input-checkbox>
      </div>
    </div>
    <div class="vsk__inline">
      <atc-button (click)="resetResorts()"
                  color="ternary"
                  label="Effacer">
      </atc-button>
      <div class="vsk__spacer"></div>
      <atc-button (click)="validation()"
                  color="secondary"
                  label="Ajouter des voyageurs">
      </atc-button>
    </div>
  </atc-dropdown>
</ng-container>

<!-- Mobile -->
<ng-container *ngIf="responsive">
  <div class="vsk__container-activity
              md:mb-4
              lg:mb-4">
    <atc-input-checkbox *ngFor="let element of resortsElement"
                        [control]="getControl(element.control)"
                        [ngClass]="element.classCss">
      <div class="flex flex-row ml-4 items-center">
        <img [alt]="element.id?.name" [src]="element.id?.urlLogo" class="max-h-6 logo-resort"/>
        <p>{{ element.label }}</p>
      </div>
    </atc-input-checkbox>
  </div>
  <div class="vsk__inline">
    <atc-button (click)="resetResorts()"
                color="ternary"
                label="Effacer"></atc-button>
    <div class="vsk__spacer"></div>
    <atc-button (click)="validate.emit()"
                color="secondary"
                label="Ajouter des voyageurs"></atc-button>
  </div>
</ng-container>
