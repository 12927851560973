import { NgModule } from '@angular/core';
import { FooterModule } from '../../footer/footer.module';
import { SharedModule } from '../../shared/shared.module';
import { NgxTranslateModule } from '../../store/translate.module';
import { TermsOfUseRoutingModule } from './terms-of-use-routing.module';
import { TermsOfUseComponent } from './terms-of-use.component';

@NgModule({
  declarations: [TermsOfUseComponent],
  imports: [
    NgxTranslateModule,
    SharedModule,
    FooterModule,
    TermsOfUseRoutingModule
  ],
  exports: [TermsOfUseComponent]
})
export class TermsOfUseModule {}
