export const environment: any = {
  name: 'production',
  production: true,
  log: false,
  cdnUrl: 'https://cdn.verymountain.fr',
  urlAddress: 'https://verymountain.fr',
  redirectApple: 'https://verymountain.fr',
  redirectPayment: 'https://verymountain.fr/confirm-payment',
  stripeKey:
    'pk_live_51JxDK0AXm0QCbE23D3fIfORG7YKOlKdrePZV1zPPUYCNnEysOPdaExFKV75qaw6MVxANNB8LOIOphs0HAl9N3KbR00Q5zIHo58'
};
