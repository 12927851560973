import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Criteria } from '../shared/models/criteria';
import { FiltersStation } from '../shared/models/filters/filters-station';
import { Resort } from '../shared/models/resort/resort';
import { ResortAvailability } from '../shared/models/resort/resort-availibility';
import { ResortMap } from '../shared/models/resort/resort-map';
import { ResortMin } from '../shared/models/resort/resort-min';
import { ResortsBorders } from '../shared/models/resort/resorts-borders';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class StationService extends RepositoryService {
  private URL_STATION = 'api/webapp/resort';

  getStationsByFilters(filters: FiltersStation): Observable<ResortMap[]> {
    return this.post(this.URL_STATION + '/stations', filters).pipe(
      map((resorts: ResortMap[]) =>
        resorts.map((resort: ResortMap) => new ResortMap({ ...resort }))
      )
    );
  }

  getAllResortBorders(): Observable<ResortsBorders> {
    return this.post(this.URL_STATION + '/all-resort-borders', null).pipe(
      map((stationsBorders: any) => new ResortsBorders(stationsBorders))
    );
  }

  getStationByName(name: string, sessionId: string): Observable<Resort> {
    return this.get(
      this.URL_STATION + '/by-name?name=' + name + '&sessionId=' + sessionId
    ).pipe(map((station: any) => new Resort(station)));
  }

  getAllResorts(): Observable<ResortMin[]> {
    return this.get(this.URL_STATION + '/all-stations').pipe(
      map((stations: ResortMin[]) =>
        stations.map((station) => new ResortMin(station))
      )
    );
  }

  checkAvailability(criteria: Criteria): Observable<ResortAvailability> {
    return this.post(this.URL_STATION + '/check-availability', criteria).pipe(
      map((response: ResortAvailability) => new ResortAvailability(response))
    );
  }
}
