<ng-container *ngIf="!cart.isEmpty">
  <main
    class="flex flex-col justify-between w-[100%] max-w-[1350px] xl:px-[15rem] lg:px-[15rem] mx-auto px-8 gap-[1rem] pt-8 pb-[4rem]">

    <h1>Merci d'avoir réservé votre séjour chez VeryMountain !</h1>
    <p>Nous vous avons envoyé un mail de confirmation ainsi que toutes les informations dont vous avez besoin pour
      votre
      séjour.</p>

    <div class="price">
      <h2 class="flex justify-center items-center gap-2">
        Total :
        <ng-container *ngIf="cart.hasPromoCode">
          <ng-container *ngIf="cart.getPublicPrice() as publicPrice">
            <ng-container *ngIf="cart.getPriceWithPromo() as promoPrice">

              <h3 class="line-through">{{ publicPrice | number : '1.2-2':'fr' }} €</h3>
              <h3 class="color-neutral-70"> {{ promoPrice | number : '1.2-2':'fr' }} €</h3>

              <ng-container *ngIf="publicPrice !== promoPrice">
                (-{{ PricingUtils.reductionPercentage(publicPrice, promoPrice) }}%)
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </h2>

      <ng-container *ngIf="!cart.hasPromoCode">
        <ng-container *ngIf="cart.getPublicPrice() as publicPrice">
          <ng-container *ngIf="cart.getPrice() as price">


            <h3 *ngIf="publicPrice !== price" class="line-through">
              {{ cart.getPublicPrice() | number : '1.2-2':'fr' }} € &nbsp;
            </h3>


            <h3 class="color-neutral-70">
              {{ cart.getPrice() | number : '1.2-2':'fr' }} €
            </h3>

            <span *ngIf="publicPrice !== price" class="percentage">
                                (- {{ PricingUtils.reductionPercentage(publicPrice, price) }}%)
                        </span>

          </ng-container>
        </ng-container>
      </ng-container>

      <p *ngIf="cart.hasPromoCode" class="text-left">Avec le code : {{ cart.promoCode?.name }}</p>
    </div>
    <atc-button (click)="goToMap()"
                class="mx-auto"
                color="ternary"
                icon="map"
                label="Retour à la carte">
    </atc-button>
    <ng-container *ngIf="period$ | async as period">
      <vsk-cart-content *ngIf="!cart.isEmpty"
                        [canDeleteItem]="false"
                        [canEditItem]="false"
                        [cart]="cart"
                        [disableEcoChart]="true">
      </vsk-cart-content>
    </ng-container>
  </main>
</ng-container>

<ng-container *ngIf="cart.isEmpty && !isPaymentIncomplete">
  <h1>Une erreur est survenue lors de la validation de votre commande</h1>
  <p>Notre équipe est notifiée, nous revenons vers vous dans les plus brefs délai.</p>
  <p>Nous sommes disponible par email : <b>hello&#64;verymountain.fr</b> ou par téléphone au <a class="font-bold"
                                                                                                href="tel:+33465847469">04 65
    84
    74 69</a></p>
</ng-container>

<ng-container *ngIf="cart.isEmpty && isPaymentIncomplete">
  <h1>Ooops, votre paiement n'a pas abouti, vous pouvez réessayer en vous rendant dans le panier</h1>
  <p>Si le problème persiste n'hésitez pas à nous contacter par email : <b>hello&#64;verymountain.fr</b> ou par
    téléphone
    au <a class="font-bold" href="tel:+33465847469">04 65 84 74 69</a></p>
</ng-container>

<div *ngIf="loading" class="vsk__container">
  <atc-loader></atc-loader>
</div>
<vsk-footer></vsk-footer>
