import { Criteria } from '../criteria';

export class ProposalQuery {
  partnerCode: string;
  establishmentId: number;
  roomCode: string;
  criteria: Criteria;
  participants: string[];

  constructor(obj: Partial<ProposalQuery>) {
    Object.assign(this, obj);
  }
}
