import { Region } from '../region';
import { FilterCount } from './filter-count';

export class FiltersTripInfo {
  regions: FilterCount<Region>[] = [];
  types: FilterCount<number>[] = [];
  pool: number;
  spa: number;
  sauna: number;
  hammam: number;
  balconyTerrace: number;
  tv: number;
  chimney: number;
  bbq: number;
  pmr: number;
  parking: number;
  animalsAdmitted: number;
  wifi: number;
  childrenClub: number;

  constructor(obj: any) {
    Object.assign(this, obj);

    if (this.types) {
      this.types = this.types.map((type) => new FilterCount({ ...type }));
    }
  }
}
