import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountInformationComponent } from './account-information/account-information.component';
import { AccountOrdersComponent } from './account-orders/account-orders.component';
import { AccountPassComponent } from './account-pass/account-pass.component';
import { MyAccountComponent } from './my-account.component';

const routes: Routes = [
  {
    path: '',
    component: MyAccountComponent
  },
  {
    path: 'information',
    component: AccountInformationComponent
  },
  {
    path: 'orders',
    component: AccountOrdersComponent
  },
  {
    path: 'pass',
    component: AccountPassComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyAccountRoutingModule {}
