<div *ngIf="cart$ | async as cart" class="vsk__content">
  <atc-stepper>
    <!-- Step 1 - CONNEXION -->
    <atc-step [done]="isDone(Step.CONNEXION)"
              [order]="0"
              [show]="step === Step.CONNEXION"
              class="flex flex-col gap-4"
              label="Connexion">
      <div class="flex flex-row xsm:items-center justify-between w-full sm:flex-col sm:mt-4">
        <atc-button (click)="goBackToResort(cart.getResort())"
                    class="h-[26px] sm:hidden"
                    color="simple"
                    icon="chevron-left"
                    label="Retour à la station">
        </atc-button>

        <p>
          Une question ? N'hésitez pas à contacter notre équipe au
          <a class="font-bold" href="tel:+33465847469">04 65 84 74 69</a>
        </p>
      </div>

      <!-- Mobile -->
      <div *ngIf="!(windowResource.width() | async | screen : 'small-desktop-above')"
           class="flex flex-col gap-y-2 sm:mt-0 md:mt-4 lg:mt-4">
        <vsk-price [cart]="cart"
                   class="w-full px-8 py-4 border-dark bg-white rounded-[20px] box-shadow"></vsk-price>

        <vsk-step-connexion (next)="next()"
                            [endDate]="endDate$ | async"
                            [hasInformationStep]="participantsWithItems.length !== 0"
                            [isLoggedIn]="isLoggedIn$ | async"
                            [session]="sessionId$ | async"
                            [startDate]="startDate$ | async">
        </vsk-step-connexion>
      </div>

      <!-- Tablet & above -->
      <div *ngIf="windowResource.width() | async | screen : 'small-desktop-above'"
           class="flex flex-row gap-x-4">
        <div class="flex flex-col gap-y-4 flex-1">
          <div class="flex flex-row bg-white p-8 py-4 gap-4 border-dark rounded-[20px] items-center">
            <svg class="w-[24px] h-[24px] stroke-black">
              <use [attr.xlink:href]="'assets/icons/icons.svg#mail'"></use>
            </svg>
            <h2>Connexion</h2>
          </div>

          <vsk-step-connexion (next)="next()"
                              [endDate]="endDate$ | async"
                              [hasInformationStep]="participantsWithItems.length !== 0"
                              [isLoggedIn]="isLoggedIn$ | async"
                              [session]="sessionId$ | async"
                              [startDate]="startDate$ | async">
          </vsk-step-connexion>
        </div>

        <div class="flex flex-col flex-1 mb-4 rounded-[20px] max-h-full gap-y-4">
          <vsk-price [cart]="cart"
                     class="w-full px-8 py-4 border-dark bg-white rounded-[20px] box-shadow"></vsk-price>

          <div class="flex flex-col bg-white px-8 py-4 mb-4 rounded-[20px]">
            <h2 *ngIf="getLabelCart(period$ | async) as label">{{ label }}</h2>

            <vsk-cart-content (redirectToEstablishmentChanged)="redirectToEstablishment($event)"
                              (redirectToExperienceChanged)="redirectToExperience($event)"
                              (redirectToMapChanged)="redirectToMap()"
                              [cart]="cart"
                              [isEcoTourismeChecked]="isEcoTourismeChecked$ | async"
                              [isPayment]="true"
                              class="mt-4 rounded-[20px] bg-white">
            </vsk-cart-content>
          </div>
        </div>
      </div>

    </atc-step>

    <!-- Step 2 - PARTICIPANTS -->
    <atc-step *ngIf="participantsWithItems.length"
              [done]="isDone(Step.PARTICIPANT)"
              [order]="1"
              [show]="step === Step.PARTICIPANT"
              class="flex flex-col gap-4"
              label="Informations voyageurs">

      <div class="flex flex-row xsm:items-center justify-between w-full sm:flex-col sm:mt-4">
        <atc-button (click)="goBackToResort(cart.getResort())"
                    class="h-[26px] sm:hidden"
                    color="simple"
                    icon="chevron-left"
                    label="Retour à la station">
        </atc-button>

        <p>
          Une question ? N'hésitez pas à contacter notre équipe au
          <a class="font-bold" href="tel:+33465847469">04 65 84 74 69</a>
        </p>
      </div>

      <!-- Mobile -->
      <div *ngIf="!(windowResource.width() | async | screen : 'small-desktop-above')"
           class="flex flex-col gap-y-2 sm:mt-0 md:mt-4 lg:mt-4">
        <vsk-price [cart]="cart"
                   class="w-full px-8 py-4 border-dark bg-white rounded-[20px] box-shadow"></vsk-price>

        <vsk-step-participants-information (next)="next()"
                                           (previous)="previous()"
                                           [cart]="cart"
                                           [participants]="participantsWithItems"
                                           [userSession]="user$ | async">
        </vsk-step-participants-information>
      </div>

      <!-- Tablet & above -->
      <div *ngIf="windowResource.width() | async | screen : 'small-desktop-above'"
           class="flex flex-row gap-8">

        <div class="flex flex-col gap-y-4 flex-1">
          <div class="flex flex-row bg-white p-8 py-4 gap-4 border-dark rounded-[20px] items-center">
            <svg class="w-[24px] h-[24px] stroke-black">
              <use [attr.xlink:href]="'assets/icons/icons.svg#group'"></use>
            </svg>
            <h2>Informations voyageurs</h2>
          </div>

          <vsk-step-participants-information (next)="next()"
                                             (previous)="previous()"
                                             [cart]="cart"
                                             [participants]="participantsWithItems"
                                             [userSession]="user$ | async">
          </vsk-step-participants-information>
        </div>

        <div class="flex flex-col flex-1 mb-4 rounded-[20px] max-h-full gap-y-4">
          <vsk-price [cart]="cart"
                     class="w-full px-8 py-4 border-dark bg-white rounded-[20px] box-shadow"></vsk-price>

          <div class="flex flex-col bg-white px-8 py-4 mb-4 rounded-[20px]">
            <h2 *ngIf="getLabelCart(period$ | async) as label">{{ label }}</h2>

            <vsk-cart-content (redirectToEstablishmentChanged)="redirectToEstablishment($event)"
                              (redirectToExperienceChanged)="redirectToExperience($event)"
                              (redirectToMapChanged)="redirectToMap()"
                              [cart]="cart"
                              [isEcoTourismeChecked]="isEcoTourismeChecked$ | async"
                              [isPayment]="true"
                              class="mt-4 rounded-[20px] bg-white">
            </vsk-cart-content>
          </div>
        </div>
      </div>
    </atc-step>


    <!-- Step 3 - PAYMENT -->
    <atc-step [done]="isDone(Step.PAYMENT)"
              [order]="participantsWithItems.length > 0 ? 2 : 1"
              [show]="step === Step.PAYMENT"
              class="flex flex-col gap-4"
              label="Paiement">

      <div class="flex flex-row xsm:items-center justify-between w-full sm:flex-col sm:mt-4">
        <atc-button (click)="goBackToResort(cart.getResort())"
                    class="h-[26px] sm:hidden"
                    color="simple"
                    icon="chevron-left"
                    label="Retour à la station">
        </atc-button>

        <p>
          Une question ? N'hésitez pas à contacter notre équipe au
          <a class="font-bold" href="tel:+33465847469">04 65 84 74 69</a>
        </p>
      </div>

      <!-- Mobile -->
      <div *ngIf="!(windowResource.width() | async | screen : 'small-desktop-above')"
           class="flex flex-col gap-y-2 sm:mt-0 md:mt-4 lg:mt-4">
        <vsk-price [cart]="cart"
                   [depositEnabled]="true"
                   [startDate]="startDate$ | async"
                   class="w-full px-8 py-4 border-dark bg-white rounded-[20px] box-shadow"></vsk-price>

        <vsk-step-payment (payEvent)="changeLoading($event)"
                          (paymentSuccess)="paymentSuccess()"
                          (previous)="previous()"
                          *ngIf="step === Step.PAYMENT"
                          [error]="error"
                          [initForm]="cart"
                          [startDate]="startDate$ | async">
        </vsk-step-payment>
      </div>

      <!-- Tablet & above -->
      <div *ngIf="windowResource.width() | async | screen : 'small-desktop-above'"
           class="flex flex-row gap-8">

        <div class="flex flex-col gap-y-4 flex-1">
          <div class="flex flex-row bg-white p-8 py-4 gap-4 border-dark rounded-[20px] items-center">
            <svg class="w-[24px] h-[24px] stroke-black">
              <use [attr.xlink:href]="'assets/icons/icons.svg#lock-closed'"></use>
            </svg>
            <h2>Paiement</h2>
          </div>

          <vsk-step-payment (payEvent)="changeLoading($event)"
                            (paymentSuccess)="paymentSuccess()"
                            (previous)="previous()"
                            *ngIf="step === Step.PAYMENT"
                            [error]="error"
                            [initForm]="cart"
                            [startDate]="startDate$ | async">
          </vsk-step-payment>
        </div>

        <div class="flex flex-col flex-1 mb-4 rounded-[20px] max-h-full gap-y-4">
          <vsk-price [cart]="cart"
                     [depositEnabled]="true"
                     [startDate]="startDate$ | async"
                     class="w-full px-8 py-4 border-dark bg-white rounded-[20px] box-shadow"></vsk-price>

          <div class="flex flex-col bg-white px-8 py-4 mb-4 rounded-[20px]">
            <h2 *ngIf="getLabelCart(period$ | async) as label">{{ label }}</h2>

            <vsk-cart-content (redirectToEstablishmentChanged)="redirectToEstablishment($event)"
                              (redirectToExperienceChanged)="redirectToExperience($event)"
                              (redirectToMapChanged)="redirectToMap()"
                              [cart]="cart"
                              [isEcoTourismeChecked]="isEcoTourismeChecked$ | async"
                              [isPayment]="true"
                              class="mt-4 rounded-[20px] bg-white">
            </vsk-cart-content>
          </div>
        </div>
      </div>
    </atc-step>

    <!-- Step 4 -->
    <atc-step [done]="isDone(Step.CONFIRMATION)"
              [order]="participantsWithItems.length > 0 ? 3 : 2"
              [show]="step === Step.CONFIRMATION"
              class="vsk__inline"
              label="Votre séjour">
    </atc-step>
  </atc-stepper>
</div>

<div *ngIf="loading" class="vsk__container">
  <atc-loader color="secondary"></atc-loader>
</div>

<vsk-footer [isHome]="false"></vsk-footer>
