<div class="flex flex-col gap-4 m-auto">
  <h3>Suppression de compte</h3>
  <p>Êtes-vous sûr de vouloir supprimer votre compte ? Toutes vos données seront supprimées</p>
  <atc-button (click)="closeWindow()"
              [fullWidth]="true"
              class="w-full flex"
              label="Annuler la suppression"></atc-button>
  <atc-button (click)="deleteAccount()"
              [fullWidth]="true"
              class="w-full flex"
              color="danger"
              label="Confirmer la suppression"></atc-button>
</div>
