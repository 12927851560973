import moment, { Moment } from 'moment';

export class UserSession {
  jwt: string;
  refreshToken: string;
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  birthdate: Moment;
  cardSkidata: string;
  cardAxess: string;
  roles: string[];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.birthdate = moment(this.birthdate);
  }

  get isAdmin() {
    return this.roles?.indexOf('ROLE_ADMIN') !== -1;
  }
}
