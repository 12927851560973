import { Moment } from 'moment';
import { Participant } from 'src/app/shared/models/participant/participant';
import { Pack } from 'src/app/shared/models/ski-equipment/pack';

export class ParamsSelectedEquipment {
  selectedPack: Pack;
  packIds: (number | null)[];
  participant: Participant;
  duration: number;
  startDate: Moment;
  endDate: Moment;
  hasShoes: boolean;
  hasHelmet: boolean;

  constructor(obj?: Partial<ParamsSelectedEquipment>) {
    Object.assign(this, obj);
  }
}
