<atc-popup (closeChanged)="refreshChanged.emit(false)"
           [height]="windowResource.isDesktop ? '600px' : ''"
           [width]="windowResource.isDesktop ? '600px' : ''"
           [withImg]="false">
  <div body class="flex flex-col gap-y-4 px-4">
    <h1 class="text-center my-8">Filtres</h1>

    <div class="flex flex-col gap-y-4">
      <hr>
      <h2>Inclus dans mon séjour</h2>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of tripIncludeFilter"
                            [control]="getControl(element.control)">
          {{ element.label }}
        </atc-input-checkbox>
      </div>
    </div>

    <div class="flex flex-col gap-y-4">
      <hr>
      <h2>Massif</h2>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of regionsFilter"
                            [control]="getControl(element.control)">
          {{ element.label }}
        </atc-input-checkbox>
      </div>
    </div>

    <div class="flex flex-col gap-y-4">
      <hr>
      <h2>Type d'hébergement</h2>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of accommodationTypeFilter"
                            [control]="getControl(element.control)">
          {{ element.label }}
        </atc-input-checkbox>
      </div>
    </div>

    <div class="flex flex-col gap-y-4">
      <hr>
      <h2>Prix</h2>
      <atc-range-input
        (valueChange)="optionReceived($event)"
        [displayedMaxValue]="selectedMaxValue"
        [displayedMinValue]="selectedMinValue"
        [maxValue]="maxValueRange"
        [minValue]="minValueRange"
        [typeOfValues]="'price'"
        class="w-[80%] md:w-[100%] block mx-auto">
      </atc-range-input>
    </div>

    <div class="flex flex-col gap-y-4">
      <hr>
      <h2>Équipements</h2>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of equipmentsFilter"
                            [control]="getControl(element.control)">
          {{ element.label }}
        </atc-input-checkbox>
      </div>
    </div>

    <div class="flex flex-col gap-y-4">
      <hr>
      <h2>Service</h2>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of servicesFilter"
                            [control]="getControl(element.control)">
          {{ element.label }}
        </atc-input-checkbox>
      </div>
    </div>

    <div class="flex flex-col gap-y-4">
      <hr>
      <h2>Accessibilité</h2>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of accessFilter"
                            [control]="getControl(element.control)">
          {{ element.label }}
        </atc-input-checkbox>
      </div>
    </div>

    <div class="flex flex-col gap-y-4">
      <hr>
      <h2>Label de station</h2>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of resortLabelsFilter"
                            [control]="getControl(element.control)">
          {{ element.label }}
        </atc-input-checkbox>
      </div>
    </div>

    <!--    <div class="flex flex-col gap-y-4">-->
    <!--      <hr>-->
    <!--      <h2>Activité</h2>-->
    <!--      <div class="grid grid-cols-2 gap-y-4">-->
    <!--        <atc-input-checkbox *ngFor="let element of tagsFilter"-->
    <!--                            [control]="getControl(element.control)">-->
    <!--          {{ element.label }}-->
    <!--        </atc-input-checkbox>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>

  <div class="flex flex-row justify-center items-center gap-x-4 sm:pb-8 py-4" footer>
    <atc-button (click)="clearFiltersChanged.emit()"
                [fitContent]="true"
                color="ternary"
                icon="trash"
                label="Effacer les filtres"
                size="medium">
    </atc-button>

    <atc-button (click)="refreshChanged.emit(true)"
                [fitContent]="true"
                color="secondary"
                icon="checkmark"
                label="Appliquer"
                size="medium">
    </atc-button>
  </div>
</atc-popup>

