import { NgModule } from '@angular/core';
import { FooterModule } from '../../footer/footer.module';
import { SharedModule } from '../../shared/shared.module';
import { NgxTranslateModule } from '../../store/translate.module';
import { TermsAndConditionsRoutingModule } from './terms-and-conditions-routing.module';
import { TermsAndConditionsComponent } from './terms-and-conditions.component';

@NgModule({
  declarations: [TermsAndConditionsComponent],
  imports: [
    NgxTranslateModule,
    SharedModule,
    FooterModule,
    TermsAndConditionsRoutingModule
  ],
  exports: [TermsAndConditionsComponent]
})
export class TermsAndConditionsModule {}
