<!-- Desktop -->
<ng-container *ngIf="!responsive && isHome">
  <div class="more-packages ">
    <div class="flex items-center">
      <svg class="lg:max-h-8 lg:min-h-8 lg:max-w-[2rem] stroke-secondary">
        <use [attr.xlink:href]="'assets/icons/icons.svg#filters'"></use>
      </svg>
      <p class="packages-title">Je recherche </p>
      <svg class="lg:max-h-8 lg:min-h-8 lg:max-w-[1.2rem] stroke-neutral-70 mx-4">
        <use [attr.xlink:href]="'assets/icons/icons.svg#chevron-right'"></use>
      </svg>
      <div class="flex">
        <atc-input-checkbox
          [control]="skiPackageForm">
          Forfait
        </atc-input-checkbox>
      </div>
      <div class="flex">
        <atc-input-checkbox
          [control]="materialForm">
          Matériel
        </atc-input-checkbox>
      </div>
    </div>
  </div>


</ng-container>

<!-- Mobile -->
<ng-container *ngIf="responsive">
  <div class="responsive-filters md:mb-4 lg:mb-4 md:px-4 md:pb-4">
    <div class="flex">
      <atc-input-checkbox
        [control]="skiPackageForm">
      </atc-input-checkbox>
      <label>Forfait</label>
    </div>
    <div class="flex">
      <atc-input-checkbox
        [control]="materialForm">
      </atc-input-checkbox>
      <label>Matériel</label>
    </div>
  </div>
</ng-container>
