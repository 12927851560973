<atc-button-dropdown (click)="openLogin()"
                     (openChanged)="isOpen = $event"
                     [monoletter]="isLoggedIn"
                     [color]="'ternary'"
                     [icon]="'person'"
                     [isOpen]="isOpen"
                     [label]="isLoggedIn ? user.firstname : ''"
                     [openOnClick]="false"
                     [optionElements]="links"
                     [position]="'bottom-right'">
</atc-button-dropdown>

<vsk-login (closeChanged)="openLoginPopup = false" *ngIf="openLoginPopup">
</vsk-login>
