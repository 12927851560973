<!-- TODO: Replace ngIf by ngSwitchCase -->
<atc-popup (closeChanged)="closeChanged.emit()"
           [height]="windowResource.isDesktop() ? 'auto' : '100%'"
           [width]="windowResource.isDesktop() ? '600px' : ''"
           [withImg]="false">

  <div body class="p-6">
    <!-- Step 0 : choose connexion mode -->
    <div *ngIf="!step || step === 'connexion'" class="vsk__block-form">
      <img alt="logo" atcLazyLoad class="vsk__logo" src="assets/images/logo-mobile.svg">
      <h3 class="vsk__header-login">Connexion ou inscription</h3>

      <div *ngIf="googleAuth">
        <p class="google-info">{{ googleAuth }}</p>
      </div>

      <form class="vsk__form">
        <atc-input [control]="emailForm"
                   [errorMessage]="'Email invalide'"
                   [focus]="true"
                   id="id__input-email-login"
                   label="Adresse mail"
                   placeholder="jean@verymountain.fr"
                   type="email"></atc-input>
        <atc-input (enterKeyPressed)="nextStep()"
                   [control]="passwordConnexionForm"
                   [type]="fieldTextType ? 'text' : 'password'"
                   id="id__input-password-login"
                   label="Mot de passe"
                   placeholder="***************">
        </atc-input>
        <p *ngIf="error" class="vsk__error-text">{{ error }}</p>
        <atc-button (click)="nextStep()"
                    [fullWidth]="true"
                    color="secondary"
                    id="id__button-connexion-login"
                    label="Se connecter"></atc-button>
      </form>
      <atc-button (click)="setStepValue('inscription')"
                  [fullWidth]="true"
                  color="ternary"
                  id="id__button-create-account"
                  label="Se créer un compte"></atc-button>
      <div class="vsk__inline vsk__spacing-bottom">
        <a (click)="forgotPassword()" class="vsk__link" id="id__a-forgot-password">Mot de passe oublié ?</a>
      </div>
      <hr>
      <div class="vsk__inline m-4">
        <asl-google-signin-button
          class="m-auto"
          logo_alignment="center"
          shape="circle"
          size="large"
          text="continue_with"
          theme="outline"
          type="standard">
        </asl-google-signin-button>
      </div>
    </div>

    <!-- Step inscription -->
    <div *ngIf="step === 'inscription'" class="vsk__block-form">
      <img alt="logo" atcLazyLoad class="vsk__logo" src="assets/images/logo-mobile.svg">
      <h3 class="vsk__header-login">Inscription</h3>
      <p *ngIf="error" class="vsk__error-text">{{ error }}</p>
      <div *ngIf="googleAuth">
        <p class="google-info">{{ googleAuth }}</p>
      </div>
      <div class="vsk__form">

        <div class="flex flex-row gap-2">
          <!-- Lastname -->
          <atc-input (enterKeyPressed)="nextStep()"
                     [control]="lastNameForm"
                     [focus]="true"
                     errorMessage="Champs obligatoire*"
                     id="id__input-create-account-lastname"
                     label="Nom"
                     placeholder="Dupont"
                     type="text"></atc-input>

          <!-- Firstname -->
          <atc-input (enterKeyPressed)="nextStep()"
                     [control]="firstNameForm"
                     errorMessage="Champs obligatoire*"
                     id="id__input-create-account-firstname"
                     label="Prénom"
                     placeholder="Jean"
                     type="text"></atc-input>
        </div>

        <div class="flex flex-row gap-2">
          <!-- Email-->
          <atc-input (enterKeyPressed)="nextStep()"
                     [control]="emailForm"
                     [errorMessage]="'Email invalide'"
                     errorMessage="Champs obligatoire*"
                     id="id__input-create-account-email"
                     label="Adresse mail"
                     placeholder="jean-dupont@gmail.com"
                     type="email"></atc-input>

          <!-- Phone -->
          <atc-input [control]="phoneForm"
                     [dropSpecialCharacters]="false"
                     [specialCharacters]="[' ']"
                     errorMessage="Champs obligatoire ne pouvant contenir uniquement des chiffres*"
                     label="Téléphone"
                     mask="00 00 00 00 00"
                     placeholder="06 01 02 03 04"></atc-input>
        </div>

        <!-- Birthdate -->
        <atc-input-date (dateChanged)="birthdateChanged($event)"
                        id="id__input-create-account-birthdate"
                        label="Date de naissance">
        </atc-input-date>

        <!-- Address -->
        <atc-input [control]="addressForm"
                   errorMessage="Ce champs est obligatoire*"
                   id="id__input-my-account-address"
                   label="Adresse postal"
                   placeholder="Mon adresse"
                   type="text"></atc-input>

        <div class="flex flex-row gap-2">
          <!-- City -->
          <atc-input [control]="cityForm"
                     errorMessage="Ce champs est obligatoire*"
                     id="id__input-my-account-city"
                     label="Ville"
                     placeholder="Ville"
                     type="text"></atc-input>

          <!-- Post code -->
          <atc-input [control]="postCodeForm"
                     errorMessage="Ce champs est obligatoire*"
                     id="id__input-my-account-postCode"
                     label="Code postal"
                     mask="00000"
                     placeholder="69000"
                     type="number"></atc-input>
        </div>

        <!-- Pays -->
        <atc-select [control]="countryForm"
                    [optionElements]="countries"
                    [searchable]="true"
                    id="id__input-my-account-country"
                    label="Pays"></atc-select>

        <div *ngIf="provider === 'veryski'"
             class="flex flex-row gap-2">
          <!-- Password -->
          <atc-input (enterKeyPressed)="nextStep()"
                     (focusOut)="checkPasswordValidity()"
                     [control]="passwordForm"
                     [type]="fieldTextType ? 'text' : 'password'"
                     id="id__input-create-account-password"
                     label="Mot de passe"
                     placeholder="************"></atc-input>
          <!-- Password confirmation -->
          <atc-input (enterKeyPressed)="nextStep()"
                     (focusOut)="checkSamePassword()"
                     [control]="passwordConfirmationForm"
                     [type]="fieldTextType ? 'text' : 'password'"
                     id="id__input-create-account-password-confirmation"
                     label="Confirmation de mot de passe"
                     placeholder="************"></atc-input>
        </div>

        <p class="text-center">Nous vous enverrons les confirmations et les reçus de réservations d'activités par
          e-mail.</p>

        <!-- CGU -->
        <atc-input-checkbox [control]="cguCheckedForm">
          <p class="text-center">En cochant cette case, j'accepte les
            <a class="underline" href="/terms-of-use" target="_blank">Conditions générales d'utilisation</a> et les
            <a class="underline" href="/selling-conditions" target="_blank">Conditions générales de vente</a> de
            VeryMountain
          </p>
        </atc-input-checkbox>

        <atc-input-checkbox [control]="newsletter">
          <p>Je souhaites recevoir les bons plans VeryMountain</p>
        </atc-input-checkbox>

        <atc-button (click)="nextStep()"
                    [disabled]="!isInscriptionValid()"
                    [fullWidth]="true"
                    class="vsk__responsive-button"
                    color="secondary"
                    id="id__button-create-account-confirmation"
                    label="Finaliser inscription"></atc-button>
      </div>
    </div>


    <!-- Step password -->
    <div *ngIf="step === 'password'" class="vsk__block-form">
      <img alt="logo" atcLazyLoad class="vsk__logo" src="assets/images/logo-mobile.svg">
      <h3 class="vsk__header-login">Mot de passe oublié</h3>
      <!-- Email-->
      <atc-input (enterKeyPressed)="nextStep()"
                 [control]="emailForm"
                 [errorMessage]="'Email invalide'"
                 [focus]="true"
                 class="mb-4"
                 id="id__input-forgotten-password-email"
                 label="Adresse mail"
                 placeholder="jean@verymountain.fr"
                 type="email"></atc-input>
      <p *ngIf="error" class="vsk__error-text">{{ error }}</p>
      <atc-button (click)="nextStep()"
                  [fullWidth]="true"
                  class="vsk__margin-top"
                  color="secondary"
                  id="id--button-send-reset-email"
                  label="Envoyer un mail de réinitialisation"></atc-button>
    </div>

    <!-- Step confirmation -->
    <div *ngIf="step === 'reset'" class="vsk__block-form">
      <img alt="logo" atcLazyLoad class="vsk__logo" src="assets/images/logo-mobile.svg">
      <h3 class="vsk__header-login">
        Email de réinitialisation de mot de passe
      </h3>
      <p class="vsk__confirmation">
        Un email pour réinitialiser votre mot de passe vous a été envoyé à l'adresse : <span
        id="id__text-email-to-send-reset">{{ emailForm.value }}</span>
      </p>
    </div>

    <div *ngIf="loading" class="vsk__spinner-overlay">
      <atc-loader></atc-loader>
    </div>
  </div>
</atc-popup>
