<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="showCart">
    <atc-side-drawer (closeChange)="openClose(false)"
                     [title]="title">

      <!-- body -->
      <div class="m-4" header>
        <h1>Commande du {{buyDate?.format('DD MMMM YYYY')}}</h1>
        <h3 class="text-center">Destination · {{destination}}</h3>
      </div>

      <!-- body -->
      <div body class="px-4">
        <vsk-cart-content [canDeleteItem]="false"
                          [cart]="cart"
                          [disableEcoChart]="true"
        ></vsk-cart-content>
      </div>

      <!-- footer -->
      <div *ngIf="!cart.isEmpty" class="vsk__footer-history md:p-4 pt-2" footer>


        <ng-container *ngIf="cart.hasPromoCode">
          <ng-container *ngIf="cart.getPublicPrice() as publicPrice">
            <ng-container *ngIf="cart.getPriceWithPromo() as promoPrice">
              <h3><span>Total TTC : </span>
                <span class="line-through">{{ publicPrice | number : '1.2-2':'fr' }} €</span>
                <span class="color-neutral-70"> {{ promoPrice | number : '1.2-2':'fr' }} €</span>
                <ng-container *ngIf="publicPrice !== promoPrice"> (-{{PricingUtils.reductionPercentage(publicPrice, promoPrice)}}%)</ng-container>
              </h3>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!cart.hasPromoCode">
         <h3><span>Total TTC : </span>{{cart.getPrice() | number : '1.2-2':'fr'}} €</h3>
        </ng-container>


        <atc-button (click)="openClose(true)"
                    color="ternary"
                    label="Faire une réclamation"></atc-button>
      </div>
    </atc-side-drawer>
  </ng-container>
</ng-container>

<vsk-reclamation-order (closeChanged)="openClose(true)"
                       *ngIf="openReclamation"
                       [transaction]="transactionId">
</vsk-reclamation-order>
