import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import { DisconnectUser } from '../../app.action';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { UserSession } from '../../shared/models/user-session';

@Component({
  selector: 'vsk-login-user',
  templateUrl: './login-user.component.html',
  styleUrls: ['./login-user.component.scss']
})
export class LoginUserComponent extends RxjsComponent {
  isOpen = false;
  openLoginPopup = false;
  links: OptionElement<string>[] = [
    {
      id: 'Mon compte',
      label: 'Mon compte',
      callback: () => {
        this.router.navigate(['my-account'], { queryParamsHandling: 'merge' });
      }
    },
    {
      id: 'logout',
      label: 'Se déconnecter',
      callback: this.logout.bind(this)
    }
  ];

  @Input() isLoggedIn = false;
  @Input() user: UserSession;

  constructor(
    private store: Store,
    private router: Router
  ) {
    super();
  }

  openLogin(): void {
    if (this.isLoggedIn) {
      this.isOpen = !this.isOpen;
    } else {
      this.isOpen = false;
      this.openLoginPopup = true;
    }
  }

  logout(): void {
    this.store.dispatch(new DisconnectUser());
  }
}
