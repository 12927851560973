import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Action,
  Select,
  Selector,
  State,
  StateContext,
  Store
} from '@ngxs/store';
import { Moment } from 'moment';
import moment from 'moment/moment';
import { EMPTY, Observable, combineLatest, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { FiltersState } from '../filters.state';
import { LocalStorageResource } from '../resource/local-storage.resource';
import { CartAccommodationService } from '../service/cart-accommodation.service';
import { CartActivityService } from '../service/cart-activity.service';
import { CartMasterService } from '../service/cart-master.service';
import { CartSkiEquipmentService } from '../service/cart-ski-equipment.service';
import { PromoService } from '../service/promo.service';
import { Establishment } from '../shared/models/accommodation/establishment';
import { ItemAccommodation } from '../shared/models/accommodation/item-accommodation';
import { Cart } from '../shared/models/cart/cart';
import { EcoChart } from '../shared/models/cart/eco-chart';
import { MarkerWrapper } from '../shared/models/marker-wrapper';
import { Item } from '../shared/models/package/item';
import { Participant } from '../shared/models/participant/participant';
import { PromoCode } from '../shared/models/promo-code';
import { DateUtils } from '../utils/date-utils';
import { MarkerUtils } from '../utils/marker-utils';
import {
  AddAccommodationToCart,
  AddItemsToCart,
  AddPromoCode,
  AddSkiEquipmentToCart,
  CartExpired,
  ChangeCartDrawerState,
  CreateCartMaster,
  DeletePromoCode,
  DepositMode,
  FetchCart,
  FetchPropositionCart,
  InitCart,
  RefuseCartMaster,
  RemoveItemAccommodationFromCart,
  RemoveItemsToCart,
  RemoveItemsWithNoParticipant,
  RemoveSkiEquipmentFromCart,
  TimerUpdate,
  UpdateAccommodationRemarks,
  UpdateEcoTourismChart
} from './cart.action';

const PROMO = 'promo';

export interface CartStateModel {
  cart: Cart;
  timer: Moment | null;
  promoCode: PromoCode | null;
  markers: MarkerWrapper<Item>[];
  ecoTourismChart: EcoChart;
  propositionCart: Cart | null;
  isOpen: boolean;
  deposit: boolean;
}

/**
 * TODO :
 * - Remove all useless functions
 * - Remove old cart strategy
 *   . use only session cart
 *   . no more sync with connected user
 *   . clean up backend endpoints
 *   . change summary / payment info endpoints
 */
@State<CartStateModel>({
  name: 'cart',
  defaults: {
    cart: new Cart({}),
    timer: null,
    promoCode: null,
    ecoTourismChart: LocalStorageResource.IsEcoChart(),
    propositionCart: null,
    isOpen: false,
    deposit: false,
    markers: []
  }
})
@Injectable()
export class CartState {
  @Select(FiltersState.participants) participants$: Observable<Participant[]>;

  constructor(
    private store: Store,
    private promoService: PromoService,
    private cartLockService: CartActivityService,
    private cartMasterService: CartMasterService,
    private cartSkiEquipmentService: CartSkiEquipmentService,
    private cartAccommodationService: CartAccommodationService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  @Selector()
  static markers(state: CartStateModel): MarkerWrapper<Item>[] {
    return state.markers;
  }

  @Selector()
  static cart(state: CartStateModel): Cart {
    return new Cart({
      ...state.cart,
      promoCode: state.promoCode,
      ecoTourismChart: state.ecoTourismChart
    });
  }

  @Selector()
  static propositionCart(state: CartStateModel): Cart | null {
    if (!state.propositionCart) {
      return null;
    }

    return new Cart({
      ...state.propositionCart,
      promoCode: state.promoCode,
      ecoTourismChart: state.ecoTourismChart,
      isProposition: true
    });
  }

  @Selector()
  static isCartEmpty(state: CartStateModel): boolean {
    return state.cart.isEmpty;
  }

  @Selector()
  static isEcoTourismChartChecked(state: CartStateModel) {
    return state.ecoTourismChart.isChecked;
  }

  @Selector()
  static quantityItems(state: CartStateModel): number {
    return state.cart.getQuantity();
  }

  @Selector()
  static totalPriceDisplay(state: CartStateModel): string {
    return state.cart.getPrice().toFixed(2);
  }

  @Selector()
  static promoCode(state: CartStateModel): PromoCode | null {
    return state.promoCode;
  }

  @Selector()
  static isOpen(state: CartStateModel): boolean {
    return state.isOpen;
  }

  @Selector()
  static isDeposit(state: CartStateModel): boolean {
    return state.deposit;
  }

  @Action(InitCart)
  initCart(ctx: StateContext<CartStateModel>, action: InitCart): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      promoCode: window.localStorage.getItem(PROMO)
        ? new PromoCode({
            ...JSON.parse(window.localStorage.getItem(PROMO) as string)
          })
        : null
    });

    if (
      !!action.queryParams.get('promoCode') &&
      !window.localStorage.getItem(PROMO)
    ) {
      this.promoService
        .setPromoCode(action.queryParams.get('promoCode') as string)
        .subscribe((code) => {
          if (
            DateUtils.isBetween(
              moment(),
              code.dateValidityStart,
              code.dateValidityEnd
            ) &&
            code.used < code.usedLimit
          ) {
            this.store.dispatch(new AddPromoCode(code));
          }
        });
    }

    this.fetchCart(ctx);
  }

  @Action(AddItemsToCart)
  addItemsToCart(ctx: StateContext<CartStateModel>, action: AddItemsToCart) {
    if (action.itemsToAdd.length) {
      return this.cartLockService
        .addItems(
          this.store.selectSnapshot(FiltersState.sessionId),
          action.itemsToAdd
        )
        .pipe(
          tap((items) => {
            const cart = new Cart({
              ...ctx.getState().cart,
              itemsActivity: [...items]
            });
            const markers = [
              ...MarkerUtils.buildActivitiesMarkers(cart.itemsActivity),
              ...MarkerUtils.buildAccommodationMarkers(cart.itemsAccommodation)
            ];
            ctx.patchState({ cart, isOpen: true, markers });
          })
        );
    }

    return of(EMPTY);
  }

  @Action(CreateCartMaster)
  createCartMaster(
    ctx: StateContext<CartStateModel>,
    action: CreateCartMaster
  ) {
    if (action.copyCart) {
      return this.cartMasterService.copyCart(action.copyCart).pipe(
        tap(() => {
          ctx.patchState({
            propositionCart: null
          });
        }),
        switchMap(() =>
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
              sessionMaster: null
            },
            queryParamsHandling: 'merge',
            replaceUrl: true
          })
        ),
        tap(() => this.fetchCart(ctx))
      );
    }

    return of(EMPTY);
  }

  @Action(RefuseCartMaster)
  refuseCartMaster(ctx: StateContext<CartStateModel>) {
    ctx.patchState({
      propositionCart: null
    });
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        sessionMaster: null
      },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  @Action(AddSkiEquipmentToCart)
  addSkiEquipmentToCart(
    ctx: StateContext<CartStateModel>,
    action: AddSkiEquipmentToCart
  ) {
    if (action.itemSkiEquipment) {
      return this.cartSkiEquipmentService.addItem(action.itemSkiEquipment).pipe(
        tap((item) => {
          const currentState = ctx.getState();
          // Ajouter le nouvel élément au tableau existant
          const updatedItemsSkiEquipment = [
            ...currentState.cart.itemsSkiEquipment,
            item
          ];
          // Mettre à jour le panier avec le nouveau tableau d'équipements
          const cart = new Cart({
            ...currentState.cart,
            itemsSkiEquipment: updatedItemsSkiEquipment
          });
          const markers = [
            ...MarkerUtils.buildActivitiesMarkers(cart.itemsActivity),
            ...MarkerUtils.buildAccommodationMarkers(cart.itemsAccommodation)
          ];
          ctx.patchState({ cart, markers });
        })
      );
    }

    return of(EMPTY);
  }

  @Action(RemoveSkiEquipmentFromCart)
  removeSkiEquipmentFromCart(
    ctx: StateContext<CartStateModel>,
    action: RemoveSkiEquipmentFromCart
  ) {
    if (action.cartItemId) {
      return this.cartSkiEquipmentService
        .deleteItem(
          this.store.selectSnapshot(FiltersState.sessionId),
          action.cartItemId
        )
        .pipe(
          tap((items) => {
            const cart = new Cart({
              ...ctx.getState().cart,
              itemsSkiEquipment: [...items]
            });
            const markers = [
              ...MarkerUtils.buildActivitiesMarkers(cart.itemsActivity),
              ...MarkerUtils.buildAccommodationMarkers(cart.itemsAccommodation)
            ];
            ctx.patchState({ cart, markers });
          })
        );
    }

    return of(EMPTY);
  }

  @Action(AddAccommodationToCart)
  addAccommodationToCart(
    ctx: StateContext<CartStateModel>,
    action: AddAccommodationToCart
  ) {
    if (action.itemAccommodation) {
      return this.cartAccommodationService
        .addItems(action.itemAccommodation)
        .pipe(
          tap((items) => {
            const cart = new Cart({
              ...ctx.getState().cart,
              itemsAccommodation: [...items]
            });
            const markers = [
              ...MarkerUtils.buildActivitiesMarkers(cart.itemsActivity),
              ...MarkerUtils.buildAccommodationMarkers(cart.itemsAccommodation)
            ];
            ctx.patchState({ cart, markers });
          })
        );
    }

    return of([]);
  }

  @Action(RemoveItemAccommodationFromCart)
  removeItemAccommodationFromCart(
    ctx: StateContext<CartStateModel>,
    action: RemoveItemAccommodationFromCart
  ) {
    if (action.id) {
      return this.cartAccommodationService
        .deleteItem(action.id)
        .pipe(tap(() => this.fetchCart(ctx)));
    }

    return of(EMPTY);
  }

  @Action(UpdateAccommodationRemarks)
  updateAccommodation(
    ctx: StateContext<CartStateModel>,
    action: UpdateAccommodationRemarks
  ) {
    if (action.remarks) {
      const items = ctx.getState().cart.itemsAccommodation.map((item) => {
        return new ItemAccommodation({
          ...item,
          establishment: new Establishment({
            id: item.establishment.id
          }),
          remarks: action.remarks
        });
      });

      return this.cartAccommodationService
        .updateItems(items, this.store.selectSnapshot(FiltersState.sessionId))
        .pipe(
          tap((items) => {
            const cart = new Cart({
              ...ctx.getState().cart,
              itemsAccommodation: [...items]
            });
            const markers = [
              ...MarkerUtils.buildActivitiesMarkers(cart.itemsActivity),
              ...MarkerUtils.buildAccommodationMarkers(cart.itemsAccommodation)
            ];
            ctx.patchState({ cart, markers });
          })
        );
    }

    return of(EMPTY);
  }

  @Action(RemoveItemsToCart)
  removeItemsToCart(
    ctx: StateContext<CartStateModel>,
    action: RemoveItemsToCart
  ) {
    if (action.itemsToRemove.length) {
      return this.cartLockService
        .removeItems(
          this.store.selectSnapshot(FiltersState.sessionId),
          action.itemsToRemove
        )
        .pipe(
          tap((items) => {
            const cart = new Cart({
              ...ctx.getState().cart,
              itemsActivity: [...items]
            });
            const markers = [
              ...MarkerUtils.buildActivitiesMarkers(cart.itemsActivity),
              ...MarkerUtils.buildAccommodationMarkers(cart.itemsAccommodation)
            ];
            ctx.patchState({ cart, markers });

            if (cart.isEmpty) {
              ctx.patchState({ timer: null });
            }
          })
        );
    }

    return of(EMPTY);
  }

  @Action(FetchCart)
  fetchCart(ctx: StateContext<CartStateModel>): void {
    combineLatest([
      this.cartLockService.getItems(
        this.store.selectSnapshot(FiltersState.sessionId)
      ),
      this.cartAccommodationService.getItems(
        this.store.selectSnapshot(FiltersState.sessionId)
      ),
      this.cartSkiEquipmentService.getItems(
        this.store.selectSnapshot(FiltersState.sessionId),
        false
      )
    ]).subscribe(([itemsActivity, itemsAccommodation, itemsSkiEquipment]) => {
      const cart = new Cart({
        itemsActivity,
        itemsAccommodation,
        itemsSkiEquipment,
        sessionId: this.store.selectSnapshot(FiltersState.sessionId)
      });
      const markers = [
        ...MarkerUtils.buildActivitiesMarkers(cart.itemsActivity),
        ...MarkerUtils.buildAccommodationMarkers(cart.itemsAccommodation)
      ];
      ctx.patchState({ cart, markers: markers });
    });
  }

  @Action(FetchPropositionCart)
  fetchPropositionCart(
    ctx: StateContext<CartStateModel>,
    action: FetchPropositionCart
  ): void {
    combineLatest([
      this.cartLockService.getItems(action.sessionId),
      this.cartAccommodationService.getItems(action.sessionId),
      this.cartSkiEquipmentService.getItems(action.sessionId, false)
    ]).subscribe(([itemsActivity, itemsAccommodation, itemsSkiEquipment]) => {
      const cart = new Cart({
        itemsActivity,
        itemsAccommodation,
        itemsSkiEquipment,
        sessionId: action.sessionId
      });
      ctx.patchState({ propositionCart: cart });
    });
  }

  @Action(RemoveItemsWithNoParticipant)
  removeItemsWithNoParticipant(
    ctx: StateContext<CartStateModel>,
    action: RemoveItemsWithNoParticipant
  ): void {
    const state = ctx.getState();
    const cart = ctx.getState().cart;
    cart.removeElementIfParticipantIsAbsent(action.participants);

    ctx.setState({
      ...state,
      cart
    });
  }

  @Action(TimerUpdate)
  updateTimerExpiration(
    ctx: StateContext<CartStateModel>,
    action: TimerUpdate
  ): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      timer: action.timer
    });
  }

  @Action(CartExpired)
  cartExpired(ctx: StateContext<CartStateModel>): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      timer: null,
      cart: new Cart({ itemsActivity: [], itemsAccommodation: [] })
    });
  }

  @Action(AddPromoCode)
  setPromoCode(ctx: StateContext<CartStateModel>, action: AddPromoCode): void {
    const state = ctx.getState();
    window.localStorage.setItem(PROMO, JSON.stringify(action.promoCode));

    ctx.setState({
      ...state,
      promoCode: action.promoCode
    });
  }

  @Action(DepositMode)
  depositMode(ctx: StateContext<CartStateModel>, action: DepositMode): void {
    const state = ctx.getState();

    ctx.patchState({
      cart: new Cart({
        ...state.cart,
        deposit: action.deposit
      })
    });
  }

  @Action(UpdateEcoTourismChart)
  updateEcoTourismChart(
    ctx: StateContext<CartStateModel>,
    action: UpdateEcoTourismChart
  ): void {
    const state = ctx.getState();
    window.localStorage.setItem(
      LocalStorageResource.CHARTE_ECO,
      String(action.value.isChecked)
    );

    ctx.setState({
      ...state,
      ecoTourismChart: action.value
    });
  }

  @Action(DeletePromoCode)
  deletePromoCode(ctx: StateContext<CartStateModel>): void {
    this.promoService.deletePromoCode().subscribe(() => {
      window.localStorage.removeItem(PROMO);

      ctx.patchState({
        promoCode: null
      });
    });
  }

  @Action(ChangeCartDrawerState)
  changeCartDrawerState(
    ctx: StateContext<CartStateModel>,
    action: ChangeCartDrawerState
  ): void {
    ctx.patchState({
      isOpen: action.isOpen
    });
  }
}
