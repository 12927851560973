import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Activity } from '../models/activity/activity';

@Component({
  selector: 'vsk-activity-selector',
  templateUrl: './activity-selector.component.html',
  styleUrls: ['./activity-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivitySelectorComponent {
  @Input() activity: Activity;
  @Input() size: 'small' | 'medium' = 'small';

  @Output() selectedChange = new EventEmitter<void>();
  protected readonly String = String;

  toggle(): void {
    this.activity.selected = !this.activity.selected;
    this.selectedChange.emit();
  }

  generateId() {
    return `id__activity-${this.activity.id}`;
  }
}
