import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService extends RepositoryService {
  private URL_LOCATION = 'api/webapp/location';

  constructor(http: HttpClient) {
    super(http);
  }

  getCountriesNames(): Observable<string[]> {
    return this.get(this.URL_LOCATION + '/countries');
  }
}
