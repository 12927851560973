import { Moment } from 'moment';
import { Experience } from '../activity/experience';
import { AgeRange } from '../age-range';
import { PackagePrice } from './package-price';

export class Package {
  id: number;
  prices: PackagePrice[];
  experience: Experience;
  ageRange: AgeRange;

  constructor(obj: Partial<Package>) {
    Object.assign(this, obj);
    this.ageRange = new AgeRange({ ...this.ageRange });
    this.experience = new Experience({ ...this.experience });

    if (this.prices) {
      this.prices = this.prices.map((price) => new PackagePrice({ ...price }));
    }
  }

  public get minPrice() {
    if (!this.prices?.length) {
      return 0;
    }

    return this.prices
      .map((pckPrice) => pckPrice.price)
      .reduce((prev, curr) => (prev < curr ? prev : curr));
  }

  public priceByParticipantSizeAndDates(
    nbParticipant: number,
    startDate: Moment,
    isPublic = false
  ) {
    if (!this.prices) {
      return 0;
    }

    const filtered = this.prices
      .filter(
        (price) => price.nbMin <= nbParticipant && price.nbMax >= nbParticipant
      )
      .filter((price) => price.dateIsMatching(startDate));

    if (isPublic) {
      return filtered.length ? filtered[0].publicPrice : 0;
    }

    return filtered.length ? filtered[0].price : 0;
  }
}
