<div *ngIf="_cart" class="flex flex-col gap-y-1">
  <div class="flex flex-row justify-between items-center sm:flex-col">
    <div class="flex flex-row bg-white gap-4 items-center">
      <h2>Récapitulatif</h2>
      <svg class="w-[24px] h-[24px] stroke-black">
        <use [attr.xlink:href]="'assets/icons/icons.svg#cart'"></use>
      </svg>
    </div>

    <vsk-promo-code *ngIf="!_cart.promoCode"
                    [cart]="_cart"
                    [paymentPage]=true
                    [promoCode]="_cart.promoCode" class="xsm:max-w-[55%] sm:mt-4">
    </vsk-promo-code>
  </div>

  <div class="flex flex-row justify-between items-center">
    <h3>Prix {{ _cart.getPublicPrice() !== _cart.getPrice() ? 'public' : 'VeryMountain' }}</h3>
    <span>{{ _cart.getPublicPrice() | number : '1.2-2' }} €</span>
  </div>

  <ng-container *ngIf="_cart.getPublicPrice() !== _cart.getPrice()">
    <div *ngIf="PricingUtils.reductionPrice(_cart.getPublicPrice(), _cart.getPrice()) as reduction"
         class="flex flex-row justify-between items-center">
      <h3 *ngIf="PricingUtils.reductionPercentage(_cart.getPublicPrice(), _cart.getPrice()) as percentage"
          class="max-w-[70%]">
        Réduction VeryMountain (- {{ percentage }} %)</h3>
      <span class="color-green">- {{ reduction | number : '1.2-2' }} €</span>
    </div>
  </ng-container>

  <div *ngIf="!!_cart.promoCode"
       class="flex flex-row justify-between items-center">
    <div class="flex flex-row gap-x-2 items-center max-w-[70%]">
      <ng-container *ngIf="_cart.hasPromoCode">
        <h3 *ngIf="PricingUtils.reductionPercentage(_cart.getPrice(), _cart.getPriceWithPromo()) as percentage">
          Réduction code promo · {{ _cart.promoCode?.name }} (- {{ percentage }} %)
        </h3>
      </ng-container>

      <h3 *ngIf="!_cart.hasPromoCode" class="warning">
        Réduction non appliqué · (Min. de {{ _cart.promoCode?.minPrice }} € d'achat)
      </h3>

      <svg (click)="deletePromoCode()"
           class="h-[20px] w-[20px] mt-0.5 stroke-black hover:cursor-pointer">
        <use href="assets/icons/icons.svg#trash"></use>
      </svg>
    </div>

    <span *ngIf="PricingUtils.reductionPrice(_cart.getPrice(), _cart.getPriceWithPromo()) as reduction"
          class="color-green">
      - {{ reduction | number : '1.2-2' }} €
    </span>
  </div>

  <hr class="my-2">

  <div *ngIf="!depositEnabled || !_cart.deposit"
       class="flex flex-row justify-between items-center">
    <h2>Prix TTC</h2>
    <h2>{{ _cart.getPriceWithPromo() | number : '1.2-2' }} €</h2>
  </div>

  <ng-container *ngIf="depositEnabled && _cart.deposit">
    <div class="flex flex-row justify-between items-center">
      <h3>Prix TTC</h3>
      <span>{{ _cart.getPriceWithPromo() | number : '1.2-2' }} €</span>
    </div>

    <div class="flex flex-row justify-between items-center">
      <h2>Acompte à régler</h2>
      <h2>{{ _cart.getPriceWithPromo() * .3 | number : '1.2-2' }} €</h2>
    </div>

    <div class="flex flex-row justify-between items-center">
      <h3 class="max-w-[70%]">Prélèvement automatique du reste à régler
        le {{ startDate.clone().add(-45, 'day').format('DD/MM/YYYY') }}</h3>
      <span>{{ _cart.getPriceWithPromo() * .7 | number : '1.2-2' }} €</span>
    </div>
  </ng-container>
</div>
