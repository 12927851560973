<!-- Desktop -->
<ng-container *ngIf="!responsive && (isFoncia$ | async) === false">
  <div (click)="openChange(!showPicker)"
       [ngClass]="{'vsk__is-in-navbar': isInNavbar,
       'vsk__is-not-in-navbar': !isInNavbar,
       'vsk__is-active': showPicker && currentPage !== 'Home',
       'vsk__is-active-in-home-page activity': showPicker && currentPage === 'Home',
       'hover-element': currentPage !== 'Home',
       'home-element': currentPage === 'Home'
       }">
    <svg *ngIf="!isInNavbar" class="lg:max-h-8 lg:min-h-8 lg:max-w-[2rem] lg:mt-[1.2rem] lg:ml-2 stroke-secondary">
      <use [attr.xlink:href]="'assets/icons/icons.svg#activity'"></use>
    </svg>
    <svg *ngIf="isInNavbar" class="lg:max-h-8 lg:min-h-8 lg:max-w-[2rem] stroke-secondary">
      <use [attr.xlink:href]="'assets/icons/icons.svg#activity'"></use>
    </svg>
    <div class="vsk__column lg:my-auto lg:mx-4">
      <p [ngClass]="{'vsk__selected': isSelected()}" class="subtitle">{{ subTitleActivities() }}</p>
    </div>
  </div>

  <atc-dropdown (closeChanged)="openChange(false)"
                [classes]="['no-padding']"
                [isOpen]="showPicker"
                [left]="LEFT"
                [position]="'bottom-left'"
                [width]="WIDTH">
    <atc-input [control]="searchForm"
               icon="search"
               placeholder="Rechercher...">
    </atc-input>
    <div class="vsk__container-activity">
      <ng-container *ngFor="let activitiesByCategory of activitiesByCategories">
        <ng-container *ngIf="isInCategory(activitiesByCategory.categoryName)">
          <h5>{{ activitiesByCategory.categoryName }}</h5>
          <div class="vsk__categories">
            <ng-container *ngFor="let activity of activitiesByCategory.activities">
              <vsk-activity-selector (selectedChange)="toggleActivity(activity)"
                                     *ngIf="filterActivity(activity)"
                                     [activity]="activity">
              </vsk-activity-selector>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="vsk__inline p-4">
      <atc-button (click)="resetActivities()"
                  color="ternary"
                  label="Effacer"></atc-button>
      <div class="vsk__spacer"></div>

      <atc-button (click)="goTo(URL_SEARCH_TRIP)"
                  *ngIf="currentPage === 'Resort' && (isFoncia$ | async) === false"
                  class="mr-4"
                  color="ternary"
                  label="Découvrir d'autres destinations">
      </atc-button>

      <atc-button (click)="update()"
                  *ngIf="currentPage !== 'Home'"
                  color="secondary"
                  label="Mettre à jour mes activités">
      </atc-button>

      <atc-button (click)="validation()"
                  *ngIf="currentPage === 'Home'"
                  color="secondary"
                  id="id__button-continuer-activity"
                  label="Ajouter des voyageurs">
      </atc-button>


    </div>
  </atc-dropdown>
</ng-container>

<!-- Mobile -->
<ng-container *ngIf="responsive">
  <div class="vsk__container-activity
              md:mb-4
              lg:mb-4">
    <ng-container *ngFor="let activitiesByCategory of activitiesByCategories">
      <ng-container *ngIf="isInCategory(activitiesByCategory.categoryName)">
        <h5>{{ activitiesByCategory.categoryName }}</h5>
        <div class="vsk__categories
                    md:mt-4
                    lg:mt-4">
          <ng-container *ngFor="let activity of activitiesByCategory.activities">
            <vsk-activity-selector (selectedChange)="toggleActivity(activity)"
                                   *ngIf="filterActivity(activity)"
                                   [activity]="activity">
            </vsk-activity-selector>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="vsk__inline">
    <atc-button (click)="resetActivities()"
                color="ternary"
                label="Effacer"></atc-button>
    <div class="vsk__spacer"></div>
    <atc-button (click)="validation()"
                color="secondary"
                label="Ajouter des voyageurs"></atc-button>
  </div>
</ng-container>
