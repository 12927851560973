<div (click)="goToSearchPopup.emit()" class="flex flex-col flex-1 py-2 items-center">
  <div class="flex flex-row gap-2">
    <svg class="stroke-black">
      <use [attr.xlink:href]="'assets/icons/icons.svg#calendar'"></use>
    </svg>
    <h2>{{ titleDates || 'Dates' }}</h2>
  </div>
</div>

<ng-container *ngIf="(isFoncia$ | async) === false">
  <div (click)="goToSearchPopup.emit()" class="flex flex-col flex-1 py-2 items-center">
    <div class="flex flex-row gap-2">
      <svg class="stroke-black">
        <use [attr.xlink:href]="'assets/icons/icons.svg#activity'"></use>
      </svg>
      <h2 *ngIf="!activitiesSelected.length">Activités</h2>
      <h2 *ngFor="let activity of activitiesSelected | slice:0:1">{{ activity }}</h2>
    </div>
  </div>
</ng-container>

<div (click)="goToSearchPopup.emit()" class="flex flex-col flex-1 py-2 items-center">
  <div class="flex flex-row gap-2">
    <svg class="stroke-black">
      <use [attr.xlink:href]="'assets/icons/icons.svg#group'"></use>
    </svg>
    <h2>{{ titleParticipants }}</h2>
  </div>
</div>
