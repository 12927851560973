<ng-container *ngIf="!showMobileFilters">
  <div class="flex flex-col gap-2 p-4 rounded-b-[30px]">
    <div class="flex flex-row gap-x-4 w-full items-center justify-between">
      <h1 *ngIf="!_loading">
        <span>{{ totalStationsFiltered }}</span>
        {{ totalStationsFiltered > 1 ? 'stations de montagne' : 'station de montagne' }}
      </h1>

      <ng-container *ngIf="_loading">
        <div class="rounded-[20px] min-h-[2.5rem] w-[40%]" vskSkeletonLoad></div>
      </ng-container>
    </div>

    <div class="flex items-center justify-between gap-x-2 pt-2">
      <vsk-search-bar (filteredValues)="stationsFiltered = $event"
                      *ngIf="!_loading"
                      [values]="allStations || []"
                      class="flex-1" placeholder="Rechercher une station ..."
                      property="name">
      </vsk-search-bar>

      <atc-select *ngIf="!_loading"
                  [control]="orderForm"
                  [optionElements]="ordersSelection"
                  class="w-[16rem]">
      </atc-select>

      <ng-container *ngIf="_loading">
        <div class="rounded-[20px] min-h-[2.5rem] w-[70%]" vskSkeletonLoad></div>
        <div class="rounded-[20px] min-h-[2.5rem] w-[30%]" vskSkeletonLoad></div>
      </ng-container>
    </div>
  </div>

  <div #drawerContainer [ngClass]="{'vsk___loading': _loading}" class="vsk__drawer">
    <div #scrollableContent
         class="scrollable-content">
      <div class="bg-white px-2 pt-6 rounded-[30px] box-border
                  vsk__drawer-container text-center box-shadow-bottom
                  xl:py-6 xl:m-4">
        <h2 *ngIf="!_loading">
          <span>{{ totalStationsFiltered }}</span>
          {{ totalStationsFiltered > 1 ? 'stations de montagne' : 'station de montagne' }}
        </h2>

        <ng-container *ngIf="_loading">
          <div class="mx-8">
            <div class="rounded-[20px] min-h-[25px]" vskSkeletonLoad></div>
          </div>
        </ng-container>

        <vsk-search-bar (filteredValues)="stationsFiltered = $event"
                        *ngIf="!_loading"
                        [values]="allStations || []" placeholder="Rechercher une station ..."
                        property="name">
        </vsk-search-bar>

        <ng-container *ngIf="_loading">
          <div class="mx-8">
            <div class="rounded-[20px] min-h-[25px]" vskSkeletonLoad></div>
          </div>
        </ng-container>

        <div class="flex justify-between mx-8">
          <atc-select *ngIf="!_loading"
                      [control]="orderForm"
                      [optionElements]="ordersSelection"
                      class="w-[100%]"></atc-select>

          <ng-container *ngIf="_loading">
            <div class="rounded-[20px] min-h-[25px] w-[100%]" vskSkeletonLoad></div>
          </ng-container>
        </div>

        <vsk-header-filters (filterApplied)="scrollOnTopPage()"
                            *ngIf="!_loading"
                            [filtersTripInfo]="filtersMap"
                            [filters]="filters"
                            [ngClass]="{'fixed': sticky}"
                            [resortLabels]="resortLabels"
                            [tags]="tags">
        </vsk-header-filters>

      </div>
      <ng-container *ngIf="!_loading">
        <p *ngIf="!_loading && resortsBorders.length === 0" class="vsk__no-station">Aucune station ne correspond
          à vos critères 😥 </p>
        <ng-container *ngIf="resortsBorders.length > 0">
          <ng-container *ngFor="let resort of resortsBorders">
            <vsk-list-station-card (mouseout)="isOutMarker(resort.marker)"
                                   (mouseover)="isOverMarker(resort.marker)"
                                   *ngIf="filterResort(resort)"
                                   [activities]="activities"
                                   [filters]="filters"
                                   [loading]="_loading"
                                   [nbNights]="nbNights"
                                   [selectedActivities]="selectedActivities"
                                   [station]="resort">
            </vsk-list-station-card>
          </ng-container>

          <div class="separator">
            <hr class="decoration">
            <p>Nos autres destinations a découvrir</p>
            <hr class="decoration">
          </div>

          <ng-container *ngFor="let resort of remainingStations | sortById: 'name'">
            <vsk-list-station-card *ngIf="filterResort(resort)"
                                   [activities]="activities"
                                   [cardIsMinified]=true
                                   [filters]="filters"
                                   [loading]="_loading"
                                   [station]="resort">
            </vsk-list-station-card>
          </ng-container>
        </ng-container>
      </ng-container>


      <ng-container *ngIf="_loading">
        <div *ngFor="let index of [0, 1, 2, 3]" class="vsk__card no-y-padding">
          <div class="rounded-[20px] min-h-[200px]" vskSkeletonLoad></div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
