import { Component, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FiltersState } from 'src/app/filters.state';
import { CloseBanner } from '../../app.action';
import { InformationBanner } from '../models/information-banner';

@Component({
  selector: 'vsk-information-banner',
  templateUrl: './information-banner.component.html',
  styleUrls: ['./information-banner.component.scss']
})
export class InformationBannerComponent {
  @Select(FiltersState.isFoncia) isFoncia$: Observable<boolean>;

  @Input() banner: InformationBanner;

  constructor(private store: Store) {}

  closeBanner() {
    this.store.dispatch(new CloseBanner());
  }
}
