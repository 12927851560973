import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FiltersState } from 'src/app/filters.state';
import { Activity } from '../../shared/models/activity/activity';
import { Participant } from '../../shared/models/participant/participant';
import { Period } from '../../shared/models/period';

@Component({
  selector: 'vsk-search-navbar',
  templateUrl: './search-navbar.component.html',
  styleUrls: ['./search-navbar.component.scss']
})
export class SearchNavbarComponent {
  @Select(FiltersState.isFoncia) isFoncia$: Observable<boolean>;

  titleDates = 'Quand ?';
  titleParticipants = 'Qui ?';
  activitiesSelected: string[] = [];

  @Input() set dates(period: Period) {
    if (period && period.isValid) {
      this.titleDates =
        period.startDate.locale('fr').format('DD') +
        '-' +
        period.endDate.locale('fr').format('DD MMM');
    }
  }

  @Input() set activities(activities: Activity[]) {
    if (activities) {
      const activitiesSelected = activities.filter((act) => act.selected);
      this.activitiesSelected = activitiesSelected.length
        ? activitiesSelected.map((activity) => activity.name)
        : [];
    }
  }

  @Input() set participants(participants: Participant[]) {
    if (participants) {
      const participantsFiltered = participants.filter((participant) =>
        participant.isValid()
      );
      if (participantsFiltered.length) {
        this.titleParticipants = `${participants.length} voyageur${participants.length > 1 ? 's' : ''}`;
      }
    }
  }

  @Output() goToSearchPopup: EventEmitter<void> = new EventEmitter<void>();
}
