import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from '../../../service/account.service';
import { RxjsComponent } from '../../../shared/component/rxjs.component';

@Component({
  selector: 'vsk-dialog-delete-account',
  templateUrl: './dialog-delete-account.component.html',
  styleUrls: ['./dialog-delete-account.component.scss']
})
export class DialogDeleteAccountComponent extends RxjsComponent {
  confirmationValue: string;
  deleteForm: UntypedFormControl = new UntypedFormControl(
    '',
    Validators.required
  );

  @Input() email: string;
  @Output() closeChanged: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private accountService: AccountService,
    private router: Router
  ) {
    super();
    this.confirmationValue = (Math.random() * 10000).toFixed(0);
  }

  deleteAccount(): void {
    if (this.isFormValid()) {
      this.register(
        this.accountService.deleteAccount(this.email).subscribe(() => {
          this.router.navigate(['/']);
          window.location.reload();
        })
      );
    }
  }

  isFormValid(): boolean {
    return (
      this.deleteForm.valid && this.confirmationValue === this.deleteForm.value
    );
  }
}
