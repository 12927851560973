import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ItemCart } from '../shared/models/package/item-cart';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class CartActivityService extends RepositoryService {
  private URL_ARTICLE = 'api/webapp/cart';

  constructor(http: HttpClient) {
    super(http);
  }

  addItems(sessionId: string, items: ItemCart[]): Observable<ItemCart[]> {
    return this.post(
      `${this.URL_ARTICLE}/add-items-session?sessionId=${sessionId}`,
      items
    ).pipe(
      map((itemCarts: ItemCart[]) =>
        itemCarts
          ? itemCarts.map((itemCart) => new ItemCart({ ...itemCart }))
          : []
      )
    );
  }

  getItems(sessionId: string, isPayed = false): Observable<ItemCart[]> {
    return this.get(
      `${this.URL_ARTICLE}/get-items-session?sessionId=${sessionId}&isPayed=${isPayed}`
    ).pipe(
      map((itemCarts: ItemCart[]) =>
        itemCarts
          ? itemCarts.map((itemCart) => new ItemCart({ ...itemCart }))
          : []
      )
    );
  }

  removeItems(sessionId: string, items: string[]): Observable<ItemCart[]> {
    return this.post(
      `${this.URL_ARTICLE}/delete-items-session?sessionId=${sessionId}`,
      items
    ).pipe(
      map((itemCarts: ItemCart[]) =>
        itemCarts
          ? itemCarts.map((itemCart) => new ItemCart({ ...itemCart }))
          : []
      )
    );
  }
}
