import { Component } from '@angular/core';

@Component({
  selector: 'vsk-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent {
  constructor() {
    document.title = "Conditions générales d'utilisation - VeryMountain";
  }
}
