import { ItemAccommodation } from '../shared/models/accommodation/item-accommodation';
import { Experience } from '../shared/models/activity/experience';
import { MarkerWrapper } from '../shared/models/marker-wrapper';
import { Item } from '../shared/models/package/item';
import { ItemCart } from '../shared/models/package/item-cart';

export class MarkerUtils {
  public static buildAccommodationMarkers(items: ItemAccommodation[]) {
    return items.map((item) => {
      const text = item.room.name + ' - ' + Math.round(item.price) + ' €';
      return new MarkerWrapper<Item>(
        item,
        {
          clickable: true,
          position: {
            lat: Number(item.establishment.location.lat),
            lng: Number(item.establishment.location.lon)
          },
          title: text,
          label: {
            color: 'white',
            text: text,
            fontWeight: '500',
            fontSize: '11px',
            className: 'pinpoint-cart',
            fontFamily: 'General'
          },
          icon: {
            url: '../../../../assets/svg/pinpoint.svg',
            scaledSize: new google.maps.Size(
              text.length * 5 + 40,
              36,
              'px',
              'px'
            ),
            origin: new google.maps.Point(-13, -5)
          },
          anchorPoint: new google.maps.Point(0, 0),
          optimized: false,
          zIndex: -1
        },
        false,
        {
          link: '/station/logement',
          params: {
            establishmentId: item.establishment.id,
            roomCode: item.room.codeRoom,
            partnerCode: item.partner.partnerCode,
            station: item.resort
          }
        }
      );
    });
  }

  public static buildActivitiesMarkers(items: ItemCart[]) {
    return items.map((item) => {
      const experience = item.item?.experience as Experience;
      const text = experience.name + ' - ' + Math.round(item.price) + ' €';

      return new MarkerWrapper<Item>(
        item,
        {
          clickable: true,
          position: {
            lat: experience.latitude,
            lng: experience.longitude
          },
          title: item.activity + ' - ' + item.partnerName,
          label: {
            color: 'white',
            text: text,
            fontWeight: '500',
            fontSize: '11px',
            className: 'pinpoint-cart',
            fontFamily: 'General'
          },
          icon: {
            url: '../../../../assets/svg/pinpoint.svg',
            scaledSize: new google.maps.Size(
              text.length * 5 + 40,
              36,
              'px',
              'px'
            ),
            origin: new google.maps.Point(-13, -5)
          },
          anchorPoint: new google.maps.Point(0, 0),
          optimized: false,
          zIndex: -1
        },
        false,
        {
          link: '/station/fiche-activite',
          params: {
            experienceId: experience.id,
            station: item.resort
          }
        }
      );
    });
  }
}
