export class PricingUtils {
  public static reductionPercentage(publicPrice: number, price: number) {
    const reduction = Math.round(((publicPrice - price) / publicPrice) * 100);
    return reduction === 0 ? 0 : reduction;
  }

  public static reductionPrice(publicPrice: number, price: number) {
    return publicPrice - price;
  }
}
