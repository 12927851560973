import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { OrderService } from '../../service/order.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { OrderHistory } from '../../shared/models/order-history';
import { UserSession } from '../../shared/models/user-session';
import { DownloadUtils } from '../../utils/download-utils';

@Component({
  selector: 'vsk-account-orders',
  templateUrl: './account-orders.component.html',
  styleUrls: ['./account-orders.component.scss']
})
export class AccountOrdersComponent extends RxjsComponent {
  @Select(AppState.user) user$: Observable<UserSession>;
  @Select(AppState.isUserLoggedIn) isLoggedIn$: Observable<boolean>;

  countries: OptionElement<string>[];
  ordersToCome: OrderHistory[];
  ordersPast: OrderHistory[];
  loadingTripToCome = true;
  loadingTripPast = true;

  constructor(
    private orderService: OrderService,
    private location: Location,
    router: Router
  ) {
    super();
    this.register(
      this.isLoggedIn$
        .pipe(filter((isLoggedIn) => !isLoggedIn))
        .subscribe(() => router.navigate(['/']))
    );

    document.title = 'Mon compte - VeryMountain';

    this.register(
      this.user$
        .pipe(switchMap((user) => this.orderService.getOrdersToCome(user?.id)))
        .subscribe((ordersByTransactionId) => {
          this.ordersToCome = ordersByTransactionId;
          this.loadingTripToCome = false;
        })
    );

    this.register(
      this.user$
        .pipe(switchMap((user) => this.orderService.getOrdersPast(user?.id)))
        .subscribe((ordersByTransactionId) => {
          this.ordersPast = ordersByTransactionId;
          this.loadingTripPast = false;
        })
    );
  }

  downloadReceipt(transactionId: string): void {
    this.register(
      this.orderService
        .getReceiptByTransactionId(transactionId)
        .subscribe((pdf: any) =>
          DownloadUtils.downloadPDF(pdf, 'receipt-' + transactionId)
        )
    );
  }
}
