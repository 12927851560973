export class MessageReclamation {
  userId: number;
  firstName: string;
  lastName: string;
  transactionId: string;
  message: string;

  constructor(obj: MessageReclamation) {
    Object.assign(this, obj);
  }
}
