import { SkiPass } from './enum/ski-pass.enum';

export class Pass {
  type: SkiPass;
  price: number;

  constructor(obj: Partial<Pass>) {
    Object.assign(this, obj);
  }
}
