import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmailSubscribe } from '../shared/models/email-subscribe';
import { MessageContact } from '../shared/models/message-contact';
import { MessageReclamation } from '../shared/models/message-reclamation';

import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService extends RepositoryService {
  private URL_ACCOUNT = 'api/webapp/contact';

  constructor(http: HttpClient) {
    super(http);
  }

  sendMessage(message: MessageContact): Observable<void> {
    return this.post(this.URL_ACCOUNT + '/send', message);
  }

  sendReclamation(reclamation: MessageReclamation): Observable<void> {
    return this.post(this.URL_ACCOUNT + '/send-reclamation', reclamation);
  }

  registerEmail(email: string, from: string): Observable<void> {
    const emailSubscribe: EmailSubscribe = {
      email,
      from
    };
    return this.post(this.URL_ACCOUNT + '/register-email', emailSubscribe);
  }
}
