export class ResortAvailability {
  accommodationAvailable: boolean;
  skiPassAvailable: boolean;
  skiEquipmentAvailable: boolean;
  skiEquipmentCustomAvailable: boolean;
  skiLessonAvailable: boolean;

  constructor(obj: Partial<ResortAvailability>) {
    Object.assign(this, obj);
  }
}
