<ng-container *ngIf="resort$ | async as resort" class="xl:min-h-[380px] xl:pb-4">
  <atc-banner #header
              (photoClicked)="pictures = banner.pictures"
              *ngIf="getBanner(resort) as banner"
              [labels]="banner.labels || []"
              [location]="banner.location"
              [logo]="banner.logo || ''"
              [nbRooms]="banner.nbRooms || 0"
              [pictures]="banner.pictures"
              [title]="banner.title"
              class="banner">
  </atc-banner>

  <div *ngIf="(isFoncia$ | async) === false"
       [ngClass]="{'sticky-custom': sticky}"
       class="submenu flex flex-row max-w-full overflow-x-auto overflow-y-hidden">

    <atc-sub-menu
      (goToPaymentClicked)="goToPayment()"
      [elements]="resortsLinks"
      [place]="resort.regionLabel"
      [resort]="resort.name"
      [sizeElement]="160"
      [sizeElementsToShow]="8"
      [totalPrice]="totalPrice$ | async">
    </atc-sub-menu>
  </div>
</ng-container>
<router-outlet></router-outlet>

<atc-popup (closeChanged)="pictures = []"
           *ngIf="pictures.length"
           [showBody]="false"
           [showCredit]="true"
           [slides]="pictures">
</atc-popup>
