<ng-container *ngIf="windowResource.width() | async | screen: 'tablet-above'">
  <atc-select (selectChange)="validateFilters()"
              *ngIf="filterByResorts"
              [checkbox]="true"
              [optionElements]="resortsElements"
              [searchable]="true"
              label="Station">
  </atc-select>

  <atc-select (selectChange)="validateFilters()"
              [checkbox]="true"
              [optionElements]="activitiesElements"
              [searchable]="true"
              label="Activité">
  </atc-select>

  <atc-select (selectChange)="validateFilters()"
              [checkbox]="true"
              [optionElements]="difficultiesElements"
              label="Difficulté">
  </atc-select>

  <div class="flex flex-row items-center ml-2">
    <!--    <atc-input-checkbox (checkChanged)="validateFilters()"-->
    <!--                        [control]="heartForm">-->
    <!--      <div class="flex flex-row gap-x-1 items-center">-->
    <!--        <label>Coup de</label>-->
    <!--        <svg class="max-h-6 w-6 my-auto stroke-red">-->
    <!--          <use [attr.xlink:href]="'assets/icons/icons.svg#heart-fill'"></use>-->
    <!--        </svg>-->
    <!--      </div>-->
    <!--    </atc-input-checkbox>-->

    <atc-input-checkbox (checkChanged)="validateFilters()"
                        [control]="promoForm">
      <div class="flex flex-row gap-x-1 items-center">
        <label>Promo</label>
        <svg class="max-h-6 w-6 my-auto stroke-green">
          <use [attr.xlink:href]="'assets/icons/icons.svg#gift'"></use>
        </svg>
      </div>
    </atc-input-checkbox>
  </div>

  <atc-button (click)="openPopup()"
              [color]="totalFiltersHidden() ? 'clicked' : 'ternary'"
              [fitContent]="true"
              [text]="totalFiltersHidden()"
              atcBadge
              class="relative ml-auto"
              icon="filters"
              label="Plus de filtres"
              positionBadge="topRight"
              size="medium">
  </atc-button>

  <atc-button (click)="clearFilters()"
              color="ternary"
              icon="trash">
  </atc-button>
</ng-container>

<div *ngIf="!(windowResource.width() | async | screen: 'tablet-above')"
     class="flex gap-x-4 w-full">
  <atc-button (click)="openPopup()"
              [color]="totalFilters() ? 'clicked' : 'ternary'"
              [fullWidth]="true"
              [text]="totalFilters()"
              atcBadge
              class="flex w-full relative"
              icon="filters"
              label="Voir les filtres"
              positionBadge="topRight"
              size="medium">
  </atc-button>

  <atc-button (click)="clearFilters()"
              color="ternary"
              icon="trash">
  </atc-button>
</div>

<vsk-experience-filters-popup
  (closeChanged)="closePopup()"
  (validateFiltersChanged)="validateFilters()"
  *ngIf="isPopupOpen"
  [activitiesElements]="activitiesElements"
  [experienceDifficulties]="difficultiesElements"
  [filterResorts]="filterByResorts"
  [filtersInfo]="_filtersInfo"
  [filters]="_filters"
  [heartForm]="heartForm"
  [resortsElements]="resortsElements">
</vsk-experience-filters-popup>
