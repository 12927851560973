<ng-container *ngIf="windowResource.width() | async | desktop">
  <vsk-information-banner *ngIf="banner$ | async as banner"
                          [banner]="banner"
                          [ngClass]="{'z-[11]': (popup$ | async) === false}">
  </vsk-information-banner>
</ng-container>
<vsk-navbar [isLandingPage]="isLandingPage"
            [isMapPage]="isMapPage"
            [isPaymentPage]="isPaymentPage"
            [ngClass]="{
              'z-10': (popup$ | async) === false,
              'bg-transparent': isLandingPage,
              'banner-margin': banner$ | async
            }">
</vsk-navbar>
<ng-container *ngIf="windowResource.width() | async | mobile">
  <vsk-information-banner *ngIf="banner$ | async as banner"
                          [banner]="banner"
                          [ngClass]="{'z-10': (popup$ | async) === false}"
                          class="min-h-[50px]">
  </vsk-information-banner>
</ng-container>
<router-outlet [ngClass]="{'banner-push': banner$ | async}"></router-outlet>
<atc-alert *ngIf="alert"
           [level]="alert.level"
           class="fixed top-0 left-0 right-0 w-full z-[101]">
  <div class="flex flex-row gap-x-4 mh-8 items-center">
    <span class="my-auto">{{ alert.message }}</span>
    <atc-loader *ngIf="alert.loader" color="ternary" size="small"></atc-loader>
    <svg *ngIf="alert.check" class="h-20 w-20 my-auto stroke-alert-success">
      <use [attr.xlink:href]="'assets/icons/icons.svg#cart'"></use>
    </svg>
  </div>
</atc-alert>
<atc-banner-environment *ngIf="!environment.production"
                        [env]="environment.name">
</atc-banner-environment>
<div *ngIf="loading$ | async" class="loader-wrapper">
  <atc-loader color="secondary"></atc-loader>
</div>
