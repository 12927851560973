import moment, { Moment } from 'moment';
import { Package } from '../package/package';
import { TimeSlot } from './time-slot';

export class Internship {
  id: number;
  parentId: number;
  dateStart: Moment;
  dateEnd: Moment;
  slotAvailable: number;
  timeSlots: TimeSlot[] = [];
  packages: Package[] = [];

  constructor(obj: Partial<Internship>) {
    Object.assign(this, obj);
    this.dateStart = moment(this.dateStart, 'YYYY-MM-DD');
    this.dateEnd = moment(this.dateEnd, 'YYYY-MM-DD');

    if (this.timeSlots) {
      this.timeSlots = this.timeSlots.map(
        (timeSlot) => new TimeSlot({ ...timeSlot })
      );
    }

    if (this.packages) {
      this.packages = this.packages.map((pack) => new Package({ ...pack }));
    }
  }
}
