import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PassSki } from '../shared/models/pass-ski';
import { User } from '../shared/models/user';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends RepositoryService {
  private URL_USER = 'api/webapp/user';

  constructor(http: HttpClient) {
    super(http);
  }

  getUser(email: string): Observable<User> {
    return this.get(this.URL_USER + '/by-email?email=' + email).pipe(
      map((user) => new User(user))
    );
  }

  updateUser(user: User, email: string): Observable<User> {
    return this.put(this.URL_USER + '/update-information?email=' + email, user);
  }

  updatePass(passSki: PassSki): Observable<User> {
    return this.put(this.URL_USER + '/update-pass', passSki);
  }
}
