import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement, WindowResource } from 'atomic-lib';
import { TriggerAlert } from 'src/app/app.action';
import { AddSkiEquipmentToCart } from 'src/app/cart/cart.action';
import { ParticipantService } from 'src/app/service/participant.service';
import { Alert } from 'src/app/shared/models/alert';
import { Participant } from 'src/app/shared/models/participant/participant';
import { ParamsSelectedEquipment } from 'src/app/shared/models/ski-equipment/equipment-selected';
import { Pack } from 'src/app/shared/models/ski-equipment/pack';

interface ParticipantInformationForm {
  firstName: FormControl<string | null>;
  weight: FormControl<number | null>;
  shoeSize: FormControl<number | null>;
  height: FormControl<number | null>;
}

@Component({
  selector: 'vsk-resort-material-drawer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './resort-material-drawer.component.html',
  styleUrls: ['./resort-material-drawer.component.scss']
})
export class ResortMaterialDrawerComponent implements OnInit, OnChanges {
  @Input() showDrawer = false;
  @Input() selectedEquipment: ParamsSelectedEquipment;
  @Input() hasAccommodation = false;
  @Input() resortId: number;

  @Output() showDrawerChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  duration: number;
  selectedPack: Pack;
  participant: Participant;
  total: number;
  formChanged = false;
  participantInformationForm: FormGroup<ParticipantInformationForm>;
  firstNameForm = new FormControl<string>('');
  weightForm = new FormControl<number | null>(null);
  shoeSizeForm = new FormControl<number | null>(null);
  heightForm = new FormControl<number | null>(null);
  shoeSizes: OptionElement<number>[] = Array.from({ length: 31 }, (_, i) => {
    return {
      id: i + 20,
      label: (i + 20).toString(),
      disabled: false
    };
  });
  heights: OptionElement<number>[] = Array.from({ length: 131 }, (_, i) => {
    return {
      id: i + 90,
      label: (i + 90).toString(),
      disabled: false
    };
  });
  weights: OptionElement<number>[] = Array.from({ length: 141 }, (_, i) => {
    return {
      id: i + 10,
      label: (i + 10).toString(),
      disabled: false
    };
  });

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    public windowResource: WindowResource,
    private participantService: ParticipantService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.participantInformationForm = new FormGroup<ParticipantInformationForm>(
      {
        firstName: this.firstNameForm,
        weight: this.weightForm,
        shoeSize: this.shoeSizeForm,
        height: this.heightForm
      }
    );

    this.participantInformationForm.valueChanges.subscribe(() => {
      this.formChanged = true;
      this.updateParticipant();
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showDrawer) {
      this.showDrawer = changes.showDrawer.currentValue;
    }

    if (changes.selectedEquipment && changes.selectedEquipment.currentValue) {
      this.firstNameForm.setValue(this.selectedEquipment.participant.firstname);
      this.weightForm.setValue(null);
      this.shoeSizeForm.setValue(null);
      this.heightForm.setValue(null);
      this.duration = this.selectedEquipment.duration;
      this.selectedPack = this.selectedEquipment.selectedPack;
      this.participant = this.selectedEquipment.participant;
      this.calculateTotalPrice();
      this.changeDetectorRef.markForCheck();
    }
  }

  closeDrawer() {
    this.showDrawer = !this.showDrawer;
    this.showDrawerChange.emit(this.showDrawer);
  }

  calculateTotalPrice(): void {
    let total = this.selectedPack.netPrice;

    if (this.selectedEquipment.hasShoes) {
      total += this.selectedPack.priceShoes;
    }

    if (this.selectedEquipment.hasHelmet) {
      total += this.selectedPack.priceHelmet
        ? this.selectedPack.priceHelmet
        : 0;
    }

    this.total = total;
  }

  saveEquipmentCart() {
    if (this.formChanged) {
      this.participantService.upsert(this.participant).subscribe(() => {
        this.formChanged = false;
        this.changeDetectorRef.detectChanges();
      });
    }
    this.store.dispatch(
      new AddSkiEquipmentToCart({
        participantSession: this.participant,
        skisetResortId: this.selectedEquipment.selectedPack.skiSetResortId,
        skisetPackId: this.selectedEquipment.packIds[0],
        linkedPackId: this.selectedEquipment.packIds[1],
        resortId: this.resortId,
        firstDay: this.selectedEquipment.startDate,
        duration: this.selectedEquipment.duration,
        isSelected: true
      })
    );

    this.store.dispatch(
      new TriggerAlert(
        new Alert({
          message: `Votre pack ${this.selectedPack.offer.category} a bien été ajouté au panier`,
          timeout: 2000,
          level: 'success'
        })
      )
    );

    this.closeDrawer();
  }

  isDisabled() {
    return !this.hasAccommodation;
  }

  private updateParticipant() {
    if (!this.participant) {
      return;
    }

    this.participant.firstname = this.firstNameForm?.value || '';
    this.participant.weight = this.weightForm?.value || null;
    this.participant.shoeSize = this.shoeSizeForm?.value || null;
    this.participant.height = this.heightForm?.value || null;
  }
}
