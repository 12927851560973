import { Directive, HostBinding, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[vskSkeletonLoad]'
})
export class SkeletonLoadDirective implements OnChanges {
  @HostBinding('class') class = 'vsk__loading-skeleton';
  @Input() loading = true;

  @Input() set classCss(classes: string) {
    this.class = this.class + ' ' + classes;
  }

  ngOnChanges(): void {
    if (!this.loading) {
      this.class = '';
    }
  }
}
