import { Moment } from 'moment';

export class ArrayUtils {
  public static concatArrayMoment(
    array1: Moment[],
    array2: Moment[]
  ): Moment[] {
    const finalArray = array1;

    array2.forEach((value) => {
      if (!finalArray.filter((date) => date.diff(value, 'days') === 0).length) {
        finalArray.push(value);
      }
    });
    return finalArray;
  }

  public static areEquals<T>(array1: T[], array2: T[]) {
    return array1.sort().join(',') === array2.sort().join(',');
  }

  public static groupBy<T>(array: T[], key: string): T[] {
    return array.reduce((value, x: any) => {
      (value[x[key] as T] = value[x[key]] || []).push(x);
      return value;
    }, {} as any);
  }
}
