import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { WindowResource } from 'atomic-lib';
import { map } from 'rxjs/operators';
import { SetFiltersExperiences } from '../filters.action';
import { UrlManagerResource } from '../resource/url-manager.resource';
import { FiltersService } from '../service/filters.service';
import { MetaDescriptionService } from '../service/meta-description.service';
import { StationService } from '../service/station.service';
import { ExperienceTemplateComponent } from '../shared/component/experience-template.component';
import { FiltersExperienceSearch } from '../shared/models/activity/filters-experience-search';
import { ResortMin } from '../shared/models/resort/resort-min';
import { ToHourPipe } from '../shared/pipe/to-hour.pipe';

@Component({
  selector: 'vsk-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent extends ExperienceTemplateComponent {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

  resorts: ResortMin[];

  constructor(
    public windowResource: WindowResource,
    protected store: Store,
    protected hourPipe: ToHourPipe,
    protected translate: TranslateService,
    protected changeDetectionRef: ChangeDetectorRef,
    protected filtersService: FiltersService,
    protected activatedRoute: ActivatedRoute,
    protected resortService: StationService,
    protected urlManager: UrlManagerResource,
    private metaDescriptionService: MetaDescriptionService,
    public router: Router
  ) {
    super(
      windowResource,
      store,
      hourPipe,
      translate,
      changeDetectionRef,
      filtersService,
      activatedRoute,
      urlManager,
      router
    );
    this.markerResort = undefined;

    const title = `Activités et expériences à la montagne · VeryMountain`;
    const description = `Découvrez les meilleures activités et expériences à faire en montagne avec VeryMountain : activités à sensations, familiales ou instant bien-être en amoureux`;
    const url = 'recherche-activites';
    this.metaDescriptionService.updateMeta(title, description, url);

    this.resortService
      .getAllResorts()
      .pipe(
        map((resorts) =>
          resorts.sort((prev, curr) => (prev.name < curr.name ? -1 : 1))
        )
      )
      .subscribe((resorts) => (this.resorts = resorts));
  }

  clearFilters(filters: FiltersExperienceSearch) {
    const newFilters = new FiltersExperienceSearch({
      ...filters,
      priceRange: {
        min: 0,
        max: 1000
      },
      durationRange: {
        min: 0,
        max: 1000
      },
      difficulties: [],
      activities: [],
      heart: false,
      promo: false,
      resorts: []
    });

    this.center = {
      lat: 45.052825927734375,
      lng: 2.7113842964172363
    };

    setTimeout(() => {
      this.boundsChanged();
      this.store.dispatch(new SetFiltersExperiences(newFilters));
    }, 300);
  }
}
