import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExperienceGroup } from '../shared/models/activity/experience-group';
import { ActivityFilters } from '../shared/models/const/activity-filters';
import { Criteria } from '../shared/models/criteria';
import { ExperienceCategoryEnum } from '../shared/models/enum/experience-category.enum';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ExperienceGroupService extends RepositoryService {
  private URL_EXPERIENCE_GROUP = 'api/webapp/experience-group';

  getByCriteria(
    criteria: Criteria,
    resortFilters: ActivityFilters,
    type: ExperienceCategoryEnum,
    page: number
  ): Observable<ExperienceGroup[]> {
    return this.post(
      this.URL_EXPERIENCE_GROUP +
        '/by-criteria?resortFilters=' +
        resortFilters +
        `&category=${type}&page=${page}&size=20`,
      criteria
    ).pipe(
      map((experiencesGroups) =>
        experiencesGroups.map(
          (experienceGroup: ExperienceGroup) =>
            new ExperienceGroup({ ...experienceGroup })
        )
      )
    );
  }
}
