export class MarkerWrapper<T> extends google.maps.Marker {
  value: T;
  canHover = true;
  redirect: {
    link: string;
    params: any;
  };

  constructor(
    value: T,
    opts?: google.maps.MarkerOptions,
    canHover = true,
    redirect?: {
      link: string;
      params: any;
    }
  ) {
    super(opts);
    this.value = value;
    this.canHover = canHover;

    if (redirect) {
      this.redirect = redirect;
    }
  }
}
