import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Participant } from '../shared/models/participant/participant';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ParticipantService extends RepositoryService {
  private URL_ARTICLE = 'api/webapp/participant';

  constructor(http: HttpClient) {
    super(http);
  }

  upsert(participant: Participant): Observable<void> {
    return this.post(this.URL_ARTICLE + '/upsert', participant);
  }

  upsertAll(participants: Participant[]): Observable<void> {
    return this.post(this.URL_ARTICLE + '/upsert-all', participants);
  }

  getParticipants(sessionId: string): Observable<Participant[]> {
    return this.get(
      this.URL_ARTICLE +
        '/fetch-participants-by-session-id?sessionId=' +
        sessionId
    );
  }

  deleteParticipant(participant: Participant): Observable<void> {
    return this.delete(
      this.URL_ARTICLE +
        '/delete-by-uuid?uuid=' +
        participant.uuid +
        '&sessionId=' +
        participant.sessionId
    );
  }
}
