import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OptionElement } from 'atomic-lib';
import { AccommodationType } from '../../models/accommodation/accommodation-type.enum';
import { FiltersAccommodationSearch } from '../../models/filters/filters-accommodation-search';
import { FiltersAccommodationManagerComponent } from '../filters-accommodation-manager.component';

@Component({
  selector: 'vsk-filters-accommodation-popup',
  templateUrl: './filters-accommodation-popup.component.html',
  styleUrls: ['./filters-accommodation-popup.component.scss']
})
export class FiltersAccommodationPopupComponent extends FiltersAccommodationManagerComponent {
  @Input() resortsElements: OptionElement<number>[] = [];
  @Input() regionsElements: OptionElement<number>[] = [];
  @Input() accommodationTypesElements: OptionElement<AccommodationType>[] = [];
  @Input() equipmentsFilter: OptionElement<number>[] = [];
  @Input() accessFilter: OptionElement<number>[] = [];
  @Input() servicesFilter: OptionElement<number>[] = [];

  @Output() closeChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() applyFilters: EventEmitter<FiltersAccommodationSearch> =
    new EventEmitter<FiltersAccommodationSearch>();

  getControl(control: FormControl<boolean | null> | undefined) {
    return control as FormControl<boolean | null>;
  }
}
