import { Pipe, PipeTransform } from '@angular/core';
import { Participant } from '../models/participant/participant';

@Pipe({
  name: 'sortParticipants'
})
export class SortParticipantsPipe implements PipeTransform {
  transform(participants: Participant[]): Participant[] {
    return participants.sort((p1, p2) => (p1.index < p2.index ? -1 : 1));
  }
}
