import { uniqBy } from 'lodash-es';
import { PackageUtils } from '../../../utils/package-utils';
import { AgeRange } from '../age-range';
import { ExperienceCategoryEnum } from '../enum/experience-category.enum';
import { Origin } from '../enum/origin.enum';
import { SkiPass } from '../enum/ski-pass.enum';
import { Package } from '../package/package';
import { PackageGroup } from '../package/package-group';
import { Internship } from '../partner/internship';
import { TimeSlotExperience } from '../partner/time-slot-experience';
import { Period } from '../period';
import { Tag } from '../tag';
import { Activity } from './activity';

export class Experience {
  id: number;
  name: string;
  partnerName: string;
  address: string;
  packagesGroup: PackageGroup[];
  ageRanges: AgeRange[];
  activity: Activity;
  difficulty: string;
  duration: number;
  timeSlots: TimeSlotExperience[];
  internships: Internship[];
  description: string;
  weLove: string;
  included: string;
  notIncluded: string;
  predictable: string;
  videoUrl: string;
  pictures: string[];
  withAccommodation: boolean;
  heart: boolean;
  experienceCategory: ExperienceCategoryEnum;
  skiPassType?: SkiPass;
  origin: Origin;
  resorts: string[];
  review: number;
  latitude: number;
  longitude: number;
  nbReviews: number;
  tags: Tag[];

  constructor(obj: Partial<Experience>) {
    Object.assign(this, obj);

    if (this.packagesGroup) {
      this.packagesGroup = this.packagesGroup.map(
        (group) => new PackageGroup({ ...group })
      );
    }

    if (this.ageRanges) {
      this.ageRanges = this.ageRanges.map(
        (ageRange) => new AgeRange({ ...ageRange })
      );
    }

    if (this.timeSlots) {
      this.timeSlots = this.timeSlots.map(
        (timeSlot) => new TimeSlotExperience({ ...timeSlot })
      );
    }

    if (this.internships) {
      this.internships = this.internships.map(
        (internship) => new Internship({ ...internship })
      );
    }

    this.activity = new Activity(this.activity);
  }

  public get hasInternship() {
    return this.internships?.length;
  }

  public get experience() {
    return this.experienceCategory === ExperienceCategoryEnum.GENERAL;
  }

  public get ski() {
    return this.experienceCategory === ExperienceCategoryEnum.SKIPASS;
  }

  public get equipment() {
    return this.experienceCategory === ExperienceCategoryEnum.EQUIPMENT;
  }

  public get packages(): Package[] {
    if (this.internships?.length) {
      return uniqBy(
        this.internships
          .map((internship) => internship.packages)
          .reduce((prev, curr) => [...prev, ...curr]) || [],
        'id'
      );
    }

    if (!this.timeSlots?.length) {
      return [];
    }

    return uniqBy(
      this.timeSlots
        .map((timeSlot) => timeSlot.packages)
        .reduce((prev, curr) => [...prev, ...curr]) || [],
      'id'
    );
  }

  public packagesByPeriod(period: Period): Package[] {
    return PackageUtils.filterAvailablePackagesByDate(this.packages, period);
  }
}
