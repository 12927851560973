<atc-popup (closeChanged)="closeChanged.emit()"
           [withImg]="false"
           title="Filtres">
  <div body class="flex flex-col gap-y-4 p-4">
    <h1 class="text-center mb-4">Filtres</h1>
    <div *ngIf="filterByResorts" class="flex flex-col gap-y-4">
      <hr>
      <h2>Station</h2>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of resortsElements"
                            [control]="getControl(element.control)">
          <p>{{ element.label }}</p> <span *ngIf="element.quantity">{{ element.quantity }}</span>
        </atc-input-checkbox>
      </div>
    </div>

    <div *ngIf="filterByRegions" class="flex flex-col gap-y-4">
      <hr>
      <h2>Massif</h2>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of regionsElements"
                            [control]="getControl(element.control)">
          <p>{{ element.label }}</p> <span *ngIf="element.quantity">{{ element.quantity }}</span>
        </atc-input-checkbox>
      </div>
    </div>

    <div class="flex flex-col gap-y-4">
      <hr>
      <h2>Type d'hébergement</h2>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of accommodationTypesElements"
                            [control]="getControl(element.control)">
          <p>{{ element.label }}</p> <span *ngIf="element.quantity">{{ element.quantity }}</span>
        </atc-input-checkbox>
      </div>
    </div>

    <div class="flex flex-col gap-y-4">
      <hr>
      <h2>Équipements</h2>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of equipmentsFilter"
                            [control]="getControl(element.control)">
          <p>{{ element.label }}</p> <span *ngIf="element.quantity">{{ element.quantity }}</span>
        </atc-input-checkbox>
      </div>
    </div>

    <div class="flex flex-col gap-y-4">
      <hr>
      <h2>Service</h2>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of servicesFilter"
                            [control]="getControl(element.control)">
          <p>{{ element.label }}</p> <span *ngIf="element.quantity">{{ element.quantity }}</span>
        </atc-input-checkbox>
      </div>
    </div>

    <div class="flex flex-col gap-y-4">
      <hr>
      <h2>Prix</h2>
      <atc-range-input (valueChange)="selectChangePrices($event)"
                       [displayedMaxValue]="currentFilters.priceRange?.max || 20000"
                       [displayedMinValue]="currentFilters.priceRange?.min || 0"
                       [maxValue]="maxPriceDisplay"
                       [minValue]="minPriceDisplay"
                       [typeOfValues]="'price'">
      </atc-range-input>
    </div>

    <div class="flex flex-col gap-y-4">
      <hr>
      <h2>Accessibilité</h2>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of accessFilter"
                            [control]="getControl(element.control)">
          <p>{{ element.label }}</p> <span *ngIf="element.quantity">{{ element.quantity }}</span>
        </atc-input-checkbox>
      </div>
    </div>

    <div class="flex flex-col gap-y-4">
      <hr>
      <h2>Promo</h2>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox (checkChanged)="selectChange()"
                            [control]="promoForm">
          <div class="flex flex-row gap-x-1 items-center">
            <svg class="max-h-6 w-6 my-auto stroke-green">
              <use [attr.xlink:href]="'assets/icons/icons.svg#gift'"></use>
            </svg>
          </div>
        </atc-input-checkbox>
      </div>
    </div>
  </div>

  <div class="text-center justify-center m-4 flex gap-[2rem]" footer>
    <atc-button (click)="clearFilters()"
                [fitContent]="true"
                color="ternary"
                icon="trash"
                label="Effacer les filtres"
                size="medium">
    </atc-button>

    <atc-button (click)="applyFilters.emit(currentFilters)"
                [fitContent]="true"
                color="secondary"
                icon="checkmark"
                label="Appliquer"
                size="medium">
    </atc-button>
  </div>
</atc-popup>
