import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DisconnectUser } from '../../app.action';
import { AppState } from '../../app.state';
import { CartState } from '../../cart/cart.state';
import { SetChangingFilters } from '../../filters.action';
import { NavigateService } from '../../service/navigate.service';

@Component({
  selector: 'vsk-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  @Select(AppState.isUserLoggedIn) isLoggedIn$: Observable<boolean>;
  @Select(CartState.quantityItems) quantityItems$: Observable<number>;

  openLoginPopup = false;

  @Input() showMenu = false;
  @Output() showMenuChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private store: Store,
    private filtersService: NavigateService
  ) {}

  goToSearch() {
    this.closeMenu();
    this.store.dispatch(new SetChangingFilters(true));
  }

  closeMenu() {
    this.showMenu = !this.showMenu;
    this.showMenuChange.emit(this.showMenu);
  }

  goToLogin(): void {
    this.closeMenu();
    this.openLoginPopup = true;
  }

  goTo(url: string) {
    this.closeMenu();
    this.filtersService.navigateWithQueryParams(url, {}, true);
  }

  logout() {
    this.store.dispatch(new DisconnectUser());
  }
}
