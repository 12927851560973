import { NgModule } from '@angular/core';
import { FooterModule } from '../../footer/footer.module';
import { SharedModule } from '../../shared/shared.module';
import { NgxTranslateModule } from '../../store/translate.module';
import { SellingConditionsRoutingModule } from './selling-conditions-routing.module';
import { SellingConditionsComponent } from './selling-conditions.component';

@NgModule({
  declarations: [SellingConditionsComponent],
  imports: [
    NgxTranslateModule,
    SharedModule,
    FooterModule,
    SellingConditionsRoutingModule
  ],
  exports: [SellingConditionsComponent]
})
export class SellingConditionsModule {}
