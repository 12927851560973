import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Account } from '../shared/models/account';
import { Login } from '../shared/models/login';
import { TokenRefresh } from '../shared/models/token-refresh';
import { UserSession } from '../shared/models/user-session';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends RepositoryService {
  private URL_ACCOUNT = 'api/account';

  constructor(http: HttpClient) {
    super(http);
  }

  createAccount(account: Account): Observable<void> {
    return this.post(this.URL_ACCOUNT + '/signup', {
      ...account,
      email: account.email.toLowerCase()
    });
  }

  isEmailExisting(email: string): Observable<string> {
    return this.get(
      this.URL_ACCOUNT + '/exist?email=' + email.toLowerCase(),
      {},
      'text'
    );
  }

  connectUser(authentication: Login): Observable<UserSession> {
    return this.post(this.URL_ACCOUNT + '/sign-in', {
      ...authentication,
      email: authentication.email?.toLowerCase()
    });
  }

  refreshSession(refreshToken: string): Observable<TokenRefresh> {
    return this.post(this.URL_ACCOUNT + '/refresh-token', { refreshToken });
  }

  register(token: string): Observable<void> {
    return this.get(
      this.URL_ACCOUNT + '/confirmation-registration?token=' + token
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    return this.put(this.URL_ACCOUNT + '/forgot-password', email);
  }

  resetPassword(token: string, authentication: Login): Observable<string> {
    return this.post(
      this.URL_ACCOUNT + '/reset-password?token=' + token,
      authentication,
      'text'
    );
  }

  deleteAccount(email: string): Observable<boolean> {
    return this.delete(this.URL_ACCOUNT + '/delete?email=' + email);
  }

  isConnected(): Observable<boolean> {
    return this.get(this.URL_ACCOUNT + '/is-connected');
  }
}
