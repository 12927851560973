import moment, { Moment } from 'moment';
import { Activity } from '../activity/activity';
import { Participant } from '../participant/participant';

export class Session {
  id: string;
  startDate: Moment;
  endDate: Moment;
  participants: Participant[] = [];
  activities: Activity[] = [];
  activitiesSelected: Activity[] = [];

  constructor(obj: Partial<Session>) {
    Object.assign(this, obj);

    if (this.startDate) {
      this.startDate = moment(this.startDate);
    }

    if (this.endDate) {
      this.endDate = moment(this.endDate);
    }

    if (this.participants) {
      this.participants = this.participants.map(
        (participant) => new Participant({ ...participant })
      );
    }

    if (this.activities) {
      this.activities = this.activities.map(
        (activity) => new Activity({ ...activity })
      );
    }

    if (this.activitiesSelected) {
      this.activitiesSelected = this.activitiesSelected.map(
        (activity) => new Activity({ ...activity })
      );
    } else {
      this.activitiesSelected = [];
    }
  }

  get periodValid() {
    return (
      this.startDate &&
      this.endDate &&
      this.startDate?.isValid() &&
      this.endDate?.isValid()
    );
  }
}
