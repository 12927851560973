import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import moment from 'moment';
import { Observable, combineLatest } from 'rxjs';
import { AppState } from '../app.state';
import { FiltersState } from '../filters.state';
import { WindowResource } from '../resource/window.resource';
import { ExperienceService } from '../service/experience.service';
import { NavigateService } from '../service/navigate.service';
import { StationService } from '../service/station.service';

import { TriggerAlert } from '../app.action';
import { TemporaryExperienceCart } from '../resort/resort.action';
import { SessionService } from '../service/session.service';
import { Experience } from '../shared/models/activity/experience';
import { Alert } from '../shared/models/alert';
import { Cart } from '../shared/models/cart/cart';
import { MarkerWrapper } from '../shared/models/marker-wrapper';
import { Item } from '../shared/models/package/item';
import { ItemCart } from '../shared/models/package/item-cart';
import { Participant } from '../shared/models/participant/participant';
import { Period } from '../shared/models/period';
import { Resort } from '../shared/models/resort/resort';
import { PricingUtils } from '../utils/pricing-utils';
import {
  EstablishmentRoom,
  ExperienceLink
} from './cart-content/cart-content.component';
import {
  ChangeCartDrawerState,
  CreateCartMaster,
  RefuseCartMaster,
  RemoveItemsToCart
} from './cart.action';
import { CartState } from './cart.state';

@Component({
  selector: 'vsk-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent {
  @Select(AppState.isUserAdmin) isUserAdmin$: Observable<boolean>;
  @Select(CartState.cart) cart$: Observable<Cart>;
  @Select(CartState.markers) markers$: Observable<MarkerWrapper<Item>[]>;
  @Select(CartState.isEcoTourismChartChecked)
  isEcoTourismeChecked$: Observable<boolean>;
  @Select(CartState.propositionCart) propositionCart$: Observable<Cart>;
  @Select(FiltersState.period) period$: Observable<Period>;
  @Select(FiltersState.participants) participants$: Observable<Participant[]>;
  @Select(FiltersState.sessionId) sessionId$: Observable<string>;

  @Input() showCart = false;
  @Input() canDeleteItem = true;
  @Input() canEditItem = true;
  @Input() canAddPromoCode = true;
  @Output() showCartChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  loading = false;
  showMap = false;
  experienceToEdit?: Experience;
  resortExperienceToEdit?: Resort;
  itemToEdit?: ItemCart;
  showExperienceDrawer = false;
  currentDay = moment();

  constructor(
    private filtersService: NavigateService,
    public windowResource: WindowResource,
    private decimalPipe: DecimalPipe,
    private store: Store,
    private experienceService: ExperienceService,
    private resortService: StationService,
    private sessionService: SessionService
  ) {}

  formatNumber(nb: number) {
    return this.decimalPipe.transform(nb, '1.2-2');
  }

  getLabelWithPrice(cart: Cart, label = 'Réserver mon séjour'): string {
    if (cart.hasPromoCode) {
      const reduction = PricingUtils.reductionPercentage(
        cart.getPublicPrice(),
        cart.getPriceWithPromo()
      );

      return (
        `${label} (` +
        this.formatNumber(cart.getPriceWithPromo()) +
        ' €) ' +
        (reduction !== 0 ? `(-${reduction} %)` : '')
      );
    }

    if (cart.getPublicPrice() !== cart.getPrice()) {
      const reduction = PricingUtils.reductionPercentage(
        cart.getPublicPrice(),
        cart.getPrice()
      );

      return (
        `${label} (` +
        this.formatNumber(cart.getPrice()) +
        ' €) ' +
        (reduction !== 0 ? `(-${reduction} %)` : '')
      );
    }

    return `${label} (` + this.formatNumber(cart.getPrice()) + ' €)';
  }

  goToPayment(): void {
    this.closeCart();
    this.filtersService.navigateWithQueryParams('/payment');
  }

  closeCart(cart?: Cart) {
    if (cart?.isProposition) {
      this.store.dispatch(new RefuseCartMaster());
    }

    this.showCart = !this.showCart;
    this.showCartChange.emit(this.showCart);
    this.store.dispatch(new ChangeCartDrawerState(this.showCart));
  }

  redirectToEstablishment(
    establishmentRoom: EstablishmentRoom,
    isProposition = false
  ) {
    if (!isProposition) {
      this.filtersService.navigateWithQueryParams('/resort/room', {
        establishmentId: establishmentRoom.establishment.id,
        roomCode: establishmentRoom.room.codeRoom,
        partnerCode: establishmentRoom.establishment.partnerCode,
        station: establishmentRoom.resort
      });
      this.closeCart();
    }
  }

  redirectToExperience(ExperienceLink: ExperienceLink, isProposition = false) {
    if (!isProposition) {
      this.filtersService.navigateWithQueryParams('/resort/details-activity', {
        experienceId: ExperienceLink.experienceId,
        station: ExperienceLink.resort
      });
      this.closeCart();
    }
  }

  goToMap() {
    this.filtersService.navigateWithQueryParams('/map');
    this.closeCart();
  }

  getCart(cart: Cart | null, propositionCart: Cart | null) {
    if (propositionCart) {
      return propositionCart;
    }

    return cart;
  }

  addToCart(cart: Cart) {
    const copy = cart.filterNotSelected(cart.sessionId);
    return this.store.dispatch(new CreateCartMaster(copy));
  }

  addToCartAndCloseCart(cart: Cart) {
    this.loading = true;

    this.addToCart(cart).subscribe(() => {
      this.showCart = false;
      this.loading = false;
      this.showCartChange.emit(this.showCart);
      this.store.dispatch(new ChangeCartDrawerState(this.showCart));
    });
  }

  addToCartAndGoToPayment(cart: Cart) {
    this.loading = true;

    this.addToCart(cart).subscribe(() => {
      this.filtersService.navigateWithQueryParams('/payment').then(() => {
        this.showCart = false;
        this.loading = false;
        this.showCartChange.emit(this.showCart);
        this.store.dispatch(new ChangeCartDrawerState(this.showCart));
      });
    });
  }

  editExperience(item: ItemCart) {
    combineLatest([
      this.resortService.getStationByName(
        item.resort,
        this.store.selectSnapshot(FiltersState.sessionId)
      ),
      this.experienceService.getExperienceById(
        this.store.selectSnapshot(FiltersState.criteria),
        item.item?.experience.id as number
      ),
      this.store.dispatch(
        new TemporaryExperienceCart({
          idExperience: item.item?.experience.id as number,
          participants: item.participants,
          selectedDate: item.startDate,
          isEditMode: true
        })
      )
    ]).subscribe(([resort, experience]) => {
      this.resortExperienceToEdit = resort;
      this.itemToEdit = item;
      this.experienceToEdit = experience;
      this.showExperienceDrawer = true;
    });
  }

  deleteItemEdited() {
    if (this.itemToEdit) {
      this.store
        .dispatch(new RemoveItemsToCart([this.itemToEdit.uuid]))
        .subscribe(() => {
          this.itemToEdit = undefined;
          this.experienceToEdit = undefined;
          this.resortExperienceToEdit = undefined;
        });
    }
  }

  createSessionMaster(session: string, resort: string) {
    this.sessionService.shareCart(session, resort).subscribe(() => {
      navigator.clipboard
        .writeText(window.location.href + `&sessionMaster=${session}`)
        .then(() =>
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                level: 'success',
                message: 'URL de partage de panier copiée !',
                timeout: 5000
              })
            )
          )
        );
    });
  }
}
