import { DifficultyExperience } from '../enum/difficulty-experience.enum';
import { FilterCount } from './filter-count';

export class FiltersInfoExperience {
  minPrice: number;
  maxPrice: number;
  minDuration: number;
  maxDuration: number;
  activities: FilterCount<number>[];
  difficulties: FilterCount<DifficultyExperience>[];
  resorts: FilterCount<number>[];
  total: number;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}
