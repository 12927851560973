<div class="vsk__card">
  <img alt="logo" src="assets/images/monogramme.png">
  <h1>Réinitialisation de mot de passe</h1>

  <div class="vsk__form">
    <!-- Email -->
    <atc-input (enterKeyPressed)="validate()"
               [control]="emailForm"
               label="Email"
               type="email"></atc-input>

    <!-- First password -->
    <atc-input (enterKeyPressed)="validate()"
               [control]="passwordFirstForm"
               [focus]="true"
               label="Mot de passe"
               placeholder="*****************"
               type="password"></atc-input>

    <!-- Confirmation password -->
    <atc-input (enterKeyPressed)="validate()"
               [control]="passwordSecondForm"
               label="Confirmation du mot de passe"
               placeholder="*****************"
               type="password"></atc-input>

    <p  *ngIf="error" class="vsk__error-text text-lg">{{error}}</p>

    <!-- Validate -->
    <atc-button (click)="validate()"
                class="vsk__spacer"
                label="Confirmer"></atc-button>
  </div>
</div>
