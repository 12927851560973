import { Moment } from 'moment';

export class Period {
  startDate: Moment;
  endDate: Moment;

  constructor(obj: Partial<Period>) {
    Object.assign(this, obj);
  }

  get isValid() {
    return (
      !!this.startDate &&
      !!this.endDate &&
      this.startDate.isValid() &&
      this.endDate.isValid()
    );
  }
}
