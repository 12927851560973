export class CookiesType {
  googleAnalytics = true;
  googleAds = true;
  pixelFacebook = true;
  hotjar = true;

  constructor(obj: Partial<CookiesType>) {
    Object.assign(obj, this);
  }
}
