import { Participant } from '../participant/participant';

export class SessionCreate {
  id: string;
  startDate?: string;
  endDate?: string;
  participants?: Participant[] = [];

  constructor(obj: Partial<SessionCreate>) {
    Object.assign(this, obj);

    if (this.participants) {
      this.participants = this.participants.map(
        (participant) => new Participant({ ...participant })
      );
    }
  }
}
