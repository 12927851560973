import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { AppState } from '../app.state';
import { CartState } from '../cart/cart.state';
import { FiltersState } from '../filters.state';
import { NavbarState } from '../navbar/navbar.state';
import { AccommodationState } from '../resort/resort-accommodation/accommodation.state';
import { ResortState } from '../resort/resort.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    NgxsModule.forRoot([
      AppState,
      AccommodationState,
      FiltersState,
      CartState,
      ResortState,
      NavbarState
    ])
  ],
  exports: [NgxsModule]
})
export class NgxsStoreModule {}
