import { ResortLabel } from './resort-label';

export class ResortMin {
  id: number;
  name: string;
  regionLabel: string;
  description: string;
  urlCover: string;
  urlLogo: string;
  labels: ResortLabel[];

  constructor(obj: Partial<ResortMin>) {
    Object.assign(this, obj);

    if (this.labels) {
      this.labels = this.labels?.map((label) => new ResortLabel({ ...label }));
    }
  }
}
