<div class="vsk__card">
  <h1>Confirmation création de compte</h1>
  <p>Votre compte est maintenant actif, vous pouvez désormais vous connecter et commander votre séjour.</p>
  <div class="flex flex-row w-full gap-x-4 mt-4 justify-center">
    <atc-button (click)="openLoginPopup = true"
                *ngIf="(loggedIn$ | async) === false"
                [fullWidth]="true"
                class="md:flex md:flex-1 sm:flex sm:flex-1 md:mx-auto"
                color="ternary"
                label="Se connecter">
    </atc-button>

    <atc-button (click)="redirect()"
                *ngIf="(loggedIn$ | async) === true"
                [fullWidth]="true"
                class="md:flex md:flex-1 sm:flex sm:flex-1"
                color="ternary"
                label="Continuer ma navigation">
    </atc-button>

    <atc-button (click)="goToPayment()"
                *ngIf="(isCartEmpty$ | async) === false"
                [fullWidth]="true"
                class="md:flex md:flex-1 sm:flex sm:flex-1"
                color="secondary"
                label="Finaliser votre achat">
    </atc-button>
  </div>
</div>

<vsk-login (closeChanged)="openLoginPopup = false"
           *ngIf="openLoginPopup">
</vsk-login>
