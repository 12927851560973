import { FormControl } from '@angular/forms';
import moment, { Moment } from 'moment';
import { Origin } from '../enum/origin.enum';
import { Item } from '../package/item';
import { Participant } from '../participant/participant';
import { Session } from '../session/session';
import { AccommodationPartner } from './accommodation-partner';
import { Establishment } from './establishment';
import { Room } from './room';

export class ItemAccommodation extends Item {
  id: string;
  session: Session;
  establishment: Establishment;
  room: Room;
  partner: AccommodationPartner;
  price: number;
  publicPrice: number;
  baseProductLabel: string;
  baseProductCode: string;
  origin: Origin;
  payed: boolean;
  history: boolean;
  remarks: string;
  startDate: Moment;
  endDate: Moment;
  resort: string;
  participants: Partial<Participant>[] = [];
  selectForm = new FormControl<boolean>(true);

  constructor(obj: Partial<ItemAccommodation>) {
    super(obj);
    Object.assign(this, obj);

    if (this.session) {
      this.session = new Session({ ...this.session });
    }

    if (this.establishment) {
      this.establishment = new Establishment({ ...this.establishment });
    }

    if (this.room) {
      this.room = new Room({ ...this.room });
    }

    if (this.partner) {
      this.partner = new AccommodationPartner({ ...this.partner });
    }

    if (this.participants) {
      this.participants = this.participants.map(
        (participant) => new Participant({ ...participant })
      );
    }

    if (this.startDate) {
      this.startDate = moment(this.startDate, 'YYYY-MM-DD');
    }

    if (this.endDate) {
      this.endDate = moment(this.endDate, 'YYYY-MM-DD');
    }
  }

  public get isLocation() {
    return this.baseProductCode === 'LOC' || this.origin === 'MAEVA';
  }
}
