import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { OptionElement } from 'atomic-lib';
import moment from 'moment';
import { WindowResource } from '../../resource/window.resource';
import { RxjsComponent } from '../component/rxjs.component';
import { Participant } from '../models/participant/participant';

export enum AgeRange {
  CHILD,
  ADULT,
  SENIOR
}

export interface ParticipantForm {
  name: FormControl<string | null>;
  ageRange: FormControl<AgeRange | null>;
  age: FormControl<number | null>;
}

@Component({
  selector: 'vsk-input-participant',
  templateUrl: './input-participant.component.html',
  styleUrls: ['./input-participant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputParticipantComponent extends RxjsComponent implements OnInit {
  redirectLink = '/';
  ageRangeItems: OptionElement<AgeRange>[] = [
    {
      id: AgeRange.CHILD,
      label: '0-17 ans'
    },
    {
      id: AgeRange.ADULT,
      label: '18-64 ans'
    },
    {
      id: AgeRange.SENIOR,
      label: '65 ans et +'
    }
  ];
  ageItems: OptionElement<number>[] = [
    {
      id: 0,
      label: '0 an'
    },
    {
      id: 1,
      label: '1 an'
    }
  ];
  nameControl: FormControl<string | null> = new FormControl<string | null>(
    '',
    Validators.required
  );
  ageRangeControl: FormControl<AgeRange | null> =
    new FormControl<AgeRange | null>(
      this.ageRangeItems[1].id,
      Validators.required
    );
  ageControl: FormControl<number | null> = new FormControl<number | null>(
    this.ageItems[0].id,
    Validators.required
  );
  participantForm: FormGroup<ParticipantForm>;

  @Input() header: boolean;
  @Input() participant: Participant;
  @Input() index = 1;
  @Input() error = false;

  constructor(
    private route: ActivatedRoute,
    public windowResource: WindowResource
  ) {
    super();
    this.participantForm = new FormGroup<ParticipantForm>({
      name: this.nameControl,
      ageRange: this.ageRangeControl,
      age: this.ageControl
    });

    for (let i = 2; i < 18; i++) {
      this.ageItems.push({
        id: i,
        label: i + ' ans'
      });
    }
    this.ageControl.patchValue(this.ageItems[10].id);

    this.route.queryParams.subscribe(
      (value) => (this.redirectLink = value.previous)
    );
  }

  ngOnInit(): void {
    if (this.participant.birthdate) {
      const age = moment().diff(this.participant.birthdate, 'year');
      if (age < 18) {
        this.ageRangeControl.patchValue(this.ageRangeItems[0].id);
        this.ageControl.patchValue(
          this.ageItems.find((item) => item.id === age)?.id as number
        );
      } else if (age > 64) {
        this.ageRangeControl.patchValue(this.ageRangeItems[2].id);
      } else {
        this.ageRangeControl.patchValue(this.ageRangeItems[1].id);
      }
    }

    if (this.participant.firstname) {
      this.nameControl.patchValue(this.participant.firstname);
    }

    this.ageControl.valueChanges.subscribe(() => this.validate());
    this.ageRangeControl.valueChanges.subscribe(() => this.validate());
  }

  validate(): void {
    if (this.nameControl.invalid) {
      this.nameControl.setValue('Voyageur ' + this.index);
    }

    if (this.participantForm.valid) {
      this.participant.birthdate = this.calculateBirthdate();
      this.participant.firstname = this.nameControl.valid
        ? (this.nameControl.value as string)
        : 'Voyageur ' + this.index;
      this.participant.student = false;
    }
  }

  isChild() {
    return this.ageRangeControl.value === AgeRange.CHILD.valueOf();
  }

  calculateBirthdate() {
    let age = this.isChild() ? this.ageControl.value : 25;

    if (this.ageRangeControl.value === AgeRange.SENIOR.valueOf()) {
      age = 65;
    }

    return moment()
      .add(-1, 'day')
      .add(-(age as number), 'year');
  }

  removeDefaultName() {
    if (
      this.nameControl.value &&
      this.nameControl.value?.indexOf('Voyageur') !== -1
    ) {
      this.nameControl.setValue('');
    }
  }
}
