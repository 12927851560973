import moment, { Moment } from 'moment';
import { Package } from './package';

export class PackageGroup {
  id: number;
  name: string;
  price: number;
  publicPrice: number;
  description: string;
  startDate: Moment;
  endDate: Moment;
  packages: Package[];

  constructor(obj: Partial<PackageGroup>) {
    Object.assign(this, obj);

    if (this.startDate) {
      this.startDate = moment(this.startDate);
    }

    if (this.endDate) {
      this.endDate = moment(this.endDate);
    }

    if (this.packages) {
      this.packages = this.packages.map((pck) => new Package({ ...pck }));
    }
  }
}
