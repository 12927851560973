import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

// tslint:disable-next-line:typedef
moment.fn.toJSON = function () {
  return this.local(true).format('yyyy-MM-DDTHH:mm:ss.SSS') + 'Z';
};

@Injectable({
  providedIn: 'root'
})
export class RepositoryService {
  constructor(protected http: HttpClient) {}

  private static createCompleteRoute(
    route: string,
    envAddress: string
  ): string {
    return `${envAddress}/${route}`;
  }

  public get(
    route: string,
    params: any = {},
    responseType = 'json'
  ): Observable<any> {
    return this.http.get<any>(
      RepositoryService.createCompleteRoute(route, environment.urlAddress),
      // @ts-ignore
      { params, responseType }
    );
  }

  public post(
    route: string,
    body: any,
    responseType = 'json'
  ): Observable<any> {
    return this.http.post<any>(
      RepositoryService.createCompleteRoute(route, environment.urlAddress),
      body,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          timeout: '20000'
        }),
        // @ts-ignore
        responseType
      }
    );
  }

  public put(route: string, body: any, responseType = 'json'): Observable<any> {
    return this.http.put<any>(
      RepositoryService.createCompleteRoute(route, environment.urlAddress),
      body,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }),
        // @ts-ignore
        responseType
      }
    );
  }

  public delete(route: string): Observable<any> {
    return this.http.delete<any>(
      RepositoryService.createCompleteRoute(route, environment.urlAddress)
    );
  }
}
