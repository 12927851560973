export class FilterCount<T> {
  id: T;
  name: string;
  quantity: number;
  hideQuantity = false;

  constructor(obj: FilterCount<T>) {
    Object.assign(this, obj);
  }
}
