import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AtomicLibModule, ScreenPipe } from 'atomic-lib';
import { CartModule } from '../cart/cart.module';
import { SharedModule } from '../shared/shared.module';
import { FormActivityComponent } from './form-date-range-picker/form-activity/form-activity.component';
import { FormDateRangePeriodPickerComponent } from './form-date-range-picker/form-date-range-period-picker/form-date-range-period-picker.component';
import { FormDateRangePickerComponent } from './form-date-range-picker/form-date-range-picker.component';
import { FormManageParticipantComponent } from './form-date-range-picker/form-manage-participant/form-manage-participant.component';
import { FormResortComponent } from './form-date-range-picker/form-resort/form-resort.component';
import { GoToSearchMobileComponent } from './form-date-range-picker/go-to-search-mobile/go-to-search-mobile.component';
import { MoreFiltersComponent } from './form-date-range-picker/more-filters/more-filters.component';
import { SearchPopupMobileComponent } from './form-date-range-picker/search-popup-mobile/search-popup-mobile.component';
import { LoginUserComponent } from './login-user/login-user.component';
import { MenuComponent } from './menu/menu.component';
import { NavbarComponent } from './navbar.component';
import { SearchNavbarComponent } from './search-navbar/search-navbar.component';

@NgModule({
  declarations: [
    NavbarComponent,
    FormDateRangePickerComponent,
    FormActivityComponent,
    FormDateRangePeriodPickerComponent,
    FormManageParticipantComponent,
    LoginUserComponent,
    MenuComponent,
    GoToSearchMobileComponent,
    SearchPopupMobileComponent,
    SearchNavbarComponent,
    MoreFiltersComponent,
    FormResortComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    AtomicLibModule,
    RouterModule,
    CartModule,
    FormsModule,
    NgOptimizedImage,
    ScreenPipe
  ],
  exports: [NavbarComponent, FormDateRangePickerComponent, MoreFiltersComponent]
})
export class NavbarModule {}
