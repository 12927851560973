import { Criteria } from '../criteria';
import { DifficultyExperience } from '../enum/difficulty-experience.enum';
import { ExperienceCategoryEnum } from '../enum/experience-category.enum';
import { FilterRange } from '../filters/filter-range';

export class FiltersExperienceSearch extends Criteria {
  resorts: number[] = [];
  type: ExperienceCategoryEnum;
  difficulties: DifficultyExperience[] = [];
  priceRange: FilterRange = new FilterRange({});
  durationRange: FilterRange = new FilterRange({});
  heart = false;
  promo = false;
  geoBoundsActive = false;
  mapPolygon: google.maps.LatLngBoundsLiteral;

  constructor(obj: Partial<FiltersExperienceSearch>) {
    super(obj);

    if (obj) {
      Object.assign(this, obj);
    }
  }

  toQueryParams() {
    return {
      resorts: this.resorts.length ? this.resorts : null,
      difficulties: this.difficulties.length ? this.difficulties : null,
      activities: this.activities.length ? this.activities : null,
      heart: this.heart ? this.heart : null,
      promo: this.promo ? this.promo : null,
      startDate: this.startDate ? this.startDate.format('YYYY-MM-DD') : null,
      endDate: this.endDate ? this.endDate.format('YYYY-MM-DD') : null
    };
  }
}
