import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Select } from '@ngxs/store';
import { WindowResource } from 'atomic-lib';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppState } from '../../../../app.state';
import { ContactService } from '../../../../service/contact.service';
import { RxjsComponent } from '../../../../shared/component/rxjs.component';
import { MessageReclamation } from '../../../../shared/models/message-reclamation';
import { UserSession } from '../../../../shared/models/user-session';

@Component({
  selector: 'vsk-reclamation-order',
  templateUrl: './reclamation-order.component.html',
  styleUrls: ['./reclamation-order.component.scss']
})
export class ReclamationOrderComponent extends RxjsComponent {
  @Select(AppState.user) user$: Observable<UserSession>;

  isSend = false;
  error: string;

  transactionIdForm = new UntypedFormControl('', Validators.required);
  messageForm = new UntypedFormControl('', Validators.required);
  reclamationForm: UntypedFormGroup;
  @Output() closeChanged: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private contactService: ContactService,
    public windowResource: WindowResource
  ) {
    super();
    this.reclamationForm = new UntypedFormGroup({
      transactionId: this.transactionIdForm,
      message: this.messageForm
    });
  }

  @Input() set transaction(transactionId: string) {
    this.transactionIdForm.setValue(transactionId);
  }

  sendReclamation(): void {
    if (this.reclamationForm.valid) {
      this.register(
        this.user$
          .pipe(
            switchMap((user) =>
              this.contactService.sendReclamation(
                new MessageReclamation({
                  firstName: user.firstname,
                  lastName: user.lastname,
                  userId: user.id,
                  transactionId: this.transactionIdForm.value,
                  message: this.messageForm.value
                })
              )
            )
          )
          .subscribe(
            () => {
              this.isSend = true;
              this.error = '';
            },
            () =>
              (this.error =
                "Oops une erreur est survenue lors de l'envoi de votre message ... \n" +
                'Veuillez réessayer ultérieurement')
          )
      );
    }
  }
}
