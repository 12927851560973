<ng-container *ngIf="!responsive">
  <div (click)="closePicker(!showPicker)"
       [ngClass]="{
       'vsk__is-in-navbar': isInNavbar,
       'vsk__is-not-in-navbar': !isInNavbar,
       'vsk__is-active': showPicker && currentPage !== 'Home',
       'vsk__is-active-in-home-page accommodation-activity': showPicker && currentPage === 'Home',
       'hover-element': currentPage !== 'Home',
       'home-element': currentPage === 'Home'}"
       id="id__button-dates-home-page">

    <div class="vsk__date-start">
      <svg *ngIf="!isInNavbar"
           class="lg:max-h-8 lg:min-h-8 lg:w-8 lg:mt-[1.2rem] lg:ml-8 stroke-secondary">
        <use [attr.xlink:href]="'assets/icons/icons.svg#calendar'"></use>
      </svg>
      <svg *ngIf="isInNavbar"
           class="lg:max-h-8 lg:min-h-8 lg:max-w-[2rem] stroke-secondary">
        <use [attr.xlink:href]="'assets/icons/icons.svg#calendar'"></use>
      </svg>
      <div class="vsk__column">
        <p *ngIf="period?.startDate" class="vsk__selected">
          {{ period?.startDate?.locale('fr').format('DD MMM') }} - {{ period?.endDate?.locale('fr').format('DD MMM') }}
        </p>
        <p *ngIf="!period?.startDate" class="vsk__selected">
          Quand ?
        </p>
      </div>
    </div>
  </div>

  <atc-dropdown (closeChanged)="closePicker(false)"
                [isOpen]="showPicker"
                [left]="'0'"
                [ngClass]="{'vsk__navbar-dropdown': isInNavbar}"
                [position]="'bottom-left'"
                [width]="WIDTH">

    <atc-date-range-picker (dateRangeChanged)="datesPicked($event)"
                           [endDate]="period?.endDate"
                           [maxDays]="28"
                           [max]="maxDate"
                           [min]="minDate"
                           [startDate]="period?.startDate">
    </atc-date-range-picker>
    <div class="vsk__inline">
      <div class="vsk__spacer"></div>

      <atc-button (click)="goTo(URL_SEARCH_TRIP)"
                  *ngIf="currentPage === 'Resort' && (isFoncia$ | async) === false"
                  class="mr-4"
                  color="ternary"
                  label="Découvrir d'autres destinations">
      </atc-button>

      <atc-button (click)="update()"
                  *ngIf="currentPage !== 'Home'"
                  color="secondary"
                  label="Mettre à jour mes dates">
      </atc-button>

      <atc-button (click)="goNext()"
                  *ngIf="currentPage === 'Home' && (isFoncia$ | async) === false"
                  [label]="getLabelNextStep()"
                  color="secondary"
                  id="id__continuer-date-range">
      </atc-button>

    </div>
  </atc-dropdown>
</ng-container>

<ng-container *ngIf="responsive">
  <atc-date-range-picker (dateRangeChanged)="datesPicked($event)"
                         [endDate]="period?.endDate"
                         [maxDays]="14"
                         [max]="maxDate"
                         [min]="minDate"
                         [startDate]="period?.startDate"
                         title="Séjour">
    <div class="vsk__buttons-date-picker">
      <atc-button (click)="resetDates()"
                  color="ternary"
                  label="Effacer"></atc-button>
      <atc-button (click)="goNext()"
                  *ngIf="(isFoncia$ | async) === false"
                  [disabled]="!period.isValid"
                  [label]="getLabelNextStep()"
                  color="secondary"></atc-button>
    </div>
  </atc-date-range-picker>
</ng-container>
