import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { SubMenuLink, WindowResource } from 'atomic-lib';
import hash from 'object-hash';
import { Observable, combineLatest } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import { CartState } from '../cart/cart.state';
import { SetResort } from '../filters.action';
import { FiltersState } from '../filters.state';
import { UrlManagerResource } from '../resource/url-manager.resource';
import { StationService } from '../service/station.service';
import { RxjsComponent } from '../shared/component/rxjs.component';
import { Criteria } from '../shared/models/criteria';
import { Participant } from '../shared/models/participant/participant';
import { Period } from '../shared/models/period';
import { Resort } from '../shared/models/resort/resort';
import { ResortAvailability } from '../shared/models/resort/resort-availibility';
import { ResortLabel } from '../shared/models/resort/resort-label';
import { UrlUtils } from '../utils/url-utils';
import {
  URL_RESORT_ACCOMMODATION,
  URL_RESORT_ACTIVITIES,
  URL_RESORT_INFORMATION,
  URL_RESORT_MATERIAL,
  URL_RESORT_PACKAGE,
  URL_RESORT_SKI_CLASS
} from './resort-routing.module';
import { ResetStoreState, SetNavbarSticky } from './resort.action';

export interface Banner {
  title: string;
  location: string;
  logo?: string;
  labels?: ResortLabel[];
  nbRooms?: number;
  pictures: string[];
}

@Component({
  selector: 'vsk-resort',
  templateUrl: './resort.component.html',
  styleUrls: ['./resort.component.scss']
})
export class ResortComponent
  extends RxjsComponent
  implements OnInit, OnDestroy
{
  @Select(FiltersState.resort) resort$: Observable<Resort>;
  @Select(FiltersState.sessionId) sessionId$: Observable<string>;
  @Select(FiltersState.criteria) criteria$: Observable<Criteria>;
  @Select(FiltersState.period) period$: Observable<Period>;
  @Select(FiltersState.participants) participants$: Observable<Participant[]>;
  @Select(CartState.totalPriceDisplay) totalPrice$: Observable<any>;
  @Select(FiltersState.isFoncia) isFoncia$: Observable<boolean>;

  @ViewChild('header', { static: false, read: ElementRef })
  banner: ElementRef<HTMLElement>;
  sticky = false;
  resortsLinks: SubMenuLink[] = [];
  pictures: string[] = [];
  resortName: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private stationService: StationService,
    public windowResource: WindowResource,
    private store: Store,
    private elementRef: ElementRef<HTMLElement>,
    private router: Router,
    private urlManager: UrlManagerResource
  ) {
    super();
    this.store.dispatch(new ResetStoreState());
  }

  @HostListener('scroll', ['$event'])
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (this.elementRef && this.banner) {
      const prevSticky = this.sticky;
      this.sticky =
        this.banner.nativeElement.offsetHeight <=
          this.elementRef.nativeElement.scrollTop ||
        this.banner.nativeElement.offsetHeight <= window.scrollY;

      if (prevSticky !== this.sticky) {
        this.store.dispatch(new SetNavbarSticky(this.sticky));
      }
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.elementRef.nativeElement.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        this.scrollToMenu();
      }
    });

    this.register(
      combineLatest([
        this.sessionId$.pipe(filter((sessionId) => !!sessionId)),
        this.period$.pipe(
          filter((period) => period.isValid),
          distinctUntilChanged(
            (prev, curr) =>
              prev.startDate.isSame(curr.startDate, 'day') &&
              prev.endDate.isSame(curr.endDate, 'day')
          )
        ),
        this.resort$.pipe(
          filter((resort) => !!resort),
          tap((resort) => (this.resortName = resort.name))
        ),
        this.participants$.pipe(
          distinctUntilChanged(
            (prev, curr) =>
              hash(prev.map((participant) => participant.uuid)) ===
              hash(curr.map((participant) => participant.uuid))
          )
        )
      ])
        .pipe(
          debounceTime(300),
          map(([sessionId, period, resort, participants]) => {
            return new Criteria({
              startDate: period.startDate,
              endDate: period.endDate,
              stationName: resort.name,
              sessionId,
              participants
            });
          }),
          filter((criteria) => criteria.isValid),
          switchMap((criteria) =>
            this.stationService.checkAvailability(criteria)
          ),
          catchError(() => {
            this.setAllSubMenuLinks(this.resortName);
            return [];
          })
        )
        .subscribe((availability) => {
          if (availability) {
            this.updateSubMenuLinks(availability, this.resortName);
          }
        })
    );

    const queryParams$ = combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.queryParams
    ]).pipe(
      filter(
        ([params, queryParams]) =>
          params['resort'] !== undefined || queryParams['station'] !== undefined
      ),
      tap(() => this.checkOldUrl(this.router.url)),
      map(
        ([params, queryParams]) => queryParams['station'] || params['resort']
      ),
      distinctUntilChanged((prev, curr) => prev === curr)
    );

    this.register(
      combineLatest([
        queryParams$,
        this.sessionId$.pipe(
          distinctUntilChanged((prev, curr) => prev === curr)
        ),
        this.period$.pipe(
          distinctUntilChanged(
            (prev, curr) =>
              prev.startDate?.isSame(curr.startDate, 'day') &&
              prev.endDate?.isSame(curr.endDate, 'day')
          )
        )
      ])
        .pipe(
          filter(([, sessionId]) => !!sessionId),
          distinctUntilChanged(
            ([prevResortName], [currResortName]) =>
              prevResortName === currResortName
          ),
          switchMap(([resortName, sessionId]) =>
            this.stationService.getStationByName(resortName, sessionId)
          )
        )
        .subscribe((resort) => {
          this.store.dispatch(new SetResort(resort));
          document.title = resort.name + ' - VeryMountain';
        })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    setTimeout(() => {
      this.urlManager.mergeParams({
        resorts: null,
        establishmentId: null,
        roomCode: null,
        partnerCode: null,
        station: null
      });
    });
  }

  getBanner(resort: Resort): Banner {
    return {
      labels: resort.labels,
      location: resort.regionLabel,
      logo: resort.urlLogo,
      pictures: resort.urlsCover,
      title: resort.name
    };
  }

  goToPayment() {
    this.router.navigate(['/payment'], {
      queryParamsHandling: 'merge'
    });
  }

  private updateSubMenuLinks(
    availability: ResortAvailability,
    resortName: string
  ): void {
    // Réinitialiser les liens
    this.resortsLinks = [];

    this.resortsLinks.push({
      id: URL_RESORT_INFORMATION,
      title: 'Informations',
      url: `/station/${UrlUtils.encodeToURL(resortName)}/informations`,
      icon: 'light-bulb',
      params: {},
      size: 'medium'
    });

    if (availability.accommodationAvailable) {
      this.resortsLinks.push({
        id: URL_RESORT_ACCOMMODATION,
        title: 'Hébergements',
        url: `/station/${UrlUtils.encodeToURL(resortName)}/hebergements`,
        icon: 'home',
        params: {},
        size: 'medium'
      });
    }

    if (availability.skiLessonAvailable) {
      this.resortsLinks.push({
        id: URL_RESORT_SKI_CLASS,
        title: 'Cours de ski',
        url: `/station/${UrlUtils.encodeToURL(resortName)}/cours-ski`,
        icon: 'academic-cap',
        params: {},
        size: 'medium'
      });
    }

    this.resortsLinks.push({
      id: URL_RESORT_ACTIVITIES,
      title: 'Activités',
      url: `/station/${UrlUtils.encodeToURL(resortName)}/activites`,
      icon: 'activity',
      params: {},
      size: 'medium'
    });

    if (availability.skiPassAvailable) {
      this.resortsLinks.push({
        id: URL_RESORT_PACKAGE,
        title: 'Forfaits',
        url: `/station/${UrlUtils.encodeToURL(resortName)}/forfaits`,
        icon: 'ticket',
        params: {},
        size: 'medium'
      });
    }

    if (availability.skiEquipmentAvailable) {
      this.resortsLinks.push({
        id: URL_RESORT_MATERIAL,
        title: 'Matériel',
        url: `/station/${UrlUtils.encodeToURL(resortName)}/location-materiel`,
        icon: 'skis',
        params: {},
        size: 'medium'
      });
    }

    if (availability.skiEquipmentCustomAvailable) {
      this.resortsLinks.push({
        id: URL_RESORT_MATERIAL,
        title: 'Matériel',
        url: `/station/${UrlUtils.encodeToURL(resortName)}/location-materiel-intersport`,
        icon: 'skis',
        params: {},
        size: 'medium'
      });
    }

    this.scrollToMenu();
  }

  private setAllSubMenuLinks(resortName: string): void {
    this.resortsLinks = [
      {
        id: URL_RESORT_INFORMATION,
        title: 'Informations',
        url: `/station/${UrlUtils.encodeToURL(resortName)}/informations`,
        icon: 'light-bulb',
        params: {},
        size: 'medium'
      },
      {
        id: URL_RESORT_ACCOMMODATION,
        title: 'Hébergements',
        url: `/station/${UrlUtils.encodeToURL(resortName)}/hebergements`,
        icon: 'home',
        params: {},
        size: 'medium'
      },
      {
        id: URL_RESORT_SKI_CLASS,
        title: 'Cours de ski',
        url: `/station/${UrlUtils.encodeToURL(resortName)}/cours-ski`,
        icon: 'academic-cap',
        params: {},
        size: 'medium'
      },
      {
        id: URL_RESORT_PACKAGE,
        title: 'Forfaits',
        url: `/station/${UrlUtils.encodeToURL(resortName)}/forfaits`,
        icon: 'ticket',
        params: {},
        size: 'medium'
      },
      {
        id: URL_RESORT_ACTIVITIES,
        title: 'Activités',
        url: `/station/${UrlUtils.encodeToURL(resortName)}/activites`,
        icon: 'activity',
        params: {},
        size: 'medium'
      },
      {
        id: URL_RESORT_MATERIAL,
        title: 'Matériel',
        url: `/station/${UrlUtils.encodeToURL(resortName)}/location-materiel`,
        icon: 'skis',
        params: {},
        size: 'medium'
      }
    ];
  }

  private scrollToMenu() {
    setTimeout(() => {
      const urlFragments = this.router.url.split('/');
      const page = urlFragments[urlFragments.length - 1].split('?')[0];
      document.querySelector(`#${page}`)?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'end'
      });
    }, 100);
  }

  private checkOldUrl(url: string) {
    const fragments = url.split('?')[0].split('/');
    fragments.shift();

    if (fragments.length >= 3) {
      return;
    }

    const resortName = this.activatedRoute.snapshot.queryParams[
      'station'
    ] as string;

    this.router.navigate(
      [`/${fragments[0]}/${UrlUtils.encodeToURL(resortName)}/${fragments[1]}`],
      { replaceUrl: true, queryParamsHandling: 'merge' }
    );
  }
}
