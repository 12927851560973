import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewChild
} from '@angular/core';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { CardProductPresentation, WindowResource } from 'atomic-lib';
import { Moment } from 'moment';
import { Observable, combineLatest, filter } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { TriggerAlert } from '../../../app.action';
import { CartState } from '../../../cart/cart.state';
import { FiltersState } from '../../../filters.state';
import { MapConfiguration } from '../../../map/map.component';
import { MetaDescriptionService } from '../../../service/meta-description.service';
import { ManageCartComponent } from '../../../shared/component/manage-cart.component';
import { Experience } from '../../../shared/models/activity/experience';
import { Alert } from '../../../shared/models/alert';
import { Cart } from '../../../shared/models/cart/cart';
import { Criteria } from '../../../shared/models/criteria';
import { ItemCart } from '../../../shared/models/package/item-cart';
import { Package } from '../../../shared/models/package/package';
import { Participant } from '../../../shared/models/participant/participant';
import { Period } from '../../../shared/models/period';
import { Resort } from '../../../shared/models/resort/resort';
import { PackageUtils } from '../../../utils/package-utils';
import { UrlUtils } from '../../../utils/url-utils';
import { BreadcrumbLink } from '../../resort-accommodation/resort-establishment/resort-establishment.component';
import { FetchExperience, FetchSimilarExperiences } from '../../resort.action';
import { ResortState } from '../../resort.state';

@Component({
  selector: 'vsk-details-experience',
  templateUrl: './details-experience.component.html',
  styleUrls: ['./details-experience.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailsExperienceComponent extends ManageCartComponent {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild('marker', { static: false }) markerMap: MapMarker;
  @Select(ResortState.experience) experience$: Observable<Experience>;
  @Select(FiltersState.sessionId) sessionId$: Observable<string>;
  @Select(FiltersState.period) period$: Observable<Period>;
  @Select(FiltersState.participants) participants$: Observable<Participant[]>;
  @Select(FiltersState.resort) resort$: Observable<Resort>;
  @Select(CartState.cart) cart$: Observable<Cart>;
  @Select(ResortState.similarExperiences) similarExperiences$: Observable<
    Experience[]
  >;

  hasTimeslotsAvailable = false;
  period: Period;
  currentDay: Moment;
  participants: Participant[] = [];
  cart: Cart;
  resort: Resort;

  readonly packageUtils = PackageUtils;

  participantsEligible: Participant[] = [];
  loaderExperience = true;
  experience: Experience;
  currentSlide = 0;
  experiencesCarousel: CardProductPresentation[] = [];
  packages: Package[] = [];
  pictures: string[] = [];
  showExperienceDrawer = false;
  sanitizedVideoUrl?: SafeResourceUrl;
  breadcrumbResort: BreadcrumbLink;
  breadcrumbItems: BreadcrumbLink[] = [];
  // Map
  markerExperience: google.maps.Marker;
  markerResort: google.maps.Marker;
  zoom = 12;
  center: google.maps.LatLngLiteral = {
    lat: 45.052825927734375,
    lng: 2.7113842964172363
  };
  options: MapConfiguration = {
    scaleControl: true,
    streetViewControl: false,
    fullscreenControl: false,
    zoomControl: this.windowResource.isDesktop,
    mapTypeControl: false,
    maxZoom: 15,
    minZoom: 6,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    clickableIcons: false,
    styles: [
      {
        featureType: 'road',
        elementType: 'all',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      }
    ]
  };

  constructor(
    public windowResource: WindowResource,
    private activatedRoute: ActivatedRoute,
    protected store: Store,
    private sanitizer: DomSanitizer,
    private router: Router,
    protected changeDetectorRef: ChangeDetectorRef,
    protected decimalPipe: DecimalPipe,
    private metaDescriptionService: MetaDescriptionService
  ) {
    super(store, decimalPipe, changeDetectorRef);
    const criteria$ = combineLatest([
      this.sessionId$.pipe(filter((sessionId) => !!sessionId)),
      this.period$,
      this.resort$.pipe(
        filter((resort) => !!resort),
        tap((resort) => {
          this.breadcrumbResort = {
            label: resort.name,
            url: `station/${UrlUtils.encodeToURL(resort.name)}/informations`
          };
        })
      ),
      this.participants$
    ]).pipe(
      debounceTime(300),
      map(([sessionId, period, resort, participants]) => {
        this.period = period;
        return new Criteria({
          startDate: period.startDate,
          endDate: period.endDate,
          stationName: resort.name,
          sessionId,
          participants
        });
      }),
      filter((criteria) => criteria.isValid)
    );

    this.register(
      combineLatest([
        this.activatedRoute.queryParams.pipe(
          filter((params) => params['experienceId'] !== undefined),
          map((params) => params['experienceId'])
        ),
        criteria$
      ])
        .pipe(
          tap(() => (this.loaderExperience = true)),
          switchMap(([experienceId]) =>
            this.store.dispatch(new FetchExperience(experienceId))
          )
        )
        .subscribe(() => (this.loaderExperience = false))
    );

    this.register(
      this.experience$
        .pipe(filter((experience) => !!experience))
        .subscribe((experience) => {
          const title = `${experience.activity.name}, ${experience.name} · VeryMountain`;
          const description = `${experience.description}`;

          const url = `station/fiche-activite`;
          this.metaDescriptionService.updateMeta(title, description, url);

          this.breadcrumbItems = [
            this.breadcrumbResort,
            {
              label: 'Activités',
              url: `station/${UrlUtils.encodeToURL(this.breadcrumbResort.label)}/activites`
            },
            { label: experience.name, url: '' }
          ];

          this.center = {
            lat: experience.latitude,
            lng: experience.longitude
          };
          this.markerExperience = new google.maps.Marker({
            clickable: true,
            position: {
              lat: experience.latitude,
              lng: experience.longitude
            },
            label: {
              color: '#1f1f1f',
              text: experience.name,
              fontWeight: '500',
              fontSize: '11px',
              className: 'pinpoint',
              fontFamily: 'General'
            },
            title: experience.name,
            optimized: false,
            zIndex: 100
          });
          if (experience.videoUrl) {
            this.sanitizedVideoUrl =
              this.sanitizer.bypassSecurityTrustResourceUrl(
                experience.videoUrl
              );
          } else {
            this.sanitizedVideoUrl = undefined;
          }
          this.experience = experience;
          this.initCartWithFirstTimeSlotForParticipant();
        })
    );

    this.resort$.pipe(filter((resort) => !!resort)).subscribe((resort) => {
      this.markerResort = new google.maps.Marker({
        clickable: true,
        position: {
          lat: resort.location.lat,
          lng: resort.location.lon
        },
        icon: {
          url: '../../../../assets/svg/station-circle.svg',
          scaledSize: new google.maps.Size(80, 80, 'px', 'px'),
          origin: new google.maps.Point(0, -20)
        },
        title: resort.name,
        optimized: false,
        zIndex: 100
      });
    });

    this.register(
      this.participants$.subscribe(
        (participants) => (this.participants = participants)
      )
    );

    this.experience$.subscribe((experience) => {
      if (experience) {
        this.store.dispatch(
          new FetchSimilarExperiences(experience.activity.id)
        );
        this.hasTimeslotsAvailable =
          experience.timeSlots.length > 0 || experience.internships.length > 0;
      }
    });

    this.similarExperiences$.subscribe((experiences) => {
      if (experiences.length > 0) {
        this.experiencesCarousel = experiences.map((experience) => {
          return {
            id: experience.id,
            title: experience.name,
            background: experience.pictures[0],
            description: experience.description,
            link: `/station/${UrlUtils.encodeToURL(this.breadcrumbResort.label)}/fiche-activite`,
            params: { experienceId: experience.id },
            selected: false,
            price: this.packageUtils.minPriceString(
              experience.packagesByPeriod(this.period),
              this.participants
            )
          } as CardProductPresentation;
        });
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  postRegisterAction(): void {
    this.store.dispatch(
      new TriggerAlert(
        new Alert({
          message: 'Activité ajoutée au panier !',
          timeout: 5000,
          level: 'success'
        })
      )
    );
    this.initCartWithFirstTimeSlotForParticipant();
  }

  openItinerary(experience: Experience) {
    const googleMapUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(experience.address)}`;
    window.open(googleMapUrl, '_blank');
  }

  openTimeSlots() {
    this.showExperienceDrawer = true;
  }

  setZoom(): void {
    this.zoom = this.map.getZoom() as number;
  }

  goToWithParams(url: string) {
    this.router.navigate([url], { queryParamsHandling: 'merge' });
  }

  getSizeGrid(width: number | null) {
    const widthValue = width ?? window.innerWidth;

    if (widthValue >= WindowResource.WIDTH_MAX_DESKTOP) {
      return 5;
    }

    if (widthValue >= WindowResource.WIDTH_MAX_TABLET) {
      return 3;
    }
    return 1;
  }

  initCartWithFirstTimeSlotForParticipant() {
    if (
      !this.experience?.timeSlots?.length &&
      !this.experience?.internships?.length
    ) {
      return;
    }

    let itemCart: ItemCart;
    const pck = PackageUtils.filterCheapestPackagesByParticipants(
      this.experience.packagesByPeriod(this.period),
      this.participants
    );

    if (!pck) {
      return;
    }

    const participants = PackageUtils.getParticipantsForPackage(
      pck,
      this.participants
    );

    if (this.experience.hasInternship) {
      const firstInternship = this.experience.internships[0];
      this.participantsEligible = participants.splice(
        0,
        firstInternship.slotAvailable
      );

      itemCart = new ItemCart({
        timeSlotModelId: undefined,
        internshipId: firstInternship.id,
        origin: this.experience.origin,
        startDate: firstInternship.dateStart,
        endDate: firstInternship.dateEnd,
        participants: this.participantsEligible,
        resort: this.store.selectSnapshot(FiltersState.resort)?.name,
        item: pck
      });
    } else {
      const firstTimeSlot = this.experience.timeSlots[0];
      const dateStart = firstTimeSlot.dateStart.set({
        hour: firstTimeSlot.hourStart.hour(),
        minute: firstTimeSlot.hourStart.minute()
      });
      this.participantsEligible = participants.splice(
        0,
        firstTimeSlot.remainingSlots
      );

      itemCart = new ItemCart({
        timeSlotModelId: firstTimeSlot.timeSlotModelId,
        internshipId: undefined,
        origin: this.experience.origin,
        startDate: dateStart,
        participants: this.participantsEligible,
        resort: this.store.selectSnapshot(FiltersState.resort)?.name,
        item: pck
      });
    }

    this.itemsToAdd.push(itemCart);
    this.changeDetectorRef.markForCheck();
  }

  getStartDate(experience: Experience) {
    if (experience.hasInternship) {
      return experience.internships[0].dateStart;
    }

    return experience.timeSlots[0].dateStart;
  }

  getStartHour(experience: Experience) {
    if (experience.hasInternship) {
      return experience.internships[0].timeSlots[0].hourStart;
    }

    return experience.timeSlots[0].hourStart;
  }

  openPictures(currentSlide: number) {
    this.pictures = this.experience.pictures;
    this.currentSlide = currentSlide;
  }

  getTooltipMandatory(experience: Experience) {
    if (experience.ski) {
      return "Ajoutez d'abord un hébergement à votre panier pour acheter un forfait.";
    }

    if (experience.equipment) {
      return "Ajoutez d'abord un hébergement à votre panier pour louer du matériel.";
    }

    return "Ajoutez d'abord un hébergement à votre panier pour réserver cette expérience.";
  }
}
