import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { WindowResource } from 'atomic-lib';
import { ContactService } from '../../service/contact.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { MessageContact } from '../../shared/models/message-contact';

@Component({
  selector: 'vsk-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends RxjsComponent {
  isSend = false;
  error: string;

  firstNameForm = new FormControl<string>('', Validators.required);
  lastNameForm = new FormControl<string>('', Validators.required);
  emailForm = new FormControl<string>('', [
    Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$'),
    Validators.required
  ]);
  messageForm = new FormControl<string>('', Validators.required);
  contactForm: UntypedFormGroup;

  @Output() closeChanged: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private contactService: ContactService,
    public windowResource: WindowResource
  ) {
    super();
    this.contactForm = new UntypedFormGroup({
      firstName: this.firstNameForm,
      lastName: this.lastNameForm,
      email: this.emailForm,
      message: this.messageForm
    });
  }

  send(): void {
    if (this.contactForm.valid) {
      this.register(
        this.contactService
          .sendMessage(this.contactForm.getRawValue() as MessageContact)
          .subscribe(
            () => {
              this.isSend = true;
              this.error = '';
            },
            () =>
              (this.error =
                "Oops une erreur est survenue lors de l'envoi de votre message ...")
          )
      );
    }
  }
}
