import { ResortMap } from './resort-map';

export class ResortsBorders {
  stations: ResortMap[];

  constructor(obj: Partial<ResortsBorders>) {
    Object.assign(this, obj);
    this.stations = this.stations.map((station) => new ResortMap(station));
  }
}
