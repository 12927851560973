import moment, { Moment } from 'moment';

export class PackagePrice {
  nbMin: number;
  nbMax: number;
  startDate: Moment;
  endDate: Moment;
  price: number;
  publicPrice: number;

  constructor(obj: Partial<PackagePrice>) {
    Object.assign(this, obj);

    if (this.startDate) {
      this.startDate = moment(this.startDate);
    }

    if (this.endDate) {
      this.endDate = moment(this.endDate);
    }
  }

  atLeastOneDateIsMatching(dates: Moment[]) {
    return dates.some((date) => this.dateIsMatching(date));
  }

  dateIsMatching(date: Moment) {
    return date.isBetween(this.startDate, this.endDate, 'day', '[]');
  }
}
