import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { EquipmentResponse } from '../shared/models/ski-equipment/equipment-response';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class SkiEquipmentService extends RepositoryService {
  private URL_DOMAIN = 'api/webapp/skiset';

  constructor(http: HttpClient) {
    super(http);
  }

  getPacksByStationAndDuration(
    stationName: string,
    duration: number,
    firstSkiDate: Moment
  ): Observable<EquipmentResponse> {
    return this.get(
      `${this.URL_DOMAIN}/get-packs-by-station-and-duration?stationName=${stationName}&duration=${duration}&firstSkiDate=${firstSkiDate.format('YYYY-MM-DD')}`
    );
  }
}
