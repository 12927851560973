import { ParamMap } from '@angular/router';
import { Moment } from 'moment';
import { ItemAccommodation } from '../shared/models/accommodation/item-accommodation';
import { EcoChart } from '../shared/models/cart/eco-chart';
import { CopyCart } from '../shared/models/package/copy-cart';
import { ItemCart } from '../shared/models/package/item-cart';
import { Participant } from '../shared/models/participant/participant';
import { PromoCode } from '../shared/models/promo-code';
import { ItemSkiEquipment } from '../shared/models/ski-equipment/item-ski-equipment';

export class InitCart {
  static readonly type = '[CartState] Initialise cart';

  constructor(public queryParams: ParamMap) {}
}

export class AddItemsToCart {
  static readonly type = '[CartState] Add items to cart';

  constructor(public itemsToAdd: ItemCart[]) {}
}

export class CreateCartMaster {
  static readonly type = '[CartState] Create cart from master session';

  constructor(public copyCart: CopyCart) {}
}

export class RefuseCartMaster {
  static readonly type = '[CartState] Refuse cart from master session';

  constructor() {}
}

export class AddSkiEquipmentToCart {
  static readonly type = '[CartState] Add ski equipment to cart';

  constructor(public itemSkiEquipment: ItemSkiEquipment) {}
}

export class RemoveSkiEquipmentFromCart {
  static readonly type = '[CartState] Remove ski equipment from cart';

  constructor(public cartItemId: number) {}
}

export class AddAccommodationToCart {
  static readonly type = '[CartState] Add accommodation to cart';

  constructor(public itemAccommodation: ItemAccommodation) {}
}

export class UpdateAccommodationRemarks {
  static readonly type = '[CartState] Update accommodation remarks';

  constructor(public remarks: string) {}
}

export class RemoveItemAccommodationFromCart {
  static readonly type = '[CartState] Remove item accommodation from cart';

  constructor(public id: string) {}
}

export class RemoveItemsToCart {
  static readonly type = '[CartState] Remove items to cart';

  constructor(public itemsToRemove: string[]) {}
}

export class FetchCart {
  static readonly type = '[CartState] Fetch cart session';
}

export class FetchPropositionCart {
  static readonly type = '[CartState] Fetch proposition cart by session';

  constructor(public sessionId: string) {}
}

export class RemoveItemsWithNoParticipant {
  static readonly type =
    '[CartState] Remove items with no participant from the cart';

  constructor(public participants: Participant[]) {}
}

export class TimerUpdate {
  static readonly type = '[CartState] Update cart timer expiration';

  constructor(public timer: Moment | null) {}
}

export class CartExpired {
  static readonly type = '[CartState] Cart expired';
}

export class AddPromoCode {
  static readonly type = '[CartState] Set promotional code';

  constructor(public promoCode: PromoCode) {}
}

export class DepositMode {
  static readonly type = '[CartState] Set mode deposit';

  constructor(public deposit: boolean) {}
}

export class ChangeCartDrawerState {
  static readonly type = '[CartState] Change cart drawer state';

  constructor(public isOpen: boolean) {}
}

export class DeletePromoCode {
  static readonly type = '[CartState] Delete promotional code';
}

export class UpdateEcoTourismChart {
  static readonly type = '[CartState] Update eco tourism chart';

  constructor(public value: EcoChart) {}
}
