import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppState } from '../app.state';
import { RxjsComponent } from '../shared/component/rxjs.component';

@Component({
  selector: 'vsk-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent extends RxjsComponent {
  @Select(AppState.isUserLoggedIn) isLoggedIn$: Observable<boolean>;

  constructor(router: Router) {
    super();
    this.register(
      this.isLoggedIn$
        .pipe(filter((isLoggedIn) => !isLoggedIn))
        .subscribe(() => router.navigate(['/']))
    );

    document.title = 'Mon compte - VeryMountain';
  }
}
