import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderHistory } from '../shared/models/order-history';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends RepositoryService {
  private URL_STATION = 'api/webapp/order';

  constructor(http: HttpClient) {
    super(http);
  }

  getOrdersToCome(userId: number): Observable<OrderHistory[]> {
    return this.get(this.URL_STATION + '/orders-to-come?userId=' + userId).pipe(
      map((orders) => orders.map((order: any) => new OrderHistory(order)))
    );
  }

  getOrdersPast(userId: number): Observable<OrderHistory[]> {
    return this.get(this.URL_STATION + '/past-orders?userId=' + userId).pipe(
      map((orders) => orders.map((order: any) => new OrderHistory(order)))
    );
  }

  getReceiptByTransactionId(sessionId: string): Observable<any> {
    return this.get(
      this.URL_STATION + '/generate-receipt-order?sessionId=' + sessionId,
      {},
      'blob'
    );
  }
}
