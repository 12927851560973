import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Router } from '@angular/router';
import {
  CardResortScrollableComponent,
  InputCheckboxComponent,
  ScreenPipe,
  WindowResource
} from 'atomic-lib';
import { MapComponent } from '../../shared/component/map.component';
import { MarkerWrapper } from '../../shared/models/marker-wrapper';
import { Item } from '../../shared/models/package/item';

@Component({
  selector: 'vsk-cart-map',
  standalone: true,
  imports: [
    AsyncPipe,
    CardResortScrollableComponent,
    GoogleMap,
    InputCheckboxComponent,
    MapMarker,
    MapInfoWindow,
    NgForOf,
    NgIf,
    ScreenPipe
  ],
  templateUrl: './cart-map.component.html',
  styleUrl: './cart-map.component.scss'
})
export class CartMapComponent extends MapComponent<Item> {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

  constructor(
    public windowResource: WindowResource,
    public router: Router
  ) {
    super(windowResource, router);
    this.classPinpoint = 'pinpoint-cart';
  }

  @Input() set markersCart(markers: MarkerWrapper<any>[]) {
    this.markers = markers;
    this.placeMarkersOnMap();
  }

  protected boundsChangedAction(): void {}
}
