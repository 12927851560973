import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { DateRange } from 'atomic-lib';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { FiltersState } from 'src/app/filters.state';
import { URL_SEARCH_TRIP } from '../../../app-routing.module';
import { Period } from '../../../shared/models/period';
import { DatePickerOpen } from '../../navbar.action';

@Component({
  selector: 'vsk-form-date-range-period-picker',
  templateUrl: './form-date-range-period-picker.component.html',
  styleUrls: ['./form-date-range-period-picker.component.scss']
})
export class FormDateRangePeriodPickerComponent {
  @Select(FiltersState.isFoncia) isFoncia$: Observable<boolean>;

  readonly WIDTH = '675px';

  @Input() showPicker = false;
  @Input() period: Period;
  @Input() responsive = false;
  @Input() minDate: Moment = moment();
  @Input() maxDate: Moment = moment().add(6, 'month');
  @Input() isInNavbar = false;
  @Input() currentPage: 'Home' | 'Map' | 'Resort' | 'none' = 'none';
  @Input() searchMode: 'accommodation' | 'all' | 'experience' = 'all';
  @Output() datesChanged = new EventEmitter<Period>();
  @Output() nextStep = new EventEmitter<void>();
  @Output() partialValidate = new EventEmitter<void>();

  protected readonly URL_SEARCH_TRIP = URL_SEARCH_TRIP;

  constructor(
    private store: Store,
    private router: Router
  ) {}

  datesPicked(dateRange: DateRange) {
    this.period = new Period({ ...dateRange });
    this.datesChanged.emit(this.period);
  }

  goNext(): void {
    this.store.dispatch(new DatePickerOpen(false));
    this.nextStep.emit();
  }

  resetDates() {
    this.period = new Period({});
  }

  closePicker(open: boolean) {
    this.store.dispatch(new DatePickerOpen(open));
  }

  goTo(destination: string) {
    this.store.dispatch(new DatePickerOpen(false));
    this.partialValidate.emit();
    this.router.navigate([destination]);
  }

  update() {
    this.store.dispatch(new DatePickerOpen(false));
    this.partialValidate.emit();
  }

  getLabelNextStep() {
    if (this.searchMode === 'accommodation') {
      return 'Choisir mes destinations';
    }

    return 'Choisir mes activités';
  }
}
