import moment, { Moment } from 'moment';

export class User {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  country: string;
  postCode: string;
  birthdate: Moment;
  cardSkidata: string;
  cardAxess: string;
  newsletter: boolean;

  constructor(obj: Partial<User>) {
    Object.assign(this, obj);
    if (this.birthdate) {
      this.birthdate = moment(this.birthdate);
    }
  }
}
