<div class="bg-white rounded-[30px] flex flex-col gap-4 p-8 mx-4
            xl:max-w-[1100px] xl:mx-auto box-shadow">
  <h1 class="flex-1 text-center">Mes cartes physiques</h1>
  <p class="text-center">Ces identifiants sont inscrits sur vos badges physiques pour les remontées mécaniques.</p>

  <!-- Skidata -->
  <atc-input [control]="skidataForm"
             label="Skidata"
             mask="00-0000 0000 0000 0000 0000-0"
             placeholder="00-0000 0000 0000 0000 0000-0"
             type="text"></atc-input>

  <!-- Team Axess -->
  <atc-input [control]="axessForm"
             label="Team Axess"
             mask="AAAAAAAA-AAA-AAA"
             placeholder="A0000BCD-EFG-IJ0"
             type="text"></atc-input>

  <div class="vsk__inline vsk__responsive-button vsk__account-buttons">
    <div class="vsk__spacer"></div>
    <atc-button (click)="validation()"
                [fullWidth]="true"
                class="w-full flex"
                color="secondary"
                label="Modifier"></atc-button>
  </div>
</div>

<atc-alert *ngIf="success" class="fixed top-0 left-0 right-0 w-full z-[2000]" level="success">{{success}}</atc-alert>
<atc-alert *ngIf="error" class="fixed top-0 left-0 right-0 w-full z-[2000]" level="error">{{error}}</atc-alert>
