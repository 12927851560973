<div class="vsk__account-menu
            md:w-full md:px-8
            lg:w-full lg:px-8
            xl:w-[1100px]">
  <a class="bg-white rounded-[30px] flex flex-row gap-4 p-8 items-center box-shadow
            md:w-full
            lg:w-full
            xl:w-[1100px] xl:mx-auto"
     routerLink="/my-account/orders"
     routerLinkActive="false">
    <svg class="h-12 w-12 my-auto ml-10 stroke-black">
      <use [attr.xlink:href]="'assets/icons/icons.svg#cart'"></use>
    </svg>
    <h3>Mes commandes</h3>
  </a>

  <a class="bg-white rounded-[30px] flex flex-col gap-4 p-8 items-center box-shadow
            md:w-full
            lg:w-full
            xl:w-[1100px] xl:mx-auto"
     routerLink="/my-account/information"
     routerLinkActive="true">
    <svg class="h-12 w-12 my-auto ml-10 stroke-black">
      <use [attr.xlink:href]="'assets/icons/icons.svg#person'"></use>
    </svg>
    <h3>Mes informations</h3>
  </a>

  <a class="bg-white rounded-[30px] flex flex-col gap-4 p-8 items-center box-shadow
            md:w-full
            lg:w-full
            xl:w-[1100px] xl:mx-auto"
     routerLink="/my-account/pass"
     routerLinkActive="true">
    <svg class="h-12 w-12 my-auto ml-10 stroke-black">
      <use [attr.xlink:href]="'assets/icons/icons.svg#ticket'"></use>
    </svg>
    <h3>Mes cartes physiques</h3>
  </a>
</div>
