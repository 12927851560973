<ng-container *ngIf="loaderExperience">
  <div *ngIf="windowResource.width() | async | screen: 'small-desktop-above'"
       class="flex flex-col gap-4 mx-auto sm:gap-2
    xl:w-[1350px] w-[100%] max-w-[1350px] xl:flex-col xl:px-0 px-[30px] pt-2 pb-[4rem]">
    <div
      class="rounded-[15px] h-[35px]"
      vskSkeletonLoad>
    </div>
    <div class="rounded-[20px] h-[70px]" vskSkeletonLoad></div>
    <div
      class="flex flex-row justify-between gap-[10px] w-full">
      <div
        class="rounded-[20px] h-auto w-full" vskSkeletonLoad>
      </div>
      <div class="grid grid-cols-2 w-full gap-2">
        <div *ngFor="let nb of [0,1,2,3]"
             class="rounded-[20px] h-[250px]" vskSkeletonLoad>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(windowResource.width() | async | screen: 'small-desktop-above')"
       class="flex flex-col gap-4 mx-auto sm:gap-2
    xl:w-[1350px] w-[100%] max-w-[1350px] xl:flex-col xl:px-0 px-[30px] pt-2 pb-[4rem]">
    <div
      class="rounded-[15px] h-[35px]"
      vskSkeletonLoad>
    </div>
    <div class="rounded-[20px] h-[70px]" vskSkeletonLoad></div>
    <div
      class="flex flex-row justify-between gap-[10px] w-full">
      <div
        class="rounded-[20px] h-[250px] w-full" vskSkeletonLoad>
      </div>
    </div>
  </div>
</ng-container>


<ng-container *ngIf="!hasTimeslotsAvailable && period">
  <div class="mx-auto min-h-[35vh] mt-4 items-center flex flex-col">
    <h3 class="text-center">
      Aucun créneau disponible pour les dates du
      {{ period.startDate.locale('fr').format('DD MMMM YYYY') }} au
      {{ period.endDate.locale('fr').format('DD MMMM YYYY') }} ...
    </h3>
    <a class="mx-auto mt-8"
       queryParamsHandling="merge"
       routerLink="/station/activites"
       routerLinkActive="true">
      <atc-button color="ternary"
                  icon="activity"
                  label="Voir les autres activités de la station">
      </atc-button>
    </a>
  </div>
</ng-container>

<div *ngIf="experience && hasTimeslotsAvailable"
     class="flex flex-col justify-between w-[100%] max-w-[1350px] mx-auto
            xl:flex-col gap-2 xl:px-0
            px-[30px] pt-2 lg:pb-4">

  <atc-breadcrumb (itemClick)="goToWithParams($event)"
                  [items]="breadcrumbItems">
  </atc-breadcrumb>

  <div *ngIf="experience" class="info-card sm:max-w-[100%]">

    <div class="flex lg:flex-row lg:justify-between slg:flex-col slg:gap-y-4">
      <div class="flex flex-col flex-1">
        <div class="flex flex-row justify-between items-center">
          <h2>{{ experience.activity.name }} · <span>{{ experience.name }}</span></h2>
          <atc-tag
            *ngIf="experience.withAccommodation && (windowResource.width() | async | screen: 'small-desktop-above')"
            [tooltip]="getTooltipMandatory(experience)"
            atcTooltip
            color="danger"
            icon="fat-exclamation-circle"
            size="medium">Hébergement nécessaire
          </atc-tag>
        </div>
        <h3>Avec {{ experience.partnerName }}</h3>

        <section class="feature-list flex flex-row gap-[1rem] py-[.3rem]">
          <div class="feature-icon">
            <svg class="icon">
              <use href="assets/icons/icons.svg#group"></use>
            </svg>
            <p class="feature-txt">{{ packageUtils.ageMin(experience.packagesByPeriod(period)) }}
              - {{ packageUtils.ageMax(experience.packagesByPeriod(period)) }}
              ans
            </p>
          </div>

          <span class="w-[1px] h-[2rem] bg-[#7a7a7a]"></span>

          <div class="feature-icon">
            <svg class="icon">
              <use href="assets/icons/icons.svg#clock"></use>
            </svg>
            <p class="feature-txt">
              {{ experience.duration  | toHour }}
            </p>
          </div>
          <span class="w-[1px] h-[2rem] bg-[#7a7a7a]"></span>
          <div class="feature-icon">
            <svg class="icon">
              <use href="assets/icons/icons.svg#chart-bar"></use>
            </svg>
            <p class="feature-txt">
              {{ 'detail-station.activities.difficulty.' + experience.difficulty  | translate }}
            </p>
          </div>

        </section>
      </div>
    </div>

    <div class="pb-[1rem]">
      <atc-tag *ngIf="experience.withAccommodation && !(windowResource.width() | async | screen: 'small-desktop-above')"
               atcTooltip
               color="danger"
               icon="fat-exclamation-circle"
               size="medium"
               tooltip="Ajoutez d'abord un hébergement à votre panier pour louer du matériel.">Hébergement nécessaire
      </atc-tag>
    </div>

    <atc-picture-gallery (openPictures)="openPictures($event)"
                         [sizeGrid]="getSizeGrid(windowResource.width() | async)"
                         [slides]="experience.pictures"
                         class="picture-gallery mb-8 sm:max-w-[100%]">
    </atc-picture-gallery>

    <div *ngIf="!(windowResource.width() | async | screen: 'small-desktop-above')"
         class="gap-4 pt-8 flex flex-col">
      <atc-star-rating *ngIf="experience.review"
                       [nbRatings]="experience.nbReviews"
                       [rating]="experience.review">
      </atc-star-rating>
      <atc-card-add-to-cart (addActivityToCartTrigger)="registerParticipants($event)"
                            (editSlotsTrigger)="openTimeSlots()"
                            [activityType]="experience.activity.name"
                            [address]="experience.address"
                            [experience]=true
                            [hours]="getStartHour(experience)"
                            [name]="experience.name"
                            [nbParticipants]="participantsEligible.length"
                            [priceByPerson]="packageUtils.minPriceString(experience.packagesByPeriod(period), participantsEligible)"
                            [startDate]="getStartDate(experience)"
                            [totalPrice]="packageUtils.minPriceForAllParticipantsString(experience.packagesByPeriod(period), participantsEligible)">
      </atc-card-add-to-cart>
    </div>
    <div class="flex-row flex gap-x-6 mt-4">
      <div class="flex flex-col flex-[3] lg:flex-[2] max-w-fit sxl:max-w-[720px] slg:max-w-full lg:pb-4">
        <ng-container *ngIf="experience.description?.length">
          <h4 class="py-[1rem]">📖&nbsp; On vous raconte :</h4>
          <p [innerHTML]="experience.description"></p>
        </ng-container>

        <ng-container *ngIf="experience.weLove?.length">
          <h4 class="py-[1rem]">❤️&nbsp; On adore :</h4>
          <p [innerHTML]="experience.weLove"></p>
        </ng-container>

        <ng-container *ngIf="experience.predictable?.length">
          <h4 class="py-[1rem]">📅&nbsp; À prévoir :</h4>
          <p [innerHTML]="experience.predictable"></p>
        </ng-container>

        <div class="flex flex-row gap-x-8 justify-between slg:flex-col slg:gap-y-2">
          <div *ngIf="experience.included?.length" class="flex flex-col w-full lg:w-1/2">
            <h4 class="py-[1rem]">✔️&nbsp; Inclus dans la prestation :</h4>
            <p [innerHTML]="experience.included"></p>
          </div>

          <div *ngIf="experience.notIncluded?.length" class="flex flex-col w-full lg:w-1/2">
            <h4 class="py-[1rem]">❌&nbsp; Non inclus dans la prestation :</h4>
            <p [innerHTML]="experience.notIncluded"></p>
          </div>
        </div>

        <div *ngIf="sanitizedVideoUrl" class="vsk__inline vsk__player pt-4">
          <div class="items-center justify-center">
            <iframe [src]="sanitizedVideoUrl"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                    frameborder="0"
                    height="500"
                    title="Vidéo de présentation"
                    width="1000">
            </iframe>
          </div>
        </div>

        <div class="flex flex-col gap-y-6 mt-8">
          <h4>📍&nbsp;Localisation :</h4>
          <google-map (zoomChanged)="setZoom()"
                      [center]="center"
                      [options]="options"
                      [zoom]="zoom"
                      class="flex-1 map-experience"
                      height="300px"
          >
            <map-marker *ngIf="markerExperience"
                        [position]="markerExperience.getPosition()!"
                        [title]="markerExperience.getTitle()!"
            >
            </map-marker>
            <map-marker *ngIf="markerResort"
                        [icon]="markerResort.getIcon()!"
                        [position]="markerResort.getPosition()!"
                        [title]="markerResort.getTitle()!"
            >
            </map-marker>
          </google-map>
          <atc-button (click)="openItinerary(experience)"
                      [fullWidth]="true"
                      color="ternary"
                      icon="search"
                      label="Calculer mon temps de trajet"
                      size="medium">
          </atc-button>
        </div>
      </div>

      <div *ngIf="(windowResource.width() | async | screen: 'small-desktop-above')" class="info-content">

        <atc-star-rating *ngIf="experience.review"
                         [nbRatings]="experience.nbReviews"
                         [rating]="experience.review"
                         class="py-[1rem]">
        </atc-star-rating>

        <atc-card-add-to-cart (addActivityToCartTrigger)="registerParticipants($event)"
                              (editSlotsTrigger)="openTimeSlots()"
                              [activityType]="experience.activity.name"
                              [address]="experience.address"
                              [experience]=true
                              [hours]="getStartHour(experience)"
                              [name]="experience.name"
                              [nbParticipants]="participantsEligible.length"
                              [priceByPerson]="packageUtils.minPriceString(experience.packagesByPeriod(period), participantsEligible)"
                              [startDate]="getStartDate(experience)"
                              [totalPrice]="packageUtils.findMinPriceforMaxPackage(experience.packagesByPeriod(period), participantsEligible)">
        </atc-card-add-to-cart>
      </div>
    </div>

  </div>

  <vsk-experience-drawer *ngIf="showExperienceDrawer"
                         [(showDrawer)]="showExperienceDrawer"
                         [cart]="cart$ | async"
                         [currentDay]="currentDay"
                         [experience]="experience"
                         [participants]="participants"
                         [period]="period$ | async"
                         [resort]="resort$ | async">
  </vsk-experience-drawer>

  <atc-popup (closeChanged)="pictures = []"
             *ngIf="pictures.length"
             [currentSlide]="currentSlide"
             [showBody]="false"
             [slides]="pictures">
  </atc-popup>
</div>

<div *ngIf="experiencesCarousel.length > 1 && hasTimeslotsAvailable"
     class="bg-color-neutral-15 w-full pb-16 pt-8 sxl:px-8">
  <div class="mx-auto flex max-w-[1350px] flex-col">
    <h2 class="mb-4">Quelques expériences similaires que vous pourriez aimer</h2>

    <atc-carousel-products
      *ngIf="experiencesCarousel.length > 1 && (windowResource.width() | async | screen: 'small-desktop-above')"
      [products]="experiencesCarousel"
      target="_self">
    </atc-carousel-products>

    <div
      *ngIf="experiencesCarousel.length <= 1 || !(windowResource.width() | async | screen: 'small-desktop-above')"
      class="flex max-w-full flex-row gap-x-4 overflow-x-auto">
      <atc-card-product-presentation *ngFor="let experience of experiencesCarousel"
                                     [background]="experience.background"
                                     [description]="experience.description"
                                     [link]="experience.link"
                                     [price]="experience.price"
                                     [title]="experience.title"
                                     target="_self">
      </atc-card-product-presentation>
    </div>
  </div>
</div>

<vsk-footer [isHome]="false"></vsk-footer>
