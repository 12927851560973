export class InformationBanner {
  id: number;
  content: string;
  fontColor: string;
  backgroundColor: string;

  constructor(obj: Partial<InformationBanner>) {
    Object.assign(this, obj);
  }
}
