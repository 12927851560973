import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { ParticipantCart, WindowResource } from 'atomic-lib';
import { Observable } from 'rxjs';
import { CartItemSkiEquipment } from 'src/app/shared/models/ski-equipment/cart-item-equipment';
import {
  URL_PAYMENT,
  URL_RESORT,
  URL_SEARCH_ACCOMMODATIONS,
  URL_SEARCH_EXPERIENCES
} from '../../app-routing.module';
import { FiltersState } from '../../filters.state';
import {
  URL_RESORT_ACCOMMODATION,
  URL_RESORT_ACTIVITIES
} from '../../resort/resort-routing.module';
import { Establishment } from '../../shared/models/accommodation/establishment';
import { ItemAccommodation } from '../../shared/models/accommodation/item-accommodation';
import { Room } from '../../shared/models/accommodation/room';
import { Cart } from '../../shared/models/cart/cart';
import { EcoChart } from '../../shared/models/cart/eco-chart';
import { SkiPass } from '../../shared/models/enum/ski-pass.enum';
import { ItemCart } from '../../shared/models/package/item-cart';
import { Participant } from '../../shared/models/participant/participant';
import {
  ChangeCartDrawerState,
  RemoveItemAccommodationFromCart,
  RemoveItemsToCart,
  RemoveSkiEquipmentFromCart,
  UpdateEcoTourismChart
} from '../cart.action';

export interface EstablishmentRoom {
  establishment: Establishment;
  room: Room;
  resort: string;
}

export interface ExperienceLink {
  experienceId: number;
  resort: string;
}

@Component({
  selector: 'vsk-cart-content',
  templateUrl: './cart-content.component.html',
  styleUrls: ['./cart-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartContentComponent implements OnInit {
  @Select(FiltersState.isFoncia) isFoncia$: Observable<boolean>;

  public readonly mapItemsParticipants =
    CartContentComponent.mapItemsParticipants;

  isProposition = false;
  isEmpty = false;
  itemsAccommodation: ItemAccommodation[] = [];
  itemsActivity: ItemCart[] = [];
  itemsEquipements: ItemCart[] = [];
  itemsSkiEquipment: CartItemSkiEquipment[] = [];
  resort: string;
  datesReferences: ItemCart[] = [];
  ecoChart: EcoChart;
  ecoTourismChart: FormControl<boolean | null> = new FormControl<boolean>(
    false
  );

  @Input() loading = false;
  @Input() isPayment = false;
  @Input() canDeleteItem = true;
  @Input() canEditItem = true;

  @Output() redirectToEstablishmentChanged: EventEmitter<EstablishmentRoom> =
    new EventEmitter<EstablishmentRoom>();
  @Output() redirectToExperienceChanged: EventEmitter<ExperienceLink> =
    new EventEmitter<ExperienceLink>();
  @Output() redirectToMapChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() editExperienceChange: EventEmitter<ItemCart> =
    new EventEmitter<ItemCart>();

  constructor(
    private store: Store,
    public windowResource: WindowResource,
    private router: Router,
    private changeRef: ChangeDetectorRef
  ) {}

  @Input() set cart(cart: Cart) {
    if (!cart || cart.isEmpty) {
      this.isEmpty = true;
      this.itemsAccommodation = [];
      this.itemsActivity = [];
      this.itemsEquipements = [];
      this.itemsSkiEquipment = [];
      return;
    }

    this.resort = cart.getResort();
    this.isProposition = cart.isProposition;
    this.itemsAccommodation = cart.itemsAccommodation;
    this.itemsSkiEquipment = cart.itemsSkiEquipment;
    this.ecoChart = cart.ecoTourismChart;
    this.datesReferences = [];
    this.itemsActivity = cart.itemsActivity
      .filter((item) => !item.equipment)
      .sort((prev, curr) =>
        prev.startDate.isSameOrBefore(curr.startDate, 'minutes') ? -1 : 1
      );
    this.itemsEquipements = cart.itemsActivity.filter((item) => item.equipment);

    if (this.ecoChart && !cart.sessionId) {
      this.ecoTourismChart.setValue(this.ecoChart.isChecked);
    }

    this.isEmpty = false;
    this.changeRef.markForCheck();
  }

  @Input() set disableEcoChart(value: boolean) {
    if (value) {
      this.ecoTourismChart.disable();
    }
  }

  @Input() set isEcoTourismeChecked(value: boolean) {
    this.ecoTourismChart.setValue(value);
  }

  get isPaymentPage(): boolean {
    return this.router.url.includes(URL_PAYMENT);
  }

  public static mapItemsParticipants(
    participants: Partial<Participant>[]
  ): ParticipantCart[] {
    return participants
      .sort((prev, curr) => ((prev.index || 0) < (curr.index || 0) ? -1 : 1))
      .map((participant) => {
        return {
          name: participant.firstname,
          age: participant.age
        } as ParticipantCart;
      });
  }

  ngOnInit(): void {
    this.ecoTourismChart.valueChanges.subscribe((value) => {
      this.store.dispatch(
        new UpdateEcoTourismChart(new EcoChart(value ?? false))
      );
    });
  }

  deleteItem(item: ItemCart) {
    this.store.dispatch(new RemoveItemsToCart([item.uuid]));
    this.changeRef.markForCheck();
  }

  formatHour(item: ItemCart, isEnd = false) {
    if (item.internshipId) {
      return '';
    }

    const duration = item.item
      ? item.item.experience.duration
      : item.group?.packages[0].experience.duration;
    const clone = item.startDate.clone();
    return isEnd
      ? clone.add(duration, 'minutes').locale('fr').format('HH:mm')
      : clone.locale('fr').format('HH:mm');
  }

  nbCardToBuy(item: ItemCart) {
    if (!item.ski) {
      return 0;
    }

    return item.participants.filter((participant) => {
      if (item.skiPass?.type === SkiPass.SKIDATA) {
        return !(participant.cardSkidata && participant.hasCard);
      }

      return !(participant.cardAxess && participant.hasCard);
    }).length;
  }

  deleteItemAccommodation(item: ItemAccommodation) {
    this.loading = true;
    this.store
      .dispatch(new RemoveItemAccommodationFromCart(item.id))
      .subscribe(() => (this.loading = false));
  }

  redirectToEstablishment(
    establishment: Establishment,
    room: Room,
    resort: string,
    event: MouseEvent
  ) {
    event.stopPropagation();
    this.redirectToEstablishmentChanged.emit({
      establishment,
      room,
      resort
    });
  }

  redirectToExperience(
    experienceId: number,
    resort: string,
    event: MouseEvent
  ) {
    event.stopPropagation();
    this.redirectToExperienceChanged.emit({
      experienceId,
      resort
    });
  }

  deleteSkiEquipmentItem(item: CartItemSkiEquipment) {
    this.loading = true;
    this.store
      .dispatch(new RemoveSkiEquipmentFromCart(item.id))
      .subscribe(() => {
        this.loading = false;
        this.changeRef.markForCheck();
      });
  }

  shouldPrintDate(newItem: ItemCart) {
    if (this.datesReferences.find((item) => item.uuid === newItem.uuid)) {
      return true;
    }

    if (
      !this.datesReferences.find((item) =>
        item.startDate.isSame(newItem.startDate, 'day')
      )
    ) {
      this.datesReferences.push(newItem);
      return true;
    }

    return false;
  }

  selectedItem(
    item: ItemCart | ItemAccommodation | CartItemSkiEquipment,
    isSelected: boolean
  ) {
    const hasAccommodation = !!this.itemsAccommodation.filter(
      (itemAccommodation) => itemAccommodation.selectForm.value
    ).length;

    if (item instanceof CartItemSkiEquipment) {
      if (!hasAccommodation) {
        item.selectForm.setValue(false);
        return;
      }

      if (item.selectForm.value === isSelected) {
        item.selectForm.setValue(isSelected);
      }

      return;
    }

    if (item instanceof ItemCart && item.withAccommodation) {
      if (!hasAccommodation) {
        item.selectForm.setValue(false);
        return;
      }

      if (item.selectForm.value === isSelected) {
        item.selectForm.setValue(isSelected);
      }

      return;
    }

    if (item.selectForm.value !== isSelected) {
      item.selectForm.setValue(isSelected);
    }

    if (item instanceof ItemAccommodation) {
      const hasAccommodation = !!this.itemsAccommodation.filter(
        (itemAccommodation) => itemAccommodation.selectForm.value
      ).length;

      if (!hasAccommodation) {
        this.itemsSkiEquipment.forEach((itemSkiEquipment) =>
          itemSkiEquipment.selectForm.setValue(false)
        );

        this.itemsActivity.forEach((itemActivity) => {
          if (itemActivity.withAccommodation) {
            itemActivity.selectForm.setValue(false);
          }
        });
      }
    }
  }

  ecoChartPrice() {
    return this.ecoChart.isChecked
      ? "0€ <span class='ml-1  line-through text-gray-400'>15€<span>"
      : '15€';
  }

  close() {
    this.store.dispatch(new ChangeCartDrawerState(false));
  }

  getLinkActivities() {
    if (this.itemsAccommodation?.length) {
      return `/${URL_RESORT}/${URL_RESORT_ACTIVITIES}`;
    }

    return this.router.url.includes(`/${URL_RESORT}/`)
      ? `/${URL_RESORT}/${URL_RESORT_ACTIVITIES}`
      : `/${URL_SEARCH_EXPERIENCES}`;
  }

  getLinkAccommodations() {
    return this.router.url.includes(`/${URL_RESORT}/`)
      ? `/${URL_RESORT}/${URL_RESORT_ACCOMMODATION}`
      : `/${URL_SEARCH_ACCOMMODATIONS}`;
  }

  editItem(item: ItemCart) {
    this.editExperienceChange.emit(item);
  }

  getQueryParamActivity() {
    if (this.itemsAccommodation?.length) {
      const resort = this.itemsAccommodation[0].resort;
      return {
        station: resort
      };
    }

    return {};
  }
}
