import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Experience } from '../shared/models/activity/experience';
import { Criteria } from '../shared/models/criteria';
import { Resort } from '../shared/models/resort/resort';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ExperienceService extends RepositoryService {
  private URL_EXPERIENCE = 'api/webapp/experience';

  constructor(http: HttpClient) {
    super(http);
  }

  getExperienceById(
    criteria: Criteria,
    optionId: number
  ): Observable<Experience> {
    return this.post(
      this.URL_EXPERIENCE + '/by-id?id=' + optionId,
      criteria
    ).pipe(map((option) => new Experience({ ...option })));
  }

  getExperiencesByResort(
    criteria: Criteria,
    resort: Resort
  ): Observable<Experience[]> {
    return this.post(
      this.URL_EXPERIENCE +
        `/by-criteria?category=GENERAL&resortId=${resort.id}`,
      criteria
    ).pipe(
      map((experiences) =>
        experiences.map(
          (experience: Experience) => new Experience({ ...experience })
        )
      )
    );
  }

  getSimilarExpByActivity(
    criteria: Criteria,
    size = 30
  ): Observable<Experience[]> {
    return this.post(
      this.URL_EXPERIENCE + `/similar-activities?&page=0&size=${size}`,
      criteria
    ).pipe(
      map((experiences) =>
        experiences.map(
          (experience: Experience) => new Experience({ ...experience })
        )
      )
    );
  }
}
