import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FiltersExperienceSearch } from '../shared/models/activity/filters-experience-search';
import { FiltersAccommodationSearch } from '../shared/models/filters/filters-accommodation-search';
import { FiltersInfoAccommodation } from '../shared/models/filters/filters-info-accommodation';
import { FiltersInfoExperience } from '../shared/models/filters/filters-info-experience';
import { FiltersStation } from '../shared/models/filters/filters-station';
import { FiltersTripInfo } from '../shared/models/filters/filters-trip-info';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class FiltersService extends RepositoryService {
  private URL_FILTERS = 'api/webapp/filters';

  constructor(http: HttpClient) {
    super(http);
  }

  getFiltersMap(filtersStation: FiltersStation): Observable<FiltersTripInfo> {
    return this.post(this.URL_FILTERS + '/trip', filtersStation).pipe(
      map((filters) => new FiltersTripInfo({ ...filters }))
    );
  }

  getFiltersAccommodationInfo(
    filtersSearch: FiltersAccommodationSearch
  ): Observable<FiltersInfoAccommodation> {
    return this.post(this.URL_FILTERS + '/accommodation', filtersSearch).pipe(
      map((filters) => new FiltersInfoAccommodation({ ...filters }))
    );
  }

  getFiltersExperienceInfo(
    filters: FiltersExperienceSearch
  ): Observable<FiltersInfoExperience> {
    return this.post(this.URL_FILTERS + '/experience', filters).pipe(
      map((filters) => new FiltersInfoExperience({ ...filters }))
    );
  }
}
