import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AtomicLibModule, ScreenPipe } from 'atomic-lib';
import { SharedModule } from '../shared/shared.module';
import { NgxTranslateModule } from '../store/translate.module';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer.component';

@NgModule({
  declarations: [FooterComponent, ContactComponent],
  imports: [
    RouterModule,
    CommonModule,
    SharedModule,
    NgxTranslateModule,
    NgOptimizedImage,
    AtomicLibModule,
    ScreenPipe
  ],
  exports: [FooterComponent]
})
export class FooterModule {}
