import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement, WindowResource } from 'atomic-lib';
import { fromEvent } from 'rxjs';
import { FiltersStationChangeOrderBy } from '../../filters.action';
import { Activity } from '../../shared/models/activity/activity';
import { OrderBy } from '../../shared/models/const/order-by';
import { FiltersStation } from '../../shared/models/filters/filters-station';
import { FiltersTripInfo } from '../../shared/models/filters/filters-trip-info';
import { ResortLabel } from '../../shared/models/resort/resort-label';
import { ResortMap } from '../../shared/models/resort/resort-map';
import { Tag } from '../../shared/models/tag';

@Component({
  selector: 'vsk-side-map',
  templateUrl: './side-map.component.html',
  styleUrls: ['./side-map.component.scss']
})
export class SideMapComponent implements AfterViewInit {
  @ViewChild('scrollableContent') elementScroll: ElementRef<HTMLElement>;
  @ViewChild('drawerContainer') drawerContainer: ElementRef;

  showMobileFilters = false;
  ordersSelection: OptionElement<OrderBy>[] = [
    {
      id: OrderBy.RELEVANCE,
      label: 'Trier par pertinence',
      disabled: false
    },
    {
      id: OrderBy.PRICEASC,
      label: 'Prix croissant',
      disabled: false
    },
    {
      id: OrderBy.PRICEDESC,
      label: 'Prix décroissant',
      disabled: false
    }
  ];
  orderForm: FormControl<OrderBy | null> = new FormControl<OrderBy | null>(
    this.ordersSelection[0].id
  );
  resortsBorders: ResortMap[];
  remainingStations: ResortMap[];
  stationsFiltered: ResortMap[] = [];
  sticky = false;
  @Input() currentPage: number;
  @Input() totalStationsFiltered: number;
  @Input() allStations: ResortMap[];
  @Input() filters: FiltersStation;
  @Input() filtersMap: FiltersTripInfo;
  @Input() resortLabels: ResortLabel[];
  @Input() tags: Tag[];
  @Input() activities: Activity[];
  @Input() selectedActivities: Activity[];
  @Input() nbNights = 0;
  @Output() overMarker = new EventEmitter<google.maps.Marker>();
  @Output() outMarker = new EventEmitter<google.maps.Marker>();

  constructor(
    private store: Store,
    public windowResource: WindowResource
  ) {
    this.orderForm.valueChanges.subscribe((selectItem) => {
      if (selectItem) {
        this.store.dispatch(new FiltersStationChangeOrderBy(selectItem));
      }
    });
  }

  _loading = true;

  @Input() set loading(loading: boolean) {
    this._loading = loading;
    if (this._loading) {
      this.stationsFiltered = [];
    }
  }

  @Input() set remainingResortsMap(stations: ResortMap[]) {
    this.remainingStations = stations;
    this.stationsFiltered.concat(stations);
  }

  @Input() set resortsMap(stations: ResortMap[]) {
    this.resortsBorders = stations;
    this.stationsFiltered.concat(stations);
  }

  scrollOnTopPage() {
    if (this.drawerContainer) {
      this.drawerContainer.nativeElement.scrollTop = 0;
    }
    window.scrollTo(0, 0);
  }

  ngAfterViewInit(): void {
    fromEvent(this.elementScroll.nativeElement, 'scroll').subscribe(() => {
      this.sticky = this.elementScroll.nativeElement.scrollTop >= 130;
    });
  }

  isOverMarker(marker: google.maps.Marker | undefined): void {
    this.overMarker.emit(marker);
  }

  isOutMarker(marker: google.maps.Marker | undefined): void {
    this.outMarker.emit(marker);
  }

  filterResort(resort: ResortMap) {
    if (this.stationsFiltered.length === 0) {
      return true;
    }

    return !!this.stationsFiltered.find(
      (station) => station.name === resort.name
    );
  }
}
