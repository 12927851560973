import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import { Moment } from 'moment';
import { Observable, Subject, of } from 'rxjs';
import { CartState } from '../../cart/cart.state';
import { AccountResource } from '../../resource/account.resource';
import { WindowResource } from '../../resource/window.resource';
import { AccountService } from '../../service/account.service';
import { LocationService } from '../../service/location.service';
import { NavigateService } from '../../service/navigate.service';
import { RxjsComponent } from '../component/rxjs.component';
import { Account } from '../models/account';
import { Provider } from '../models/const/provider';
import { Login } from '../models/login';

interface LoginForm {
  email: FormControl<string | null>;
  password: FormControl<string | null>;
}

interface SignUpForm {
  firstname: FormControl<string | null>;
  lastname: FormControl<string | null>;
  birthdate: FormControl<Moment | null>;
  email: FormControl<string | null>;
  phone: FormControl<string | null>;
  password: FormControl<string | null>;
  address: FormControl<string | null>;
  postCode: FormControl<string | null>;
  city: FormControl<string | null>;
  country: FormControl<string | null>;
  newsletter: FormControl<boolean | null>;
}

@Component({
  selector: 'vsk-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent
  extends RxjsComponent
  implements AfterContentInit, OnInit
{
  @Select(CartState.isCartEmpty) isCartEmpty$: Observable<boolean>;

  user: SocialUser;
  step: 'connexion' | 'inscription' | 'reset' | 'password' = 'connexion';
  birthdate: Moment;
  provider = Provider.VERYSKI;
  result: any;
  loading = false;
  googleAuth: string = '';

  countries: OptionElement<string>[] = [
    {
      id: null,
      label: 'Sélectionnez un pays',
      disabled: true,
      classCss: 'disabled'
    }
  ];

  inscriptionForm: FormGroup<SignUpForm>;
  LoginForm: FormGroup<LoginForm>;
  firstNameForm = new FormControl<string>('', Validators.required);
  lastNameForm = new FormControl<string>('', Validators.required);
  birthdateForm = new FormControl<Moment | null>(null, Validators.required);
  passwordForm = new FormControl<string>('', [
    Validators.required,
    Validators.pattern(
      '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*()-]).{8,}$'
    )
  ]);
  passwordConnexionForm = new FormControl<string>('', Validators.required);
  passwordConfirmationForm = new FormControl<string>('', Validators.required);
  emailForm = new FormControl<string>('', [
    Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$'),
    Validators.required
  ]);
  phoneForm = new FormControl<string>('', [
    Validators.required,
    Validators.pattern('[0-9 ]{14}')
  ]);
  addressForm = new FormControl<string>('', Validators.required);
  cityForm = new FormControl<string>('', Validators.required);
  postCodeForm = new FormControl<string>('', Validators.required);
  countryForm = new FormControl<string>('France', Validators.required);
  cguCheckedForm = new FormControl<boolean>(false, Validators.required);
  newsletter = new FormControl<boolean>(false, Validators.required);

  error: string | undefined;
  $canGoBack = new Subject<boolean>();
  fieldTextType: boolean;

  @Input() goToPayment = false;
  @Output() closeChanged: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private authService: SocialAuthService,
    private accountService: AccountService,
    private accountResource: AccountResource,
    private filtersService: NavigateService,
    private router: Router,
    private locationService: LocationService,
    private changeRefDetector: ChangeDetectorRef,
    public windowResource: WindowResource
  ) {
    super();
    document.title = 'Connexion - VeryMountain';

    if (windowResource.isDesktop() && this.router.url.indexOf('login') !== -1) {
      this.router.navigate(['/']);
    }

    this.locationService.getCountriesNames().subscribe(
      (countries) =>
        (this.countries = countries.map((country) => {
          return {
            id: country,
            label: country
          } as OptionElement<string>;
        }))
    );
  }

  ngOnInit(): void {
    this.authService.authState.subscribe((user) => {
      this.register(
        this.accountService
          .isEmailExisting(user.email)
          .subscribe((provider) => {
            this.provider = Provider.GOOGLE;
            this.emailForm.setValue(user.email);
            this.firstNameForm.setValue(user.firstName);
            this.lastNameForm.setValue(user.lastName);

            if (this.provider === provider) {
              this.register(
                this.accountResource
                  .connectUser({
                    email: user.email,
                    password: 'vm' + user.id
                  })
                  .subscribe(
                    () => this.afterConnect(),
                    (err) => this.afterConnectError(err)
                  )
              );
            } else if (provider === Provider.VERYSKI) {
              this.googleAuth =
                'Ce compte ne peut pas être authentifié via Google';
              this.setStepValue('connexion');
            } else {
              this.googleAuth =
                'Complétez ces informations afin de finaliser votre inscription avec Google';
              this.passwordForm.clearValidators();
              this.passwordForm.setValidators(Validators.required);
              this.passwordForm.setValue('vm' + user.id);
              this.passwordConfirmationForm.setValue('vm' + user.id);
              this.setStepValue('inscription');
              this.changeRefDetector.markForCheck();
            }
          })
      );
    });
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.register(
        this.authService.authState.subscribe((user) => {
          this.user = user;
        })
      );

      this.inscriptionForm = new FormGroup<SignUpForm>({
        firstname: this.firstNameForm,
        lastname: this.lastNameForm,
        birthdate: this.birthdateForm,
        email: this.emailForm,
        phone: this.phoneForm,
        password: this.passwordForm,
        address: this.addressForm,
        city: this.cityForm,
        postCode: this.postCodeForm,
        country: this.countryForm,
        newsletter: this.newsletter
      });

      this.LoginForm = new FormGroup<LoginForm>({
        email: this.emailForm,
        password: this.passwordConnexionForm
      });
    });

    this.passwordForm.valueChanges.subscribe(() => {
      if (this.passwordForm.valid) {
        this.error = '';
      }
    });

    this.passwordConfirmationForm.valueChanges.subscribe(() => {
      if (this.passwordForm.value === this.passwordConfirmationForm.value) {
        this.error = '';
      }
    });
  }

  nextStep(): void {
    if (
      this.step === 'password' &&
      this.emailForm.valid &&
      this.emailForm.value !== null
    ) {
      this.error = undefined;
      this.loading = true;
      this.register(
        this.accountService.forgotPassword(this.emailForm?.value).subscribe(
          () => this.setStepValue('reset'),
          () => {
            this.error = "Cet email n'est lié à aucun compte";
            this.loading = false;
          }
        )
      );
    } else if (this.step === 'password') {
      this.error = 'Adresse email non conforme';
      this.loading = false;
    }

    if (
      this.isInscriptionValid() &&
      this.passwordForm.value === this.passwordConfirmationForm.value
    ) {
      this.error = undefined;
      this.loading = true;
      const account: Account = new Account({
        ...this.inscriptionForm.getRawValue(),
        provider: this.provider
      } as any);
      this.register(
        this.accountService.createAccount(account).subscribe(
          () => {
            this.register(
              this.accountResource
                .connectUser({
                  email: this.emailForm.value,
                  password: this.passwordForm.value
                })
                .subscribe(
                  () => this.afterConnect(),
                  (err) => this.afterConnectError(err)
                )
            );
          },
          () => {
            this.error = 'Un compte lié à cette adresse email existe déjà';
            this.loading = false;
          }
        )
      );
    } else if (this.step === 'inscription') {
      if (this.passwordForm.value !== this.passwordConfirmationForm.value) {
        this.error = 'Les mots de passe ne sont pas identiques';
        this.loading = false;
      } else {
        this.error = 'Des champs ne sont pas remplis ou de façon incorrect';
        this.loading = false;
      }
    }

    if (this.step === 'connexion' && this.LoginForm.valid) {
      this.loading = true;
      const identification: Login = new Login(this.LoginForm.getRawValue());
      this.register(
        this.accountResource.connectUser(identification).subscribe(
          () => this.afterConnect(),
          (err) => this.afterConnectError(err)
        )
      );
    }
  }

  isInscriptionValid(): boolean {
    return (
      this.step === 'inscription' &&
      this.inscriptionForm.valid &&
      !!this.cguCheckedForm.value
    );
  }

  forgotPassword(): void {
    this.setStepValue('password');
  }

  afterConnect(): void {
    this.loading = false;
    if (this.goToPayment) {
      this.filtersService
        .navigateWithQueryParams('/payment')
        .then(() => window.location.reload());
    } else {
      window.location.reload();
    }
  }

  afterConnectError(error: any): void {
    this.loading = false;
    this.changeRefDetector.markForCheck();
    switch (error.status) {
      case 401:
        this.error = 'Mot de passe incorrect';
        break;
      case 412:
        this.error = "Le compte associé à cet email n'est pas activé";
        break;
      default:
        this.error =
          'Une erreur est survenue, veuillez réessayer dans quelques instants';
        break;
    }
  }

  setStepValue(
    newStep: 'connexion' | 'inscription' | 'reset' | 'password'
  ): void {
    this.step = newStep;
    this.loading = false;
    if (newStep === 'connexion') {
      this.$canGoBack.next(false);
    } else {
      this.$canGoBack.next(true);
    }
  }

  close(): Observable<void> {
    return of();
  }

  checkPasswordValidity(): void {
    if (!this.passwordForm.valid) {
      this.error =
        'Le mot de passe doit contenir au moins 8 caractères dont au moins 1 majuscule, 1 minuscule, 1 caractère spécial (#?!@$%^&-*()) et 1 chiffre';
    }
  }

  checkSamePassword(): void {
    if (this.passwordForm.value !== this.passwordConfirmationForm.value) {
      this.error = 'Les mots de passe ne sont pas identiques';
    }
  }

  birthdateChanged(date: moment.Moment) {
    if (date && date.isValid()) {
      this.birthdateForm.setValue(date);
    }
  }
}
