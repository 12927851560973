import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ConnectUser } from '../app.action';
import { RxjsComponent } from '../shared/component/rxjs.component';
import { Login } from '../shared/models/login';

/**
 * Service handling the account state
 */
@Injectable()
export class AccountResource extends RxjsComponent {
  constructor(private store: Store) {
    super();
  }

  connectUser(login: Login): Observable<void> {
    return this.store.dispatch(new ConnectUser(login));
  }
}
