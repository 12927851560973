import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { AccountService } from '../../service/account.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { UserSession } from '../../shared/models/user-session';

@Component({
  selector: 'vsk-mobile-delete-account',
  templateUrl: './mobile-delete-account.component.html',
  styleUrls: ['./mobile-delete-account.component.scss']
})
export class MobileDeleteAccountComponent extends RxjsComponent {
  @Select(AppState.user) user$: Observable<UserSession>;

  constructor(
    private location: Location,
    private router: Router,
    private accountService: AccountService
  ) {
    super();
    document.title = 'Confirmation de suppression du compte - VeryMountain';
  }

  closeWindow(): void {
    this.location.back();
  }

  deleteAccount(): void {
    this.register(
      this.user$
        .pipe(
          switchMap((user) => this.accountService.deleteAccount(user.email))
        )
        .subscribe(() => {
          this.router.navigate(['/']);
          window.location.reload();
        })
    );
  }
}
