import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppState } from '../app.state';
import { CartState } from '../cart/cart.state';
import { AccountService } from '../service/account.service';

@Component({
  selector: 'vsk-confirmation-registration',
  templateUrl: './confirmation-registration.component.html',
  styleUrls: ['./confirmation-registration.component.scss']
})
export class ConfirmationRegistrationComponent {
  @Select(CartState.isCartEmpty) isCartEmpty$: Observable<boolean>;
  @Select(AppState.isUserLoggedIn) loggedIn$: Observable<boolean>;

  openLoginPopup = false;

  constructor(
    accountService: AccountService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    document.title = 'Confirmation de création de compte - VeryMountain';

    this.route.queryParams
      .pipe(switchMap((value) => accountService.register(value.token)))
      .subscribe();
  }

  redirect() {
    const currentUrl = this.location.path();
    this.location.back();
    const newUrl = this.location.path();
    setTimeout(() => {
      if (currentUrl === newUrl) {
        this.router.navigate(['/'], { queryParamsHandling: 'merge' });
      }
    }, 1000);
  }

  goToPayment() {
    this.router.navigate(['/payment']);
  }
}
