export class UrlUtils {
  public static encodeToURL(name: string) {
    if (!name) {
      return '';
    }

    return name
      .toLowerCase() // Mettre en minuscules
      .replace(/'/g, '-') // Retirer les apostrophes
      .replace(/\//g, '-') // Retirer les apostrophes
      .replace(/ ·/g, '') // Remplacer les espaces par des tirets
      .replace(/\s+/g, '-') // Remplacer les espaces par des tirets
      .normalize('NFD') // Normaliser pour enlever les accents
      .replace(/[\u0300-\u036f]/g, ''); // Supprimer les diacritiques
  }
}
