import { Origin } from '../enum/origin.enum';

export class AccommodationPartner {
  origin: Origin;
  name: string;
  partnerCode: string;
  totalRooms: number;

  constructor(obj: Partial<AccommodationPartner>) {
    Object.assign(this, obj);
  }
}
