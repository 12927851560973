<atc-popup (closeChanged)="goBack()"
           [height]="'100%'"
           [width]="'100%'"
           [withImg]="false">
  <div class="w-full" header>
    <h1>{{ getSearchModeLabel() }}</h1>
    <h2>Filtrer par vos préférences</h2>
  </div>

  <div body>
    <atc-expansion-card (expandedChange)="changeStep(SearchMobileStep.DATES, $event)"
                        [expanded]="isCurrentStep(SearchMobileStep.DATES)"
                        [preventCloseOnClickOutside]="true"
                        [subtitle]="subtitlesDates()"
                        icon="calendar"
                        title="Dates">
      <vsk-form-date-range-period-picker (datesChanged)="datesChanged.emit($event)"
                                         (nextStep)="nextStep()"
                                         [maxDate]="maxDate"
                                         [minDate]="minDate"
                                         [period]="period"
                                         [responsive]="true"
                                         [searchMode]="searchMode">
      </vsk-form-date-range-period-picker>
    </atc-expansion-card>

    <atc-expansion-card (expandedChange)="changeStep(SearchMobileStep.ACTIVITIES, $event)"
                        *ngIf="searchMode !== 'accommodation' && (isFoncia$ | async) === false"
                        [expanded]="isCurrentStep(SearchMobileStep.ACTIVITIES)"
                        [preventExpand]="preventExpandActivities()"
                        [subtitle]="subtitlesActivities()"
                        [tags]="activitiesNamesSelected()"
                        icon="activity"
                        title="Activités">
      <vsk-form-activity (validate)="nextStep()"
                         [activitiesByCategories]="activitiesByCategory"
                         [activities]="activities"
                         [responsive]="true">
      </vsk-form-activity>
    </atc-expansion-card>

    <atc-expansion-card (expandedChange)="changeStep(SearchMobileStep.ACTIVITIES, $event)"
                        *ngIf="searchMode === 'accommodation'"
                        [expanded]="isCurrentStep(SearchMobileStep.ACTIVITIES)"
                        icon="mountain"
                        subtitle="Filtrer par stations"
                        title="Stations">
      <vsk-form-resort (validate)="nextStep()"
                       [resorts]="resorts"
                       [responsive]="true">
      </vsk-form-resort>
    </atc-expansion-card>

    <atc-expansion-card [expanded]="isCurrentStep(SearchMobileStep.PARTICIPANTS)"
                        [subtitle]="subtitlesParticipants()"
                        icon="group"
                        title="Voyageurs">
      <vsk-form-manage-participant (search)="search()"
                                   [activities]="activities"
                                   [participants]="participants"
                                   [responsive]="true">
      </vsk-form-manage-participant>
    </atc-expansion-card>

    <atc-expansion-card *ngIf="searchMode === 'all' && (isFoncia$ | async) === false"
                        [expanded]="isCurrentStep(SearchMobileStep.PARTICIPANTS)"
                        [subtitle]="subtitleMoreFilters"
                        class="mb-28"
                        icon="filters"
                        title="Je recherche">
      <vsk-more-filters [responsive]="true"
                        [withMaterial]="withMaterial"
                        [withSkiPackage]="withSkiPackage">
      </vsk-more-filters>
    </atc-expansion-card>

    <div class="flex flex-col w-full fixed bottom-0 left-0 right-0 bg-white p-4 gap-4">
      <atc-tag *ngIf="hasAlterParticipants"
               class="mx-auto"
               color="danger">
        Attention, les participants modifiés risques de modifier votre panier
      </atc-tag>
      <atc-button (click)="search()"
                  [fullWidth]="true"
                  [label]="getLabel()"
                  class="flex"
                  color="secondary"
                  icon="search">
      </atc-button>
      <ng-container *ngIf="currentPage === 'Resort' && (isFoncia$ | async) === false">
        <atc-button (click)="goTo(URL_SEARCH_TRIP)"
                    [fullWidth]="true"
                    class="flex"
                    color="ternary"
                    icon="pinpoint"
                    label="Découvrir d'autres destinations">
        </atc-button>
      </ng-container>
    </div>
  </div>
</atc-popup>
