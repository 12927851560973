import moment, { Moment } from 'moment';
import { ActivityCategory } from './activity-category';

export class Activity {
  id: number;
  name: string;
  urlIcon: string;
  selected = false;
  dateStart: Moment;
  dateEnd: Moment;
  category: ActivityCategory;

  constructor(obj: Partial<Activity>) {
    Object.assign(this, obj);
    this.dateStart = moment(this.dateStart);
    this.dateEnd = moment(this.dateEnd);
    this.category = new ActivityCategory({ ...this.category });
  }
}
