import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ItemAccommodation } from '../shared/models/accommodation/item-accommodation';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class CartAccommodationService extends RepositoryService {
  private URL_ACCOMMODATION = 'api/webapp/cart-accommodation';

  constructor(http: HttpClient) {
    super(http);
  }

  addItems(
    itemAccommodation: ItemAccommodation
  ): Observable<ItemAccommodation[]> {
    return this.post(
      `${this.URL_ACCOMMODATION}/add-accommodation`,
      itemAccommodation
    ).pipe(
      map((itemsAccommodation: ItemAccommodation[]) =>
        itemsAccommodation
          ? itemsAccommodation.map((item) => new ItemAccommodation({ ...item }))
          : []
      )
    );
  }

  updateItems(
    itemsAccommodation: ItemAccommodation[],
    sessionId: string
  ): Observable<ItemAccommodation[]> {
    return this.put(
      `${this.URL_ACCOMMODATION}/update-accommodations?sessionId=${sessionId}`,
      itemsAccommodation
    ).pipe(
      map((itemsAccommodation: ItemAccommodation[]) =>
        itemsAccommodation
          ? itemsAccommodation.map((item) => new ItemAccommodation({ ...item }))
          : []
      )
    );
  }

  getItems(
    sessionId: string,
    isPayed = false
  ): Observable<ItemAccommodation[]> {
    return this.get(
      `${this.URL_ACCOMMODATION}/get-accommodations?sessionId=${sessionId}&isPayed=${isPayed}`
    ).pipe(
      map((itemsAccommodation: ItemAccommodation[]) =>
        itemsAccommodation
          ? itemsAccommodation.map((item) => new ItemAccommodation({ ...item }))
          : []
      )
    );
  }

  deleteItem(id: string): Observable<ItemAccommodation[]> {
    return this.post(`${this.URL_ACCOMMODATION}/delete-accommodation`, id).pipe(
      map((itemsAccommodation: ItemAccommodation[]) =>
        itemsAccommodation
          ? itemsAccommodation.map((item) => new ItemAccommodation({ ...item }))
          : []
      )
    );
  }
}
