<div *ngIf="promoCode"
     class="flex flex-row {{ !paymentPage || !windowResource.isDesktop() ? 'justify-center' : 'justify-end' }} items-center">
  <p *ngIf="isPromoCodeApplied() as text" class="{{ colorMsg }} mr-2">{{ text }}</p>
  <atc-tag [color]="colorMsg">{{ promoCode?.name }} : {{ promoCode?.displayAppliedNumber }}</atc-tag>
  <atc-button (click)="deletePromoCode()"
              color="simple"
              icon="trash">
  </atc-button>
</div>

<div *ngIf="!promoCode" class="vsk__inline">
  <atc-input (enterKeyPressed)="addPromoCode()"
             [control]="control"
             placeholder="Code promotionnel">
  </atc-input>
  <atc-button (click)="addPromoCode()"
              color="ternary"
              icon="add">
  </atc-button>
</div>
