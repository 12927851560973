import {
  Directive,
  ElementRef,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';
import { filter, fromEvent, tap } from 'rxjs';

@Directive({
  selector: '[vskScrollVisibility]'
})
export class ScrollVisibilityDirective implements OnInit {
  @Output() visible = new EventEmitter<void>();
  private threshold = 100;
  private hasLoaded = false;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    fromEvent(window, 'scroll')
      .pipe(
        filter(() => !this.hasLoaded),
        tap(() => {
          if (this.isVisible()) {
            this.visible.emit();
            this.hasLoaded = true;
          }
        })
      )
      .subscribe();
  }

  private isVisible(): boolean {
    const rect = this.el.nativeElement.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const windowWidth =
      window.innerWidth || document.documentElement.clientWidth;

    const vertInView =
      rect.top - this.threshold <= windowHeight && rect.top + rect.height >= 0;
    const horInView =
      rect.left - this.threshold <= windowWidth && rect.left + rect.width >= 0;

    return vertInView && horInView;
  }
}
