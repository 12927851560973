import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ShopService extends RepositoryService {
  private URL_SHOP = 'api/webapp/shop';

  constructor(http: HttpClient) {
    super(http);
  }

  public syncUserAndSession(
    sessionId: string,
    resortName: string
  ): Observable<void> {
    return this.get(
      this.URL_SHOP +
        '/sync-user-cart-session?sessionId=' +
        sessionId +
        '&resort=' +
        resortName
    );
  }
}
