import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaDescriptionService {
  private renderer: Renderer2;

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  updateMeta(title: string, description: string, url: string): void {
    this.titleService.setTitle(title);
    this.updateTag('description', description);
    this.updateCanonicalUrl(url);
  }

  private updateCanonicalUrl(url: string): void {
    const fullUrl = `https://verymountain.fr/${url}`;
    let link: HTMLLinkElement | null = this.doc.querySelector(
      "link[rel='canonical']"
    );

    if (link) {
      this.renderer.setAttribute(link, 'href', fullUrl);
    } else {
      link = this.renderer.createElement('link');
      this.renderer.setAttribute(link, 'rel', 'canonical');
      this.renderer.setAttribute(link, 'href', fullUrl);
      this.renderer.appendChild(this.doc.head, link);
    }
  }

  private updateTag(name: string, content: string): void {
    const tag = this.metaService.getTag(`name="${name}"`);
    if (tag) {
      this.metaService.updateTag({ name, content });
    } else {
      this.metaService.addTag({ name, content });
    }
  }
}
