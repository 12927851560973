<atc-side-drawer (closeChange)="closeMenu()"
                 *ngIf="showMenu"
                 bgColor="grey"
                 side="right">
  <!-- body -->
  <div body>
    <a (click)="closeMenu()" [queryParams]="" class="vsk__monogram" routerLink="/" routerLinkActive="true">
      <img alt="Logo" atcLazyLoad src="assets/images/monogramme.png">
    </a>
    <div class="grid grid-cols-2 gap-4 p-8">
      <!-- Search -->
      <div (click)="goToSearch()"
           class="bg-white p-8 leaf flex flex-col gap-4">
        <svg class="max-h-16 w-16 stroke-black m-auto">
          <use [attr.xlink:href]="'assets/icons/icons.svg#search'"></use>
        </svg>
        <label>Rechercher</label>
      </div>

      <!-- Login -->
      <div (click)="goToLogin()"
           *ngIf="(isLoggedIn$ | async) === false"
           class="bg-white p-8 leaf flex flex-col gap-4">
        <svg class="max-h-16 w-16 stroke-black m-auto">
          <use [attr.xlink:href]="'assets/icons/icons.svg#person'"></use>
        </svg>
        <label>Se connecter</label>
      </div>

      <ng-container *ngIf="isLoggedIn$ | async">
        <!-- Informations -->
        <div (click)="goTo('/my-account/information')"
             class="bg-white p-8 leaf flex flex-col gap-4">
          <svg class="max-h-16 w-16 stroke-black m-auto">
            <use [attr.xlink:href]="'assets/icons/icons.svg#person'"></use>
          </svg>
          <label>Mes informations</label>
        </div>

        <!-- Orders -->
        <div (click)="goTo('/my-account/orders')"
             class="bg-white p-8 leaf flex flex-col gap-4">
          <svg class="max-h-16 w-16 stroke-black m-auto">
            <use [attr.xlink:href]="'assets/icons/icons.svg#cart'"></use>
          </svg>
          <label>Mes commandes</label>
        </div>

        <!-- Ski pass -->
        <div (click)="goTo('/my-account/pass')"
             class="bg-white p-8 leaf flex flex-col gap-4">
          <svg class="max-h-16 w-16 stroke-black m-auto">
            <use [attr.xlink:href]="'assets/icons/icons.svg#ticket'"></use>
          </svg>
          <label>Mes cartes physiques</label>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- footer -->
  <div *ngIf="isLoggedIn$ | async" class="flex w-full p-8 justify-center items-center" footer>
    <div (click)="logout()"
         class="m-auto flex flex-row px-4 py-2 rounded-[30px] border-black justify-center items-center">
      <svg class="max-h-6 w-6 stroke-black">
        <use [attr.xlink:href]="'assets/icons/icons.svg#logout'"></use>
      </svg>
      <label class="label-footer">Se déconnecter</label>
    </div>
  </div>
</atc-side-drawer>

<vsk-login (closeChanged)="openLoginPopup = false" *ngIf="openLoginPopup">
</vsk-login>
