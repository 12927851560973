import { RoomFeature } from './room-feature';
import { RoomProposal } from './room-proposal';

export class Room {
  partnerCode: string;
  name: string;
  codeRoom: string;
  description: string;
  surface: number;
  totalBeds: number;
  minPeople: number;
  maxPeople: number;
  category: string;
  pictures: string[];
  features: RoomFeature[] = [];
  proposals: RoomProposal[] = [];
  spa: boolean;
  sauna: boolean;
  hammam: boolean;
  balconyTerrace: boolean;
  tv: boolean;
  chimney: boolean;
  bbq: boolean;
  pmr: boolean;
  parking: boolean;
  animalsAdmitted: boolean;

  constructor(obj: Partial<Room>) {
    Object.assign(this, obj);
  }
}
