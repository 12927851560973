import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { WindowResource } from 'atomic-lib';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FiltersState } from 'src/app/filters.state';
import { SetFiltersExperiences } from '../../filters.action';
import { UrlManagerResource } from '../../resource/url-manager.resource';
import { FiltersService } from '../../service/filters.service';
import { MetaDescriptionService } from '../../service/meta-description.service';
import { ExperienceTemplateComponent } from '../../shared/component/experience-template.component';
import { FiltersExperienceSearch } from '../../shared/models/activity/filters-experience-search';
import { ToHourPipe } from '../../shared/pipe/to-hour.pipe';
import { UrlUtils } from '../../utils/url-utils';

@Component({
  selector: 'vsk-resort-activity',
  templateUrl: './resort-activity.component.html',
  styleUrls: ['./resort-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResortActivityComponent extends ExperienceTemplateComponent {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @Select(FiltersState.isFoncia) isFoncia$: Observable<boolean>;

  constructor(
    public windowResource: WindowResource,
    protected store: Store,
    protected hourPipe: ToHourPipe,
    protected translate: TranslateService,
    protected changeDetectionRef: ChangeDetectorRef,
    protected filtersService: FiltersService,
    protected activatedRoute: ActivatedRoute,
    public elRef: ElementRef,
    protected urlManager: UrlManagerResource,
    private metaDescriptionService: MetaDescriptionService,
    public router: Router
  ) {
    super(
      windowResource,
      store,
      hourPipe,
      translate,
      changeDetectionRef,
      filtersService,
      activatedRoute,
      urlManager,
      router
    );
    this.pageResort = true;

    this.register(
      this.resort$.pipe(filter((resort) => !!resort)).subscribe((resort) => {
        this.resortName = resort.name;
        const title = `Activités à ${resort.name} · VeryMountain`;
        const description = `Découvrez les meilleures activités à ${resort.name} pour de véritables vacances : activités à sensations, familiales ou instant bien-être en amoureux`;
        const url = `station/${UrlUtils.encodeToURL(resort.name)}/activites`;
        this.metaDescriptionService.updateMeta(title, description, url);
      })
    );
  }

  clearFilters() {
    this.filters = new FiltersExperienceSearch({
      ...this.filters,
      geoBoundsActive: false,
      priceRange: {
        min: 0,
        max: 1000
      },
      durationRange: {
        min: 0,
        max: 1000
      },
      difficulties: [],
      activities: [],
      heart: false,
      promo: false,
      resorts: []
    });

    this.store.dispatch(new SetFiltersExperiences(this.filters));
  }
}
