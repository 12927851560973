import { Component, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { WindowResource } from 'atomic-lib';
import { Observable } from 'rxjs';
import { FiltersState } from '../filters.state';

@Component({
  selector: 'vsk-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Select(FiltersState.isFoncia) isFoncia$: Observable<boolean>;

  openContact = false;
  @Input() isHome = true;

  constructor(public windowResource: WindowResource) {}

  /**
   * Open contact popup
   */
  openCloseContact(): void {
    this.openContact = !this.openContact;
  }
}
