import { Component } from '@angular/core';

@Component({
  selector: 'vsk-selling-conditions',
  templateUrl: './selling-conditions.component.html',
  styleUrls: ['./selling-conditions.component.scss']
})
export class SellingConditionsComponent {
  constructor() {
    document.title = 'Conditions générales de ventes - VeryMountain';
  }
}
