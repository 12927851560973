<ng-container *ngIf="ordersHistory">
  <h3>{{title}}</h3>
  <div *ngIf="ordersHistory.length === 0" class="vsk__margin">
    <p>Aucune commande passée pour le moment</p>
  </div>

  <div *ngFor="let order of ordersHistory" class="flex space-between w-full
              md:flex-col md:gap-4
              xl:flex-row xl:justify-center xl:items-center">
    <p class="flex-1">Date d'achat : <span>{{order.buyDate.locale('fr').format('ddd DD MMM YYYY') | titlecase}}</span>
    </p>
    <div class="flex flex-row gap-4
                md:justify-between md:border-b-gray-300 md:border-b-2 md:pb-4">
      <atc-button (click)="openDetails(order)"
                  [fullWidth]="true"
                  class="md:flex md:w-full"
                  color="secondary"
                  label="Voir le détail"></atc-button>
      <!--      <atc-button label="Imprimer un reçu"-->
      <!--                  color="ternary"-->
      <!--                  class="md:flex md:w-full"-->
      <!--                  [fullWidth]="true"-->
      <!--                  (click)="downloadReceipt(order.transactionId)"></atc-button>-->
    </div>
  </div>
</ng-container>

<vsk-history-order (showCartChange)="openChange($event)"
                   [order]="order"
                   [showCart]="showItemsSession">
</vsk-history-order>

