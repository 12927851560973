import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement, WindowResource } from 'atomic-lib';
import { FiltersExperienceSearch } from '../../models/activity/filters-experience-search';
import { DifficultyExperience } from '../../models/enum/difficulty-experience.enum';
import { FiltersInfoExperience } from '../../models/filters/filters-info-experience';

@Component({
  selector: 'vsk-experience-filters-popup',
  templateUrl: './experience-filters-popup.component.html',
  styleUrls: ['./experience-filters-popup.component.scss']
})
export class ExperienceFiltersPopupComponent {
  @Input() filters: FiltersExperienceSearch;
  @Input() filterResorts = false;
  @Input() experienceDifficulties: OptionElement<DifficultyExperience>[] = [];
  @Input() resortsElements: OptionElement<number>[] = [];
  @Input() activitiesElements: OptionElement<number>[] = [];
  @Input() heartForm: FormControl<boolean | null> = new FormControl<boolean>(
    false
  );
  @Output() validateFiltersChanged: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() closeChanged: EventEmitter<void> = new EventEmitter<void>();
  minValueRangePrice: number;
  maxValueRangePrice: number;
  minValueRangeDuration: number;
  maxValueRangeDuration: number;

  constructor(
    public windowResource: WindowResource,
    public store: Store
  ) {}

  @Input() set filtersInfo(filters: FiltersInfoExperience) {
    if (filters) {
      this.minValueRangePrice = filters.minPrice;
      this.maxValueRangePrice = filters.maxPrice;
      this.minValueRangeDuration = filters.minDuration;
      this.maxValueRangeDuration = filters.maxDuration;
    }
  }

  getControl(control: FormControl<boolean | null> | undefined) {
    return control as FormControl<boolean | null>;
  }
}
