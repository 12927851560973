import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Store } from '@ngxs/store';
import {
  StripeElements,
  StripeElementsOptionsClientSecret,
  StripePaymentElement
} from '@stripe/stripe-js';
import { RadioControl, WindowResource } from 'atomic-lib';
import moment from 'moment';
import { Moment } from 'moment/moment';
import { StripeService } from 'ngx-stripe';
import { environment } from '../../../environments/environment';
import { DepositMode } from '../../cart/cart.action';
import { CartState } from '../../cart/cart.state';
import { FiltersState } from '../../filters.state';
import { PaymentService } from '../../service/payment.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { Cart } from '../../shared/models/cart/cart';
import { PaymentSetup } from '../../shared/models/stripe/payment-setup';

@Component({
  selector: 'vsk-step-payment',
  templateUrl: './step-payment.component.html',
  styleUrls: ['./step-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepPaymentComponent extends RxjsComponent {
  @Input() error: string | undefined;
  @Input() startDate: Moment;

  @Output() payEvent = new EventEmitter<boolean>();
  @Output() previous = new EventEmitter<boolean>();
  @Output() paymentSuccess = new EventEmitter<boolean>();

  loading = false;
  isDesktop = true;
  showCart = false;
  setup: PaymentSetup;
  elements: StripeElements;
  paymentElement: StripePaymentElement;
  cart: Cart;
  payMode: 'DEPOSIT' | 'FULL' = 'FULL';
  payModeRadios: RadioControl<'DEPOSIT' | 'FULL'>[] = [
    {
      name: 'Payer en une fois',
      value: 'FULL',
      selected: true
    },
    {
      name: 'Payer un acompte de 30%',
      value: 'DEPOSIT',
      selected: false
    }
  ];

  constructor(
    private stripeService: StripeService,
    private paymentService: PaymentService,
    public windowResource: WindowResource,
    private store: Store,
    private changeRef: ChangeDetectorRef
  ) {
    super();
  }

  @Input() set initForm(cart: Cart) {
    if (
      cart &&
      (this.cart?.getPriceWithPromo() !== cart.getPriceWithPromo() ||
        cart.deposit !== this.cart.deposit)
    ) {
      this.cart = cart;

      if (cart.getPriceWithPromo() < 300 && cart.deposit) {
        this.store.dispatch(new DepositMode(false));
        return;
      }

      this.loading = true;

      const promoCode = this.store.selectSnapshot(CartState.promoCode);
      const sessionId = this.store.selectSnapshot(FiltersState.sessionId);
      const isDeposit = this.payMode === 'DEPOSIT';
      const ecoTourismChart = this.store.selectSnapshot(
        CartState.isEcoTourismChartChecked
      );

      this.paymentService
        .initIntentPayment(
          promoCode?.name as string,
          sessionId,
          ecoTourismChart,
          isDeposit
        )
        .subscribe(
          (setup) => {
            if (setup) {
              this.setup = setup;
              const options: StripeElementsOptionsClientSecret = {
                clientSecret: this.setup.paymentIntent,
                appearance: {
                  theme: 'stripe'
                },
                locale: 'fr-FR'
              };

              this.stripeService.elements(options).subscribe(
                (elements) => {
                  this.elements = elements;
                  this.paymentElement = this.elements.create('payment', {
                    paymentMethodOrder: this.getPaymentOptions(isDeposit)
                  });
                  this.paymentElement.mount('#payment-element');
                  this.loading = false;
                  this.changeRef.markForCheck();
                },
                () => window.location.reload()
              );
            }
          },
          (error) => {
            if (error) {
              // Error creating the token
              this.error = error?.message;
            }
          }
        );
    }
  }

  pay(): void {
    this.error = '';
    this.payEvent.emit(true);

    const promoCode = this.store.selectSnapshot(CartState.promoCode);
    const sessionId = this.store.selectSnapshot(FiltersState.sessionId);

    let args = '?sessionIdPaid=' + sessionId;

    if (promoCode) {
      args += '&promoCode=' + promoCode.name;
    }

    this.elements.submit().then((value) => {
      if (value.error) {
        this.error = value.error.message;
        this.payEvent.emit(false);
        this.changeRef.markForCheck();
        return;
      }

      this.register(
        this.stripeService
          .confirmPayment({
            elements: this.elements,
            clientSecret: this.setup.paymentIntent,
            confirmParams: {
              return_url: environment.redirectPayment + args
            }
          })
          .subscribe(
            (value) => {
              this.error = value.error.message;
              this.payEvent.emit(false);
              this.changeRef.markForCheck();
            },
            () => this.handleErrorPackages()
          )
      );
    });
  }

  handleErrorPackages(): void {
    this.payEvent.emit(false);
    this.error =
      'Une erreur est survenue lors du paiement, veuillez réessayer dans quelques instants';
    this.changeRef.markForCheck();
  }

  canPayDeposit() {
    return (
      this.cart.getPriceWithPromo() > 300 &&
      this.startDate.isSameOrAfter(moment().add(46, 'day'))
    );
  }

  updatePayMode(control: RadioControl<'DEPOSIT' | 'FULL'>) {
    this.payMode = control.value;
    this.store.dispatch(new DepositMode(control.value === 'DEPOSIT'));
  }

  private getPaymentOptions(isDeposit: boolean) {
    const options = ['card'];

    if (!isDeposit) {
      options.push('apple_pay', 'google_pay');
    }

    return options;
  }
}
