import moment from 'moment';
import { Participant } from '../shared/models/participant/participant';

export class ParticipantUtils {
  public static mapToParticipant(
    sessionId: string,
    nbAdults: number | null,
    nbChildren?: number,
    nbSenior?: number
  ) {
    let participants: Participant[] = [];

    if (nbAdults) {
      participants = ParticipantUtils.loopOverParticipants(
        nbAdults,
        participants,
        sessionId,
        30
      );
    }

    if (nbChildren) {
      participants = ParticipantUtils.loopOverParticipants(
        nbChildren,
        participants,
        sessionId,
        10
      );
    }

    if (nbSenior) {
      participants = ParticipantUtils.loopOverParticipants(
        nbSenior,
        participants,
        sessionId,
        70
      );
    }

    return participants;
  }

  public static loopOverParticipants(
    nbLoops: number,
    participants: Participant[],
    sessionId: string,
    age: number
  ) {
    for (let i = 0; i < nbLoops; i++) {
      participants.push(
        ParticipantUtils.initParticipant(
          sessionId,
          age,
          participants.length + 1
        )
      );
    }

    return participants;
  }

  public static initParticipant(sessionId: string, age: number, index: number) {
    return new Participant({
      index,
      sessionId,
      firstname: `Voyageur ${index}`,
      birthdate: moment().add(-age, 'years')
    });
  }

  static getNbAdults(participants: Participant[]) {
    return participants.filter(
      (participant) => participant.age >= 18 && participant.age < 65
    ).length;
  }

  static getNbChildren(participants: Participant[]) {
    return participants.filter(
      (participant) => participant.age >= 0 && participant.age < 18
    ).length;
  }

  static getNbSenior(participants: Participant[]) {
    return participants.filter((participant) => participant.age >= 70).length;
  }
}
