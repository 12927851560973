export class CartItemSkiEquipmentPack {
  pictureURL: string;
  name: string;
  description: string;
  ageRange: string;

  constructor(obj: Partial<CartItemSkiEquipmentPack>) {
    Object.assign(this, obj);
  }
}
