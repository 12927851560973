import { GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { CommonModule, DatePipe, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AtomicLibModule, ScreenPipe } from 'atomic-lib';
import { ActivitySelectorComponent } from './activity-selector/activity-selector.component';
import { LoadingComponent } from './component/loading.component';
import { MobileComponent } from './component/mobile.component';
import { RxjsComponent } from './component/rxjs.component';
import { DraggableDirective } from './directive/draggable.directive';
import { ScrollVisibilityDirective } from './directive/scroll-visibility.directive';
import { SkeletonLoadDirective } from './directive/skeleton-load.directive';
import { ExperienceDrawerComponent } from './experience-drawer/experience-drawer.component';
import { ExperienceFiltersPopupComponent } from './experience-filters/experience-filters-popup/experience-filters-popup.component';
import { ExperienceFiltersComponent } from './experience-filters/experience-filters.component';
import { FiltersAccommodationPopupComponent } from './filters-accommodation/filters-accommocation-popup/filters-accommodation-popup.component';
import { FiltersAccommodationComponent } from './filters-accommodation/filters-accommodation.component';
import { FormInputNumberComponent } from './form-input-number/form-input-number.component';
import { InformationBannerComponent } from './information-banner/information-banner.component';
import { InputParticipantComponent } from './input-participant/input-participant.component';
import { LoginComponent } from './login/login.component';
import { MobilePageComponent } from './mobile-page/mobile-page.component';
import { AgePipe } from './pipe/age.pipe';
import { SortByAgeDescPipe } from './pipe/sort-by-age.pipe';
import { SortByIdPipe } from './pipe/sort-by-id.pipe';
import { SortParticipantsPipe } from './pipe/sort-participants.pipe';
import { ToHourPipe } from './pipe/to-hour.pipe';
import { PriceComponent } from './price/price.component';
import { PromoCodeComponent } from './promo-code/promo-code.component';
import { SearchBarComponent } from './search-bar/search-bar.component';

@NgModule({
  declarations: [
    RxjsComponent,
    AgePipe,
    ToHourPipe,
    InputParticipantComponent,
    PromoCodeComponent,
    SkeletonLoadDirective,
    LoadingComponent,
    ActivitySelectorComponent,
    FormInputNumberComponent,
    SortParticipantsPipe,
    SortByIdPipe,
    SortByAgeDescPipe,
    SearchBarComponent,
    MobilePageComponent,
    DraggableDirective,
    ScrollVisibilityDirective,
    MobileComponent,
    LoginComponent,
    PriceComponent,
    InformationBannerComponent,
    FiltersAccommodationComponent,
    FiltersAccommodationPopupComponent,
    ExperienceFiltersComponent,
    ExperienceFiltersPopupComponent,
    ExperienceDrawerComponent
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    RouterModule,
    AtomicLibModule,
    NgOptimizedImage,
    ScreenPipe,
    GoogleSigninButtonModule
  ],
  exports: [
    RxjsComponent,
    AgePipe,
    ToHourPipe,
    InputParticipantComponent,
    SkeletonLoadDirective,
    LoadingComponent,
    SortParticipantsPipe,
    PromoCodeComponent,
    SortByIdPipe,
    ActivitySelectorComponent,
    SearchBarComponent,
    MobilePageComponent,
    DraggableDirective,
    ScrollVisibilityDirective,
    MobileComponent,
    LoginComponent,
    PriceComponent,
    SortByAgeDescPipe,
    InformationBannerComponent,
    FiltersAccommodationComponent,
    FiltersAccommodationPopupComponent,
    ExperienceFiltersComponent,
    ExperienceDrawerComponent
  ],
  providers: [DatePipe]
})
export class SharedModule {}
