import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Proposal } from 'atomic-lib';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Establishment } from '../shared/models/accommodation/establishment';
import { ProposalQuery } from '../shared/models/accommodation/proposal-query';
import { Room } from '../shared/models/accommodation/room';
import { Criteria } from '../shared/models/criteria';
import { FiltersAccommodationSearch } from '../shared/models/filters/filters-accommodation-search';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class AccommodationService extends RepositoryService {
  private URL_ACCOUNT = 'api/webapp/accommodation';

  constructor(http: HttpClient) {
    super(http);
  }

  getEstablishmentsByFilters(
    filters: FiltersAccommodationSearch,
    page = 0
  ): Observable<Establishment[]> {
    return this.post(
      this.URL_ACCOUNT +
        `/get-establishments-by-station?page=${page}&size=${30}`,
      filters
    ).pipe(
      map(
        (establishments) =>
          establishments?.map(
            (establishment: Establishment) =>
              new Establishment({ ...establishment })
          ) || []
      )
    );
  }

  getEstablishmentByPartnerCodeAndId(
    establishmentId: number,
    partnerCode: string
  ): Observable<Establishment> {
    return this.get(
      this.URL_ACCOUNT +
        `/get-establishment-by-id-and-partner-code?establishmentId=${establishmentId}&partnerCode=${partnerCode}`
    );
  }

  getRoomsByCriteria(
    criteria: Criteria,
    establishmentId: number,
    partnerCode: string
  ): Observable<Room[]> {
    return this.post(
      this.URL_ACCOUNT +
        `/get-rooms-by-criteria?establishmentId=${establishmentId}&partnerCode=${partnerCode}`,
      criteria
    ).pipe(map((rooms) => rooms || []));
  }

  getProposalsForRoom(query: ProposalQuery): Observable<Proposal[]> {
    return this.post(this.URL_ACCOUNT + `/get-proposals-for-room`, query);
  }
}
