import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewChild
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { WindowResource } from 'atomic-lib';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FiltersService } from '../service/filters.service';
import { MetaDescriptionService } from '../service/meta-description.service';
import { StationService } from '../service/station.service';
import { AccommodationTemplateComponent } from '../shared/component/accommodation-template.component';
import { ResortMin } from '../shared/models/resort/resort-min';

@Component({
  selector: 'vsk-accommodations',
  templateUrl: './accommodations.component.html',
  styleUrls: ['./accommodations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccommodationsComponent extends AccommodationTemplateComponent {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

  allResorts$: Observable<ResortMin[]>;

  constructor(
    public activatedRoute: ActivatedRoute,
    public store: Store,
    protected filtersService: FiltersService,
    public windowResource: WindowResource,
    protected stationService: StationService,
    protected changeRef: ChangeDetectorRef,
    private metaDescriptionService: MetaDescriptionService,
    public router: Router
  ) {
    super(
      activatedRoute,
      store,
      filtersService,
      windowResource,
      changeRef,
      router
    );

    this.markerResort = undefined;
    const title = `Découvrez nos résidences et locations en montagne · VeryMountain`;
    const description = `Trouvez la résidence idéale en montagne avec VeryMountain parmi 20.000 hébergements : chalets, résidences all-inclusive ou appartements de charme`;
    const url = 'recherche-hebergements';

    this.metaDescriptionService.updateMeta(title, description, url);

    this.allResorts$ = this.stationService
      .getAllResorts()
      .pipe(
        map((resorts) =>
          resorts.sort((prev, curr) => (prev.name < curr.name ? -1 : 1))
        )
      );
  }

  resetFilters() {
    this.center = {
      lat: 45.052825927734375,
      lng: 2.7113842964172363
    };

    setTimeout(() => {
      this.boundsChanged();
      this.clearFilters = true;
    }, 300);
  }
}
