import { ParamMap } from '@angular/router';
import { NavbarStateModel } from './navbar/navbar.state';
import { FiltersExperienceSearch } from './shared/models/activity/filters-experience-search';
import { OrderBy } from './shared/models/const/order-by';
import { WhiteLabel } from './shared/models/enum/white-label.enum';
import { FiltersAccommodationSearch } from './shared/models/filters/filters-accommodation-search';
import { Participant } from './shared/models/participant/participant';
import { TimeSlot } from './shared/models/partner/time-slot';
import { Resort } from './shared/models/resort/resort';

export class UpdateFilters {
  static readonly type = '[FiltersState] Update filters';

  constructor(
    public queryParams: ParamMap,
    public isHome = false,
    public fragment?: string | null
  ) {}
}

export class AddParticipants {
  static readonly type = '[Filters] Set participants';

  constructor(public participants: Participant[]) {}
}

export class DeleteParticipant {
  static readonly type = '[Filters] Delete a participant';

  constructor(public participant: Participant) {}
}

export class UpsertParticipant {
  static readonly type = '[Filters] Add participant';

  constructor(public participant: Participant) {}
}

export class Bounds {
  static readonly type = '[Filters] Set bounds';

  constructor(public bounds: google.maps.LatLngBoundsLiteral | undefined) {}
}

export class FiltersStationChangeOrderBy {
  static readonly type = '[Filters] Filters change order by';

  constructor(public orderBy: OrderBy) {}
}

export class GeoBoundsActiveChange {
  static readonly type = '[Filters] Filters change GeoBounds active';

  constructor(public isActive: boolean) {}
}

export class TimeSlots {
  static readonly type = '[Filters] Set time slots';

  constructor(public timeSlots: TimeSlot[]) {}
}

export class SetResort {
  static readonly type = '[Filters] Set resort';

  constructor(public resort: Resort | null) {}
}

export class FiltersNavbarChanged {
  static readonly type = '[Filters] Update filters navbar';

  constructor(public navbarState: NavbarStateModel) {}
}

export class GenerateNewSessionId {
  static readonly type = '[Filters] Generate new session id';
}

export class SetChangingFilters {
  static readonly type = '[FiltersState] Set state changing filters';

  constructor(public isChangingFilters: boolean) {}
}

export class SetSearchMode {
  static readonly type = '[FiltersState] Set search mode';

  constructor(
    public searchMode: 'all' | 'accommodation' | 'experience' = 'all'
  ) {}
}

export class SetFiltersAccommodations {
  static readonly type = '[Filters] Set filters accommodations';

  constructor(public filters: FiltersAccommodationSearch) {}
}

export class SetFiltersExperiences {
  static readonly type = '[Filters] Set Filters Experience';

  constructor(public filtersExperiences: FiltersExperienceSearch | undefined) {}
}

export class SetWhiteLabel {
  static readonly type = '[Filters] Set WhiteLabel';

  constructor(public whiteLabel: WhiteLabel) {}
}
