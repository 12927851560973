
<div class="rounded-[30px] flex flex-col gap-4 p-8 mx-4 box-shadow">
  <h1 class="text-center">Mes commandes</h1>
  <p class="text-center">Vous pouvez retrouver ici vos voyages à venir et passés.</p>
  <vsk-my-orders *ngIf="!loadingTripPast && !loadingTripToCome"
                 (downloadReceiptChange)="downloadReceipt($event)"
                 [ordersHistory]="ordersToCome"
                 title="A VENIR">
  </vsk-my-orders>

  <vsk-my-orders *ngIf="!loadingTripPast && !loadingTripToCome"
                 (downloadReceiptChange)="downloadReceipt($event)"
                 [ordersHistory]="ordersPast"
                 title="PASSÉES">
  </vsk-my-orders>

  <atc-loader *ngIf="loadingTripPast || loadingTripToCome"></atc-loader>
</div>
