import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InformationBanner } from '../shared/models/information-banner';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class InformationBannerService extends RepositoryService {
  private URL_INFO_BANNER = 'api/webapp/information-banner';

  constructor(http: HttpClient) {
    super(http);
  }

  getBanner(): Observable<InformationBanner> {
    return this.get(this.URL_INFO_BANNER).pipe(
      filter((banner) => !!banner),
      map((banner) => new InformationBanner({ ...banner }))
    );
  }
}
