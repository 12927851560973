import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tag } from '../shared/models/tag';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class TagsService extends RepositoryService {
  private URL_TAGS = 'api/webapp/tag';

  constructor(http: HttpClient) {
    super(http);
  }

  getTags(): Observable<Tag[]> {
    return this.get(this.URL_TAGS + '/all-tags');
  }
}
