import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderHistory } from '../../../shared/models/order-history';

@Component({
  selector: 'vsk-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss']
})
export class MyOrdersComponent {
  showItemsSession = false;
  order: OrderHistory | null;

  @Input() title: string;
  @Input() ordersHistory: OrderHistory[] = [];

  @Output() downloadReceiptChange = new EventEmitter<string>();

  downloadReceipt(transactionId: string): void {
    this.downloadReceiptChange.emit(transactionId);
  }

  openDetails(order: OrderHistory) {
    this.order = order;
    this.showItemsSession = true;
  }

  openChange(isOpen: boolean) {
    this.showItemsSession = isOpen;
  }
}
