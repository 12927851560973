<div class="flex flex-col w-[100%] max-w-[1350px] mx-auto sxl:px-[30px]
            xl:flex-col gap-y-4 pb-8">

  <ng-container *ngIf="loading">
    <div class="flex flex-col gap-4 mx-auto sm:gap-2
      xl:w-[1350px] w-[100%] max-w-[1350px] xl:flex-col xl:px-0 pt-2 pb-[4rem]">
      <div class="rounded-[20px] h-[70px]" vskSkeletonLoad></div>
      <div class="flex flex-row justify-between gap-[10px] w-full">
        <div class="grid w-full gap-2">
          <div *ngFor="let nb of [0,1]" class="rounded-[20px] h-[250px]" vskSkeletonLoad>
          </div>
        </div>
        <div class="rounded-[20px] h-auto w-full" vskSkeletonLoad>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!loading">
    <ng-container *ngIf="resort$ | async as resort">
      <ng-container *ngIf="equipmentPacks$ | async as packs">
        <div class="flex flex-row justify-between items-center mt-4 md:flex-col md:gap-y-4 md:justify-start">
          <h2 class="w-full md:text-center">Location de matériel</h2>
          <atc-tag
            atcTooltip
            class="w-full"
            color="danger"
            icon="fat-exclamation-circle"
            size="medium"
            tooltip="Ajoutez d'abord un hébergement à votre panier<br> pour louer du matériel.">
            Hébergement nécessaire
          </atc-tag>
        </div>

        <div class="flex flex-row gap-[2rem] w-full main">
          <ng-container *ngIf="packs && packs.length > 0; else noPacksTemplate">

            <div class="flex flex-col gap-[2rem] custom-content">
              <div class="step-container">
                <p class="step-title"><span class="number">1</span> Du matériel pour :</p>
                <div class="toggle-participant grid justify-items-start	gap-[1rem] justify-start mb-[1rem]">
                  <ng-container *ngFor="let participant of participants$ | async; let i = index">
                    <atc-button-toggle
                      (click)="onAgeGroupSelected(participant, packs)"
                      [color]="'secondary'"
                      [icon]="'person'"
                      [isDefault]="i === 0" [selected]="isSelected(participant)" [size]="'medium'"
                      [subtitle]="participant.firstname + ' - ' + participant.ageGroup">
                    </atc-button-toggle>
                  </ng-container>
                </div>
              </div>
              <div class="step-container">
                <p class="step-title"><span class="number">2</span> Durée de location</p>
                <atc-date-timeline-selector (dateRangeSelected)="onDateRangeSelected($event)"
                                            [endDate]="endDate"
                                            [selectedDate]="startDate"
                                            [selectionMode]="'range'"
                                            [startDate]="startDate">
                </atc-date-timeline-selector>
                <div class="duration-infos">
                  <p>Attention, 2 jours minimum requis pour la location.</p>
                  <p class="step-title">Nombre de jours de location :<span class="duration">{{ duration }} jours</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="packs-container flex flex-col flex-2 gap-[2rem]">
              <div class="packs-select-container">
                <p class="step-title"><span class="number">3</span> Nos packs au meilleur prix</p>
                <div class="all-btn-select">
                  <div *ngFor="let groupName of uniqueGroupNames">
                    <atc-button-select (click)="onGroupNameSelected(groupName, packs)"
                                       [label]="groupName | titlecase " [selected]="groupName === selectedGroupName"
                                       class="flex flex-row row gap-1"
                                       color="ternary"
                                       size="medium">
                    </atc-button-select>
                  </div>
                </div>
              </div>
              <div class="ski-cards">
                <atc-card-resort-skiset
                  (paramsSelected)="onParamsSelected($event, pack)"
                  *ngFor="let pack of filteredPacks"
                  [description]="pack.offer.equipments[0]?.description"
                  [discountPercentage]="pack.discountPercentage"
                  [imageLink]="pack.offer.equipments[0]?.photoUrl"
                  [isSki]="pack.isSki"
                  [level]="pack.offer.minAge && pack.offer.maxAge ? pack.offer.minAge + ' - ' + pack.offer.maxAge + ' ans' : 'Adulte'"
                  [packName]="pack.offer.category"
                  [priceHelmet]="pack.priceHelmet"
                  [priceShoes]="pack.priceShoes"
                  [price]="pack.netPrice"
                  [publicPrice]="pack.publicPrice">
                </atc-card-resort-skiset>
              </div>
            </div>
          </ng-container>
        </div>

        <vsk-resort-material-drawer
          *ngIf="packs?.length > 0"
          [(showDrawer)]="showSkiEquipmentDrawer"
          [hasAccommodation]="(cart$ | async)?.quantityAccommodation > 0"
          [resortId]="resort.id"
          [selectedEquipment]="paramsSelected">
        </vsk-resort-material-drawer>
      </ng-container>
    </ng-container>

    <ng-template #noPacksTemplate>
      <p class="w-full text-center p-4 max-w-[1350px] mx-auto">Aucun pack de matériel disponible.</p>
    </ng-template>
  </ng-container>
</div>

<vsk-footer [isHome]="false"></vsk-footer>
