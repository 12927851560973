import { Alert } from './shared/models/alert';
import { CookiesType } from './shared/models/cookies-type';
import { Login } from './shared/models/login';
import { PassSki } from './shared/models/pass-ski';
import { User } from './shared/models/user';

export class ConnectUser {
  static readonly type = '[Account API] Connect user session';

  constructor(public login: Login) {}
}

export class InvitedUser {
  static readonly type = '[Account API] Invited user session';

  constructor(public invited: boolean) {}
}

export class UpdateUserData {
  static readonly type = '[Account API] Update user session data';

  constructor(public userUpdate: User) {}
}

export class UpdateUserPass {
  static readonly type = '[Account API] Update user pass session data';

  constructor(public passSki: PassSki) {}
}

export class DisconnectUser {
  static readonly type = '[Account API] Disconnect user session';

  constructor(public reload = true) {}
}

export class DeleteUser {
  static readonly type = '[Account API] Delete user';
}

export class ConsentCookies {
  static readonly type = '[Cookies] Validate cookies';

  constructor(public cookies: CookiesType) {}
}

export class RenewToken {
  static readonly type = '[Account API] Renew token session';
}

export class GoToSearch {
  static readonly type = '[APP] Go to search';

  constructor(public search: boolean) {}
}

export class TriggerAlert {
  static readonly type = '[APP] Show an alert';

  constructor(public alert: Alert) {}
}

export class TriggerLoading {
  static readonly type = '[APP] Show a loading screen';

  constructor(public loading: boolean) {}
}

export class TriggerPopup {
  static readonly type = '[APP] Show popup';

  constructor(public popup: boolean) {}
}

export class FetchBanner {
  static readonly type = '[APP] Fetch banner';
}

export class CloseBanner {
  static readonly type = '[APP] Close banner';
}
