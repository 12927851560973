import moment, { Moment } from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { CustomField } from './custom-field';

export class Participant {
  index = 0;
  sessionId: string;
  uuid = uuidv4();
  firstname: string;
  lastname: string;
  birthdate: Moment;
  email: string;
  cardSkidata: string;
  cardAxess: string;
  hasCard: boolean;
  weight: number | null;
  height: number | null;
  shoeSize: number | null;
  student = false;
  customFields: CustomField[] = [];

  constructor(obj: Partial<Participant>) {
    Object.assign(this, obj);
    this.customFields = this.customFields.map(
      (customField) => new CustomField({ ...customField })
    );
    if (this.birthdate) {
      this.birthdate = moment(this.birthdate, 'YYYY-MM-DD');
    }
  }

  get age() {
    if (!this.birthdate) {
      return 0;
    }

    return moment().diff(this.birthdate, 'year');
  }

  get ageGroup() {
    if (this.age < 18) {
      return 'Enfant';
    }

    return 'Adulte';
  }

  isValid(): boolean {
    if (!this.birthdate) {
      return false;
    }

    return this.birthdate.isValid();
  }

  isValidPayment(): boolean {
    return (
      this.isValid() &&
      !!this.lastname &&
      this.lastname?.length >= 2 &&
      this.firstname?.length >= 2 &&
      (!this.customFields.length ||
        this.customFields
          .map((customField) => customField.valid)
          .reduce((prev, curr) => prev && curr)) &&
      (!this.hasCard ||
        (this.hasCard &&
          (!!this.cardSkidata.match(/^01-1614[0-9]{16}-[0-9]$/)?.length ||
            !!this.cardAxess.match(/^[A-Z0-9]{8}-[A-Z0-9]{3}-[A-Z0-9]{3}$/)
              ?.length)))
    );
  }
}
