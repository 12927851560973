import moment, { Moment } from 'moment';
import { ExperienceCategoryEnum } from './enum/experience-category.enum';
import { Participant } from './participant/participant';

export class Criteria {
  startDate: Moment;
  endDate: Moment;
  stationName: string;
  participants: Participant[] = [];
  activities: number[] = [];
  sessionId?: string;
  type = ExperienceCategoryEnum.GENERAL;

  constructor(obj: Partial<Criteria>) {
    if (obj) {
      Object.assign(this, obj);

      if (this.startDate) {
        this.startDate = moment(this.startDate, 'YYYY-MM-DD');
      }

      if (this.endDate) {
        this.endDate = moment(this.endDate, 'YYYY-MM-DD');
      }
    }
  }

  public get isValid() {
    return !!this.startDate && !!this.endDate && !!this.sessionId;
  }
}
