<img [src]="realImageUrl" alt="Couverture" class="banner-img-desktop" />

<div class="banner-desktop-container relative flex w-full flex-col">
  <div class="banner-desktop mx-auto flex min-h-full max-w-[835px] flex-col">
    <div class="header-search flex w-full pb-6">
      <img *ngIf="windowResource.width() | async | screen: 'small-desktop-above'"
           alt="Logo"
           src="assets/images/logo-mobile-blanc.png"
      />
    </div>
    <ng-container *ngIf="searchMode === 'all'">
      <h1 @fadeInOut [innerHTML]="getTitle()"
          class="pb-12 slg:min-h-[7rem] slg:text-center md:mx-auto sm:pb-0">
        {{
          textBlocks[0]
            ? textBlocks[0].textBlockContents[0]?.title
            : 'Organisez votre séjour tout compris'
        }}
      </h1>
    </ng-container>
    <ng-container *ngIf="searchMode === 'accommodation'">
      <h1 @fadeInOut class="pb-12 slg:min-h-[7rem] slg:text-center md:mx-auto sm:pb-0">
        Trouvez l'<b class="font-semibold">hébergement</b> qui vous correspond
      </h1>
    </ng-container>
    <ng-container *ngIf="searchMode === 'experience'">
      <h1 @fadeInOut class="pb-12 slg:min-h-[7rem] slg:text-center md:mx-auto sm:pb-0">
        Réservez une <b class="font-semibold">activité</b> à vivre en famille</h1>
    </ng-container>

    <section class="navbar-container">


      <atc-button
        (click)="scrollToRight()"
        class="mobile-btn-see-more" color="grey"
        icon="arrow-circle-right">
      </atc-button>

      <div class="search-mode flex flex-row">

        <button (click)="changeSearchMode('all')"
                [ngClass]="{ active: searchMode === 'all' }"
                class="accommodation-and-activity" id="all">
          <svg class="min-h-6 my-auto max-h-6 w-6 stroke-white">
            <use [attr.xlink:href]="'assets/icons/icons.svg#mountain'"></use>
          </svg>
          Hébergement + Activités
        </button>
        <button (click)="changeSearchMode('accommodation')"
                [ngClass]="{ active: searchMode === 'accommodation' }"
                id="accommodation">
          <svg class="min-h-6 my-auto max-h-6 w-6 stroke-white">
            <use [attr.xlink:href]="'assets/icons/icons.svg#home'"></use>
          </svg>
          Hébergements
        </button>
        <button (click)="changeSearchMode('experience')"
                [ngClass]="{ active: searchMode === 'experience' }"
                id="experience">
          <svg class="min-h-6 my-auto max-h-6 w-6 stroke-white">
            <use [attr.xlink:href]="'assets/icons/icons.svg#activity'"></use>
          </svg>
          Activités
        </button>
      </div>

      <vsk-form-date-range-picker (validateSearch)="search()"
                                  *ngIf="windowResource.width() | async | screen: 'small-desktop-above'"
                                  [activitiesByCategories]="activitiesByCategories$ | async"
                                  [activities]="activities$ | async"
                                  [currentPage]="'Home'"
                                  [hasAlterParticipants]="hasAlterParticipants$ | async"
                                  [isDesktop]="windowResource.width() | async | screen: 'small-desktop-above'"
                                  [isHome]="true"
                                  [isInNavbar]="true"
                                  [participants]="participants$ | async"
                                  [period]="period$ | async"
                                  [resorts]="resorts"
                                  [searchMode]="searchMode"
                                  [selectedActivities]="selectedActivities$ | async"
                                  [showActivities]="isActivityPickerOpen$ | async"
                                  [showDates]="isDatePickerOpen$ | async"
                                  [showParticipants]="isParticipantPickerOpen$ | async"
                                  [showSearchPopup]="isChangingFilters$ | async"
                                  [withMaterial]="withMaterial$ | async"
                                  [withSkiPackage]="withSkiPackage$ | async">
      </vsk-form-date-range-picker>
    </section>
    <button (click)="openSearchPopup()"
            *ngIf="!(windowResource.width() | async | screen: 'small-desktop-above')"
            class="search-button-mobile">
      <ng-container *ngIf="searchMode === 'all'">
        <svg class="min-h-5 my-auto max-h-5 w-5 stroke-black">
          <use [attr.xlink:href]="'assets/icons/icons.svg#search'"></use>
        </svg>
        Rechercher un hébergement et des activités
      </ng-container>
      <ng-container *ngIf="searchMode === 'accommodation'">
        <svg class="min-h-5 my-auto max-h-5 w-5 stroke-black">
          <use [attr.xlink:href]="'assets/icons/icons.svg#home'"></use>
        </svg>
        Rechercher un hébergement
      </ng-container>
      <ng-container *ngIf="searchMode === 'experience'">
        <svg class="min-h-5 my-auto max-h-5 w-5 stroke-black">
          <use [attr.xlink:href]="'assets/icons/icons.svg#activity'"></use>
        </svg>
        Rechercher une activité
      </ng-container>
    </button>
  </div>
</div>

<div class="benefits-cards-container flex min-w-full flex-row">
  <img *ngIf="windowResource.width() | async | screen: 'tablet-above'"
       alt="benefits"
       class="max-h-[500px] min-h-[400px] w-full object-contain px-32"
       height="640"
       ngSrc="assets/images/benefits-background.svg"
       width="960" />
  <img *ngIf="!(windowResource.width() | async | screen: 'tablet-above')"
       alt="benefits"
       class="max-h-[600px] min-h-[500px] w-full object-contain p-8 sm:min-h-[700px]"
       height="640"
       ngSrc="assets/images/benefits-background-mobile.svg"
       width="960" />

  <article class="benefits-article-container absolute flex-row">
    <section class="flex flex-col gap-[1.5rem]">
      <div class="title-benefits">
        <h4>Composez votre séjour</h4>
        <img alt="bag icon" class="max-h-16"
             src="assets/images/bag-icon-orange.svg" />
      </div>
      <p class="benefits-paragraph">
        Dites-nous quand vous partez, avec qui et les<b class="font-semibold">
        activités</b>
        que vous souhaitez faire, nous nous occupons de trouver les
        <b class="font-semibold">destinations idéales</b> pour votre escapade en
        montagne.</p>
    </section>

    <section class="flex flex-col gap-[1.5rem]">
      <div class="title-benefits">
        <h4>Trouvez votre destination</h4>
        <img alt="cottage icon" class="max-h-16"
             src="assets/images/cottage-icon-orange.svg" />
      </div>
      <p class="benefits-paragraph">
        Choisissez parmi les <b class="font-semibold">destinations</b> proposées
        celle qui vous ressemble le plus. <br /><br />
        <b class="font-semibold">Explorez</b> de nouvelles stations de montagne
        ou redécouvrez vos stations favorites.
      </p>
    </section>

    <section class="flex flex-col gap-[1.5rem]">
      <div class="title-benefits">
        <h4>Personnalisez votre expérience</h4>
        <img alt="fire icon" class="max-h-16"
             src="assets/images/fire-icon-orange.svg" />
      </div>
      <p class="benefits-paragraph">
        Créez un séjour unique. Ajoutez un
        <b class="font-semibold">hébergement,</b> des
        <b class="font-semibold">activités</b> outdoors ou de bien être, des
        forfaits de ski et même votre
        <b class="font-semibold">matériel.</b>
        <br /><br />
        Décidez ensuite qui fait quoi et réglez l’ensemble de votre séjour sur
        VeryMountain.
      </p>
    </section>
  </article>
</div>

<div (visible)="loadCarouselByZone(1)"
     class="carousel-zone"
     id="zone-1"
     vskScrollVisibility>
  <div *ngFor="let carousel of carouselsZone1">
    <atc-carousel [elements]="mapToElements(carousel)"
                  [nbrScrollingElements]="1"
                  [sizeElement]="320"
                  [title]="carousel.title">
    </atc-carousel>
  </div>
</div>

<div class="relative">
  <atc-carousel-products [bannerTitle]="getTitleResorts(productResort.length)"
                         [icon]="'cottage'"
                         [link]="'/recherche-sejours'"
                         [placeholder]="'Rechercher'"
                         [products]="productResort"
                         [showMapButton]="true"
                         [showNextButton]="true"
                         [sizeElement]="getSizeCard(windowResource.width() | async | screen: 'mobile')"
                         [sizeElementsToShow]="getNbCard(windowResource.width() | async | screen: 'mobile')"
                         bannerImage="assets/images/cover-resorts.webp"
                         bannerSubtitle="Trouvez celle qui vous correspond">
  </atc-carousel-products>
</div>
<div (visible)="loadCarouselByZone(2)"
     class="carousel-zone"
     id="zone-2"
     vskScrollVisibility>
  <div *ngFor="let carousel of carouselsZone2">
    <atc-carousel [elements]="mapToElements(carousel)"
                  [nbrScrollingElements]="1"
                  [sizeElement]="320"
                  [title]="carousel.title">
    </atc-carousel>
  </div>
</div>

<div
  class="no-scrollbar activity-icon gap-4 xl:grid xl:grid-cols-10 sxl:flex sxl:max-w-full sxl:flex-row sxl:overflow-x-auto md:flex md:max-w-full md:flex-row md:overflow-x-auto">
  <atc-leaf-link (clickLinkEvent)="setActivity(activity)"
                 *ngFor="let activity of activities | slice : 0 : ((windowResource.width() | async | screen: 'small-desktop-above') ? totalActivities : activities.length)"
                 [id]="'id__activity-' + activity.name"
                 [image]="activity.urlIcon || '#'"
                 [linkActive]="'true'"
                 [link]="'map'"
                 [size]="(windowResource.width() | async | screen: 'small-desktop-above') ? 'medium' : 'small'"
                 [title]="activity.name">
  </atc-leaf-link>

  <ng-container *ngIf="!activities.length">
    <div *ngFor="let skeleton of skeletonSize" class="vsk__activity">
      <img [loading]="!activities.length"
           alt="activité"
           src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAQAAAA3fa6RAAAADklEQVR42mNkAANGCAUAACMAA2w/AMgAAAAASUVORK5CYII="
           vskSkeletonLoad />
      <p [loading]="!activities.length" vskSkeletonLoad></p>
    </div>
  </ng-container>
</div>

<atc-button (click)="expand()"
            *ngIf="(windowResource.width() | async | screen: 'small-desktop-above') && !activitiesExpanded"
            [fullWidth]="true"
            class="pt-4 xl:mx-auto sxl:mx-auto"
            color="ternary"
            label="Afficher toutes les activités"
            size="large">
</atc-button>

<div class="benefits-cards-container flex min-w-full flex-col overflow-visible">
  <img *ngIf="windowResource.width() | async | screen: 'tablet-above'"
       alt="benefits"
       class="absolute top-0 z-0 max-h-[400px] min-h-[400px] w-full object-contain p-8 opacity-50 sm:min-h-[700px]"
       height="640"
       ngSrc="assets/images/benefits-background.svg"
       width="960" />

  <img *ngIf="!(windowResource.width() | async | screen: 'tablet-above')"
       alt="benefits"
       class="absolute z-0 max-h-[600px] min-h-[600px] w-full object-contain p-8 sm:min-h-[700px]"
       height="640"
       ngSrc="assets/images/benefits-background-mobile.svg"
       width="960" />

  <div class="benefits flex flex-col">
    <article class="benefits-article-container z-10">
      <section class="flex w-full flex-col gap-[1.5rem]">
        <div class="title-benefits">
          <h4>Des enjeux qui nous tiennent à cœur</h4>
          <img *ngIf="windowResource.width() | async | screen: 'tablet-above'"
               alt="bag icon"
               class="max-h-12"
               src="assets/svg/benefits-eco.svg" />
        </div>
        <p class="benefits-paragraph">VeryMountain travaille avec des acteurs locaux et privilégie les
          activités décarbonées au coeur de nos stations.</p>
      </section>

      <section class="flex w-full flex-col gap-[1.5rem]">
        <div class="title-benefits">
          <h4>Annulation gratuite</h4>
          <img *ngIf="windowResource.width() | async | screen: 'tablet-above'"
               alt="bag icon"
               class="max-h-12"
               src="assets/svg/benefits-date.svg" />
        </div>
        <p class="benefits-paragraph">
          Un imprévu ou un contre-temps ? Prévenez-nous 30 jours avant votre
          séjour, l’annulation est gratuite.
        </p>
      </section>

      <section class="flex w-full flex-col gap-[1.5rem]">
        <div class="title-benefits">
          <h4>Payer seulement un <b>acompte de 30%</b> aujourd'hui et le reste plus tard.</h4>
          <img *ngIf="windowResource.width() | async | screen: 'tablet-above'"
               alt="bag icon"
               class="max-h-12"
               src="assets/svg/benefits-payment.svg" />
        </div>
        <p class="benefits-paragraph">
          Vous ne souhaitez pas tout régler d'un coup ? Paiement en 3 fois sans
          frais échelonné sur plusieurs mois.
        </p>
      </section>

      <section class="flex w-full flex-col gap-[1.5rem]">
        <div class="title-benefits">
          <h4>Meilleurs prix garantis</h4>
          <img *ngIf="windowResource.width() | async | screen: 'tablet-above'"
               alt="bag icon"
               class="max-h-12"
               src="assets/svg/benefits-price.svg" />
        </div>
        <p class="benefits-paragraph min-w-full">
          Payez en une seule fois votre séjour, sans intermédiaire. Les prix
          affichés sont les mêmes (ou moins chers) qu’en station.
        </p>
      </section>

      <section class="flex w-full flex-col gap-[1.5rem]">
        <div class="title-benefits">
          <h4>Votre séjour sur mesure</h4>
          <img *ngIf="windowResource.width() | async | screen: 'tablet-above'"
               alt="bag icon"
               class="max-h-12"
               src="assets/svg/benefits-sejours.svg" />
        </div>
        <p class="benefits-paragraph min-w-full">
          Partir à la montagne n’a jamais été aussi simple. Construisez
          vous-même votre séjour et réservez ce qui vous convient le mieux.
        </p>
      </section>
    </article>
  </div>
</div>
<section class="mail-wrapper">
  <div class="mail-container">
    <div class="mail-content newsletter-container">
      <img alt="Newsletter"
           class="filter-img max-h-[140px] gap-2 md:max-h-[120px]"
           src="assets/svg/newsletter.svg" />
      <div class="flex flex-col gap-[1rem]">
        <h4>Abonnez-vous à la newsletter !</h4>
        <p>Inscrivez-vous pour profiter en avant-première des dernières
          nouveautés sur VeryMountain !
        </p>
        <div class="newsletter-form flex flex-col gap-[1rem]">
          <atc-input [control]="emailForm"
                     errorMessage="Une adresse email est obligatoire"
                     id="id__input-contact-email"
                     label="Adresse mail*"
                     placeholder="Votre email..."
                     type="email">
          </atc-input>
          <atc-button (click)="sendEmail()"
                      [fullWidth]="true"
                      class="vsk__spacing-bottom flex w-full"
                      color="secondary"
                      id="id__button-contact-send"
                      label="M’inscrire"
                      size="large">
          </atc-button>
        </div>
        <p *ngIf="isSend"> Merci pour votre inscription, à bientôt !</p>
      </div>
    </div>
  </div>
</section>

<div
  class="partners xl:flex xl:max-w-full xl:flex-row xl:justify-around xl:justify-items-center xl:gap-4 xl:px-12 xl:py-20 slg:grid slg:grid-cols-3 slg:gap-12 slg:p-10 md:grid md:grid-cols-3 md:p-10 sm:gap-4 sm:py-0 sm:pt-[1.5rem]">
  <img alt="Alpes Tourisme Lab"
       class="object-contain grayscale-[100%] hover:grayscale-0 xl:h-[80px] sxl:mx-auto sxl:h-[80px] sxl:w-[180px] md:h-12"
       loading="lazy"
       srcset="assets/images/partners/alpes-tourisme-lab.webp" />
  <img alt="BPI France"
       class="object-contain grayscale-[100%] hover:grayscale-0 xl:h-[80px] sxl:mx-auto sxl:h-[80px] sxl:w-[180px] md:h-12"
       loading="lazy"
       srcset="assets/images/partners/bpi-france.png" />
  <img alt="Cluster Montagne"
       class="object-contain grayscale-[100%] hover:grayscale-0 xl:h-[80px] sxl:mx-auto sxl:h-[80px] sxl:w-[180px] md:h-12"
       loading="lazy"
       srcset="assets/images/partners/cluster-montagne.png" />
  <img alt="French Tech"
       class="object-contain grayscale-[100%] hover:grayscale-0 xl:h-[80px] sxl:mx-auto sxl:h-[80px] sxl:w-[180px] md:h-12"
       loading="lazy"
       srcset="assets/images/partners/french-tech.png" />
  <img alt="Novacité"
       class="object-contain grayscale-[100%] hover:grayscale-0 xl:h-[80px] sxl:mx-auto sxl:h-[80px] sxl:w-[180px] md:h-12"
       loading="lazy"
       srcset="assets/images/partners/novacite.png" />
  <img alt="Tech360"
       class="object-contain grayscale-[100%] hover:grayscale-0 xl:h-[80px] sxl:mx-auto sxl:h-[80px] sxl:w-[180px] md:h-12"
       loading="lazy"
       srcset="assets/images/partners/tech360.png" />
</div>

<vsk-footer [isHome]="true"></vsk-footer>
