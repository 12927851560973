import moment, { Moment } from 'moment';
import { PromoType } from './promo-type';

export class PromoCode {
  name: string;
  applyNumber: number;
  dateValidityStart: Moment;
  dateValidityEnd: Moment;
  typePromo: PromoType;
  used: number;
  usedLimit: number;
  minPrice: number;

  constructor(obj: PromoCode) {
    Object.assign(this, obj);
    this.dateValidityStart = moment(this.dateValidityStart);
    this.dateValidityEnd = moment(this.dateValidityEnd);
  }

  get displayAppliedNumber() {
    if (this.typePromo === PromoType.REDUCTION) {
      return `- ${this.applyNumber * 100} %`;
    }

    return `- ${this.applyNumber} €`;
  }
}
