<div class="vsk__header
            md:hidden
            lg:hidden
            xl:flex">
  <h1><span>À vous</span> <br> la montagne</h1>
</div>
<div class="vsk__inline vsk__player">
  <h2>Manifeste</h2>
  <iframe allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          frameborder="0"
          height="500"
          src="https://www.youtube.com/embed/lKT3bFHNO0w"
          title="Manifeste"
          width="1000"></iframe>
</div>
<div class="vsk__inline">
  <img alt="VeryMountain" class="vsk__card-img" height="150" src="assets/images/step-1.webp" width="200" />
  <div class="vsk__card-text">
    <h2>VeryMountain</h2>
    <p>Nous voyons la montagne comme un terrain de jeu immense,
      dans lequel gravitent une multitude d’acteurs, de possibilités,
      d’opportunités.
      VeryMountain, c’est la possibilité offerte à tous d’organiser plus simplement et plus librement le séjour de ses
      rêves à la montagne. Avec nous, le meilleur de la montagne s’offre à vous en seulement quelques clics.
    </p>
  </div>
</div>
<div class="vsk__inline">
  <img alt="VeryMountain" class="vsk__card-img" height="150" src="assets/images/step-2.webp" width="200" />
  <div class="vsk__card-text">
    <h2>Identité</h2>
    <p>
      Nous sommes convaincus que la montagne peut faire vivre des moments extraordinaires aux aventuriers, aux
      baroudeurs, aux promeneurs, aux sportifs, aux oisifs, aux contemplatifs, … et ce, en toutes saisons.
      Aussi, nous mettons tout en œuvre pour que chaque week-end, jour férié, ou grandes vacances deviennent des temps
      forts, des temps fous, à vivre en couple, en famille ou entre amis.
    </p>
  </div>
</div>
<div class="vsk__inline">
  <img alt="VeryMountain" class="vsk__card-img" height="150" src="assets/images/step-3.webp" width="200" />
  <div class="vsk__card-text pb-[2rem]">
    <h2>Engagement</h2>
    <p>
      VeryMountain propose un tourisme qui prend soin de la montagne : la valoriser, sans l’épuiser.
      Nous privilégions les activités décarbonées. VeryMountain c’est un engagement humain. Nous nous mettons au service
      des professionnels de la montagne afin de les faire connaître, les valoriser et créer des synergies.
    </p>
  </div>
</div>

<vsk-footer></vsk-footer>
