<atc-button (click)="showCart = true"
            *ngIf="!(windowResource.width() | async | screen : 'small-desktop-above')"
            [fullWidth]="true"
            class="flex flex-1 my-4"
            color="ternary"
            label="Voir le détail de mon séjour">
</atc-button>

<div class="px-8 py-4 mb-4 bg-white rounded-[20px] flex flex-col gap-4">
  <atc-input-radio (changeSelection)="updatePayMode($event)"
                   *ngIf="canPayDeposit()"
                   [controls]="payModeRadios">
  </atc-input-radio>

  <p *ngIf="payMode === 'DEPOSIT' && !loading"
     class="text-center bold">
    Un prélèvement en complément de l'acompte sera programmé sur votre moyen de paiement <br>
    automatiquement à la date du {{ startDate.clone().add(-45, 'day').format('DD/MM/YYYY') }}
  </p>
  <p *ngIf="error" class="error">{{ error }}</p>
  <div class="flex flex-col w-full min-h-[215px] items-center justify-center">
    <atc-loader *ngIf="loading" color="secondary"></atc-loader>
    <div [ngClass]="{'hidden': loading}" class="w-full" id="payment-element"></div>
  </div>
</div>

<div class="flex flex-row gap-2">
  <atc-button (click)="previous.emit()"
              color="ternary"
              icon="left-arrow">
  </atc-button>

  <atc-button (click)="pay()"
              [fullWidth]="true"
              class="flex w-full"
              color="secondary"
              icon="cart"
              id="id__button-pay"
              label="Payer">
  </atc-button>
</div>

<vsk-cart [(showCart)]="showCart"
          [canAddPromoCode]="false"
          [canDeleteItem]="false">
</vsk-cart>
