<google-map
  [center]="center"
  [height]="(windowResource.width() | async | screen: 'small-desktop-above') ? '700px' : '100%'"
  [options]="options"
  [width]="'100%'"
  [zoom]="zoom"
  class="map-cart">

  <map-marker
    #mapMarker="mapMarker"
    (mapClick)="openInfoWindow(marker, mapMarker)"
    (mapMouseout)="outMarker(marker)"
    (mapMouseover)="overMarker(marker)"
    *ngFor="let marker of markers"
    [icon]="marker.getIcon()!"
    [label]="marker.getLabel()!"
    [options]="getOptions(marker)"
    [position]="marker.getPosition()!"
    [title]="marker.getTitle()!">
  </map-marker>

  <map-marker
    *ngIf="markerResort"
    [icon]="markerResort.getIcon()!"
    [options]="getOptions(markerResort)"
    [position]="markerResort.getPosition()!"
    [title]="markerResort.getTitle()!">
  </map-marker>
</google-map>
