<div class="pb-[5rem] flex flex-col gap-4 h-full">

<div class="bg-white rounded-[30px] flex flex-col gap-4 p-8 mx-4
            xl:w-[1100px] xl:mx-auto box-shadow">
  <h1 class="flex-1 text-center">Mes informations</h1>

  <div class="flex flex-row gap-2">
    <!-- Lastname -->
    <atc-input [control]="lastNameForm"
               [focus]="true"
               id="id__input-my-account-lastname"
               label="Nom"
               type="text"></atc-input>

    <!-- Firstname -->
    <atc-input [control]="firstNameForm"
               id="id__input-my-account-firstname"
               label="Prénom"
               type="text"></atc-input>
  </div>

  <div class="flex flex-row gap-2">
    <!-- Email-->
    <atc-input [control]="emailForm"
               id="id__input-my-account-email"
               label="Adresse mail"
    ></atc-input>

    <!-- Phone -->
    <atc-input [control]="phoneForm"
               [dropSpecialCharacters]="false"
               [specialCharacters]="[' ']"
               errorMessage="Champs obligatoire ne pouvant contenir uniquement des chiffres*"
               label="Téléphone"
               mask="00 00 00 00 00"
               placeholder="06 01 02 03 04"></atc-input>
  </div>

  <!-- Birthdate -->
  <atc-input-date [control]="birthdateForm"
                  id="id__input-my-account-birthdate"
                  label="Date de naissance">
  </atc-input-date>

  <!-- Address -->
  <atc-input [control]="addressForm"
             id="id__input-my-account-address"
             label="Adresse postal"
             type="text"></atc-input>

  <div class="flex flex-row gap-2">
    <!-- City -->
    <atc-input [control]="cityForm"
               id="id__input-my-account-city"
               label="Ville"
               type="text"></atc-input>

    <!-- Post code -->
    <atc-input [control]="postCodeForm"
               id="id__input-my-account-postCode"
               label="Code postal"
               mask="00000"
               type="number"></atc-input>
  </div>

  <!-- Pays -->
  <atc-select [control]="countryForm"
              [optionElements]="countries"
              id="id__input-my-account-country"
              label="Pays">
  </atc-select>

  <atc-input-checkbox [control]="newsletter">
    <p>Je souhaites recevoir les bons plans VeryMountain</p>
  </atc-input-checkbox>

  <atc-button (click)="validation()"
              [fullWidth]="true"
              class="w-full flex"
              color="secondary"
              id="id__button-my-account-modify"
              label="Modifier"></atc-button>
</div>

<div class="bg-white rounded-[30px] flex flex-col gap-4 p-8 mx-4
            xl:w-[1100px] xl:mx-auto box-shadow">
  <h1 class="text-center">Changer de mot de passe</h1>
  <atc-button (click)="resetPassword()"
              [fullWidth]="true"
              class="w-full flex"
              color="ternary"
              label="Réinitialiser mon mot de passe"></atc-button>
</div>

<div class="bg-white rounded-[30px] flex flex-col gap-4 p-8 mx-4
            xl:w-[1100px] xl:mx-auto box-shadow">
  <h1 class="text-center">Supprimer mon compte</h1>
  <atc-button (click)="openDeleteConfirmationDialog()"
              [fullWidth]="true"
              class="w-full flex"
              color="danger"
              label="Supprimer mon compte"></atc-button>
</div>

<vsk-dialog-delete-account (closeChanged)="emailToDelete = undefined"
                           *ngIf="emailToDelete"
                           [email]="emailToDelete">
</vsk-dialog-delete-account>

<atc-alert *ngIf="success" class="fixed top-0 left-0 right-0 w-full z-[2000]" level="success">{{success}}</atc-alert>
<atc-alert *ngIf="error" class="fixed top-0 left-0 right-0 w-full z-[2000]" level="error">{{error}}</atc-alert>
</div>
