export class Alert {
  level: 'success' | 'error' | 'warning' | 'info';
  message: string;
  timeout: number;
  loader = false;
  check = false;

  constructor(obj: Partial<Alert>) {
    Object.assign(this, obj);
  }
}
