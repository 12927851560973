<ng-container *ngIf="showDrawer">
  <atc-side-drawer
    (closeChange)="closeDrawer()"
    [side]="windowResource.isDesktop ? 'right' : 'top'"
    [title]="'Location de Matériel'">
    <div class="md:mt-4 lg:mt-4 max-w-[75%] mx-auto" header>
      <h1>Location de Matériel</h1>
      <h3 class="text-center">Pack {{ selectedPack.offer.category }}</h3>
    </div>

    <div body>
      <div class="block-select">
        <h2>Vos informations :</h2>
        <div class="flex flex-row gap-2 mt-4">
          <atc-input
            [control]="firstNameForm"
            class="w-2/4"
            label="Prénom - optionnel"
            placeholder="Jean-Claude">
          </atc-input>
          <atc-select
            [control]="heightForm"
            [optionElements]="heights"
            [searchable]="true"
            class="w-2/4"
            label="Taille (cm) - optionnel">
          </atc-select>
        </div>
        <div class="flex flex-row gap-2 mt-4">
          <atc-select
            [control]="weightForm"
            [optionElements]="weights"
            [searchable]="true"
            class="w-2/4"
            label="Poids (kg) - optionnel">
          </atc-select>
          <atc-select
            [control]="shoeSizeForm"
            [optionElements]="shoeSizes"
            [searchable]="true"
            class="w-2/4"
            label="Pointure - optionnel">
          </atc-select>
        </div>
        <p class="mt-5 italic">Gagnez du temps en magasin et remplissez les informations skieurs.</p>
      </div>
      <div class="block-select mt-4">
        <h2>Récapitulatif :</h2>
        <h5>Pack {{ selectedPack.offer.category }} - {{ selectedPack.groupName }}
          <span *ngIf="selectedPack.offer.ageGroup !== 'Adult'"> - {{ selectedPack.offer.minAge }} / {{
              selectedPack.offer.maxAge
            }} ans</span>
        </h5>
        <p>{{ participant?.firstname }} {{ participant?.lastname }} : <span
          class="light">{{ participant?.ageGroup }}</span></p>
        <p *ngIf="selectedEquipment.hasShoes">
          Chaussures incluses <span class="light">(+ {{ selectedPack.priceShoes | number : '1.2-2':'fr' }}€)</span>
        </p>
        <p *ngIf="selectedEquipment.hasHelmet">
          Casque inclus <span class="light">(+ {{ selectedPack.priceHelmet | number : '1.2-2':'fr' }}€)</span>
        </p>
        <p>
          Date de location : <span
          class="light">du {{ selectedEquipment.startDate.locale('fr').format('DD/MM/YYYY') }} au {{
            selectedEquipment.endDate.locale('fr').format('DD/MM/YYYY')
          }}</span>
        </p>
        <p>Durée de location : <span class="light">{{ selectedEquipment.duration }} jours</span></p>
      </div>
    </div>

    <div class="w-full p-8 gap-2 flex flex-col" footer>
      <div class="flex justify-between items-center py-2 rounded total">
        <h2 class="pl-2">Total</h2><br>
        <h2 class="pr-2">{{ total | number : '1.2-2':'fr' }}€</h2>
      </div>
      <div class="flex flex-col gap-y-2">
        <atc-tag *ngIf="!hasAccommodation" class="tag mx-auto" color="warning" size="semilarge">Cette location ne
          peut se réserver qu'avec un hébergement
        </atc-tag>
        <atc-button
          (click)="saveEquipmentCart()"
          [disabled]="isDisabled()"
          [fullWidth]="true"
          class="flex justify-center items-center"
          color="secondary"
          icon="cart"
          label="Ajouter au panier">
        </atc-button>
      </div>
    </div>
  </atc-side-drawer>
</ng-container>
