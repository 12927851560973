import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SetChangingFilters } from '../filters.action';

@Injectable({
  providedIn: 'root'
})
export class NavigateService {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store
  ) {}

  public navigateWithQueryParams(
    url: string,
    params?: any,
    withoutParams = false,
    fragment?: string
  ): Promise<boolean> {
    this.store.dispatch(new SetChangingFilters(false));

    if (withoutParams) {
      return this.router.navigate([url]);
    } else {
      return this.router.navigate([url], {
        relativeTo: this.activatedRoute,
        queryParams: {
          ...params
        },
        fragment,
        queryParamsHandling: 'merge' // remove to replace all query params by provided
      });
    }
  }
}
