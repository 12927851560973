import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestReceipt } from '../shared/models/request-receipt';
import { PaymentSetup } from '../shared/models/stripe/payment-setup';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends RepositoryService {
  private URL_STATION = 'api/webapp/payment';

  constructor(http: HttpClient) {
    super(http);
  }

  initIntentPayment(
    promoCode: string | null,
    sessionId: string,
    ecoTourismChart: boolean,
    depositMode: boolean
  ): Observable<PaymentSetup> {
    return this.post(this.URL_STATION + '/init-intent-payment', {
      sessionId,
      promo: promoCode,
      ecoTourismChart,
      depositMode
    });
  }

  completePayment(requestReceipt: RequestReceipt): Observable<PaymentSetup> {
    return this.post(this.URL_STATION + '/complete-payment', requestReceipt);
  }
}
