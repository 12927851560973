import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { OptionElement, WindowResource } from 'atomic-lib';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { MapsResource } from 'src/app/resource/maps.resource';
import { InvitedUser } from '../../app.action';
import { AppState } from '../../app.state';
import { CartState } from '../../cart/cart.state';
import { AccountResource } from '../../resource/account.resource';
import { AccountService } from '../../service/account.service';
import { ContactService } from '../../service/contact.service';
import { LocationService } from '../../service/location.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { Account } from '../../shared/models/account';
import { Provider } from '../../shared/models/const/provider';
import { UserSession } from '../../shared/models/user-session';
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'vsk-step-connexion',
  templateUrl: './step-connexion.component.html',
  styleUrls: ['./step-connexion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepConnexionComponent extends RxjsComponent {
  @Select(CartState.isCartEmpty) isCartEmpty$: Observable<boolean>;
  @Select(AppState.user) user$: Observable<UserSession>;

  countries: OptionElement<string>[] = [
    {
      id: null,
      label: 'Sélectionnez un pays',
      disabled: true,
      classCss: 'disabled'
    }
  ];

  formTouched = false;
  inscriptionForm: UntypedFormGroup;
  firstNameForm = new FormControl<string>('', Validators.required);
  lastNameForm = new FormControl<string>('', Validators.required);
  cguCheckedForm = new FormControl<boolean>(false, Validators.required);
  newsletter = new FormControl<boolean>(false, Validators.required);
  passwordForm = new FormControl<string>('', [
    Validators.required,
    this.passwordStrengthValidator()
  ]);
  passwordConfirmationForm = new FormControl<string>('', Validators.required);
  emailForm = new FormControl<string>('', [
    Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$'),
    Validators.required
  ]);
  phoneForm = new FormControl<string>('', [
    Validators.required,
    Validators.pattern('[0-9 ]{14}')
  ]);
  emailGuestForm = new FormControl<string>('', [
    Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$'),
    Validators.required
  ]);
  phoneGuestForm = new FormControl<string>(
    '',
    Validators.pattern('[0-9 ]{14}')
  );
  addressForm = new UntypedFormControl('');
  cityForm = new FormControl<string>('', Validators.required);
  postCodeForm = new FormControl<string>('', Validators.required);
  countryForm = new FormControl<string>('France', Validators.required);

  error: string | undefined;
  loading = false;
  inscriptionFinalize = false;
  openLoginPopup = false;
  showCart = false;
  mode: 'invited' | 'connexion' = 'connexion';

  @Input() startDate: Moment;
  @Input() endDate: Moment;
  @Input() hasInformationStep = false;
  @Input() session: string;
  @Input() isLoggedIn = false;

  @Output() next = new EventEmitter<void>();
  protected readonly Validators = Validators;

  constructor(
    public accountService: AccountService,
    public changeRef: ChangeDetectorRef,
    public accountResource: AccountResource,
    private locationService: LocationService,
    public windowResource: WindowResource,
    private mapsResource: MapsResource,
    private store: Store,
    private contactService: ContactService
  ) {
    super();
    this.inscriptionForm = new UntypedFormGroup({
      firstname: this.firstNameForm,
      lastname: this.lastNameForm,
      email: this.emailForm,
      phone: this.phoneForm,
      password: this.passwordForm,
      address: this.addressForm,
      city: this.cityForm,
      postCode: this.postCodeForm,
      country: this.countryForm,
      newsletter: this.newsletter
    });

    this.passwordConfirmationForm.valueChanges.subscribe((value) => {
      if (value !== this.passwordForm.value) {
        this.passwordConfirmationForm.setErrors(['error']);
      }
    });

    this.locationService.getCountriesNames().subscribe(
      (countries) =>
        (this.countries = countries.map((country) => {
          return {
            id: country,
            label: country
          } as OptionElement<string>;
        }))
    );
  }

  registerEnabled(): boolean {
    return (
      this.inscriptionForm.valid &&
      this.passwordForm.value === this.passwordConfirmationForm.value &&
      !!this.cguCheckedForm.value &&
      !this.loading
    );
  }

  registerAccount(): void {
    if (!this.registerEnabled()) {
      Object.values(this.inscriptionForm.controls).forEach((control) => {
        control.markAsTouched();
        control.markAsDirty();
      });
      this.formTouched = true;
      return;
    }

    const account: Account = new Account({
      ...this.inscriptionForm.getRawValue(),
      birthdate: moment().add(-25, 'years'),
      provider: Provider.VERYSKI,
      guestMode: true
    });
    this.error = '';
    this.loading = true;
    this.signup(account);
  }

  connexion(): void {
    this.register(
      this.accountResource
        .connectUser({
          email: this.emailForm.value,
          password: this.passwordForm.value
        })
        .subscribe(
          () => window.location.reload(),
          (err) => this.afterConnectError(err)
        )
    );
  }

  signup(account: Account): void {
    this.register(
      this.accountService
        .isEmailExisting(account.email)
        .pipe(
          filter((exist: string) => {
            if (exist.length > 0) {
              this.error = `L'adresse mail ${this.inscriptionForm.get('email')?.value} est déjà associée à un compte`;
              this.loading = false;
              this.changeRef.markForCheck();
            }
            return exist.length === 0;
          }),
          switchMap(() => this.accountService.createAccount(account))
        )
        .subscribe(
          () => this.connexion(),
          () => (this.loading = false)
        )
    );
  }

  afterConnectError(error: any): void {
    this.loading = false;
    this.changeRef.markForCheck();
    switch (error.status) {
      case 401:
        this.error = 'Mot de passe incorrect';
        break;
      case 412:
        this.error = "Le compte associé à cet email n'est pas activé";
        break;
      default:
        this.error =
          'Une erreur est survenue, veuillez réessayer dans quelques instants';
        break;
    }
  }

  passwordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const errors: any = {};
      if (!/[A-Z]/.test(control.value)) {
        errors.missingUpperCase =
          'Le mot de passe doit contenir au moins une majuscule';
      }
      if (!/[a-z]/.test(control.value)) {
        errors.missingLowerCase =
          'Le mot de passe doit contenir au moins une minuscule';
      }
      if (!/[0-9]/.test(control.value)) {
        errors.missingNumber =
          'Le mot de passe doit contenir au moins un chiffre';
      }
      if (!/[#?!@$%^&*()-]/.test(control.value)) {
        errors.missingSpecial =
          'Le mot de passe doit contenir au moins un caractère spécial (#?!@$%^&*()-)';
      }
      if (control.value.length < 8) {
        errors.minLength =
          'Le mot de passe doit contenir au moins 8 caractères';
      }

      return Object.keys(errors).length > 0 ? errors : null;
    };
  }

  getPasswordErrorMessage(): string {
    if (this.passwordForm.hasError('required')) {
      return 'Mot de passe obligatoire';
    }
    if (this.passwordForm.hasError('missingUpperCase')) {
      return 'Le mot de passe doit contenir au moins une majuscule';
    }
    if (this.passwordForm.hasError('missingLowerCase')) {
      return 'Le mot de passe doit contenir au moins une minuscule';
    }
    if (this.passwordForm.hasError('missingNumber')) {
      return 'Le mot de passe doit contenir au moins un chiffre';
    }
    if (this.passwordForm.hasError('missingSpecial')) {
      return 'Le mot de passe doit contenir au moins un caractère spécial (#?!@$%^&*()-)';
    }
    if (this.passwordForm.hasError('minLength')) {
      return 'Le mot de passe doit contenir au moins 8 caractères';
    }
    return '';
  }

  geocodeAddress(address: PlaceResult): void {
    if (!address) {
      return;
    }

    const formattedAddress = address.formatted_address ?? '';
    const addressParts = formattedAddress.split(',');
    this.addressForm.setValue(addressParts[0].trim());

    if (addressParts.length > 1) {
      const cityAndPostCode = addressParts[1].trim().split(' ');

      if (cityAndPostCode.length > 0) {
        this.postCodeForm.setValue(cityAndPostCode[0]);
      }

      if (cityAndPostCode.length > 1) {
        this.cityForm.setValue(cityAndPostCode.slice(1).join(' '));
      } else {
        this.cityForm.setValue('');
      }
    } else {
      this.postCodeForm.setValue('');
      this.cityForm.setValue('');
    }
  }

  getLoader() {
    return this.mapsResource.mapsLoader();
  }

  guestModeValidate() {
    if (this.emailGuestForm.invalid) {
      this.emailGuestForm.markAsDirty();
      return;
    }

    if (
      this.mode === 'invited' &&
      this.firstNameForm.valid &&
      this.lastNameForm.valid
    ) {
      this.passwordForm.setValue(this.session);

      const account: Account = new Account({
        firstname: this.firstNameForm.value as string,
        lastname: this.lastNameForm.value as string,
        email: this.emailGuestForm.value as string,
        password: this.passwordForm.value as string,
        phone: this.phoneGuestForm.value as string,
        birthdate: moment().add(-25, 'years'),
        provider: Provider.VERYSKI,
        guestMode: true
      });

      this.accountService
        .createAccount(account)
        .pipe(switchMap(() => this.connect()))
        .subscribe(
          () => {
            this.store.dispatch(new InvitedUser(true));
            this.next.emit();
          },
          () =>
            (this.error =
              'Un compte est déjà existant à cette adresse email, connectez-vous pour continuer !')
        );

      return;
    }

    this.mode = 'invited';
  }

  connect() {
    return this.accountResource.connectUser({
      email: this.emailGuestForm.value,
      password: this.passwordForm.value
    });
  }

  saveEmail(email: string | null, from: string) {
    if (email && email.length > 6) {
      this.contactService.registerEmail(email, from).subscribe();
    }
  }

  labelInvitedModeButton() {
    if (this.mode !== 'invited') {
      return 'Continuer';
    }

    return this.hasInformationStep
      ? 'Passer aux informations voyageurs'
      : 'Passer au paiement';
  }
}
