import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccommodationsComponent } from './accommodations/accommodations.component';
import { ActivitiesComponent } from './activities/activities.component';
import { ConfirmPaymentComponent } from './confirm-payment/confirm-payment.component';
import { ConfirmationRegistrationComponent } from './confirmation-registration/confirmation-registration.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MapComponent } from './map/map.component';
import { MobileDeleteAccountComponent } from './my-account/mobile-delete-account/mobile-delete-account.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SearchResortsComponent } from './search-resorts/search-resorts.component';
import { LoginComponent } from './shared/login/login.component';

export const URL_SEARCH_ACCOMMODATIONS = 'recherche-hebergements';
export const URL_SEARCH_EXPERIENCES = 'recherche-activites';
export const URL_SEARCH_TRIP = 'recherche-sejours';
export const URL_LOGIN = 'connexion';
export const URL_RESORT = 'station';
export const URL_USE_AND_TERMS = 'conditions-utilisation';
export const URL_PAYMENT = 'paiement';
export const URL_CONFIRM_PAYMENT = 'confirmation-paiement';
export const URL_ACCOUNT = 'mon-compte';
export const URL_FORGOT_PASSWORD = 'mot-de-passe-oublie';
export const URL_TERMS_AND_CONDITIONS = 'termes-et-conditions';
export const URL_ECO_CHART = 'charte-de-l-eco-tourisme';
export const URL_SELLS_CONDITIONS = 'conditions-de-vente';
export const URL_CONFIRMATION_ACCOUNT = 'confirmation-creation-compte';
export const URL_ABOUT_US = 'a-propos-de-nous';
export const URL_DELETE_CONFIRMATION = 'suppression-compte';
export const URL_SEARCH_RESORTS = 'recherche-stations';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule)
  },
  {
    path: 'map',
    redirectTo: URL_SEARCH_TRIP
  },
  {
    path: URL_SEARCH_TRIP,
    component: MapComponent
  },
  {
    path: 'details-station', // TO REMOVE
    redirectTo: URL_RESORT
  },
  {
    path: 'resort', // TO REMOVE
    redirectTo: URL_RESORT
  },
  {
    path: URL_RESORT,
    loadChildren: () =>
      import('./resort/resort.module').then((m) => m.ResortModule)
  },
  {
    path: 'payment', // TO REMOVE
    redirectTo: URL_PAYMENT
  },
  {
    path: URL_PAYMENT,
    loadChildren: () =>
      import('./payment/payment.module').then((m) => m.PaymentModule)
  },
  {
    path: 'confirm-payment', // TO REMOVE
    redirectTo: URL_CONFIRM_PAYMENT
  },
  {
    path: URL_CONFIRM_PAYMENT,
    component: ConfirmPaymentComponent
  },
  {
    path: 'my-account', // TO REMOVE
    redirectTo: URL_ACCOUNT
  },
  {
    path: URL_ACCOUNT,
    loadChildren: () =>
      import('./my-account/my-account.module').then((m) => m.MyAccountModule)
  },
  {
    path: 'forgot-password', // TO REMOVE
    redirectTo: URL_FORGOT_PASSWORD
  },
  {
    path: URL_FORGOT_PASSWORD,
    component: ForgotPasswordComponent
  },
  {
    path: 'terms-and-conditions', // TO REMOVE
    redirectTo: URL_TERMS_AND_CONDITIONS
  },
  {
    path: URL_TERMS_AND_CONDITIONS,
    loadChildren: () =>
      import('./legal/terms-and-conditions/terms-and-conditions.module').then(
        (m) => m.TermsAndConditionsModule
      )
  },
  {
    path: 'eco-tourism-chart', // TO REMOVE
    redirectTo: URL_ECO_CHART
  },
  {
    path: URL_ECO_CHART,
    loadChildren: () =>
      import('./legal/eco-tourism-chart/eco-tourisme-chart.module').then(
        (m) => m.EcoTourismChartModule
      )
  },
  {
    path: 'selling-conditions', // TO REMOVE
    redirectTo: URL_SELLS_CONDITIONS
  },
  {
    path: URL_SELLS_CONDITIONS,
    loadChildren: () =>
      import('./legal/selling-conditions/selling-conditions.module').then(
        (m) => m.SellingConditionsModule
      )
  },
  {
    path: 'terms-of-use', // TO REMOVE
    redirectTo: URL_USE_AND_TERMS
  },
  {
    path: URL_USE_AND_TERMS,
    loadChildren: () =>
      import('./legal/terms-of-use/terms-of-use.module').then(
        (m) => m.TermsOfUseModule
      )
  },
  {
    path: 'confirmation-registration', // TO REMOVE
    redirectTo: URL_CONFIRMATION_ACCOUNT
  },
  {
    path: URL_CONFIRMATION_ACCOUNT,
    component: ConfirmationRegistrationComponent
  },
  {
    path: 'login',
    redirectTo: URL_LOGIN
  },
  {
    path: URL_LOGIN,
    component: LoginComponent
  },
  {
    path: 'about-us',
    redirectTo: URL_ABOUT_US
  },
  {
    path: URL_ABOUT_US,
    loadChildren: () =>
      import('./about-us/about-us.module').then((m) => m.AboutUsModule)
  },
  {
    path: 'delete-confirmation', // TO REMOVE
    redirectTo: URL_DELETE_CONFIRMATION
  },
  {
    path: URL_DELETE_CONFIRMATION,
    component: MobileDeleteAccountComponent
  },
  {
    path: 'accommodations', // TO REMOVE
    redirectTo: URL_SEARCH_ACCOMMODATIONS
  },
  {
    path: URL_SEARCH_ACCOMMODATIONS,
    component: AccommodationsComponent
  },
  {
    path: URL_SEARCH_RESORTS,
    component: SearchResortsComponent
  },
  {
    path: 'experiences', // TO REMOVE
    redirectTo: URL_SEARCH_EXPERIENCES
  },
  {
    path: URL_SEARCH_EXPERIENCES,
    component: ActivitiesComponent
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
