import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CopyCart } from '../shared/models/package/copy-cart';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class CartMasterService extends RepositoryService {
  private URL_ARTICLE = 'api/webapp/cart-master';

  constructor(http: HttpClient) {
    super(http);
  }

  copyCart(copyCart: CopyCart): Observable<void> {
    return this.post(`${this.URL_ARTICLE}/copy`, copyCart);
  }
}
