<nav [ngClass]="{
  'vsk__large': !isPaymentPage && !isLandingPage && !isDesktop,
  'bg-white': isLandingPage && !isDesktop,
  'bg-neutral-15': isMapPage && !isDesktop,
  'background-transparent': isLandingPage && isDesktop,
  }" class="vsk__navbar">
  <ng-container *ngIf="windowResource.width() | async | screen: 'small-desktop-above'">
    <a [routerLink]="(isFoncia$ | async) ? null : '/'"
       class="flex justify-center items-center mx-[1rem]"
       queryParamsHandling="merge"
       routerLinkActive="true">
      <img [src]="isLandingPage ? 'assets/images/Logo-white.svg' : 'assets/images/logo-black.png'" alt="Logo">
      <img *ngIf="(whiteLabel$ | async) === 'FONCIA'" [src]="'assets/images/foncia-logo.png'" alt="Logo Foncia"
           class="max-h-12">
      <img *ngIf="(whiteLabel$ | async) === 'GSI'" [src]="'assets/images/gsi-by-foncia.webp'" alt="Logo GSI by Foncia"
           class="ml-4 pb-2 max-h-8">
    </a>
    <vsk-form-date-range-picker *ngIf="showDatePicker(showInNavbar$ | async)"
                                [activitiesByCategories]="activitiesByCategories$ | async"
                                [activities]="activities$ | async"
                                [currentPage]="currentPage"
                                [hasAlterParticipants]="hasAlterParticipants$ | async"
                                [isDesktop]="true"
                                [isHome]="isLandingPage"
                                [isInNavbar]="true"
                                [participants]="participants$ | async"
                                [period]="period$ | async"
                                [searchMode]="searchMode$ | async"
                                [selectedActivities]="selectedActivities$ | async"
                                [showActivities]="isActivityPickerOpen$ | async"
                                [showDates]="isDatePickerOpen$ | async"
                                [showFilters]="isFiltersPickerOpen$ | async"
                                [showParticipants]="isParticipantPickerOpen$| async"
                                [withMaterial]="withMaterial$ | async"
                                [withSkiPackage]="withSkiPackage$ | async"
                                class="flex justify-center items-center z-9">
    </vsk-form-date-range-picker>
    <div class="flex flex-row justify-end items-center gap-4 ml-[1rem] fit-content">
      <div [ngClass]="{'is-landing': isLandingPage}" class="phone">
        <svg>
          <use [attr.xlink:href]="'assets/icons/icons.svg#phone-2'"></use>
        </svg>
        <a href="tel:+33465847469">04 65 84 74 69</a>
      </div>

      <atc-button (click)="openCart()"
                  *ngIf="!isPaymentPage"
                  [float]="true"
                  [text]="quantityItems$ | async" atcBadge
                  class="relative"
                  color="primary"
                  colorBadge="success"
                  icon="cart"></atc-button>
      <vsk-login-user [isLoggedIn]="loggedIn$ | async"
                      [ngClass]="{'mr-8': isPaymentPage}"
                      [user]="user$ | async">
      </vsk-login-user>
    </div>
  </ng-container>

  <ng-container *ngIf="!(windowResource.width() | async | screen: 'small-desktop-above')">
    <div class="vsk__inline">
      <div class="vsk__position-menu">
        <atc-button (click)="goBack()"
                    *ngIf="!isLandingPage && !isPaymentPage"
                    class="vsk__button-icon"
                    color="ternary"
                    icon="left-arrow"></atc-button>
        <a class="phone-link"
           href="tel:+33465847469">
          <svg>
            <use [attr.xlink:href]="'assets/icons/icons.svg#phone-2'"></use>
          </svg>
        </a>
      </div>
      <a [routerLink]="(isFoncia$ | async) ? null : '/'" class="vsk__logo" routerLinkActive="true">
        <img [src]="isLandingPage ? 'assets/images/logo-mobile-blanc.png' : 'assets/images/logo-mobile.svg'"
             alt="Logo"
             class="w-12">
        <ng-container *ngIf="(whiteLabel$ | async) === 'FONCIA'">
          <img [src]="'assets/images/foncia-logo.png'" alt="Logo Foncia" class="max-h-10 pl-4">
        </ng-container>
        <ng-container *ngIf="(whiteLabel$ | async) === 'GSI'">
          <img [src]="'assets/images/gsi-by-foncia.webp'" alt="Logo GSI by Foncia" class="max-h-5 pl-4">
        </ng-container>
      </a>
      <div class="vsk__position-cart">
        <atc-button (click)="openCart()"
                    *ngIf="!isPaymentPage"
                    [float]="true"
                    [text]="quantityItems$ | async"
                    atcBadge
                    color="primary"
                    colorBadge="success"
                    icon="cart">
        </atc-button>
        <atc-button (click)="openMenu()" *ngIf="!isPaymentPage" class="vsk__button-icon" color="ternary"
                    icon="person"></atc-button>
      </div>
    </div>
    <vsk-form-date-range-picker [activitiesByCategories]="activitiesByCategories$ | async"
                                [activities]="activities$ | async"
                                [currentPage]="currentPage"
                                [hasAlterParticipants]="hasAlterParticipants$ | async"
                                [isDesktop]="windowResource.isAboveTablet()"
                                [isHome]="isLandingPage"
                                [isInNavbar]="true"
                                [participants]="participants$ | async"
                                [period]="period$ | async"
                                [resorts]="resorts$ | async"
                                [searchMode]="searchMode$ | async"
                                [selectedActivities]="selectedActivities$ | async"
                                [showActivities]="isActivityPickerOpen$ | async"
                                [showDates]="isDatePickerOpen$ | async"
                                [showFilters]="isFiltersPickerOpen$ | async"
                                [showParticipants]="isParticipantPickerOpen$| async"
                                [showSearchPopup]="isChangingFilters$ | async"
                                [withMaterial]="withMaterial$ | async"
                                [withSkiPackage]="withSkiPackage$ | async"
                                class="fixed bottom-0 left-0 right-0">
    </vsk-form-date-range-picker>
    <ng-container *ngIf="cart$ | async as cart">
      <atc-button (click)="goToPayment()"
                  *ngIf="!isPaymentPage && (isChangingFilters$ | async) === false && !cart.isEmpty"
                  [float]="true"
                  [label]="labelPayment(cart)"
                  [ngClass]="{'hidden': isChangingFilters$ | async, 'bottom-16 right-[1rem]': isLandingPage, 'right-8 bottom-[8rem]': !isLandingPage}"
                  class="fixed z-[0]"
                  color="secondary"
                  icon="activity">
      </atc-button>
    </ng-container>
  </ng-container>
</nav>

<vsk-cart [showCart]="isCartOpen$ | async"></vsk-cart>
<vsk-menu [(showMenu)]="showMenu"></vsk-menu>
