import { NgModule } from '@angular/core';
import { FooterModule } from '../footer/footer.module';
import { SharedModule } from '../shared/shared.module';
import { AboutUsRoutingModule } from './about-us-routing.module';
import { AboutUsComponent } from './about-us.component';

@NgModule({
  declarations: [AboutUsComponent],
  imports: [SharedModule, FooterModule, AboutUsRoutingModule],
  exports: [AboutUsComponent]
})
export class AboutUsModule {}
