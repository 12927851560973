import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * @deprecated : Use WindowResource in @atomic-lib
 */
@Injectable()
export class WindowResource {
  public static readonly WIDTH_MAX_SMALL_DESKTOP = 1350;
  public static readonly WIDTH_MIN_SMALL_DESKTOP = 1100;
  public static readonly WIDTH_MIN_TABLET = 800;
  widthChange: Observable<number>;

  constructor() {
    this.widthChange = fromEvent(window, 'resize').pipe(
      map((value: any) => value.target?.innerWidth)
    );
  }

  isDesktop(): boolean {
    return window.innerWidth >= WindowResource.WIDTH_MAX_SMALL_DESKTOP;
  }

  isAboveTablet(): boolean {
    return window.innerWidth >= WindowResource.WIDTH_MIN_SMALL_DESKTOP;
  }

  width(): Observable<number> {
    return this.widthChange;
  }
}
