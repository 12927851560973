import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortById'
})
export class SortByIdPipe implements PipeTransform {
  transform(value: any[], property = 'id'): any[] {
    return value.sort((val1, val2) =>
      val2[property] <= val1[property] ? 1 : -1
    );
  }
}
