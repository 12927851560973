<div class="mx-auto flex w-[100%] max-w-[1350px] flex-col justify-between gap-4 px-8 pb-[4rem] pt-2 xl:px-0 md:gap-[2rem]">

  <div class="justify-article mx-auto mt-[2rem]">
    <h1 class="black">Toutes nos stations</h1>
    <h2 class="black">Disponible tout au long de l'année.</h2>
    <vsk-search-bar (filteredValues)="onFilteredValues($event)"
                    [values]="allStations || []"
                    class="flex-1 mt-[1rem]"
                    placeholder="Rechercher une station ..."
                    property="name">
    </vsk-search-bar>

    <atc-button
      (click)="scrollToTop()"
      class="btn"
      color="ternary"
      icon="arrow-circle-right"
      size="medium"
    [float]="true">
    </atc-button>

    <section class="bg-color-neutral-20 flex align-center w-[100%] flex-col justify-between gap-[2.5rem] pb-[4rem] xl:flex-col lg:gap-[30px] md:gap-[2rem]">
      <article class="justify-article mx-auto">
        <div class="grid-container mt-[2rem] carousel">
          <ng-container *ngFor="let resort of productResort">

            <atc-card-product-presentation
              [srcResort]="resort.srcResort"
              [description]="resort.description"
              [background]="resort.background"
              [title]="resort.title"
              [link]="resort.link"
              [params]="resort.params">
            </atc-card-product-presentation>

          </ng-container>
        </div>
      </article>
    </section>
  </div>
</div>

<vsk-footer></vsk-footer>

