import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FiltersState } from 'src/app/filters.state';
import { URL_SEARCH_TRIP } from '../../../app-routing.module';
import { ActivitiesByCategory } from '../../../shared/models/activity/activities-by-category';
import { Activity } from '../../../shared/models/activity/activity';
import {
  ActivityPickerOpen,
  SelectActivity,
  UnselectActivity,
  UnselectAllActivities
} from '../../navbar.action';

@Component({
  selector: 'vsk-form-activity',
  templateUrl: './form-activity.component.html',
  styleUrls: ['./form-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormActivityComponent {
  @Select(FiltersState.isFoncia) isFoncia$: Observable<boolean>;

  readonly LEFT = '-245px';
  readonly WIDTH = '675px';
  filteredActivities: Activity[] = [];
  searchForm: FormControl<string | null> = new FormControl<string>('');

  @Input() activities: Activity[];
  @Input() activitiesByCategories: ActivitiesByCategory[];
  @Input() responsive = false;
  @Input() isInNavbar = false;
  @Input() showPicker = false;
  @Input() currentPage: 'Home' | 'Map' | 'Resort' | 'none' = 'none';

  @Output() validate = new EventEmitter<void>();
  @Output() partialValidate = new EventEmitter<void>();
  protected readonly URL_SEARCH_TRIP = URL_SEARCH_TRIP;

  constructor(
    private store: Store,
    private router: Router
  ) {
    this.searchForm.valueChanges.subscribe((search) => {
      if (search) {
        this.filteredActivities = this.activities.filter(
          (activity) =>
            this.transformString(activity.name).indexOf(
              this.transformString(search)
            ) !== -1
        );
      } else {
        this.filteredActivities = [];
      }
    });
  }

  goTo(destination: string) {
    this.partialValidate.emit();
    this.router.navigate([destination]);
  }

  update() {
    this.partialValidate.emit();
  }

  validation(): void {
    this.showPicker = false;
    this.validate.emit();
  }

  toggleActivity(activity: Activity): void {
    if (activity.selected) {
      this.store.dispatch(new SelectActivity(activity));
    } else if (!activity.selected) {
      this.store.dispatch(new UnselectActivity(activity));
    }
  }

  openChange(newValue: boolean): void {
    this.store.dispatch(new ActivityPickerOpen(newValue));
  }

  subTitleActivities(): string {
    const activitiesSelected = this.getActivitiesSelected();
    return activitiesSelected.length > 0
      ? activitiesSelected
          .map((activity) => activity.name)
          .reduce((prev, current) => prev + ', ' + current)
      : 'Activités';
  }

  getActivitiesSelected(): Activity[] {
    if (!this.activities?.length) {
      return [];
    }

    return this.activities.filter((act) => act.selected);
  }

  isSelected() {
    return this.getActivitiesSelected().length > 0;
  }

  filterActivity(activity: Activity): boolean {
    if (this.filteredActivities.length === 0) {
      return true;
    }

    return (
      this.filteredActivities.find(
        (filteredActivity) => filteredActivity.id === activity.id
      ) !== undefined
    );
  }

  isInCategory(categoryName: string) {
    if (!this.filteredActivities.length) {
      return true;
    }

    return this.filteredActivities.filter(
      (activity) => activity.category.name === categoryName
    ).length;
  }

  resetActivities() {
    this.store.dispatch(new UnselectAllActivities());
  }

  transformString(value: string) {
    return value
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .trim()
      .toLowerCase();
  }
}
