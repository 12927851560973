import { Moment } from 'moment/moment';
import { Activity } from '../shared/models/activity/activity';
import { Participant } from '../shared/models/participant/participant';
import { Period } from '../shared/models/period';

export class AddParticipant {
  static readonly type = '[NavbarState] Add participant';

  constructor(public participant: Participant) {}
}

export class AddParticipants {
  static readonly type = '[NavbarState] Add participants';

  constructor(public participants: Participant[]) {}
}

export class RemoveParticipant {
  static readonly type = '[NavbarState] Remove participant by uuid';

  constructor(public uuid: string) {}
}

export class ChangeDates {
  static readonly type = '[NavbarState] Change dates';

  constructor(
    public startDate: Moment,
    public endDate: Moment
  ) {}
}

export class SelectActivity {
  static readonly type = '[NavbarState] Add activity';

  constructor(public activity: Activity) {}
}

export class UnselectActivity {
  static readonly type = '[NavbarState] Remove activity';

  constructor(public activity: Activity) {}
}

export class SetResorts {
  static readonly type = '[NavbarState] Set resort';

  constructor(public resorts: number[]) {}
}

export class UnselectAllActivities {
  static readonly type = '[NavbarState] Remove all activities';

  constructor() {}
}

export class Search {
  static readonly type = '[NavbarState] Trigger search with updated filters';

  constructor(public activity?: Activity) {}
}

export class InitNavbar {
  static readonly type = '[NavbarState] Init state';

  constructor(
    public participants: Participant[],
    public activities: Activity[],
    public resorts: number[],
    public period: Period,
    public withSkiPackage: boolean,
    public withMaterial: boolean
  ) {}
}

export class SetShowInNavbar {
  static readonly type = '[NavbarState] Set show in navbar';

  constructor(public showInNavbar: boolean) {}
}

export class DatePickerOpen {
  static readonly type = '[NavbarState] Set date picker status';

  constructor(public open: boolean) {}
}

export class ActivityPickerOpen {
  static readonly type = '[NavbarState] Set activity picker status';

  constructor(public open: boolean) {}
}

export class ParticipantPickerOpen {
  static readonly type = '[NavbarState] Set participant picker status';

  constructor(public open: boolean) {}
}

export class SetSkiPackage {
  static readonly type = '[NavbarState] Set ski package filter';

  constructor(public withSkiPackage: boolean) {}
}

export class SetMaterial {
  static readonly type = '[NavbarState] Set material filter';

  constructor(public withMaterial: boolean) {}
}

export class NavbarAction {}
