<section *ngIf="filtersInfo"
         [ngClass]="{'sticky': sticky$ | async, 'z-[3]': isPopupOpen}"
         [ngStyle]="(isFoncia$ | async) ? { top: '0' } : { top: '3.5rem'}"
         class="filters-wrapper">
  <vsk-experience-filters
    [activities]="activities$ | async"
    [filtersInfo]="allFiltersInfo"
    [filters]="filters"
    class="filters-content">
  </vsk-experience-filters>
</section>

<div class="flex flex-col justify-between w-[100%] relative pt-4 px-[30px]
            xl:flex-col xl:px-0 gap-y-4 md:gap-[0px]
            sm:px-[0px] content sm:pt-0 md:pt-0 py-4">
  <div *ngIf="!filtersInfo && (windowResource.width() | async | screen: 'tablet-above')"
       class="rounded-[20px] h-[70px]"
       vskSkeletonLoad></div>

  <h1 *ngIf="filtersInfo?.total"
      class="sm:text-center md:text-center sm:pb-[10px]">
    <b>{{ filtersInfo.total }}</b>
    {{ filtersInfo.total > 1 ? 'Activités à pratiquer · ' : 'Activité à pratiquer · ' }}
    <ng-container *ngIf="resort$ | async as resort">
      {{ resort.name }}
    </ng-container>
  </h1>

  <div class="flex flex-row gap-x-4 justify-between w-full min-h-full xl:w-[1350px]">
    <div (scrolled)="loadMoreExperiences()"
         [infiniteScrollContainer]="elRef.nativeElement.parentElement"
         [infiniteScrollDistance]="2"
         class="w-[100%] max-h-full pb-[35rem]
                    sm:pb-[10rem] sm:gap-2 sm:px-[1rem]
                    md:flex md:flex-col md:gap-4 md:px-[1rem] md:pb-[10rem]
                    lg:flex-col lg:gap-4 grid-cols-1 lg:grid-cols-1 lg:grid lg:pb-[8rem]
                    xl:flex-1 xl:grid-cols-1
                    2xl:grid-cols-1 xl:grid xl:gap-y-8 gap-x-4"
         infiniteScroll>

      <div *ngIf="!loaderExperiences && !filtersInfo?.total" class="w-full text-center p-4">
        <p>
          Oops ! Aucune activité ne concorde avec vos envies.
        </p>
        <p class="mb-2">Ajustez vos dates ou ajustez vos filtres pour trouver la perle rare parmi nos offres !</p>
        <atc-button (click)="clearFilters()"
                    class="block m-auto pb-8"
                    color="ternary"
                    icon="trash"
                    label="On recommence a zero ?"
                    size="medium">
        </atc-button>

        <ng-container *ngIf="!loaderExperiences && previousFiltersInfo?.total">
          <h3 class="text-left mb-3 pb-3">Votre précédente recherche : </h3>
          <ng-container *ngFor="let group of previousExperienceGroups">
            <atc-card-resort-scrollable (mouseenter)="overMarker(group.marker)"
                                        (mouseleave)="outMarker(group.marker)"
                                        (openPictures)="pictures = group.pictures"
                                        [address]="group.address"
                                        [cardsLink]="group.linksCard"
                                        [nbElements]="group.experiences.length"
                                        [nbPhotos]="group.pictures.length"
                                        [nbRatings]="group.nbReviews"
                                        [openInNewTab]="windowResource.width() | async | screen: 'small-desktop-above'"
                                        [pictures]="group.pictures"
                                        [starRating]="group.review"
                                        [subtitle]="group.partnerName"
                                        [title]="group.activityName"
                                        [withHeart]="group.heart">
            </atc-card-resort-scrollable>
          </ng-container>
        </ng-container>
      </div>

      <ng-container *ngIf="loaderExperiences">
        <div *ngFor="let nb of [0,1,2]"
             class="rounded-[20px] h-[350px] w-[100%]" vskSkeletonLoad></div>
      </ng-container>

      <ng-container *ngFor="let group of experienceGroups">
        <atc-card-resort-scrollable (mouseenter)="overMarker(group.marker)"
                                    (mouseleave)="outMarker(group.marker)"
                                    (openPictures)="pictures = group.pictures"
                                    [address]="group.address"
                                    [cardsLink]="group.linksCard"
                                    [nbElements]="group.experiences.length"
                                    [nbPhotos]="group.pictures.length"
                                    [nbRatings]="group.nbReviews"
                                    [pictures]="group.pictures"
                                    [starRating]="group.review"
                                    [subtitle]="group.partnerName"
                                    [title]="group.activityName"
                                    [withHeart]="group.heart">
        </atc-card-resort-scrollable>
      </ng-container>
    </div>

    <google-map
      (mapDragend)="boundsChanged()"
      *ngIf="windowResource.width() | async | screen: 'small-desktop-above'"
      [center]="center"
      [options]="options"
      [zoom]="zoom"
      class="flex-1 sticky map-activity"
      height="500px">

      <ng-container *ngIf="filtersInfo?.total">
        <map-marker
          #mapMarker="mapMarker"
          (mapClick)="openInfoWindow(marker, mapMarker)"
          (mapMouseout)="outMarker(marker)"
          (mapMouseover)="overMarker(marker)"
          *ngFor="let marker of markers"
          [icon]="marker.getIcon()!"
          [label]="marker.getLabel()!"
          [options]="getOptions(marker)"
          [position]="marker.getPosition()!"
          [title]="marker.getTitle()!">
        </map-marker>
      </ng-container>

      <map-marker
        *ngIf="markerResort"
        [icon]="markerResort.getIcon()!"
        [options]="getOptions(markerResort)"
        [position]="markerResort.getPosition()!"
        [title]="markerResort.getTitle()!">
      </map-marker>

      <map-info-window>
        <atc-card-resort-scrollable (openPictures)="pictures = pinpoint.pictures"
                                    *ngIf="pinpoint"
                                    [cardsLink]="pinpoint.linksCard"
                                    [nbElements]="pinpoint.experiences.length"
                                    [nbPhotos]="pinpoint.pictures.length"
                                    [nbRatings]="pinpoint.nbReviews"
                                    [pictures]="pinpoint.pictures"
                                    [pinpoint]="true"
                                    [starRating]="pinpoint.review"
                                    [subtitle]="pinpoint.partnerName"
                                    [title]="pinpoint.activityName"
                                    [withHeart]="pinpoint.heart">
        </atc-card-resort-scrollable>
      </map-info-window>
    </google-map>
  </div>

  <atc-popup (closeChanged)="pictures = []"
             *ngIf="pictures.length"
             [showBody]="false"
             [slides]="pictures">
  </atc-popup>
</div>

<vsk-footer [isHome]="false"></vsk-footer>
