import moment, { Moment } from 'moment';
import { Origin } from '../enum/origin.enum';
import { Package } from '../package/package';

export class TimeSlotExperience {
  id: number;
  timeSlotModelId: number;
  origin: Origin;
  dateStart: Moment;
  hourStart: Moment;
  hourEnd: Moment;
  remainingSlots: number;
  slotsAvailable: number;
  packages: Package[] = [];

  constructor(obj: Partial<TimeSlotExperience>) {
    Object.assign(this, obj);
    this.dateStart = moment(this.dateStart);
    this.hourStart = moment(this.hourStart, 'HH:mm:ss');
    this.hourEnd = moment(this.hourEnd, 'HH:mm:ss');

    if (this.packages) {
      this.packages = this.packages.map((pack) => new Package({ ...pack }));
    }
  }
}
