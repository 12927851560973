import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({ template: '' })
export class RxjsComponent implements OnDestroy {
  subscriptions: Subscription[] = [];

  register(sub: Subscription | null | undefined): void {
    if (sub) {
      this.subscriptions.push(sub);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
