import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import _ from 'lodash-es';
import { Moment } from 'moment';
import { map } from 'rxjs/operators';
import { CartState } from '../cart/cart.state';
import { FiltersNavbarChanged } from '../filters.action';
import { ActivityService } from '../service/activity.service';
import { ActivitiesByCategory } from '../shared/models/activity/activities-by-category';
import { Activity } from '../shared/models/activity/activity';
import { Participant } from '../shared/models/participant/participant';
import { Period } from '../shared/models/period';
import {
  ActivityPickerOpen,
  AddParticipant,
  AddParticipants,
  ChangeDates,
  DatePickerOpen,
  InitNavbar,
  ParticipantPickerOpen,
  RemoveParticipant,
  Search,
  SelectActivity,
  SetMaterial,
  SetResorts,
  SetShowInNavbar,
  SetSkiPackage,
  UnselectActivity,
  UnselectAllActivities
} from './navbar.action';

export interface NavbarStateModel {
  participants: Participant[];
  activities: Activity[];
  resorts: number[];
  startDate: Moment | null;
  endDate: Moment | null;
  withSkiPackage: boolean;
  withMaterial: boolean;
  showInNavbar: boolean;
  isDatePickerOpen: boolean;
  isActivityPickerOpen: boolean;
  isParticipantPickerOpen: boolean;
  isFiltersPickerOpen: boolean;
  hasAlterParticipants: boolean;
}

@State<NavbarStateModel>({
  name: 'navbar',
  defaults: {
    participants: [],
    activities: [],
    resorts: [],
    startDate: null,
    endDate: null,
    withSkiPackage: false,
    withMaterial: false,
    showInNavbar: true,
    isDatePickerOpen: false,
    isActivityPickerOpen: false,
    isParticipantPickerOpen: false,
    isFiltersPickerOpen: false,
    hasAlterParticipants: false
  }
})
@Injectable()
export class NavbarState {
  constructor(
    private activityService: ActivityService,
    private store: Store
  ) {}

  @Selector()
  static period(state: NavbarStateModel): Period {
    return new Period({
      startDate: state.startDate as Moment,
      endDate: state.endDate as Moment
    });
  }

  @Selector()
  static participants(state: NavbarStateModel): Participant[] {
    return state.participants.map(
      (participant) => new Participant(participant)
    );
  }

  @Selector()
  static activities(state: NavbarStateModel): Activity[] {
    return state.activities.map((activity) => new Activity(activity));
  }

  @Selector()
  static activitiesByCategories(
    state: NavbarStateModel
  ): ActivitiesByCategory[] {
    return _.chain(state.activities)
      .groupBy((activity) => activity.category.name)
      .map((key: number, values: Activity[]) => ({
        categoryName: values,
        activities: key
      }))
      .value()
      .map((value: any) => {
        return {
          ...value
        } as ActivitiesByCategory;
      });
  }

  @Selector()
  static selectedActivities(state: NavbarStateModel): Activity[] {
    return state.activities
      .map((activity) => new Activity(activity))
      .filter((activity) => activity.selected);
  }

  @Selector()
  static withSkiPackage(state: NavbarStateModel): boolean {
    return state.withSkiPackage;
  }

  @Selector()
  static withMaterial(state: NavbarStateModel): boolean {
    return state.withMaterial;
  }

  @Selector()
  static showInNavbar(state: NavbarStateModel): boolean {
    return state.showInNavbar;
  }

  @Selector()
  static isDatePickerOpen(state: NavbarStateModel): boolean {
    return state.isDatePickerOpen;
  }

  @Selector()
  static isActivityPickerOpen(state: NavbarStateModel): boolean {
    return state.isActivityPickerOpen;
  }

  @Selector()
  static isParticipantPickerOpen(state: NavbarStateModel): boolean {
    return state.isParticipantPickerOpen;
  }

  @Selector()
  static isFiltersPickerOpen(state: NavbarStateModel): boolean {
    return state.isFiltersPickerOpen;
  }

  @Selector()
  static hasAlterParticipants(state: NavbarStateModel): boolean {
    return state.hasAlterParticipants;
  }

  static selectActivity(
    activities: Activity[],
    activitySelected: Activity
  ): Activity[] {
    return activities.map((activity) => {
      if (activity.name === activitySelected.name) {
        return new Activity({ ...activitySelected });
      } else {
        return new Activity(activity);
      }
    });
  }

  static unselectActivity(
    activities: Activity[],
    activitySelected: Activity
  ): Activity[] {
    return activities.map((activity) => {
      if (activity.name === activitySelected.name) {
        activity.selected = false;
      }
      return new Activity(activity);
    });
  }

  static unselectAllActivities(activities: Activity[]): Activity[] {
    return activities.map((activity) => {
      activity.selected = false;
      return new Activity(activity);
    });
  }

  @Action(SetShowInNavbar)
  setShowInNavbar(
    ctx: StateContext<NavbarStateModel>,
    action: SetShowInNavbar
  ): void {
    if (ctx.getState().showInNavbar !== action.showInNavbar) {
      ctx.patchState({
        showInNavbar: action.showInNavbar
      });
    }
  }

  @Action(DatePickerOpen)
  datePickerOpen(
    ctx: StateContext<NavbarStateModel>,
    action: DatePickerOpen
  ): void {
    if (ctx.getState().isDatePickerOpen !== action.open) {
      ctx.patchState({
        isDatePickerOpen: action.open
      });
    }
  }

  @Action(ActivityPickerOpen)
  activityPickerOpen(
    ctx: StateContext<NavbarStateModel>,
    action: ActivityPickerOpen
  ): void {
    if (ctx.getState().isActivityPickerOpen !== action.open) {
      ctx.patchState({
        isActivityPickerOpen: action.open
      });
    }
  }

  @Action(ParticipantPickerOpen)
  participantPickerOpen(
    ctx: StateContext<NavbarStateModel>,
    action: ParticipantPickerOpen
  ): void {
    if (ctx.getState().isParticipantPickerOpen !== action.open) {
      ctx.patchState({
        isParticipantPickerOpen: action.open
      });
    }
  }

  @Action(SetSkiPackage)
  setSkiPackage(
    ctx: StateContext<NavbarStateModel>,
    action: SetSkiPackage
  ): void {
    ctx.patchState({
      withSkiPackage: action.withSkiPackage
    });
  }

  @Action(SetMaterial)
  setMaterial(ctx: StateContext<NavbarStateModel>, action: SetMaterial): void {
    ctx.patchState({
      withMaterial: action.withMaterial
    });
  }

  @Action(AddParticipant)
  addParticipant(
    ctx: StateContext<NavbarStateModel>,
    action: AddParticipant
  ): void {
    const state = ctx.getState();

    if (action.participant === null || action.participant === undefined) {
      return;
    }

    const existingParticipants = state.participants || [];

    ctx.setState({
      ...state,
      participants: [
        ...existingParticipants.filter(
          (participant) => action.participant.uuid !== participant.uuid
        ),
        action.participant
      ]
    });
  }

  @Action(AddParticipants)
  addParticipants(
    ctx: StateContext<NavbarStateModel>,
    action: AddParticipants
  ): void {
    const state = ctx.getState();

    if (
      action.participants === null ||
      action.participants === undefined ||
      !action.participants.length
    ) {
      return;
    }

    ctx.setState({
      ...state,
      participants: [...action.participants]
    });
  }

  @Action(RemoveParticipant)
  removeParticipant(
    ctx: StateContext<NavbarStateModel>,
    action: RemoveParticipant
  ): void {
    const state = ctx.getState();

    if (action.uuid === null || action.uuid === undefined) {
      return;
    }

    const cart = this.store.selectSnapshot(CartState.cart);

    ctx.setState({
      ...state,
      hasAlterParticipants: cart.hasParticipant(
        state.participants.find(
          (participant) => action.uuid !== participant.uuid
        ) as Participant
      ),
      participants: [
        ...state.participants.filter(
          (participant) => action.uuid !== participant.uuid
        )
      ]
    });
  }

  @Action(ChangeDates)
  changeDates(ctx: StateContext<NavbarStateModel>, action: ChangeDates): void {
    const state = ctx.getState();

    if (!action.startDate && !action.endDate) {
      return;
    }

    this.activityService
      .getActivitiesForDates(action.startDate, action.endDate)
      .pipe(map((activities) => this.mapActivities(ctx.getState(), activities)))
      .subscribe((activities) => {
        ctx.setState({
          ...state,
          activities,
          startDate: action.startDate.clone(),
          endDate: action.endDate.clone()
        });
      });
  }

  @Action(SelectActivity)
  addActivity(
    ctx: StateContext<NavbarStateModel>,
    action: SelectActivity
  ): void {
    const state = ctx.getState();

    if (action.activity === null || action.activity === undefined) {
      return;
    }

    ctx.setState({
      ...state,
      activities: [
        ...NavbarState.selectActivity(state.activities, action.activity)
      ]
    });
  }

  @Action(UnselectActivity)
  removeActivity(
    ctx: StateContext<NavbarStateModel>,
    action: UnselectActivity
  ): void {
    const state = ctx.getState();

    if (action.activity === null || action.activity === undefined) {
      return;
    }

    ctx.setState({
      ...state,
      activities: [
        ...NavbarState.unselectActivity(state.activities, action.activity)
      ]
    });
  }

  @Action(SetResorts)
  setResorts(ctx: StateContext<NavbarStateModel>, action: SetResorts): void {
    ctx.patchState({
      resorts: [...action.resorts]
    });
  }

  @Action(UnselectAllActivities)
  unselectAllActivities(ctx: StateContext<NavbarStateModel>): void {
    const state = ctx.getState();

    ctx.patchState({
      activities: [...NavbarState.unselectAllActivities(state.activities)]
    });
  }

  @Action(InitNavbar)
  init(ctx: StateContext<NavbarStateModel>, action: InitNavbar): void {
    ctx.patchState({
      hasAlterParticipants: false,
      participants: action.participants,
      startDate: action.period.startDate,
      endDate: action.period.endDate,
      activities: action.activities,
      resorts: action.resorts,
      withSkiPackage: action.withSkiPackage,
      withMaterial: action.withMaterial
    });
  }

  @Action(Search)
  search(ctx: StateContext<NavbarStateModel>, action: Search) {
    if (action.activity) {
      ctx.patchState({
        activities: [...ctx.getState().activities, action.activity]
      });
    }

    return this.store.dispatch(new FiltersNavbarChanged(ctx.getState()));
  }

  private mapActivities(
    state: NavbarStateModel,
    activities: Activity[]
  ): Activity[] {
    if (state.activities.length === 0) {
      return activities;
    }

    return activities.map((activity) => {
      const exist = state.activities.find(
        (activityState) => activityState.id === activity.id
      );
      return exist ?? activity;
    });
  }
}
