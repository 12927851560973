import { Component } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { switchMap, tap } from 'rxjs/operators';
import { TriggerAlert } from '../app.action';
import { AccountService } from '../service/account.service';
import { Alert } from '../shared/models/alert';

@Component({
  selector: 'vsk-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  error: string;
  emailForm = new UntypedFormControl('', Validators.required);
  passwordFirstForm = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(
      '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&-*()]).{8,}$'
    )
  ]);
  passwordSecondForm = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(
      '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&-*()]).{8,}$'
    )
  ]);
  resetPasswordGroup: UntypedFormGroup;

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store
  ) {
    document.title = 'Mot de passe oublié - VeryMountain';

    // Init form
    this.resetPasswordGroup = new UntypedFormGroup({
      email: this.emailForm,
      password: this.passwordFirstForm
    });

    // Get email in url
    this.route.queryParams
      .pipe(
        tap((value) =>
          this.resetPasswordGroup.get('email')?.setValue(value.email)
        )
      )
      .subscribe();
  }

  /**
   * Check if form is valid and change password
   * -> In error case, show a specific message
   */
  validate(): void {
    if (
      this.resetPasswordGroup.valid &&
      this.passwordFirstForm.value === this.passwordSecondForm.value
    ) {
      this.route.queryParams
        .pipe(
          switchMap((value) =>
            this.accountService.resetPassword(
              value.token,
              this.resetPasswordGroup.getRawValue()
            )
          )
        )
        .subscribe(
          () => {
            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  level: 'success',
                  message: 'Votre mot de passe a été mis a jour !',
                  timeout: 10000
                })
              )
            );
            return this.router.navigate(['/']);
          },
          (err) => {
            switch (err.status) {
              case 500:
                this.error =
                  'Une erreur inattendue est survenue, veuillez réessayer ultérieurement';
                break;
              case 400:
                this.error = err.error;
                break;
            }
          }
        );
    } else if (this.passwordFirstForm.value !== this.passwordSecondForm.value) {
      this.error = 'Les mots de passe ne sont pas identiques';
    } else {
      this.error =
        'Le mot de passe doit contenir au moins 8 caractères dont au moins 1 majuscule, 1 minuscule, 1 caractère spécial (#?!@$%^&-*()) et 1 chiffre';
    }
  }
}
