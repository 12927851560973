import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, RouterEvent } from '@angular/router';
import { Store } from '@ngxs/store';
import { WindowResource } from 'atomic-lib';
import { UpdateAccommodationRemarks } from '../../cart/cart.action';
import { AddParticipants } from '../../filters.action';
import { Cart } from '../../shared/models/cart/cart';
import { Participant } from '../../shared/models/participant/participant';
import { UserSession } from '../../shared/models/user-session';

@Component({
  selector: 'vsk-step-participants-information',
  templateUrl: './step-participants-information.component.html',
  styleUrls: ['./step-participants-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepParticipantsInformationComponent implements OnChanges {
  error = false;
  participantsEdit: Participant[] = [];
  showCart = false;

  instructionsForm: FormControl<string | null> = new FormControl<string | null>(
    ''
  );
  @Input() participants: Participant[] = [];
  @Input() cart: Cart;
  @Input() userSession: UserSession;
  @Output() next = new EventEmitter<void>();
  @Output() previous = new EventEmitter<void>();

  constructor(
    private store: Store,
    public windowResource: WindowResource,
    private location: Location,
    private router: Router
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof RouterEvent) {
        this.store.dispatch(new AddParticipants(this.participantsEdit));
      }
    });
  }

  get isFormValid() {
    return (
      !this.participantsEdit.some(
        (participant) => !participant.isValidPayment()
      ) && this.participantsEdit.length === this.participants.length
    );
  }

  ngOnChanges(): void {
    if (this.cart && this.cart.quantityAccommodation > 0) {
      this.instructionsForm.setValue(this.cart.itemsAccommodation[0].remarks);
    }
  }

  goNextStep(): void {
    if (this.isFormValid) {
      if (this.instructionsForm.value) {
        this.store.dispatch(
          new UpdateAccommodationRemarks(this.instructionsForm.value)
        );
      }
      this.store
        .dispatch(new AddParticipants(this.participantsEdit))
        .subscribe(() => this.next.emit());
    } else {
      this.error = true;
    }
  }

  addParticipantValid(participant: Participant): void {
    this.participantsEdit = [
      ...this.participantsEdit.filter((p) => p.uuid !== participant.uuid),
      participant
    ];
  }

  previousPage(): void {
    this.store.dispatch(new AddParticipants(this.participantsEdit));
    this.previous.emit();
  }

  getSkiCardForParticipant(participant: Participant) {
    if (!this.cart || this.cart.isEmpty) {
      return undefined;
    }

    const items = this.cart.getItemsActivityByParticipant(participant);
    return items.find((item) => item.ski)?.skiPass || undefined;
  }
}
