import moment, { Moment } from 'moment';

export class OrderHistory {
  transactionId: string;
  buyDate: Moment;
  destination: string;
  startDate: Moment;
  endDate: Moment;
  ecoTourismChart: boolean;

  constructor(obj: OrderHistory) {
    Object.assign(this, obj);
    this.buyDate = moment(this.buyDate);
    this.startDate = moment(this.startDate);
    this.endDate = moment(this.endDate);
  }
}
