import moment, { Moment } from 'moment';
import { FiltersAccommodation } from '../accommodation/filters-accommodation';

export class FiltersAccommodationSearch extends FiltersAccommodation {
  resorts: number[] = [];
  startDate: Moment;
  endDate: Moment;
  nbParticipants: number;

  constructor(obj?: Partial<FiltersAccommodationSearch>) {
    super(obj);

    if (obj) {
      Object.assign(this, obj);

      if (this.startDate) {
        this.startDate = moment(this.startDate, 'YYYY-MM-DD');
      }

      if (this.endDate) {
        this.endDate = moment(this.endDate, 'YYYY-MM-DD');
      }
    }
  }

  toQueryParams() {
    return {
      resorts: this.resorts.length ? this.resorts : null,
      selectedTags: this.selectedTags.length ? this.selectedTags : null,
      selectedRegions: this.selectedRegions.length
        ? this.selectedRegions
        : null,
      selectedLabels: this.selectedLabels.length ? this.selectedLabels : null,
      packagesDisplayed: this.packagesDisplayed ? this.packagesDisplayed : null,
      materialDisplayed: this.materialDisplayed ? this.materialDisplayed : null,
      pool: this.pool ? this.pool : null,
      spa: this.spa ? this.spa : null,
      sauna: this.sauna ? this.sauna : null,
      hammam: this.hammam ? this.hammam : null,
      balconyTerrace: this.balconyTerrace ? this.balconyTerrace : null,
      tv: this.tv ? this.tv : null,
      chimney: this.chimney ? this.chimney : null,
      bbq: this.bbq ? this.bbq : null,
      pmr: this.pmr ? this.pmr : null,
      parking: this.parking ? this.parking : null,
      animalsAdmitted: this.animalsAdmitted ? this.animalsAdmitted : null,
      wifi: this.wifi ? this.wifi : null,
      childrenClub: this.childrenClub ? this.childrenClub : null,
      types: this.types.length ? this.types : null,
      promo: this.promo ? this.promo : null
    };
  }
}
