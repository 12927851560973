import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ParticipantService } from '../service/participant.service';
import { Participant } from '../shared/models/participant/participant';

/**
 * Service handling the participant state
 */
@Injectable()
export class ParticipantResource {
  constructor(
    private store: Store,
    private participantService: ParticipantService
  ) {}

  upsert(participant: Participant, sessionId: string): Observable<void> {
    return this.participantService.upsert(
      new Participant({
        ...participant,
        sessionId
      })
    );
  }

  upsertAll(participants: Participant[], sessionId: string): Observable<void> {
    return this.participantService.upsertAll(
      participants.map(
        (participant) =>
          new Participant({
            ...participant,
            sessionId
          })
      )
    );
  }

  getParticipants(sessionId: string): Observable<Participant[]> {
    return this.participantService.getParticipants(sessionId).pipe(
      map((participants) => {
        if (participants) {
          return participants.map(
            (participant) => new Participant({ ...participant })
          );
        }
        return [];
      })
    );
  }

  deleteParticipant(
    participant: Participant,
    sessionId: string
  ): Observable<void> {
    return this.participantService.deleteParticipant(
      new Participant({
        ...participant,
        sessionId
      })
    );
  }
}
