import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { Moment } from 'moment';
import { DeletePromoCode } from '../../cart/cart.action';
import { PricingUtils } from '../../utils/pricing-utils';
import { Cart } from '../models/cart/cart';

@Component({
  selector: 'vsk-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent {
  promoPriceReduction: boolean;
  priceReduction: boolean;
  @Input() depositEnabled = false;
  @Input() startDate: Moment;

  protected readonly PricingUtils = PricingUtils;

  constructor(private store: Store) {}

  _cart: Cart;

  get cart(): Cart {
    return this._cart;
  }

  @Input() set cart(value: Cart) {
    this._cart = value;
    if (value) {
      this.priceReduction = value.getPublicPrice() !== value.getPrice();
      this.promoPriceReduction =
        value.getPublicPrice() !== value.getPriceWithPromo();
    }
  }

  deletePromoCode() {
    this.store.dispatch(new DeletePromoCode());
  }
}
