export class CustomField {
  id: number;
  label: string;
  placeholder: string;
  value: string;

  constructor(obj: Partial<CustomField>) {
    Object.assign(this, obj);
  }

  get valid() {
    return !!this.value && this.value.length > 0;
  }
}
