import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { Moment } from 'moment';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { CartSkiEquipmentService } from 'src/app/service/cart-ski-equipment.service';
import { CartAccommodationService } from '../../../../service/cart-accommodation.service';
import { CartActivityService } from '../../../../service/cart-activity.service';
import { PromoService } from '../../../../service/promo.service';
import { Cart } from '../../../../shared/models/cart/cart';
import { EcoChart } from '../../../../shared/models/cart/eco-chart';
import { OrderHistory } from '../../../../shared/models/order-history';
import { Period } from '../../../../shared/models/period';
import { PricingUtils } from '../../../../utils/pricing-utils';

@Component({
  selector: 'vsk-history-order',
  templateUrl: './history-order.component.html',
  styleUrls: ['./history-order.component.scss']
})
export class HistoryOrderComponent implements OnDestroy {
  cart$: Observable<Cart>;
  title = '';
  transactionId = '';
  openReclamation = false;
  buyDate: Moment;
  destination = '';
  period: Period;
  @Input() showCart = false;
  @Output() showCartChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  protected readonly PricingUtils = PricingUtils;

  constructor(
    private cartService: CartActivityService,
    private cartAccommodationService: CartAccommodationService,
    private promoService: PromoService,
    private cartSkiEquimentService: CartSkiEquipmentService
  ) {
    document.documentElement.style.overflow = 'hidden';
  }

  @Input() set order(order: OrderHistory) {
    if (order) {
      this.buyDate = order.buyDate;
      this.destination = order.destination;
      this.transactionId = order.transactionId;
      this.cart$ = combineLatest([
        this.cartService.getItems(order.transactionId, true),
        this.cartAccommodationService.getItems(order.transactionId, true),
        this.cartSkiEquimentService.getItems(order.transactionId, true),
        this.promoService.getPromoCodeOrder(order.transactionId)
      ]).pipe(
        map(
          ([itemsActivity, itemsAccommodation, itemsSkiEquipment, promoCode]) =>
            new Cart({
              itemsActivity,
              itemsAccommodation,
              itemsSkiEquipment,
              promoCode,
              ecoTourismChart: new EcoChart(order.ecoTourismChart)
            })
        )
      );

      this.title = `${order.destination} du ${order.startDate.format('DD/MM')} au ${order.endDate.format('DD/MM')}`;
      this.period = new Period({
        startDate: order.startDate,
        endDate: order.endDate
      });
    }
  }

  ngOnDestroy(): void {
    document.documentElement.style.overflow = 'auto';
  }

  openClose(withReclamation: boolean) {
    this.showCart = !this.showCart;
    this.showCartChange.emit(this.showCart);

    if (withReclamation) {
      this.openReclamation = !this.openReclamation;
    }
  }
}
