import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CardProductPresentation, WindowResource } from 'atomic-lib';
import { map } from 'rxjs/operators';
import { MetaDescriptionService } from '../service/meta-description.service';
import { StationService } from '../service/station.service';
import { RxjsComponent } from '../shared/component/rxjs.component';
import { ResortMin } from '../shared/models/resort/resort-min';
import { UrlUtils } from '../utils/url-utils';

@Component({
  selector: 'vsk-search-resorts',
  templateUrl: './search-resorts.component.html',
  styleUrls: ['./search-resorts.component.scss']
})
export class SearchResortsComponent extends RxjsComponent {
  resorts: ResortMin[] = [];
  productResort: CardProductPresentation[] = [];
  allStations: ResortMin[] = [];
  stationsFiltered: ResortMin[] = [];

  constructor(
    private stationService: StationService,
    public windowResource: WindowResource,
    public activatedRoute: ActivatedRoute,
    private metaDescriptionService: MetaDescriptionService
  ) {
    super();

    const title = `Découvrez toutes nos stations de montagne · VeryMountain`;
    const description = `Trouvez la destination de vos rêves pour passer vos vacances en montagne avec VeryMountain`;
    const url = 'recherche-stations';

    this.metaDescriptionService.updateMeta(title, description, url);

    this.register(
      this.stationService
        .getAllResorts()
        .pipe(
          map((resorts) =>
            resorts.sort((prev, curr) => (prev.name < curr.name ? -1 : 1))
          )
        )
        .subscribe((resorts) => {
          this.resorts = resorts;
          this.allStations = resorts;
          this.stationsFiltered = resorts;
          this.mapResortProduct();
        })
    );
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onFilteredValues(filteredValues: ResortMin[]): void {
    this.stationsFiltered = filteredValues;
    this.mapResortProduct();
  }

  private mapResortProduct() {
    if (this.stationsFiltered?.length) {
      this.productResort = this.stationsFiltered.map((resort) => {
        return {
          title: resort.name,
          background: resort.urlCover,
          description: resort.description,
          srcResort: resort.urlLogo,
          link: `/station/${UrlUtils.encodeToURL(resort.name)}/informations`
        } as CardProductPresentation;
      });
    }
  }
}
