<atc-popup (closeChanged)="closeChanged.emit()"
           [width]="windowResource.isDesktop ? '600px' : ''"
           [withImg]="false">
  <div *ngIf="!isSend" body class="vsk__block-form">
    <img alt="logo" atcLazyLoad class="vsk__logo" src="assets/images/monogramme.png">
    <p *ngIf="error" class="vsk__error-text">{{ error }}</p>

    <h2>Réclamation</h2>
    <div class="flex flex-col gap-4 p-8">
      <atc-textarea [control]="messageForm"
                    [placeholder]="'Réclamation'"
                    label="Message"
                    size="large"></atc-textarea>
      <atc-button (click)="sendReclamation()"
                  [fullWidth]="true"
                  class="flex w-full"
                  label="Envoyer"></atc-button>
    </div>
  </div>

  <div *ngIf="isSend" class="vsk__block-form">
    <img alt="logo" atcLazyLoad class="vsk__logo" src="assets/images/monogramme.png">
    <p class="vsk__margin-top">Votre message a bien été envoyé, notre équipe reviendra vers vous dans les plus brefs
      délais</p>
  </div>
</atc-popup>
