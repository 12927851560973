import { DecimalPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { TriggerAlert } from '../../app.action';
import { AddItemsToCart } from '../../cart/cart.action';
import { Alert } from '../models/alert';
import { ItemCart } from '../models/package/item-cart';
import { PackageGroup } from '../models/package/package-group';
import { Participant } from '../models/participant/participant';
import { RxjsComponent } from './rxjs.component';

@Component({ template: '' })
export abstract class ManageCartComponent extends RxjsComponent {
  total = 0;
  totalPublic = 0;
  itemsGroup: PackageGroup[] = [];
  itemsToAdd: ItemCart[] = [];
  participantsTimeSlot: Participant[] = [];
  error = '';
  editMode = false;

  protected constructor(
    protected store: Store,
    protected decimalPipe: DecimalPipe,
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  reset() {
    this.itemsToAdd = [];
    this.itemsGroup = [];
    this.participantsTimeSlot = [];
    this.total = 0;
    this.totalPublic = 0;
    this.changeDetectorRef.markForCheck();
  }

  registerParticipants(event: any) {
    event.stopPropagation();
    this.store.dispatch(new AddItemsToCart(this.itemsToAdd)).subscribe(
      () => {
        this.reset();
        this.postRegisterAction();
      },
      (error: HttpErrorResponse) => {
        if (error.status === 409) {
          this.error = `Malheureusement il n'y a plus assez de place dans ce créneau.`;
        } else {
          this.error = `Une erreur est survenue, veuillez réessayer plus tard.`;
        }
        this.store.dispatch(
          new TriggerAlert(
            new Alert({
              message: this.error,
              level: 'error',
              timeout: 5000
            })
          )
        );
      }
    );
  }

  pricePerParticipant(size: number) {
    return this.total / size;
  }

  abstract postRegisterAction(): void;
}
