import {
  DecimalPipe,
  NgOptimizedImage,
  provideImgixLoader,
  registerLocaleData
} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule, HammerModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateService } from '@ngx-translate/core';

import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule
} from '@abacritt/angularx-social-login';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AtomicLibModule, ScreenPipe } from 'atomic-lib';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { environment } from '../environments/environment';
import { AboutUsModule } from './about-us/about-us.module';
import { AccommodationsComponent } from './accommodations/accommodations.component';
import { ActivitiesComponent } from './activities/activities.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CartModule } from './cart/cart.module';
import { ConfirmPaymentComponent } from './confirm-payment/confirm-payment.component';
import { ConfirmationRegistrationComponent } from './confirmation-registration/confirmation-registration.component';
import { FooterModule } from './footer/footer.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeModule } from './home/home.module';
import { SellingConditionsModule } from './legal/selling-conditions/selling-conditions.module';
import { TermsAndConditionsModule } from './legal/terms-and-conditions/terms-and-conditions.module';
import { TermsOfUseModule } from './legal/terms-of-use/terms-of-use.module';
import { HeaderFiltersComponent } from './map/header-filters/header-filters.component';
import { MapFiltersComponent } from './map/header-filters/map-filters/map-filters.component';
import { MapComponent } from './map/map.component';
import { MapComponentService } from './map/map.component.service';
import { CardListStationComponent } from './map/side-map/card-list-station/card-list-station.component';
import { FiltersComponent } from './map/side-map/filters/filters.component';
import { SideMapComponent } from './map/side-map/side-map.component';
import { MyAccountModule } from './my-account/my-account.module';
import { NavbarModule } from './navbar/navbar.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { PaymentModule } from './payment/payment.module';
import { ResortModule } from './resort/resort.module';
import { AccountResource } from './resource/account.resource';
import { LocalStorageResource } from './resource/local-storage.resource';
import { ParticipantResource } from './resource/participant.resource';
import { UrlManagerResource } from './resource/url-manager.resource';
import { WindowResource } from './resource/window.resource';
import { SearchResortsComponent } from './search-resorts/search-resorts.component';
import { WebsocketService } from './service/websocket.service';
import { ToHourPipe } from './shared/pipe/to-hour.pipe';
import { SharedModule } from './shared/shared.module';
import { NgxsStoreModule } from './store/store.module';
import { NgxTranslateModule } from './store/translate.module';
import { authInterceptorProviders } from './utils/auth.interceptor';

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    SideMapComponent,
    MapFiltersComponent,
    FiltersComponent,
    CardListStationComponent,
    ForgotPasswordComponent,
    ConfirmationRegistrationComponent,
    NotFoundComponent,
    ConfirmPaymentComponent,
    AccommodationsComponent,
    ActivitiesComponent,
    SearchResortsComponent,
    HeaderFiltersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    SocialLoginModule,
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
    NgxTranslateModule,
    HomeModule,
    TermsOfUseModule,
    TermsAndConditionsModule,
    AboutUsModule,
    SellingConditionsModule,
    MyAccountModule,
    ResortModule,
    CartModule,
    PaymentModule,
    NgxsStoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    AtomicLibModule,
    NgOptimizedImage,
    NavbarModule,
    HammerModule,
    FooterModule,
    InfiniteScrollModule,
    ScreenPipe
  ],
  providers: [
    ToHourPipe,
    DecimalPipe,
    Meta,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '325382149684-02e7jk7juhbgcolc5t0magk77p9hj99h.apps.googleusercontent.com',
              {
                oneTapEnabled: false
              }
            )
          }
        ]
      } as SocialAuthServiceConfig
    },
    authInterceptorProviders,
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    },
    {
      provide: 'googleTagManagerId',
      useValue: 'GTM-WVW3ZDD'
    },
    AccountResource,
    LocalStorageResource,
    ParticipantResource,
    WindowResource,
    MapComponentService,
    TranslateService,
    WebsocketService,
    provideImgixLoader(`${environment.cdnUrl}/`),
    UrlManagerResource
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
