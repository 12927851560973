import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import {
  CardProduct,
  CardProductPresentation,
  ProductTypeEnum,
  Tag,
  WindowResource
} from 'atomic-lib';
import moment from 'moment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SetChangingFilters, SetSearchMode } from '../filters.action';
import { FiltersState } from '../filters.state';
import { Search, SetShowInNavbar } from '../navbar/navbar.action';
import { NavbarState } from '../navbar/navbar.state';
import { ActivityService } from '../service/activity.service';
import { CmsService } from '../service/cms.service';
import { ContactService } from '../service/contact.service';
import { NavigateService } from '../service/navigate.service';
import { StationService } from '../service/station.service';
import { RxjsComponent } from '../shared/component/rxjs.component';
import { ActivitiesByCategory } from '../shared/models/activity/activities-by-category';
import { Activity } from '../shared/models/activity/activity';
import { Carousel } from '../shared/models/carousel';
import { DifficultyExperience } from '../shared/models/enum/difficulty-experience.enum';
import { FiltersStation } from '../shared/models/filters/filters-station';
import { Participant } from '../shared/models/participant/participant';
import { Period } from '../shared/models/period';
import { ResortMin } from '../shared/models/resort/resort-min';
import { TextBlock } from '../shared/models/text-block';
import { UrlUtils } from '../utils/url-utils';

@Component({
  selector: 'vsk-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(
          '400ms',
          style({
            opacity: 1
          })
        )
      ])
    ])
  ]
})
export class HomeComponent extends RxjsComponent {
  @Select(NavbarState.period) period$: Observable<Period>;
  @Select(NavbarState.activities) activities$: Observable<Activity[]>;
  @Select(NavbarState.withSkiPackage) withSkiPackage$: Observable<boolean>;
  @Select(NavbarState.withMaterial) withMaterial$: Observable<boolean>;
  @Select(NavbarState.activitiesByCategories)
  activitiesByCategories$: Observable<ActivitiesByCategory[]>;
  @Select(NavbarState.selectedActivities) selectedActivities$: Observable<
    Activity[]
  >;
  @Select(NavbarState.participants) participants$: Observable<Participant[]>;
  @Select(NavbarState.isDatePickerOpen) isDatePickerOpen$: Observable<boolean>;
  @Select(NavbarState.isActivityPickerOpen)
  isActivityPickerOpen$: Observable<boolean>;
  @Select(NavbarState.isParticipantPickerOpen)
  isParticipantPickerOpen$: Observable<boolean>;
  @Select(NavbarState.isFiltersPickerOpen)
  isFiltersPickerOpen$: Observable<boolean>;
  @Select(NavbarState.showInNavbar) showInNavbar$: Observable<boolean>;
  @Select(NavbarState.hasAlterParticipants)
  hasAlterParticipants$: Observable<boolean>;
  @Select(FiltersState.isChangingFilters)
  isChangingFilters$: Observable<boolean>;
  @Select(FiltersState.searchMode)
  searchMode$: Observable<'all' | 'accommodation' | 'experience'>;

  totalActivities = 10;
  activities: Activity[] = [];
  resorts: ResortMin[] = [];
  resortsLinks: Tag[] = [];
  textBlocks: TextBlock[] = [];

  skeletonSize: number[] = Array.from(Array(30).keys());
  activitiesExpanded = false;
  searchMode: 'all' | 'accommodation' | 'experience' = 'all';

  isSend = false;
  error: string;
  defaultImageUrl: string = 'assets/images/couverture.webp';
  realImageUrl: string | null = null;

  emailForm = new FormControl<string>('', [
    Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$'),
    Validators.required
  ]);

  productResort: CardProductPresentation[] = [];

  @Input() filters: FiltersStation;

  @ViewChild('zone1', { static: true }) zone1: ElementRef;
  carouselsZone1: Carousel[] = [];
  carouselsZone2: Carousel[] = [];

  constructor(
    private filterService: NavigateService,
    private store: Store,
    private activityService: ActivityService,
    private cmsService: CmsService,
    private stationService: StationService,
    public windowResource: WindowResource,
    private contactService: ContactService,
    private translate: TranslateService,
    private elementRef: ElementRef
  ) {
    super();
    document.title = 'VeryMountain · Votre séjour à la montagne sur mesure';
    this.store.dispatch(new SetShowInNavbar(false));

    this.register(
      this.activityService
        .getActivitiesForDates(moment(), moment().add(3, 'days'))
        .subscribe((activities) => (this.activities = activities))
    );

    this.register(
      this.stationService
        .getAllResorts()
        .pipe(
          map((resorts) =>
            resorts.sort((prev, curr) => (prev.name < curr.name ? -1 : 1))
          )
        )
        .subscribe((resorts) => {
          this.resorts = resorts;
          this.mapResortProduct();
          this.resortsLinks = this.resorts.map((resort) => {
            return {
              label: resort.urlLogo,
              icon: resort.urlLogo
            } as Tag;
          });
        })
    );

    this.register(
      this.searchMode$.subscribe((mode) => (this.searchMode = mode))
    );

    this.register(
      this.cmsService
        .getAllTextBlocks()
        .pipe(
          catchError(() => {
            this.realImageUrl = this.defaultImageUrl;
            return [];
          })
        )
        .subscribe((textBlocks) => {
          this.textBlocks = textBlocks;
          if (this.textBlocks && this.textBlocks.length > 0) {
            this.loadCoverPhoto();
          } else {
            this.realImageUrl = this.defaultImageUrl;
          }
          setTimeout(() => {
            this.elementRef.nativeElement.setAttribute(
              'style',
              'visibility: visible'
            );
          }, 200);
        })
    );
  }

  loadCoverPhoto(): void {
    const img = new Image();
    const coverPhotoUrl =
      this.textBlocks[0]?.textBlockContents[0]?.coverPhotoUrl;

    img.onload = () => {
      this.realImageUrl = coverPhotoUrl;
    };

    img.onerror = () => {
      this.realImageUrl = this.defaultImageUrl;
    };

    img.src = coverPhotoUrl;
  }

  getResortTags(resort: ResortMin): Tag[] {
    return resort.labels.map((label) => {
      return {
        icon: '',
        label: label.name
      };
    });
  }

  sendEmail(): void {
    if (this.emailForm.valid) {
      const email = this.emailForm.value;
      this.contactService
        .registerEmail(email as string, 'Newsletter accueil')
        .subscribe(
          () => {
            this.isSend = true;
            this.error = '';
          },
          () => {
            this.error =
              "Oops une erreur est survenue lors de l'envoi de votre message ...";
          }
        );
    }
  }

  /**
   * Go to map page with params
   */
  search(): void {
    if (this.searchMode === 'accommodation') {
      this.filterService.navigateWithQueryParams('/recherche-hebergements');
      return;
    }

    if (this.searchMode === 'experience') {
      this.filterService.navigateWithQueryParams('/experiences');
      return;
    }

    this.filterService.navigateWithQueryParams('/recherche-sejours');
  }

  setActivity(activity: Activity) {
    this.store.dispatch(
      new Search(new Activity({ ...activity, selected: true }))
    );
    this.search();
  }

  expand() {
    this.totalActivities = this.activities.length;
    this.activitiesExpanded = true;
  }

  changeSearchMode(mode: 'all' | 'accommodation' | 'experience') {
    this.store.dispatch(new SetSearchMode(mode));
    document.querySelector(`#${mode}`)?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'end'
    });
  }

  openSearchPopup() {
    this.store.dispatch(new SetChangingFilters(true));
  }

  getSizeCard(isMobile: boolean) {
    return isMobile ? 320 : 620;
  }

  getNbCard(isMobile: boolean) {
    return isMobile ? 2 : 3;
  }

  getTitleResorts(totalResorts: number) {
    if (!totalResorts) {
      return `Découvrez nos stations`;
    }

    return `Découvrez nos ${totalResorts} stations`;
  }

  getTitle() {
    return this.textBlocks[0]
      ? this.textBlocks[0].textBlockContents[0]?.title
      : 'Organisez votre <b class="font-semibold">séjour</b> tout compris';
  }

  loadCarouselByZone(zoneId: number): void {
    this.cmsService
      .getCarouselsByZone(zoneId)
      .subscribe((carousels: Carousel[]) => {
        if (zoneId === 1) {
          this.carouselsZone1 = carousels;
        }

        if (zoneId === 2) {
          this.carouselsZone2 = carousels;
        }
      });
  }

  translateDifficulty(difficulty: DifficultyExperience): string {
    switch (difficulty) {
      case DifficultyExperience.FOR_ALL:
        return this.translate.instant('home.difficulty.FOR_ALL');
      case DifficultyExperience.EXPERIMENTED:
        return this.translate.instant('home.difficulty.EXPERIMENTED');
      case DifficultyExperience.SPORT:
        return this.translate.instant('home.difficulty.SPORT');
      default:
        return difficulty;
    }
  }

  mapToElements(carousel: Carousel): CardProduct[] {
    if (carousel.experiences.length) {
      return carousel.experiences.map((experience) => {
        experience.difficulty = this.translateDifficulty(
          experience.difficulty as DifficultyExperience
        );
        return {
          params: {
            experienceId: experience.id
          },
          link: `/station/${UrlUtils.encodeToURL(experience.resortName)}/fiche-activite/${UrlUtils.encodeToURL(experience.name)}`,
          element: experience,
          enabled: true,
          type: ProductTypeEnum.EXPERIENCE,
          id: experience.id
        };
      });
    }
    if (carousel.accommodations.length) {
      return carousel.accommodations.map((accommodation) => {
        return {
          params: {
            roomCode: accommodation.roomCode,
            establishmentId: accommodation.establishmentId,
            partnerCode: accommodation.partnerCode,
            establishmentName: accommodation.establishmentName,
            startDate: accommodation.startDate,
            endDate: accommodation.endDate
          },
          link: `/station/${UrlUtils.encodeToURL(accommodation.resortName)}/logement/${UrlUtils.encodeToURL(accommodation.establishmentName)}`,
          enabled: true,
          element: accommodation,
          type: ProductTypeEnum.ACCOMMODATION,
          id: accommodation.roomCode
        };
      });
    }
    return [];
  }

  scrollToRight() {
    const experienceButton = document.getElementById('experience');
    if (experienceButton) {
      experienceButton.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'end'
      });
    }
  }

  private mapResortProduct() {
    if (this.resorts?.length) {
      this.productResort = this.resorts.map((resort) => {
        return {
          id: resort.id,
          title: resort.name,
          region: resort.regionLabel,
          background: resort.urlCover,
          description: resort.description,
          link: `/station/${UrlUtils.encodeToURL(resort.name)}/informations`,
          selected: false,
          srcResort: resort.urlLogo,
          tags: this.getResortTags(resort)
        } as CardProductPresentation;
      });
    }
  }
}
