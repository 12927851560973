<div
  class="mx-auto flex w-[100%] max-w-[1350px] flex-col justify-between gap-4 px-8 pb-[4rem] pt-2 xl:px-0 md:gap-[2rem]"
>
  <section *ngIf="resort$ | async as resort" class="station-info">
    <div class="txt-content">
      <h1 class="pb-[10px]">À propos de {{ resort.name }}</h1>
      <p [innerHTML]="resort.description"></p>
    </div>

    <article class="info">
      <img alt="Logo" class="logo-resort" src="{{ resort.urlLogo }}" />
      <article class="info-txt-content">
        <div class="row">
          <svg class="icon xl:max-h-8 xl:w-8">
            <use [attr.xlink:href]="'assets/icons/icons.svg#location'"></use>
          </svg>
          <div class="col">
            <span [innerHTML]="resort.name" class="bold"></span>
            <p [innerHTML]="resort.regionLabel"></p>
          </div>
        </div>
      </article>

      <atc-button
        (click)="openItinerary(resort.location.lat, resort.location.lon)"
        color="ternary"
        icon="search"
        label="Calculer mon temps de trajet"
        size="large"
      >
      </atc-button>
    </article>
  </section>
</div>

<section class="ski-slope-info hidden px-[125px] py-[4rem] lg:px-[30px] sm:px-[30px]">
  <h3 class="pb-[10px]">Détails des pistes</h3>

  <div class="row plan-et-cam-container justify-center gap-[5rem]">
    <article class="plan-et-cam">
      <h4>Plans des pistes</h4>
      <a class="color-container black" href="#">
        <img src="/assets/images/map.png" />
      </a>
    </article>

    <article class="plan-et-cam">
      <h4>Webcam</h4>
      <a class="color-container blue" href="#">
        <img src="/assets/images/eye.png" />
      </a>
    </article>
  </div>
  <div class="colored-slope pt-[3rem]">
    <div class="row">
      <div class="slope green">
        <p class="circle">17</p>
        <h5>Pistes vertes</h5>
      </div>
      <div class="slope blue">
        <div class="circle"> 17</div>
        <h5>Pistes vertes</h5>
      </div>
    </div>

    <div class="row">
      <div class="slope red">
        <div class="circle"> 17</div>
        <h5>Pistes vertes</h5>
      </div>
      <div class="slope black">
        <div class="circle"> 17</div>
        <h5>Pistes vertes</h5>
      </div>
    </div>
  </div>
  <div class="km-slope pt-[2rem]">
    <div class="slope grey">
      <div class="circle"> 17km</div>
      <h5>De pistes <span class="bold">Alpin</span></h5>
    </div>

    <div class="slope grey">
      <div class="circle"> 17km</div>
      <h5>De pistes <span class="bold">Nordique</span></h5>
    </div>
  </div>
</section>

<section
  class="bg-color-neutral-20 flex w-[100%] flex-col justify-between gap-[2.5rem] py-[4rem] pb-[4rem] xl:flex-col lg:gap-[30px] md:gap-[2rem]"
>
  <article class="justify-article mx-auto max-w-[1350px] px-8 xl:px-0">
    <h2 class="pb-[10px]">Activités disponibles</h2>
    <h4>Sur la station</h4>

    <div class="grid-container mt-[2rem]">
      <atc-card-picture-activity
        *ngFor="let experience of experiencesFiltered"
        [params]="getParams(activatedRoute.params | async, experience.id)"
        [src]="experience.pictures[0]"
        [title]="experience.activity.name"
        link="/station/fiche-activite"
      >
      </atc-card-picture-activity>

      <ng-container *ngIf="loader">
        <ng-container *ngFor="let nb of [0, 1, 2, 3]">
          <div
            class="mr-4 flex h-[300px] flex-1 rounded-[20px]"
            vskSkeletonLoad
          ></div>
        </ng-container>
      </ng-container>
    </div>
  </article>
</section>

<section
  class="mx-auto flex max-w-[1350px] flex-col px-8 py-[4rem] xl:px-0 lg:pb-[8rem] md:pb-[10rem] sm:pb-[10rem]"
>
  <article class="justify-article">
    <h3 class="pb-[10px]">Autres stations</h3>
    <h4>À découvrir</h4>
  </article>
  <atc-resort-gallery [slides]="resortsLinks"></atc-resort-gallery>
</section>

<vsk-footer [isHome]="false"></vsk-footer>
