<atc-popup (closeChanged)="closeChanged.emit()"
           [width]="windowResource.isDesktop ? '600px' : ''"
           [withImg]="false">
  <div *ngIf="!isSend" body class="vsk__block-form px-8">
    <img alt="logo" atcLazyLoad class="vsk__logo" src="assets/images/monogramme.png">
    <h3 class="vsk__header-login">Contact</h3>
    <p *ngIf="error" class="vsk__error-text">{{ error }}</p>

    <!-- Lastname -->
    <atc-input [control]="lastNameForm"
               [focus]="true"
               errorMessage="Une nom est obligatoire"
               id="id__input-contact-lastName"
               label="Nom*"
               placeholder="Dupont"></atc-input>

    <!-- Firstname -->
    <atc-input [control]="firstNameForm"
               errorMessage="Une prénom est obligatoire"
               id="id__input-contact-firstName"
               label="Prénom*"
               placeholder="Jean"></atc-input>

    <!-- Email -->
    <atc-input [control]="emailForm"
               errorMessage="Une adresse email est obligatoire"
               id="id__input-contact-email"
               label="Adresse mail*"
               placeholder="jean.dupont@gmail.com"
               type="email"></atc-input>

    <!-- Message -->
    <atc-textarea [control]="messageForm"
                  [placeholder]="'Message'"
                  id="id__form-field-contact-message"
                  label="Message"></atc-textarea>

    <!-- Validate -->
    <atc-button (click)="send()"
                [fullWidth]="true"
                class="flex w-full vsk__spacing-bottom"
                color="secondary"
                id="id__button-contact-send"
                label="Envoyer"></atc-button>
  </div>

  <div *ngIf="isSend" body class="px-8 vsk__block-form" id="id__div-contact-isSend">
    <img alt="logo" atcLazyLoad class="vsk__logo" src="assets/images/monogramme.png">
    <h3 class="vsk__header-login">Contact</h3>
    <p class="vsk__sent-text">Votre message a bien été envoyé, notre équipe reviendra vers vous dans les plus brefs
      délais</p>
  </div>
</atc-popup>
