import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AtomicLibModule } from 'atomic-lib';
import { CartModule } from '../cart/cart.module';
import { FooterModule } from '../footer/footer.module';
import { SharedModule } from '../shared/shared.module';
import { NgxTranslateModule } from '../store/translate.module';
import { AccountInformationComponent } from './account-information/account-information.component';
import { DialogDeleteAccountComponent } from './account-information/dialog-delete-account/dialog-delete-account.component';
import { AccountOrdersComponent } from './account-orders/account-orders.component';
import { HistoryOrderComponent } from './account-orders/my-orders/history-order/history-order.component';
import { MyOrdersComponent } from './account-orders/my-orders/my-orders.component';
import { ReclamationOrderComponent } from './account-orders/my-orders/reclamation-order/reclamation-order.component';
import { AccountPassComponent } from './account-pass/account-pass.component';
import { MobileDeleteAccountComponent } from './mobile-delete-account/mobile-delete-account.component';
import { MyAccountRoutingModule } from './my-account-routing.module';
import { MyAccountComponent } from './my-account.component';

@NgModule({
  declarations: [
    MyAccountComponent,
    MyOrdersComponent,
    MobileDeleteAccountComponent,
    DialogDeleteAccountComponent,
    HistoryOrderComponent,
    AccountInformationComponent,
    AccountOrdersComponent,
    AccountPassComponent,
    ReclamationOrderComponent
  ],
  imports: [
    NgxTranslateModule,
    SharedModule,
    FooterModule,
    CommonModule,
    MyAccountRoutingModule,
    ReactiveFormsModule,
    AtomicLibModule,
    CartModule
  ],
  exports: [MyAccountComponent]
})
export class MyAccountModule {}
