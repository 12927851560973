import { Component } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { UpdateUserData } from '../../app.action';
import { AppState } from '../../app.state';
import { WindowResource } from '../../resource/window.resource';
import { AccountService } from '../../service/account.service';
import { LocationService } from '../../service/location.service';
import { UserService } from '../../service/user.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { User } from '../../shared/models/user';
import { UserSession } from '../../shared/models/user-session';

@Component({
  selector: 'vsk-account-information',
  templateUrl: './account-information.component.html',
  styleUrls: ['./account-information.component.scss']
})
export class AccountInformationComponent extends RxjsComponent {
  @Select(AppState.user) user$: Observable<UserSession>;

  emailToDelete: string;
  birthdate: Moment;
  country: string;
  error: string;
  success: string;
  user: User;
  countries: OptionElement<string>[] = [
    {
      id: null,
      label: 'Sélectionnez un pays'
    }
  ];
  loadingInformation = true;

  editInformationForm: UntypedFormGroup;
  firstNameForm = new FormControl<string>('', Validators.required);
  lastNameForm = new FormControl<string>('', Validators.required);
  birthdateForm = new FormControl<string>('', Validators.required);
  addressForm = new FormControl<string>('');
  cityForm = new FormControl<string>('');
  postCodeForm = new FormControl<string>('');
  countryForm = new FormControl<string | null>(null);
  emailForm = new FormControl<string>('', [
    Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$'),
    Validators.required
  ]);
  phoneForm = new FormControl<string>('', [
    Validators.required,
    Validators.pattern('[0-9 ]{14}')
  ]);
  newsletter = new FormControl<boolean>(false, Validators.required);

  constructor(
    private userService: UserService,
    private accountService: AccountService,
    public windowResource: WindowResource,
    private router: Router,
    private locationService: LocationService,
    private store: Store
  ) {
    super();

    this.editInformationForm = new UntypedFormGroup({
      firstName: this.firstNameForm,
      lastName: this.lastNameForm,
      birthdate: this.birthdateForm,
      address: this.addressForm,
      city: this.cityForm,
      postCode: this.postCodeForm,
      email: this.emailForm,
      phone: this.phoneForm,
      country: this.countryForm,
      newsletter: this.newsletter
    });

    this.register(
      this.locationService
        .getCountriesNames()
        .pipe(
          tap((countries) => this.mapCountries(countries)),
          switchMap(() => this.user$),
          switchMap((user) => this.userService.getUser(user?.email))
        )
        .subscribe((user) => {
          this.user = user;
          this.firstNameForm.setValue(user.firstName);
          this.lastNameForm.setValue(user.lastName);
          this.birthdate = moment(user.birthdate);
          this.birthdateForm.setValue(user.birthdate.format('DD/MM/YYYY'));
          this.addressForm.setValue(user.address);
          this.phoneForm.setValue(user.phone);
          this.cityForm.setValue(user.city);
          this.postCodeForm.setValue(user.postCode);
          this.emailForm.setValue(user.email);
          this.countryForm.setValue(
            this.countries.find((country) => country.label === user.country)
              ?.id as string
          );
          this.country = user.country;
          this.loadingInformation = false;
          this.newsletter.setValue(user.newsletter);
        })
    );
  }

  validation(): void {
    if (this.editInformationForm.valid) {
      this.register(
        this.store
          .dispatch(
            new UpdateUserData({
              ...this.editInformationForm.getRawValue(),
              country: this.countryForm.value,
              birthdate: moment(this.birthdateForm.value, 'DD/MM/YYYY')
            })
          )
          .subscribe(
            () => {
              this.success = 'Vos informations sont à jour';
              this.error = '';
              this.resetMessage();
            },
            (error) => {
              switch (error.status) {
                case 409:
                  this.error = "L'adresse email est déjà liée à un compte";
                  break;
                default:
                  this.error =
                    'Une erreur est survenue lors de la mise à jour de vos informations :(';
                  break;
              }
              this.success = '';
              this.resetMessage();
            }
          )
      );
    } else {
      this.success = '';
      this.error = 'Des champs ne sont pas remplis ou de façon incorrect';
      this.resetMessage();
    }
  }

  resetPassword(): void {
    this.register(
      this.accountService
        .forgotPassword(this.emailForm.value as string)
        .subscribe(
          () =>
            (this.success =
              'Un email de réinitialisation de mot de passe vous a été envoyé'),
          () =>
            (this.error =
              "Une erreur s'est produite lors de la réinitialisation de votre mot de passe, veuillez réessayer ultérieurement")
        )
    );
  }

  openDeleteConfirmationDialog(): void {
    if (!this.windowResource.isDesktop()) {
      this.router.navigate(['/delete-confirmation']);
    }
    if (this.windowResource.isDesktop()) {
      this.user$.subscribe((user) => {
        this.emailToDelete = user.email;
      });
    }
  }

  resetMessage() {
    setTimeout(() => {
      this.success = '';
      this.error = '';
    }, 3000);
  }

  private mapCountries(countries: string[]) {
    this.countries = countries.map((country) => {
      return {
        id: country,
        label: country
      } as OptionElement<string>;
    });
  }
}
