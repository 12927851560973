import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResortAccommodationComponent } from './resort-accommodation/resort-accommodation.component';
import { ResortEstablishmentComponent } from './resort-accommodation/resort-establishment/resort-establishment.component';
import { DetailsExperienceComponent } from './resort-activity/details-experience/details-experience.component';
import { ResortActivityComponent } from './resort-activity/resort-activity.component';
import { ResortInformationComponent } from './resort-information/resort-information.component';
import { ResortMaterialIntersportComponent } from './resort-material-intersport/resort-material-intersport.component';
import { ResortMaterialComponent } from './resort-material/resort-material.component';
import { ResortPackageComponent } from './resort-package/resort-package.component';
import { ResortSkiClassComponent } from './resort-ski-class/resort-ski-class.component';
import { ResortComponent } from './resort.component';

export const URL_RESORT_INFORMATION = 'informations';
export const URL_RESORT_SKI_CLASS = 'cours-ski';
export const URL_RESORT_PACKAGE = 'forfaits';
export const URL_RESORT_ACTIVITY_DETAILS = 'fiche-activite';
export const URL_RESORT_ACTIVITIES = 'activites';
export const URL_RESORT_MATERIAL = 'location-materiel';
export const URL_RESORT_MATERIAL_INTERSPORT = 'location-materiel-intersport';
export const URL_RESORT_ROOM = 'logement';
export const URL_RESORT_ACCOMMODATION = 'hebergements';

const routes: Routes = [
  {
    path: ':resort',
    component: ResortComponent,
    children: [
      {
        path: 'accommodation', // TO REMOVE
        redirectTo: URL_RESORT_ACCOMMODATION
      },
      {
        path: URL_RESORT_ACCOMMODATION,
        component: ResortAccommodationComponent
      },
      {
        path: 'room', // TO REMOVE
        redirectTo: URL_RESORT_ROOM
      },
      {
        path: URL_RESORT_ROOM,
        component: ResortEstablishmentComponent
      },
      {
        path: URL_RESORT_ROOM + '/:name',
        component: ResortEstablishmentComponent
      },
      {
        path: 'activity', // TO REMOVE
        redirectTo: URL_RESORT_ACTIVITIES
      },
      {
        path: URL_RESORT_ACTIVITIES,
        component: ResortActivityComponent
      },
      {
        path: 'details-activity', // TO REMOVE
        redirectTo: URL_RESORT_ACTIVITY_DETAILS
      },
      {
        path: URL_RESORT_ACTIVITY_DETAILS,
        component: DetailsExperienceComponent
      },
      {
        path: URL_RESORT_ACTIVITY_DETAILS + '/:name',
        component: DetailsExperienceComponent
      },
      {
        path: 'material', // TO REMOVE
        redirectTo: URL_RESORT_MATERIAL
      },
      {
        path: URL_RESORT_MATERIAL,
        component: ResortMaterialComponent
      },
      {
        path: 'package', // TO REMOVE
        redirectTo: URL_RESORT_PACKAGE
      },
      {
        path: URL_RESORT_PACKAGE,
        component: ResortPackageComponent
      },
      {
        path: 'ski-class', // TO REMOVE
        redirectTo: URL_RESORT_SKI_CLASS
      },
      {
        path: URL_RESORT_SKI_CLASS,
        component: ResortSkiClassComponent
      },
      {
        path: 'details-resort', // TO REMOVE
        redirectTo: URL_RESORT_INFORMATION
      },
      {
        path: URL_RESORT_INFORMATION,
        component: ResortInformationComponent
      },
      {
        path: URL_RESORT_MATERIAL_INTERSPORT,
        component: ResortMaterialIntersportComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResortRoutingModule {}
