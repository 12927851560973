import moment from 'moment';
import { Moment } from 'moment/moment';
import { AgeRange } from '../shared/models/age-range';

export class DateUtils {
  static today = moment();

  public static getAgeByDate(date: Moment): number {
    return this.today.diff(date, 'years');
  }

  public static getDateRange(firstDate: Moment, lastDate: Moment): Moment[] {
    if (firstDate.isSame(lastDate, 'day')) {
      return [lastDate];
    }
    let date: Moment = firstDate.clone();
    const dates: Moment[] = [firstDate.clone()];
    while (date.isBefore(lastDate, 'day')) {
      date = date.add(1, 'day');
      dates.push(date.clone());
    }
    return dates;
  }

  public static isSameCategoryByBirthdate(
    birthdate: Moment,
    ageRange: AgeRange
  ): boolean {
    const age = DateUtils.getAgeByDate(birthdate);
    return ageRange.start <= age && ageRange.end >= age;
  }

  public static allDatesBetween2Dates(
    startDate: Moment,
    endDate: Moment
  ): Moment[] {
    const diffDays = endDate.diff(startDate, 'days');
    const dates: Moment[] = [];

    dates.push(startDate.clone());
    for (let i = 1; i <= diffDays; i++) {
      dates.push(startDate.clone().add(i, 'days'));
    }

    return dates;
  }

  public static isBetween(
    dateInBetween: Moment,
    startDate: Moment,
    endDate: Moment
  ): boolean {
    return dateInBetween.isBetween(startDate, endDate, 'day', '[]');
  }
}
