import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Moment } from 'moment';
import { Observable, combineLatest } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { DisconnectUser, TriggerAlert } from '../app.action';
import { AppState } from '../app.state';
import {
  EstablishmentRoom,
  ExperienceLink
} from '../cart/cart-content/cart-content.component';
import { FetchCart, TimerUpdate } from '../cart/cart.action';
import { CartState } from '../cart/cart.state';
import { UpdateFilters } from '../filters.action';
import { FiltersState } from '../filters.state';
import { WindowResource } from '../resource/window.resource';
import { NavigateService } from '../service/navigate.service';
import { ShopService } from '../service/shop.service';
import { RxjsComponent } from '../shared/component/rxjs.component';
import { Alert } from '../shared/models/alert';
import { Cart } from '../shared/models/cart/cart';
import { Participant } from '../shared/models/participant/participant';
import { Period } from '../shared/models/period';
import { UserSession } from '../shared/models/user-session';
import { UrlUtils } from '../utils/url-utils';

@Component({
  selector: 'vsk-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentComponent extends RxjsComponent {
  @Select(AppState.user) user$: Observable<UserSession>;
  @Select(AppState.isUserLoggedIn) isLoggedIn$: Observable<boolean>;
  @Select(AppState.invitedMode) invitedMode$: Observable<boolean>;
  @Select(FiltersState.participants) participants$: Observable<Participant[]>;
  @Select(FiltersState.startDate) startDate$: Observable<Moment>;
  @Select(FiltersState.endDate) endDate$: Observable<Moment>;
  @Select(FiltersState.sessionId) sessionId$: Observable<string>;
  @Select(FiltersState.period) period$: Observable<Period>;
  @Select(CartState.cart) cart$: Observable<Cart>;
  @Select(CartState.totalPriceDisplay) totalPriceDisplay$: Observable<string>;
  @Select(CartState.isEcoTourismChartChecked)
  isEcoTourismeChecked$: Observable<boolean>;

  readonly Step = Step;

  participantsWithItems: Participant[] = [];
  step: Step = Step.CONNEXION;
  loading = false;
  payed = false;

  error: string;

  constructor(
    private shopService: ShopService,
    private store: Store,
    private navigateService: NavigateService,
    public windowResource: WindowResource,
    private activatedRoute: ActivatedRoute,
    private decimalPipe: DecimalPipe,
    private changeDetectorRef: ChangeDetectorRef,
    router: Router
  ) {
    super();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    document.title = 'Récapitulatif de votre séjour - VeryMountain';

    // Stripe script
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/';
    head.insertBefore(script, head.firstChild);

    this.activatedRoute.queryParamMap
      .pipe(
        debounceTime(100),
        switchMap((value) =>
          this.store.dispatch(
            new UpdateFilters(value, router.url.split('?')[0] === '/')
          )
        )
      )
      .subscribe();

    this.register(
      combineLatest([
        this.isLoggedIn$,
        this.invitedMode$,
        this.cart$.pipe(filter((cart) => !cart.isEmpty)),
        this.sessionId$
      ])
        .pipe(
          filter(([isLogged, invited]) => isLogged || invited),
          tap(() => (this.loading = true)),
          filter(() => this.step !== Step.CONFIRMATION),
          switchMap(([, , cart, sessionId]) =>
            this.shopService.syncUserAndSession(sessionId, cart.getResort())
          )
        )
        .subscribe(
          () => {
            if (this.step === Step.CONNEXION) {
              this.step = this.participantsWithItems?.length
                ? Step.PARTICIPANT
                : Step.PAYMENT;
            }

            if (this.step !== Step.PAYMENT) {
              this.loading = false;
            }

            this.changeDetectorRef.markForCheck();
          },
          () => (this.loading = false)
        )
    );

    combineLatest([this.participants$, this.cart$])
      .pipe(
        tap(() => (this.loading = true)),
        map(([participants, cart]) => {
          const uniqueParticipantUuids =
            cart.getParticipantsToCompleteInformation();
          return participants.filter((participant) =>
            uniqueParticipantUuids.has(participant.uuid)
          );
        })
      )
      .subscribe(
        (participants) => {
          this.participantsWithItems = participants;
          this.loading = false;
        },
        () => (this.loading = false)
      );

    setTimeout(() => window.scrollTo(0, 0), 1000);
  }

  next(): void {
    this.step += 1;

    if (this.step === Step.PARTICIPANT && !this.participantsWithItems?.length) {
      this.step += 1;
    }

    if (this.step === Step.PAYMENT) {
      const priceOrigin = this.store.selectSnapshot(CartState.cart).getPrice();
      this.store.dispatch(new FetchCart());
      this.cart$
        .pipe(filter(() => this.step === Step.PAYMENT))
        .subscribe((cart) => {
          const diffPrice = priceOrigin - cart.getPrice();

          this.loading = false;
          if (diffPrice > 0) {
            const priceString = this.decimalPipe.transform(
              diffPrice,
              '1.2-2',
              'fr'
            );
            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  message: `Vos forfaits ont biens été pris en compte • -${priceString} € sur votre panier`,
                  timeout: 5000,
                  level: 'success'
                })
              )
            );
          }
        });
    }
  }

  previous(): void {
    if (this.payed) {
      return;
    }

    if (this.store.selectSnapshot(AppState.invitedMode)) {
      if (this.step === Step.PARTICIPANT) {
        this.step = Step.CONNEXION;
      } else {
        this.step = this.participantsWithItems.length
          ? Step.PARTICIPANT
          : Step.CONNEXION;
      }

      if (this.step === Step.CONNEXION) {
        this.store.dispatch(new DisconnectUser(false));
      }

      this.changeDetectorRef.markForCheck();
      return;
    }

    this.step -= 1;
    this.changeDetectorRef.markForCheck();
  }

  isDone(step: Step) {
    return this.step >= step;
  }

  paymentSuccess() {
    this.payed = true;
    this.step = Step.CONFIRMATION;
    this.store.dispatch(new TimerUpdate(null));
  }

  changeLoading(newLoading: boolean) {
    this.loading = newLoading;
    this.changeDetectorRef.detectChanges();
  }

  goBackToResort(resort: string) {
    this.navigateService.navigateWithQueryParams(
      `/station/${UrlUtils.encodeToURL(resort)}/hebergements`
    );
  }

  redirectToMap() {
    this.navigateService.navigateWithQueryParams('/recherche-sejours');
  }

  redirectToEstablishment(establishmentRoom: EstablishmentRoom) {
    this.navigateService.navigateWithQueryParams(
      `/station/${UrlUtils.encodeToURL(establishmentRoom.resort)}/logement/${UrlUtils.encodeToURL(establishmentRoom.establishment.name)}`,
      {
        establishmentId: establishmentRoom.establishment.id,
        roomCode: establishmentRoom.room.codeRoom,
        partnerCode: establishmentRoom.establishment.partnerCode,
        station: establishmentRoom.resort
      }
    );
  }

  redirectToExperience(experienceLink: ExperienceLink) {
    this.navigateService.navigateWithQueryParams(
      `/station/${experienceLink.resort}/fiche-activite`,
      {
        experienceId: experienceLink.experienceId,
        station: experienceLink.resort
      }
    );
  }

  getLabelCart(period: Period | null) {
    if (!period || !period.startDate || !period.endDate) {
      return '';
    }

    const startDate = period.startDate.isSame(period.endDate, 'year')
      ? period?.startDate.format('DD MMMM')
      : period?.startDate.format('DD MMMM YYYY');

    return `Votre séjour du ${startDate} au ${period?.endDate.format('DD MMMM YYYY')}`;
  }
}

export enum Step {
  CONNEXION = 0,
  PARTICIPANT = 1,
  PAYMENT = 2,
  CONFIRMATION = 4
}
