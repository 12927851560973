<!-- Desktop -->
<ng-container *ngIf="!responsive">
  <div (click)="openChange(!isOpen)"
       [ngClass]="{'vsk__navbar': isInNavbar,
       'vsk__color-black': participants?.length,
       'vsk__is-active': isOpen  && currentPage !== 'Home',
       'vsk__is-active-in-home-page': isOpen && currentPage === 'Home',
       'hover-element': currentPage !== 'Home',
       'home-element': currentPage === 'Home'
       }"
       class="vsk__dropdown-button pl-[1rem]">
    <svg *ngIf="!isInNavbar"
         class="lg:max-h-8 lg:min-h-8 lg:max-w-[2rem] lg:mt-[1.2rem] stroke-secondary ">
      <use [attr.xlink:href]="'assets/icons/icons.svg#group'"></use>
    </svg>
    <svg *ngIf="isInNavbar"
         class="lg:max-h-8 lg:min-h-8 lg:max-w-[2rem] lg:min-w-[2rem] stroke-secondary">
      <use [attr.xlink:href]="'assets/icons/icons.svg#group'"></use>
    </svg>
    <div class="vsk__column lg:my-auto lg:ml-4">
      <p *ngIf="!participants?.length" class="subtitle">Qui ?</p>
      <p *ngIf="participants?.length > 0" class="subtitle">{{ participants?.length }}
        <span class="vsk__mobile-display">voyageur<span *ngIf="participants?.length > 1">s</span></span>
      </p>
    </div>
  </div>

  <atc-dropdown (closeChanged)="openChange(false)"
                [isOpen]="isOpen"
                [left]="'-315px'"
                [position]="'bottom-left'"
                [width]="'500px'">
    <div class="flex flex-col gap-2">
      <div class="flex flex-row justify-end">
        <atc-button (click)="addParticipant()"
                    color="ternary"
                    icon="add-person"></atc-button>
      </div>
      <div *ngFor="let participant of participants | sortById: 'index'; let i = index"
           class="flex flex-row gap-4">
        <vsk-input-participant
          [error]="error"
          [index]="i + 1"
          [participant]="participant"
          class="vsk__line-height">
        </vsk-input-participant>
        <atc-button (click)="deleteParticipant(i)"
                    color="ternary"
                    icon="remove-person"></atc-button>
      </div>
      <div class="mb-4"></div>

      <atc-tag *ngIf="hasAlterParticipants"
               class="mx-auto"
               color="danger">
        Attention, vos modifications risques d'impacter votre panier
      </atc-tag>

      <atc-button (click)="searchEvent()"
                  *ngIf="currentPage === 'Home'"
                  [fullWidth]="true"
                  class="flex"
                  color="secondary"
                  id="id__button-continuer-participant"
                  label="Rechercher">
      </atc-button>

      <atc-button (click)="searchEvent()"
                  *ngIf="currentPage !== 'Home'"
                  [fullWidth]="true"
                  class="flex"
                  color="secondary"
                  label="Mettre à jour les voyageurs">
      </atc-button>

      <atc-button (click)="goTo(URL_SEARCH_TRIP)"
                  *ngIf="currentPage === 'Resort' && (isFoncia$ | async) === false"
                  [fullWidth]="true"
                  class="flex"
                  color="ternary"
                  label="Découvrir d'autres destinations">
      </atc-button>


    </div>
  </atc-dropdown>
</ng-container>

<!-- Mobile -->
<ng-container *ngIf="responsive">
  <div class="vsk__container-participants">
    <div class="flex flex-row justify-end">
      <atc-button (click)="addParticipant()"
                  color="ternary"
                  icon="add-person"></atc-button>
    </div>
    <div *ngFor="let participant of participants | sortById: 'index'; let i = index"
         class="flex flex-row gap-2">
      <vsk-input-participant
        [error]="error"
        [index]="i + 1"
        [participant]="participant"
        class="vsk__line-height">
      </vsk-input-participant>
      <atc-button (click)="deleteParticipant(i)"
                  [disabled]="isDeleteDisabled()"
                  color="ternary"
                  icon="remove-person"></atc-button>
    </div>
  </div>
</ng-container>
