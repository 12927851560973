import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { Activity } from '../shared/models/activity/activity';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityService extends RepositoryService {
  private URL_ACTIVITY = 'api/webapp/activity';

  constructor(http: HttpClient) {
    super(http);
  }

  getActivitiesForDates(
    startDate: Moment,
    endDate: Moment
  ): Observable<Activity[]> {
    return this.get(
      this.URL_ACTIVITY +
        '/get-for-dates?startDate=' +
        startDate.locale('fr').format('YYYY-MM-DD') +
        '&endDate=' +
        endDate.locale('fr').format('YYYY-MM-DD')
    );
  }
}
