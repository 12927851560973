export enum ActivityFilters {
  PERTINENCE = 'PERTINENCE',
  PRICEASC = 'PRICEASC',
  PRICEDESC = 'PRICEDESC',
  DURATIONASC = 'DURATIONASC',
  DURATIONDESC = 'DURATIONDESC',
  DIFFICULTYASC = 'DIFFICULTYASC',
  DIFFICULTYDESC = 'DIFFICULTYDESC',
  STATIONRANGEASC = 'STATIONRANGEASC',
  STATIONRANGEDESC = 'STATIONRANGEDESC'
}
