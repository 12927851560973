import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import moment from 'moment';
import { Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { URL_SEARCH_TRIP } from '../../../app-routing.module';
import { TriggerLoading } from '../../../app.action';
import { FetchCart } from '../../../cart/cart.action';
import { FiltersState } from '../../../filters.state';
import { Activity } from '../../../shared/models/activity/activity';
import { Participant } from '../../../shared/models/participant/participant';
import {
  AddParticipant,
  AddParticipants,
  ParticipantPickerOpen,
  RemoveParticipant,
  Search
} from '../../navbar.action';

@Component({
  selector: 'vsk-form-manage-participant',
  templateUrl: './form-manage-participant.component.html',
  styleUrls: ['./form-manage-participant.component.scss']
})
export class FormManageParticipantComponent {
  @Select(FiltersState.isFoncia) isFoncia$: Observable<boolean>;

  error = false;
  @Input() isInNavbar = false;
  @Input() isOpen = false;
  @Input() responsive = false;
  @Input() activities: Activity[] = [];
  @Input() participants: Participant[] = [];
  @Input() hasAlterParticipants = false;
  @Input() currentPage: 'Home' | 'Map' | 'Resort' | 'none' = 'none';

  @Output() search: EventEmitter<void> = new EventEmitter<void>();
  protected readonly URL_SEARCH_TRIP = URL_SEARCH_TRIP;

  constructor(
    private store: Store,
    private router: Router
  ) {}

  deleteParticipant(index: number): void {
    if (this.participants.length > 1) {
      this.store.dispatch(new AddParticipants(this.participants));
      this.store.dispatch(new RemoveParticipant(this.participants[index].uuid));
    }
  }

  addParticipant(): void {
    if (this.participants.length < 20) {
      this.store.dispatch(new AddParticipants(this.participants));
      this.store.dispatch(
        new AddParticipant(
          new Participant({
            index: this.participants.length + 1,
            firstname: `Voyageur ${this.participants.length + 1}`,
            birthdate: moment().add(-26, 'year'),
            sessionId: this.store.selectSnapshot(FiltersState.sessionId)
          })
        )
      );
    }
  }

  goTo(destination: string) {
    this.searchEvent();
    this.router.navigate([destination]);
    this.store.dispatch(new TriggerLoading(false));
  }

  searchEvent(): void {
    this.store.dispatch(new TriggerLoading(true));
    this.participants.forEach((participant) => {
      participant.firstname = participant.firstname?.length
        ? participant.firstname
        : 'Voyageur ' + participant.index;
    });

    this.store
      .dispatch(new AddParticipants(this.participants))
      .pipe(
        switchMap(() => this.store.dispatch(new Search())),
        debounceTime(300),
        switchMap(() => this.store.dispatch(new FetchCart()))
      )
      .subscribe(() => {
        this.store.dispatch(new TriggerLoading(false));
        this.search.emit();
      });
  }

  openChange(newValue: boolean): void {
    this.store.dispatch(new AddParticipants(this.participants));
    this.store.dispatch(new ParticipantPickerOpen(newValue));
  }

  isDeleteDisabled() {
    return this.participants.length === 1;
  }
}
