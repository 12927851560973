import { Component } from '@angular/core';

@Component({
  selector: 'vsk-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  constructor() {
    document.title = 'Page introuvable - VeryMountain';
  }
}
