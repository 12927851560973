import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StationService } from '../service/station.service';
import { FiltersStation } from '../shared/models/filters/filters-station';
import { ResortMap } from '../shared/models/resort/resort-map';
import { ResortsBorders } from '../shared/models/resort/resorts-borders';

@Injectable()
export class MapComponentService {
  constructor(private stationResource: StationService) {}

  getStationsByFilters(filters: FiltersStation): Observable<ResortMap[]> {
    return this.stationResource.getStationsByFilters(filters);
  }

  getAllResortBorders(): Observable<ResortsBorders> {
    return this.stationResource.getAllResortBorders();
  }
}
