import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment, { Moment } from 'moment';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  tap
} from 'rxjs/operators';
import { CartElibSkiPassService } from 'src/app/service/cart-elib-skipass.service';
import { SkiPass } from '../../../shared/models/enum/ski-pass.enum';
import { CustomField } from '../../../shared/models/participant/custom-field';
import { Participant } from '../../../shared/models/participant/participant';
import { Pass } from '../../../shared/models/pass';
import { UserSession } from '../../../shared/models/user-session';

export interface CustomFieldForm {
  id: number;
  label: string;
  placeholder: string;
  control: FormControl<string | null>;
}

@Component({
  selector: 'vsk-form-participant-payment',
  templateUrl: './form-participant-payment.component.html',
  styleUrls: ['./form-participant-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormParticipantPaymentComponent implements OnInit {
  errorLabel = 'Des champs sont incomplets ou incorrectes';
  birthdate: Moment;
  participant: Participant;

  firstNameForm = new FormControl<string>('', Validators.required);
  lastNameForm = new FormControl<string>('', Validators.required);
  birthdateForm = new FormControl<string>('', Validators.required);
  emailForm = new FormControl<string>('', [
    Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')
  ]);
  buyCardForm = new FormControl<boolean>(true, Validators.required);
  skidataForm = new FormControl<string>(
    '',
    Validators.pattern(new RegExp('^01-1614[0-9]{16}-[0-9]$'))
  );
  axessForm = new FormControl<string>(
    '',
    Validators.pattern(new RegExp('^[A-Z0-9]{8}-[A-Z0-9]{3}-[A-Z0-9]{3}$'))
  );
  hasCardToggle = new FormControl(false);
  customFieldsForm: CustomFieldForm[] = [];
  participantForm: FormGroup<ParticipantForm>;
  isKeyCardValid: boolean | null = null;

  @Input() user: UserSession;
  @Input() showError: boolean;
  @Input() skiCard: Pass | undefined;
  @Input() index = 1;

  @Output() participantComplete = new EventEmitter<Participant>();

  constructor(
    private decimalPipe: DecimalPipe,
    private skiPassCartService: CartElibSkiPassService
  ) {
    this.participantForm = new FormGroup<ParticipantForm>({
      firstname: this.firstNameForm,
      lastname: this.lastNameForm,
      birthdate: this.birthdateForm,
      email: this.emailForm,
      cardSkidata: this.skidataForm,
      cardAxess: this.axessForm,
      hasCardToggle: this.hasCardToggle
    });
  }

  @Input() set participantInfo(value: Participant) {
    if (this.participant) {
      return;
    }

    this.participant = value;
    this.firstNameForm.setValue(value.firstname);
    this.lastNameForm.setValue(value.lastname);
    this.emailForm.setValue(value.email);

    if (this.lastNameForm.getRawValue() !== null) {
      this.birthdateForm.setValue(value.birthdate.format('DD/MM/YYYY'));
      this.birthdate = moment(value.birthdate, 'DD/MM/YYYY');
    }

    this.participant.customFields.forEach((customField) => {
      const control = new FormControl<string | null>(
        customField.value,
        Validators.required
      );
      this.customFieldsForm.push({
        id: customField.id,
        label: customField.label + '*',
        placeholder: customField.placeholder,
        control
      });

      control.valueChanges
        .pipe(debounceTime(1000))
        .subscribe(() => this.updateParticipant());
    });

    this.updateParticipant();
  }

  ngOnInit(): void {
    this.keyCardFormObs(this.skidataForm, 'sd');
    this.keyCardFormObs(this.axessForm, 'ta');

    this.birthdateForm.valueChanges.subscribe(
      (date) => (this.birthdate = moment(date, 'DD/MM/YYYY'))
    );

    this.participantForm.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(() => this.updateParticipant());

    this.buyCardForm.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => this.updateParticipant());

    if (
      this.participant?.cardSkidata &&
      this.participant?.cardSkidata.length > 0
    ) {
      this.skidataForm.setValue(this.participant.cardSkidata);
      this.buyCardForm.setValue(false);
    }

    if (this.participant?.cardAxess && this.participant?.cardAxess.length > 0) {
      this.axessForm.setValue(this.participant.cardAxess);
      this.buyCardForm.setValue(false);
    }
  }

  autoFillInformation(): void {
    this.firstNameForm.setValue(this.user.firstname);
    this.lastNameForm.setValue(this.user.lastname);
    this.emailForm.setValue(this.user.email);

    if (this.skiCard?.type === SkiPass.SKIDATA && this.user.cardSkidata) {
      this.skidataForm.setValue(this.user.cardSkidata);
      this.buyCardForm.setValue(false);
    }

    if (this.skiCard?.type === SkiPass.TEAMAXESS && this.user.cardAxess) {
      this.axessForm.setValue(this.user.cardAxess);
      this.buyCardForm.setValue(false);
    }

    if (this.user.birthdate) {
      this.birthdateForm.setValue(this.user.birthdate.format('DD/MM/YYYY'));
      this.birthdate = this.user.birthdate;
    }
  }

  getLabelSki(price: number) {
    return this.buyCardForm.value
      ? `J'achète une carte à ` +
          this.decimalPipe.transform(price, '1.2-2', 'fr') +
          ' €'
      : 'Je recharge ma carte';
  }

  updateParticipant() {
    this.participant = new Participant({
      ...this.participant,
      ...(this.participantForm.getRawValue() as any),
      hasCard: !this.buyCardForm.value,
      birthdate: this.birthdate
    });

    this.participant.customFields = [
      ...this.participant.customFields.map((customField) => {
        const customFieldFilter = this.customFieldsForm.find(
          (cf) => cf.id === customField.id
        );

        if (customFieldFilter) {
          return new CustomField({
            ...customField,
            value: customFieldFilter.control.getRawValue() as string
          });
        }

        return customField;
      })
    ];

    this.participantComplete.emit(this.participant);

    this.showError = !(
      this.participant.isValidPayment() &&
      ((!this.skidataForm.errors && !this.axessForm.errors) ||
        this.buyCardForm.value)
    );
  }

  private keyCardFormObs(form: FormControl<string | null>, type: 'ta' | 'sd') {
    form.valueChanges
      .pipe(
        filter(() => form.valid),
        tap(() => form.setErrors({ loading: true })),
        switchMap((val) =>
          this.skiPassCartService.validateKeyCard(val as string, type)
        )
      )
      .subscribe((res) => {
        this.isKeyCardValid = res.valid;
        if (!this.isKeyCardValid) {
          form.setErrors({
            invalidKeycard: res.errors_string[0]?.message
          });
        } else {
          form.setErrors(null);
        }
      });
  }
}

export interface ParticipantForm {
  firstname: FormControl<string | null>;
  lastname: FormControl<string | null>;
  birthdate: FormControl<string | null>;
  email: FormControl<string | null>;
  cardSkidata: FormControl<string | null>;
  cardAxess: FormControl<string | null>;
  hasCardToggle: FormControl<boolean | null>;
}
