import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { WindowResource } from 'atomic-lib';
import { Observable, filter } from 'rxjs';
import { debounceTime, first, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { URL_PAYMENT, URL_SEARCH_TRIP } from './app-routing.module';
import { AppState } from './app.state';
import { InitCart } from './cart/cart.action';
import { SetWhiteLabel, UpdateFilters } from './filters.action';
import { WebsocketService } from './service/websocket.service';
import { RxjsComponent } from './shared/component/rxjs.component';
import { Alert } from './shared/models/alert';
import { WhiteLabel } from './shared/models/enum/white-label.enum';
import { InformationBanner } from './shared/models/information-banner';

@Component({
  selector: 'vsk-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends RxjsComponent {
  @Select(AppState.lang) lang$: Observable<string>;
  @Select(AppState.alert) alert$: Observable<Alert>;
  @Select(AppState.loading) loading$: Observable<boolean>;
  @Select(AppState.popup) popup$: Observable<boolean>;
  @Select(AppState.banner) banner$: Observable<InformationBanner>;
  alert: Alert | null;
  isLandingPage = false;
  isPaymentPage = false;
  isMapPage = false;
  protected readonly environment = environment;

  constructor(
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private metaService: Meta,
    private store: Store,
    private websocket: WebsocketService,
    public windowResource: WindowResource,
    private gtmService: GoogleTagManagerService,
    router: Router
  ) {
    super();
    this.websocket.init();

    if (environment.production) {
      this.gtmService.addGtmToDom();
    }

    if (!environment.production) {
      this.metaService.addTag({
        name: 'robots',
        content: 'noindex, nofollow, nosnippet, noarchive'
      });
    }

    this.translate.addLangs(['fr', 'en']);
    this.register(
      this.lang$.subscribe((lang) => this.translate.setDefaultLang(lang))
    );
    this.activatedRoute.queryParamMap
      .pipe(
        debounceTime(10),
        first(),
        tap((value) =>
          this.store.dispatch(
            new UpdateFilters(value, router.url.split('?')[0] === '/')
          )
        ),
        tap((value) => this.store.dispatch(new InitCart(value))),
        tap((value: ParamMap) => {
          if (value.has('whiteLabel')) {
            const whiteLabelValue = value.get('whiteLabel') as WhiteLabel;
            this.store.dispatch(new SetWhiteLabel(whiteLabelValue));
          }
        })
      )
      .subscribe();

    router.events.subscribe((val) => {
      if (val instanceof RouterEvent) {
        this.isLandingPage =
          val.url.split('/')[1] === '' ||
          val.url.split('/')[1]?.[0] === '#' ||
          val.url.split('/')[1]?.[0] === '' ||
          val.url.split('/')[1]?.[0] === '?';
        this.isPaymentPage = val.url.indexOf(`/${URL_PAYMENT}`) !== -1;
        this.isMapPage = val.url.indexOf(URL_SEARCH_TRIP) !== -1;
      }
    });

    this.register(
      this.alert$.pipe(filter((alert) => !!alert)).subscribe((alert) => {
        this.alert = alert;
        setTimeout(() => {
          this.alert = null;
        }, alert.timeout);
      })
    );
  }
}
