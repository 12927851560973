<ng-container *ngIf="isHome">
  <div class="content pb-4">
    <div class="wrapper">
      <a *ngIf="!(windowResource.width() | async | screen: 'small-desktop-above')"
         [routerLink]="(isFoncia$ | async) ? null : '/'"
         queryParamsHandling="merge" routerLinkActive="true">
        <img alt="Logo"
             class="m-auto mb-4 h-6"
             src="assets/images/logo-mobile-blanc.svg">

        <hr class="border-t-2 border-dotted w-full mb-0 mt-2">
      </a>
      <div *ngIf="!(windowResource.width() | async | screen: 'small-desktop-above')" class="footer-benefices">
        <p>VeryMountain c’est plus de <b>1000 expériences</b> dans <b>70 stations</b> françaises.</p>
        <ul>
          <li>Une équipe de passionnés située à Lyon</li>
          <li>Une immatriculation Atout France : IMO69230013</li>
          <li>Une garantie financière auprès de Groupama Assurance Crédit et Caution : 4000718190/0</li>
          <li>Une responsabilité civile professionnelle auprès de Groupama : 432374900001</li>
        </ul>

        <div class="flex flex-row gap-2">
          <img alt="localisation icon" class="w-[20px] h-[20px]" height="45"
               src="assets/svg/location-marker.svg">
          <p class="xl:my-auto">
            16 rue Paul Chenavard, Lyon 69001, France
            VeryMountain, 2024 ©
          </p></div>
      </div>
      <div *ngIf="(windowResource.width() | async | screen: 'small-desktop-above')"
           class="flex flex-row justify-between justify-items-center items-center links">

        <a [routerLink]="(isFoncia$ | async) ? null : '/'"
           queryParamsHandling="merge"
           routerLinkActive="true">
          <img alt="Logo" class="h-10" src="../../assets/images/Logo-white.svg">
        </a>

        <div class="flex flex-row gap-6">
          <ng-container *ngIf="(isFoncia$ | async) === false">
            <a routerLink="/about-us" routerLinkActive="true">
              {{ 'footer.about-us' | translate }}
            </a>
            <a routerLink="/terms-and-conditions" routerLinkActive="true">
              {{ 'footer.legal' | translate }}
            </a>
            <a (click)="openCloseContact()" [id]="'id__a-footer-openContact'">
              Contact
            </a>
            <a routerLink="/recherche-stations" routerLinkActive="true">
              {{ 'footer.search-resorts' | translate }}
            </a>
          </ng-container>
          <a queryParamsHandling="merge" routerLink="/selling-conditions" routerLinkActive="true">
            {{ 'footer.selling-conditions' | translate }}
          </a>
          <a queryParamsHandling="merge" routerLink="/terms-of-use" routerLinkActive="true">
            {{ 'footer.terms-of-use' | translate }}
          </a>
          <a href="javascript:openAxeptioCookies()">
            Modifier vos préférences de cookies
          </a>
        </div>
      </div>
      <hr class="border-t-2 border-dotted">

      <div class="benefices-icons-container">
        <div *ngIf="(windowResource.width() | async | screen: 'small-desktop-above')" class="footer-benefices">
          <p>VeryMountain c’est plus de <b>7000 expériences</b> dans <b>110 stations</b> françaises.</p>
          <ul>
            <li>Une équipe de passionnés située à Lyon</li>
            <li>Une immatriculation Atout France : IMO69230013</li>
            <li>Une garantie financière auprès de Groupama Assurance Crédit et Caution : 4000718190/0</li>
            <li>Une responsabilité civile professionnelle auprès de Groupama : 432374900001</li>
          </ul>

          <div class="flex flex-row gap-2">
            <img alt="localisation icon" class="w-[20px] h-[20px]" height="45"
                 src="assets/svg/location-marker.svg">
            <p class="xl:my-auto">
              16 rue Paul Chenavard, Lyon 69001, France
              VeryMountain, 2024 ©
            </p></div>
        </div>
        <div *ngIf="!(windowResource.width() | async | screen: 'small-desktop-above')"
             class="flex flex-col gap-2">
          <ng-container *ngIf="(isFoncia$ | async) === false">
            <a routerLink="/about-us" routerLinkActive="true">
              {{ 'footer.about-us' | translate }}
            </a>
            <a routerLink="/terms-and-conditions" routerLinkActive="true">
              {{ 'footer.legal' | translate }}
            </a>
            <a (click)="openCloseContact()" [id]="'id__a-footer-openContact'">
              Contact
            </a>
            <a routerLink="/recherche-stations" routerLinkActive="true">
              {{ 'footer.search-resorts' | translate }}
            </a>
          </ng-container>
          <a queryParamsHandling="merge" routerLink="/selling-conditions" routerLinkActive="true">
            {{ 'footer.selling-conditions' | translate }}
          </a>
          <a queryParamsHandling="merge" routerLink="/terms-of-use" routerLinkActive="true">
            {{ 'footer.terms-of-use' | translate }}
          </a>
          <a href="javascript:openAxeptioCookies()">
            Modifier vos préférences de cookies
          </a>
        </div>
        <section class="icons-payment-sociaux">
          <div class="flex flex-row md:flex-1 gap-4 justify-end">
            <div class="flex flex-row xl:justify-end lg:justify-end
                    icons-payment">
              <img alt="Atout france" atcLazyLoad class="h-[38px]"
                   height="70"
                   src="assets/images/logo-atoutfrance.svg" width="70">
              <img alt="Carte bleue" atcLazyLoad class="h-[38px]" height="70"
                   src="assets/images/logo-cb.svg"
                   width="70">
              <img alt="Carte bleue" atcLazyLoad class="h-[38px]" height="70"
                   src="assets/images/logo-mastercard.svg"
                   width="70">
              <img alt="Carte bleue" atcLazyLoad class="h-[38px]" height="70"
                   src="assets/images/logo-visa.svg"
                   width="70">
            </div>
          </div>

          <!-- TrustBox widget - Review Collector -->
          <div class="trustpilot-widget" data-businessunit-id="654e6c1a7917dadb05bcd02e" data-locale="fr-FR"
               data-style-height="52px" data-style-width="100%" data-template-id="56278e9abfbbba0bdcd568bc">
            <a href="https://fr.trustpilot.com/review/verymountain.fr" rel="noopener" target="_blank">Trustpilot</a>
          </div>
          <!-- End TrustBox widget -->

          <div class="flex flex-row xl:justify-end gap-4 lg:justify-end
                    ">
            <a href="https://www.instagram.com/verymountain_officiel/" target="_blank">
              <img alt="Instagram" atcLazyLoad
                   class="w-[24px] h-[24px]" height="45" src="assets/svg/insta-white.svg">
            </a>
            <a href="https://www.facebook.com/verymountain" target="_blank">
              <img alt="Facebook" atcLazyLoad class="w-[24px] h-[24px]"
                   height="45" src="/assets/svg/facebook-white.svg">
            </a>
            <a href="https://www.linkedin.com/company/verymountain/" target="_blank">
              <img alt="Facebook" atcLazyLoad class="w-[24px] h-[24px]"
                   height="45" src="/assets/svg/linkedin-white.svg">
            </a>
            <a href="https://www.youtube.com/@verymountain" target="_blank">
              <img alt="Facebook" atcLazyLoad class="w-[24px] h-[24px]"
                   height="45" src="/assets/svg/youtube-white.svg">
            </a>

          </div>

        </section>
      </div>
    </div>
  </div>
  <vsk-contact (closeChanged)="openCloseContact()" *ngIf="openContact"></vsk-contact>

</ng-container>

<ng-container *ngIf="!isHome">

  <div class="content-mini">
    <div class="wrapper-mini max-w-[1350px] flex flex-col justify-between
        w-[100%] mx-auto px-8 xl:px-0 lg:gap-[30px] md:gap-[2rem] gap-[2.5rem] py-8
       ">

      <div *ngIf="!(windowResource.width() | async | screen: 'small-desktop-above')"
           class="links-mini-container flex flex-row md:flex-col sm:flex-col">
        <a
          [routerLink]="(isFoncia$ | async) ? null : '/'"
          queryParamsHandling="merge" routerLinkActive="true">
          <img alt="Logo"
               class="m-auto mb-4 h-6"
               src="assets/images/logo-mobile-blanc.svg">
        </a>
        <div class="flex flex-row gap-6 links-mini">
          <ng-container *ngIf="(isFoncia$ | async) === false">
            <a routerLink="/about-us" routerLinkActive="true">
              {{ 'footer.about-us' | translate }}
            </a>
            <a routerLink="/terms-and-conditions" routerLinkActive="true">
              {{ 'footer.legal' | translate }}
            </a>
            <a (click)="openCloseContact()" [id]="'id__a-footer-openContact'">
              Contact
            </a>
            <a routerLink="/recherche-stations" routerLinkActive="true">
              {{ 'footer.search-resorts' | translate }}
            </a>
          </ng-container>
          <a queryParamsHandling="merge" routerLink="/selling-conditions" routerLinkActive="true">
            {{ 'footer.selling-conditions' | translate }}
          </a>
          <a queryParamsHandling="merge" routerLink="/terms-of-use" routerLinkActive="true">
            {{ 'footer.terms-of-use' | translate }}
          </a>
          <a href="javascript:openAxeptioCookies()">
            Modifier vos préférences de cookies
          </a>
        </div>
      </div>

      <div *ngIf="(windowResource.width() | async | screen: 'small-desktop-above')"
           class="flex flex-row justify-between justify-items-center items-center">

        <a [routerLink]="(isFoncia$ | async) ? null : '/'"
           queryParamsHandling="merge"
           routerLinkActive="true">
          <img alt="Logo" class="h-10 max-w-[12rem]" src="../../assets/images/Logo-white.svg">
        </a>

        <div class="flex flex-row gap-6 links-mini">
          <ng-container *ngIf="(isFoncia$ | async) === false">
            <a routerLink="/about-us" routerLinkActive="true">
              {{ 'footer.about-us' | translate }}
            </a>
            <a routerLink="/terms-and-conditions" routerLinkActive="true">
              {{ 'footer.legal' | translate }}
            </a>
            <a (click)="openCloseContact()" [id]="'id__a-footer-openContact'">
              Contact
            </a>
            <a routerLink="/recherche-stations" routerLinkActive="true">
              {{ 'footer.search-resorts' | translate }}
            </a>
          </ng-container>
          <a queryParamsHandling="merge" routerLink="/selling-conditions" routerLinkActive="true">
            {{ 'footer.selling-conditions' | translate }}
          </a>
          <a queryParamsHandling="merge" routerLink="/terms-of-use" routerLinkActive="true">
            {{ 'footer.terms-of-use' | translate }}
          </a>
          <a href="javascript:openAxeptioCookies()">
            Modifier vos préférences de cookies
          </a>
        </div>


      </div>
      <hr class="border-t-2 border-dotted">
      <section class="flex flex-row align-center justify-between pt-[.5rem]
            sm:flex-col sm:gap-4">
        <div class="flex flex-row xl:justify-end gap-4 lg:justify-end
                    ">
          <a href="https://www.instagram.com/verymountain_officiel/" target="_blank">
            <img alt="Instagram" atcLazyLoad
                 class="w-[24px] h-[24px]" height="45" src="assets/svg/insta-white.svg">
          </a>
          <a href="https://www.facebook.com/verymountain" target="_blank">
            <img alt="Facebook" atcLazyLoad class="w-[24px] h-[24px]"
                 height="45" src="/assets/svg/facebook-white.svg">
          </a>
          <a href="https://www.linkedin.com/company/verymountain/" target="_blank">
            <img alt="Facebook" atcLazyLoad class="w-[24px] h-[24px]"
                 height="45" src="/assets/svg/linkedin-white.svg">
          </a>
          <a href="https://www.youtube.com/@verymountain" target="_blank">
            <img alt="Facebook" atcLazyLoad class="w-[24px] h-[24px]"
                 height="45" src="/assets/svg/youtube-white.svg">
          </a>
        </div>

        <!-- TrustBox widget - Review Collector -->
        <div class="trustpilot-widget" data-businessunit-id="654e6c1a7917dadb05bcd02e" data-locale="fr-FR"
             data-style-height="52px" data-style-width="100%" data-template-id="56278e9abfbbba0bdcd568bc">
          <a href="https://fr.trustpilot.com/review/verymountain.fr" rel="noopener" target="_blank">Trustpilot</a>
        </div>
        <!-- End TrustBox widget -->


        <div class="flex flex-row md:flex-1 gap-4 justify-end sm:justify-start">
          <div class="flex flex-row xl:justify-end lg:justify-end sm:justify-start
                    icons-payment">
            <img alt="Atout france" atcLazyLoad class="h-[38px]"
                 height="70"
                 src="assets/images/logo-atoutfrance.svg" width="70">
            <img alt="Carte bleue" atcLazyLoad class="h-[38px]" height="70"
                 src="assets/images/logo-cb.svg"
                 width="70">
            <img alt="Carte bleue" atcLazyLoad class="h-[38px]" height="70"
                 src="assets/images/logo-mastercard.svg"
                 width="70">
            <img alt="Carte bleue" atcLazyLoad class="h-[38px]" height="70"
                 src="assets/images/logo-visa.svg"
                 width="70">
          </div>
        </div>
      </section>
    </div>
  </div>


  <vsk-contact (closeChanged)="openCloseContact()" *ngIf="openContact"></vsk-contact>

</ng-container>
