import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Select, Store } from '@ngxs/store';
import { Moment } from 'moment';
import { Observable, combineLatest } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  tap
} from 'rxjs/operators';
import { AppState } from '../app.state';
import { Bounds, GeoBoundsActiveChange } from '../filters.action';
import { FiltersState } from '../filters.state';
import { WindowResource } from '../resource/window.resource';
import { FiltersService } from '../service/filters.service';
import { MetaDescriptionService } from '../service/meta-description.service';
import { ResortLabelService } from '../service/resort-label.service';
import { TagsService } from '../service/tags.service';
import { RxjsComponent } from '../shared/component/rxjs.component';
import { Activity } from '../shared/models/activity/activity';
import { FiltersStation } from '../shared/models/filters/filters-station';
import { FiltersTripInfo } from '../shared/models/filters/filters-trip-info';
import { Participant } from '../shared/models/participant/participant';
import { Period } from '../shared/models/period';
import { ResortLabel } from '../shared/models/resort/resort-label';
import { MarkerVerySKI, ResortMap } from '../shared/models/resort/resort-map';
import { Tag } from '../shared/models/tag';
import { MapComponentService } from './map.component.service';

@Component({
  selector: 'vsk-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent
  extends RxjsComponent
  implements OnInit, AfterViewInit
{
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild('marker', { static: false }) markerMap: MapMarker;

  @Select(FiltersState.participants) participants$: Observable<Participant[]>;
  @Select(FiltersState.period) period$: Observable<Period>;
  @Select(FiltersState.filtersStation)
  filtersStation$: Observable<FiltersStation>;
  @Select(FiltersState.activities) activities$: Observable<Activity[]>;
  @Select(FiltersState.selectedActivities) selectedActivities$: Observable<
    Activity[]
  >;
  @Select(FiltersState.startDate) startDate$: Observable<Moment>;
  @Select(FiltersState.endDate) endDate$: Observable<Moment>;
  @Select(AppState.popup) popup$: Observable<boolean>;

  refreshOnMoveMap: FormControl<boolean | null> = new FormControl<boolean>(
    true
  );
  filtersMap$: Observable<FiltersTripInfo>;
  tags$: Observable<Tag[]>;
  resortLabels$: Observable<ResortLabel[]>;
  markers: any[] = [];
  stationPinpoint: ResortMap | undefined;
  resortsMap: ResortMap[];
  remainingStations: ResortMap[];
  allStations: ResortMap[];
  totalStationsFiltered: number;
  filters: FiltersStation;
  pageNumber = 0;
  loadingStation = true;
  showMap = false;
  fitBoundsInit = false;
  nbNights = 0;

  // Map
  zoom = 7;
  center: google.maps.LatLngLiteral = {
    lat: 45.052825927734375,
    lng: 2.7113842964172363
  };
  options: MapConfiguration = {
    scaleControl: true,
    streetViewControl: false,
    fullscreenControl: false,
    zoomControl: this.windowResource.isDesktop(),
    mapTypeControl: false,
    maxZoom: 15,
    minZoom: 6,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    clickableIcons: false,
    styles: [
      {
        featureType: 'road',
        elementType: 'all',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      }
    ]
  };

  constructor(
    private mapComponentService: MapComponentService,
    public windowResource: WindowResource,
    private tagsService: TagsService,
    private resortLabelService: ResortLabelService,
    private filtersService: FiltersService,
    private store: Store,
    private metaDescriptionService: MetaDescriptionService
  ) {
    super();
    if (!windowResource.isDesktop()) {
      this.zoom = 6;
    }
  }

  ngAfterViewInit(): void {
    this.map.zoomChanged.pipe(debounceTime(800)).subscribe(() => {
      this.zoom = this.map.getZoom() as number;
      this.boundsChanged();
      this.placeMarkersOnMap();
    });
  }

  ngOnInit(): void {
    this.filters = this.store.selectSnapshot(FiltersState.filtersStation);

    this.register(
      this.period$
        .pipe(
          filter((period) => !!period && period.isValid),
          debounceTime(50)
        )
        .subscribe((period) => {
          this.nbNights = period.endDate.diff(period.startDate, 'day');

          const title = `Trouvez le séjour en montagne qui vous convient · VeryMountain`;
          const description = `Réservez l'intégralité de votre séjour en montagne : Choisissez parmi plus de 159 destinations, 10.000 hébergements et 5.000 expériences à vivre en montagne.`;
          const url = 'recherche-sejours';
          this.metaDescriptionService.updateMeta(title, description, url);
        })
    );

    this.tags$ = this.tagsService.getTags();
    this.resortLabels$ = this.resortLabelService.getLabels();

    // Handle filters change
    this.loadingStation = true;
    this.register(
      this.filtersStation$
        .pipe(
          debounceTime(50),
          distinctUntilChanged((prev, curr) => prev.id === curr.id),
          tap(() => (this.loadingStation = true)),
          filter((filters) => filters.participantsAreValid()),
          switchMap((filtersStation) => {
            this.filtersMap$ =
              this.filtersService.getFiltersMap(filtersStation);
            return combineLatest([
              this.mapComponentService.getStationsByFilters(filtersStation),
              this.mapComponentService.getAllResortBorders()
            ]);
          }),
          tap(([resorts, resortBorders]) => {
            this.totalStationsFiltered = resorts.length;
            this.resortsMap = resorts;
            this.allStations = resortBorders.stations;
            this.markers = resorts.map((station) => station.marker);
            this.placeMarkersOnMap();
            this.remainingStations = this.allStations.filter(
              (station) =>
                !resorts.some(
                  (station2: ResortMap) => station2.id === station.id
                )
            );
          })
        )
        .subscribe(() => (this.loadingStation = false))
    );
  }

  overMarker(marker: google.maps.Marker): void {
    if (marker) {
      const label: google.maps.MarkerLabel =
        marker?.getLabel() as google.maps.MarkerLabel;
      marker.setLabel({
        color: 'white',
        text: label.text,
        fontWeight: '500',
        fontSize: '11px',
        className: 'pinpoint-hover',
        fontFamily: 'General'
      });

      marker.set('options', { zIndex: 1000 });
      marker.set('zIndex', 1000);
    }
  }

  outMarker(marker: google.maps.Marker): void {
    if (marker) {
      const label: google.maps.MarkerLabel =
        marker?.getLabel() as google.maps.MarkerLabel;
      marker.setLabel({
        color: '#1f1f1f',
        text: label.text,
        fontWeight: '500',
        fontSize: '11px',
        className: 'pinpoint',
        fontFamily: 'General'
      });

      marker.set('options', { zIndex: 100 });
      marker.setZIndex(100);
    }
  }

  openInfoWindow(marker: MarkerVerySKI, markerMap: MapMarker): void {
    if (marker.station === this.stationPinpoint) {
      this.stationPinpoint = undefined;
      this.infoWindow.close();
    } else {
      this.stationPinpoint = marker.station;
      this.infoWindow.open(markerMap);
    }
  }

  hideMarker() {
    this.stationPinpoint = undefined;
  }

  changeView() {
    this.showMap = !this.showMap;
  }

  boundsChanged() {
    const bounds = this.map.getBounds()?.toJSON();

    if (bounds && this.refreshOnMoveMap.value) {
      this.store.dispatch(
        new GeoBoundsActiveChange(this.refreshOnMoveMap.value)
      );
      this.store.dispatch(new Bounds(bounds));
    }
  }

  protected placeMarkersOnMap() {
    if (!this.map) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();
    this.markers.forEach((marker) => {
      const position = (marker as any).position;
      if (position.lat() !== 0 || position.lng() !== 0) {
        bounds.extend(position);
      }
    });

    if (!this.refreshOnMoveMap.value && !this.fitBoundsInit) {
      this.map.fitBounds(bounds);
      this.fitBoundsInit = true;
    }
  }
}

export interface MapConfiguration extends google.maps.MapOptions {
  readonly mapId?: string;
}
