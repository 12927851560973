export class CopyCart {
  itemsAccommodation: string[] = [];
  itemsActivity: string[] = [];
  itemsEquipment: number[] = [];
  session: string;

  constructor(obj: Partial<CopyCart>) {
    Object.assign(this, obj);
  }
}
