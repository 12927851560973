import moment, { Moment } from 'moment';
import { Activity } from '../activity/activity';
import { Experience } from '../activity/experience';
import { Origin } from '../enum/origin.enum';
import { Package } from '../package/package';
import { RegisterTimeSlot } from '../register-time-slot';

export class TimeSlot {
  id: number;
  timeSlotModelId: number;
  origin: Origin;
  activity: Activity;
  dateStart: Moment;
  dateEnd: Moment;
  hourStart: Moment;
  hourEnd: Moment;
  slotsAvailable: number;
  remainingSlots: number;
  activityPartnerId?: number;
  experience?: Experience;
  registered?: RegisterTimeSlot[];
  packages: Package[] = [];

  constructor(obj: Partial<TimeSlot>) {
    Object.assign(this, obj);
    this.activity = new Activity(this.activity);
    this.experience = new Experience({ ...this.experience });
    this.dateStart = moment(this.dateStart, 'YYYY-MM-DD');
    this.dateEnd = moment(this.dateStart.clone(), 'YYYY-MM-DD');
    this.hourStart = moment(this.hourStart, 'HH:mm:ss');
    this.hourEnd = moment(this.hourEnd, 'HH:mm:ss');

    if (this.packages) {
      this.packages = this.packages.map((pack) => new Package({ ...pack }));
    }
  }
}
