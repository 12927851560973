import { AccommodationType } from '../accommodation/accommodation-type.enum';
import { FilterCount } from './filter-count';

export class FiltersInfoAccommodation {
  resorts: FilterCount<number>[] = [];
  regions: FilterCount<number>[] = [];
  types: FilterCount<AccommodationType>[] = [];
  pool: number;
  spa: number;
  sauna: number;
  hammam: number;
  balconyTerrace: number;
  tv: number;
  chimney: number;
  bbq: number;
  pmr: number;
  parking: number;
  animalsAdmitted: number;
  wifi: number;
  childrenClub: number;
  minPrice: number;
  maxPrice: number;
  total: number;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}
